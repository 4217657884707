<div class="main-dialog-div-conitainer" style="background-color: white;">
    <div style="text-align: center;">
        <img src="../../../assets/img/omE5s3.tif.svg" alt="">
    </div>
    <div style="text-align: center; margin: 20px 0px;">
        <div>Are you sure want to</div>
        <div style="font-weight: 500;">delete this statement pair</div>
    </div>
    <div style="display: flex; justify-content: center ; gap: 20px;">

        <input style="width: 17%;" type="image" src="../../../assets/img/Group 7967.svg" (click)="close(true)">
        <input style="width: 17%;" type="image" src="../../../assets/img/Group 7968.svg" (click)="close(false)">
    </div>
</div>
<style>
    ::ng-deep .mat-dialog-container{
        padding: 0 !important;
        border-radius: 10px;
    }
    .main-dialog-div-conitainer{
        border: 2px solid red;
        border-radius: 10px;
        background-color: white;
        padding: 20px;
    }
</style>