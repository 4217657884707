<div style="height: 100vh;">
  <button (click)="goBack()"> Back</button>

  <!-- <app-header></app-header> -->
  <div class="detailedGames" *ngIf="selectedExplore">
    <div class="leftContainer">
      <div class="individualSelections" [ngClass]="{highlightdigital:selectedExplore=='digital'} "
        (click)="selectedExploreGame('digital')">
        <img src="../../../assets/img/digitalGame.svg"
          [ngClass]="{rounded:selectedExplore=='digital',circle:selectedExplore!='digital'} ">
        <b>Digital Games</b>
        <i class="arrow right arrow-digital" *ngIf="selectedExplore=='digital'"></i>
      </div>
      <div class="individualSelections" [ngClass]="{highlightphysical:selectedExplore=='physical'} "
        (click)="selectedExploreGame('physical')">
        <img src="../../../assets/img/physicalGame.svg"
          [ngClass]="{rounded:selectedExplore=='physical',circle:selectedExplore!='physical'} ">
        <b>Physical Games</b>
        <i class="arrow right arrow-physical" *ngIf="selectedExplore=='physical'"></i>
      </div>
  
    </div>
    <div class="rightContainer" [ngClass]="selectedExplore=='physical'?'bg-green':selectedExplore=='questico'?'bg-yellow':''">
      <div>
        <div class="myGameContainer" *ngIf="selectedExplore=='digital'">

          <div class="myGames" *ngFor="let game of digitalGames">
            <div class="myGameImg" style="height: 250px;width: 25%;">
              <img src="../../../../assets/img/valuecontinuum.svg" *ngIf="game.id == 1">
              <img src="../../../../assets/img/explore_bn.svg" *ngIf="game.id == 2">
              <img src="../../../../assets/img/explore_wh.svg" *ngIf="game.id == 3">
              <img src="../../../../assets/img/animalFarm.svg" *ngIf="game.id == 4">
              <img src="../../../../assets/img/animalFarm.svg" *ngIf="game.id == 5">
              <img src="../../../../assets/img/explore_bp.svg" *ngIf="game.id == 6">
            </div>
            <div style="max-width: 27%;text-align: justify;">
              Virtual & Hybrid learning sessions can be made more dynamic and impactful with these powerful games.
              After you schedule the game, Your session dashboard will give you access to the game resources, player
              link to share with your cohort and the Game control room.
            </div>
            <div class="myGameDate">
              <div class="gameResurce" style="margin-top:1rem;padding: 16px 33px;max-width: 15rem;font-size: 19px;">
                Games Resource
              </div>
            </div>
          </div>

        </div>
        <div class="myGameContainer" *ngIf="selectedExplore=='physical'">
          <div class="myGames" *ngFor="let game of physicalGames">
            <div class="myGameImg" style="height: 250px;width: 25%;">
              <img src="../../../../assets/img/human_matrix.jpg" *ngIf="game.id == 8">
              <img src="../../../../assets/img/string_theory.jpg" *ngIf="game.id == 9">
              <img src="../../../../assets/img/strom.jpg" *ngIf="game.id == 10">
              <img src="../../../../assets/img/assembley_line.jpg" *ngIf="game.id == 11">
              <img src="../../../../assets/img/magic_mats.jpg" *ngIf="game.id == 12">
              <img src="../../../../assets/img/stander.jpg" *ngIf="game.id == 13">
            </div>
            <div style="max-width: 27%;text-align: justify;">
              Virtual & Hybrid learning sessions can be made more dynamic and impactful with these powerful games.
              After you schedule the game, Your session dashboard will give you access to the game resources, player
              link to share with your cohort and the Game control room.
            </div>
            <div class="myGameDate">
              <div class="gameResurce" style="margin-top:1rem;padding: 16px 33px;max-width: 15rem;font-size: 19px;">
                Games Resource
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>