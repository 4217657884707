<div class="container">
    <div class="img-div-container">
        <img style="height: auto;" src="../../../assets/img/omE5s3.tif.svg">
        <img [src]="src" alt="" style="width: 4.2rem;" [ngClass]="flag?'borderCss':''">
    </div>
    <div style=" margin-bottom: 10px;">
        <p>Are you sure you want to <b>delete this game</b> from this session?</p>
    </div>
    <div class="d-flex" class="btns">
        <input type="image" width="35%" src="../../../assets/img/Group 7967.svg" (click)="dlt()">
        <input type="image" width="35%" src="../../../assets/img/no.svg" (click)="close()">
    </div>
</div>
<style>
    ::ng-deep .mat-dialog-container {
        box-shadow: 0px 3px 6px #00000029;
        opacity: 1;
        background-color: #ffff;
        padding: 0;

    }

    .container {
        border: 2px solid #FF0000;
        border-radius: 10px;
        padding: 68px 21px;
        display: flex;
        align-content: center;
        align-items: center;
        text-align: center;
        flex-direction: column;
    }

    .img-div-container {
        margin-bottom: 10px;
        display: flex;
        align-items: flex-end;
        gap: 12px;
        padding: 0 17px 0 1px;
        border-bottom: 1px solid #aaaaaa;
    }
    .borderCss{
        border: 1px solid #b9b9b9;
        border-radius: 10px;
    }
    .btns {
        width: 5 0%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
    }
</style>