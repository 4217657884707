<div style="height: 100vh;">
    <app-header></app-header>
    <div class="content_admin_priview_fac" *ngIf="contentPreview">
        <span>Quit Preview Mode</span>
        <img src="../../../assets/close_fac_view.svg" alt="" style="width: 34px;cursor: pointer;"
            (click)="closePreview()">
    </div>
    <div class="main-container">
        <div class="side-nav-bar" [ngClass]="gameType == 'digital'?'side-dg':gameType == 'physical'?'side-py':''">
            <div class="side-nav-first-div" *ngIf="!contentPreview">
                <img src="../../../assets/img/arrow_back_ios_FILL0_wght400_GRAD0_opsz48.svg" alt="" (click)="goBack()">
                Game Resources
            </div>
            <div class="side-nav-dtl-div">
                <div>{{gameName}}</div>
                <div>{{gameDis}}</div>
            </div>
            <div>
                <div class="legend" *ngFor="let legend of legendsData, index as i">
                    <div class="legend_container"
                        [ngClass]="{'legend_active_css': legend.toggleFlag || (legend.name == 'Gallery' && gallaryFlag)}">
                        <div class="legend_header" (click)="handleLegendClick(legend)">
                            <span>{{legend.name}}</span>
                            <span class="noOffile_span" *ngIf="legend.name != 'Gallery'">
                                <span>{{legend.resources.length}} files</span>
                                <img src="../../../assets/files_icon.png" alt="">
                            </span>
                            <span *ngIf="legend.name == 'Gallery'">
                                <img src="../../../assets/gallary_icon.svg" alt="" style="width: 26px;">
                            </span>
                        </div>
                        <div class="legend_body" *ngIf="legend.toggleFlag && legend.name != 'Gallery'">
                            <div class="legend_body_file_main_div" *ngFor="let item of legend.resources, index as i"
                                [ngClass]="{'activeFileCss': (currentResouce.resource_id == item.resource_id && currentResouce.resource_id != null)}"
                                (click)="showResouceFile(item,legend)">
                                <div class="container">
                                    <!-- <img style="height:20px;" src="../../../../assets/play_btn.svg" alt=""> -->
                                    <img src="../../../assets/img/video-icon.svg" alt="" srcset="" style="height:20px;"
                                        *ngIf="item.type == 'video'">
                                    <img src="../../../assets/img/pdf-icon.svg" alt="" srcset="" style="height:20px;"
                                        *ngIf="item.type == 'pdf'">
                                    <img src="../../../assets/img/ppt_icon.png" alt="" srcset="" style="height:20px;"
                                        *ngIf="item.type == 'ppt'">
                                    <img src="../../../assets/img/image_icon.svg" alt="" srcset="" style="height:20px;"
                                        *ngIf="item.type == 'images'">
                                    <div class="text">{{item.name}}</div>
                                </div>
                                <div
                                    *ngIf="currentResouce.resource_id == item.resource_id && currentResouce.is_downloadable == 1">
                                    <img src="../../../assets/dwonload_icon_green_bg.svg" alt=""
                                        (click)="download(item)" class="download_icon">
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                 <!-- <div *ngFor="let item of allFiles;let i = index" class="file-multi-div"
                    [ngClass]="item.fileName == fileName ?'activeCss':''" (click)="handleFileChange(i,item.fileName )">
                    <div>
                        <li style="font-weight: 500;">{{item.fileName}}</li>
                    </div>
                    <div *ngIf="item.type == 'video'"><img src="../../../assets/img/video-icon.svg" alt="" srcset=""
                            style="width: 25px;"></div>
                    <div *ngIf="item.type == 'pdf'"><img src="../../../assets/img/pdf-icon.svg" alt="" srcset=""
                            style="width: 25px;"></div>
                    <div *ngIf="item.type == 'ppt'"><img src="../../../assets/img/ppt_icon.png" alt="" srcset=""
                            style="width: 25px;"></div>
                    <div *ngIf="item.type == 'img'"><img src="../../../assets/img/ppt_icon.png" alt="" srcset=""
                            style="width: 25px;"></div>
                </div> -->
            </div>
        </div>
        <div class="width75" style="position: absolute;margin-left: 24vw; z-index: 1;" *ngIf="spinLoader">
            <div class="spinerMain">
                <div class="spin"></div>
            </div>
        </div>
        <div class="main-content width75" [ngClass]="zoomFlag?'grid-1':''"
            *ngIf="currentResouce.type == 'pdf' && !gallaryFlag">
            <div *ngIf="!zoomFlag" class="main-content-head">
                {{currentResouce.name}}
            </div>
            <div>
                <pdf-viewer [src]="currentResouce.main_resources_file" [rotation]="0" [original-size]="false"
                    [show-all]="true" [fit-to-page]="false" [zoom]="0" [zoom-scale]="zoomScale" [stick-to-page]="true"
                    [render-text]="true" [external-link-target]="'blank'" [autoresize]="true" [show-borders]="true"
                    class="pdf-viewer" (after-load-complete)="spinLoader=false"></pdf-viewer>

            </div>
            <div *ngIf="!zoomFlag" style="text-align: end;padding-right: 1rem;">
                <img src="../../../assets/img/zoom-out-fixed-svgrepo-com.svg" (click)="zoom()" alt="" class="zoom-img">
            </div>
            <div *ngIf="zoomFlag" style="position: absolute;right: 15px;">
                <img src="../../../assets/img/zoom-in-fixed-svgrepo-com.svg" (click)="zoom()" alt="" class="zoom-img">
            </div>
        </div>
        <div *ngIf="currentResouce.type == 'video' && !gallaryFlag" class="video-main-div width75">
            <!-- <div style="display: flex;justify-content: space-between;align-items: center;padding-right: 14px;"> -->
                <div class="file_name">
                    {{currentResouce.name}}
                </div>
                
            <!-- </div> -->
            <div class="video_div" style="text-align: center;">
                <video controls [muted]="'muted'" id="videoPlayer" controlsList="nodownload" disablePictureInPicture
                    #videoPlayer style="height: 80vh;max-width: 75vw;" autoplay [src]="currentResouce.main_resources_file"
                    type="video/mp4">
                    Browser not supported
                </video>
            </div>
            <div style="margin: 0 10px;color: #4f0202;">
                <span>Note:-</span>
                <span>&nbsp;</span>
                <span>Kit featured in the videos are representational. The updated kits you receive may differ slightly in appearance.</span>
            </div>
        </div>
        <div *ngIf="currentResouce.type == 'ppt' && !gallaryFlag" class="video-main-div width75">
            <div class="file_name">
                {{currentResouce.name}}
            </div>
            <div class="ppt_div">

                <pdf-viewer [src]="currentResouce.html_resources_file" [rotation]="0" [original-size]="false"
                    [show-all]="true" [fit-to-page]="true" [zoom]="0" [zoom-scale]="zoomScale"  [stick-to-page]="true"
                    [render-text]="true" [external-link-target]="'blank'" [autoresize]="true" [show-borders]="true"
                    class="pdf-viewer" (after-load-complete)="spinLoader = false"></pdf-viewer>
            </div>
            <iframe [(src)]="tempDownSrc" frameborder="0" style="position: absolute; visibility: hidden;"
                *ngIf="tempDownSrc"></iframe>
        </div>
        <div *ngIf="currentResouce.type == 'images' && !gallaryFlag" class="video-main-div width75">
            <div class="file_name">
                {{currentResouce.name}}
            </div>
            <div class="ppt_div">
                <img [(src)]="currentResouce.main_resources_file" alt="" srcset="" style="width: 98%;
                margin-left: 1%;">
            </div>
            <!-- <a id="image_download" [hidden]="true" download="image.png"></a> -->
            <!-- <img src="../../../assets/img/download-cloud-svgrepo-com.svg" alt="" (click)="download(src)"
                class="pptdownload"> -->
        </div>
        <div *ngIf="gallaryFlag" class="width75">
            <div class="file_name">Gallery</div>
            <div class="gallary_img_main_div" *ngIf="!imgSliderFlag">
                <div *ngFor="let item of gallaryImgSrcArray;let i = index">
                    <img [src]="item.main_resources_file" alt="" (click)="showImgSlider(i,gallaryImgSrcArray)">
                    <div style="text-align: center;font-weight: 500;">{{item.name}}</div>
                </div>
            </div>
            <div *ngIf="imgSliderFlag" class="imgSliderMainDiv">
                <div>
                    <div class="arrow_btn_div" (click)="slideLeft()">
                        <img src="../../../assets/img/arrow_back_ios_FILL0_wght400_GRAD0_opsz48.svg" alt=""
                            class="left_arrow_slider">
                    </div>
                </div>
                <div style="flex:1;max-height: 100%;width: 100%;">
                    <div class="img_slider_main_div">
                        <img [src]="gallaryImgSrcArray[sliderIndex].main_resources_file" alt="">
                        <img src="../../../assets/resize.svg" alt="" class="resizeGalary" (click)="resize()">
                    </div>
                    <div style="text-align: center; font-weight: 500;">{{gallaryImgSrcArray[sliderIndex].name}}</div>
                </div>
                <div>
                    <div class="arrow_btn_div" (click)="slideRight()">
                        <img src="../../../assets/img/arrow_back_ios_FILL0_wght400_GRAD0_opsz48.svg" alt=""
                            class="right_arrow_slider">
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>