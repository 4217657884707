import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DashboardService } from 'src/app/Shared/Services/Dashboard.service';
import { DataSharingServiceService } from 'src/app/services/data-sharing-service.service';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-big-pic-dashboard',
  templateUrl: './big-pic-dashboard.component.html',
  styleUrls: ['./big-pic-dashboard.component.css']
})
export class BigPicDashboardComponent implements OnInit {

  constructor(
    private dialog: MatDialog,
    private toster: ToastrService,
    private httpSv: HttpService,
    private dataShare: DashboardService,
    private router: Router
  ) { }

  ngOnInit(): void {
    if (this.dataShare.selectedSession == null) {
      this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
      this.router.navigate(['/scheduled'])
    }
    else {
      this.getGameData(this.dataShare.selectedSession.id)

    }

   
  }
  previewSrc: any = null
  codes: any = []
  playLink: any = null
  getGameData(id:any) {
    this.httpSv.getBpGameDtls(id).subscribe((res: any) => {
      if (res['status'] == true) {
        const temp = res['result'][0]
        this.previewSrc = temp.image
        this.codes = temp.codes
        this.playLink = temp.player_link
      }
    })
  }
}
