<div style="height: 100vh;">
    <app-header [oneHeader]="headerTxt" [adminFlag]="adminFlag"></app-header>
    <div class="main-div-container" id="mainDivContainer">
        <div *ngIf="disabledFlag" style="display: flex;justify-content: end;">
            <div class="editBtn" (click)="editHandler()"><span>Edit Info</span><img src="../../../assets/img/esit.svg"
                    alt=""></div>
            <!-- <div class="change-password" (click)="handlePasswordChange()"><span>Change Password</span></div> -->
        </div>
        <div class="content-main-div">
            <div class="flex_fac_heading" *ngIf="adminFlag">Admin</div>
            <div class="flex_fac_heading" *ngIf="!adminFlag">Facilitator</div>
            <div class="user_profile_card">
                <div class="grid-1-2 input-div">
                    <span>First Name</span>
                    <input type="text" [disabled]="disabledFlag" [ngClass]="disabledFlag ? 'disabledInput':''" name="name" [(ngModel)]="name">
                </div>
                <div class="grid-1-2 input-div">
                    <span>Last Name</span>
                    <input type="text" [disabled]="disabledFlag" [ngClass]="disabledFlag ? 'disabledInput':''" name="name" [(ngModel)]="last_name">
                </div>
                <div class="grid-1-2 input-div">
                    <span>Organization</span>
                    <input type="text" [disabled]="disabledFlag" [ngClass]="disabledFlag ? 'disabledInput':''" [(ngModel)]="organiztion">
                </div>
                <div class="grid-1-2 input-div">
                    <span>Email id</span>
                    <input type="email" class="disabledInput" disabled [(ngModel)]="emailId">
                </div>
                <div class="grid-1-2 input-div">
                    <span>Phone Number</span>
                    <input type="text" [disabled]="disabledFlag" [ngClass]="disabledFlag ? 'disabledInput':''"
                        [(ngModel)]="contact">
                </div>

            </div>
            <div class="user_profile_card">
                <div class="grid-1-2 input-div">
                    <span>User Name</span>
                    <input type="email" [disabled]="true" class="disabledInput" [(ngModel)]="emailId">
                </div>
                <div class="grid-1-2 input-div">
                    <span>Password</span>
                    <input type="text" [disabled]="true" class="disabledInput" value="********"
                        style="letter-spacing: 6px;padding-left: 10px !important;">
                </div>
                <div class="updtae_password_div"><span (click)="handlePasswordChange()">Update Password</span></div>
            </div>

        </div>
        <div class="update_btn_main_div" *ngIf="!disabledFlag">
            <div class="update_btn_sub_div">
                <button class="btn_custom" (click)="updateUserData()">Update</button>
            </div>
        </div>
        <!-- <div class="update-data" *ngIf="!disabledFlag">
            <button (click)="updateUserData()">Update</button>
        </div> -->
    </div>
</div>