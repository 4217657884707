import { environment } from './../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private http: HttpClient) { }
  public getScheduledData() {
    return this.http.get(environment.baseUrl + 'api/digital/games/session/scheduled/list');
  }
  downloadFile(url: string): Observable<Blob> {
    return this.http.get(url, { responseType: 'blob' });
  }
  sessionPost(data: any) {
    return this.http.post(environment.baseUrl + 'api/digital/games/session/create', data)
  }
  getVcPairs() {
    return this.http.get(environment.baseUrl + 'api/digital/games/vc/bslpairs/view')
  }
  getVcLibraryPairs() {
    return this.http.get(environment.baseUrl + 'api/digital/games/vc/mypairs/view')
  }
  addPairInMyLib(data: any) {
    return this.http.post(environment.baseUrl + 'api/digital/games/vc/mypairs/create', data)
  }
  configVcGame(data: any) {
    return this.http.post(environment.baseUrl + 'api/digital/games/vc/create', data)
  }
  deleteMyLibPair(data: any) {
    return this.http.delete(environment.baseUrl + 'api/digital/games/vc/mypairs/delete/' + data)
  }
  configWhGame(data: any) {
    return this.http.post(environment.baseUrl + 'api/digital/games/wh/create', data)
  }
  configAfGame(data: any) {
    return this.http.post(environment.baseUrl + 'api/digital/games/af/create', data)
  }
  configBnGame(data: any) {
    return this.http.post(environment.baseUrl + 'api/digital/games/bn/create', data)
  }
  configBpGame(data: any) {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    return this.http.post(environment.baseUrl + 'api/digital/games/bp/create', data)
  }
  configGORGame(data: any) {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    return this.http.post(environment.questicoUrl + 'api/questico/bundleGameConfiguration', data)
  }
  updateQuesticoGameStatus(data: any) {
    return this.http.put(environment.baseUrl + 'api/update/questico/gameStatus', data)
  }
  getVcGameDtls(data: any) {
    return this.http.get(environment.baseUrl + `api/digital/games/vc/view?program_id=${data}&game_id=1`)
  }
  getBnGameDtls(data: any) {
    return this.http.get(environment.baseUrl + `api/digital/games/bn/view?program_id=${data}&game_id=2`)
  }
  getWhGameDtls(data: any) {
    return this.http.get(environment.baseUrl + `api/digital/games/wh/view?program_id=${data}&game_id=3`)
  }
  getAfGameDtls(data: any) {
    return this.http.get(environment.baseUrl + `api/digital/games/af/view?program_id=${data}&game_id=5`)
  }
  redirectQuestico(data: any) {
    return this.http.post(environment.baseUrl + 'api/questico/games/session/create', data)
  }
  getBpGameDtls(data: any) {
    return this.http.get(environment.baseUrl + 'api/digital/games/bp/view?program_id=' + data + '&game_id=6')
  }
  getGORGameDtls(data: any) {
    return this.http.get(environment.questicoUrl + 'api/questico/bundleGameConfiguration?session_id=' + data)
  }
  getQuesticoKeys(game_id: any) {
    return this.http.get(environment.questicoUrl + 'api/questico/answerKeys?game_id=' + game_id)
  }
  getGameAccessList(game_type: number) {
    return this.http.get(environment.baseUrl + 'api/games/list/details?game_type=' + game_type)
  }

  userAllowedGameType() {
    return this.http.get(environment.baseUrl + 'api/facilitator/allowed/gameType')
  }
  raiseReachoutRequest(data: any) {
    return this.http.post(environment.baseUrl + 'api/reachout', data)
  }
  getLicenseCount() {
    return this.http.get(environment.baseUrl + 'api/facilitator/license/count')
  }








  logOut() {
    return this.http.get(environment.baseUrl + 'api/logout')
  }
  userDetails(data: any) {
    return this.http.get(environment.baseUrl + 'api/user/profile/' + data)
  }
  updateUserDetails(id: any, data: any) {
    return this.http.put(environment.baseUrl + 'api/fac/user/edit/' + id, data)
  }
  changePassword(data: any, id: any) {
    return this.http.put(environment.baseUrl + 'api/user/password/reset', data)
  }
  configPhysicalGame(data: any) {
    return this.http.post(environment.baseUrl + 'api/physical/games/session/create', data)
  }
  deletePysicalOneGame(program_id: any, game_id: any) {
    return this.http.delete(environment.baseUrl + 'api/physical/games/delete/' + program_id + '/' + game_id)
  }
  deleteDigitalOneGame(progaram_id: any, game_id: any) {
    return this.http.delete(environment.baseUrl + 'api/digital/games/session/delete/' + progaram_id + '/' + game_id);
  }



  getProgramStatus(program_id: any) {
    return this.http.get(environment.baseUrl + 'api/program/games/status/' + program_id)
  }

  updateProgramStatus(program_id: any) {
    return this.http.post(environment.baseUrl + 'api/digital/games/program/status/update', { program_id })
  }

  getProgramDtls(program_id: any) {
    return this.http.get(environment.baseUrl + 'api/digital/games/session/details', program_id)
  }



  shortUrl(link: any, programId: any) {
    const data = {
      main_url: link,
      program_id: programId
    }
    return this.http.post(environment.nodeUrl, data)
  }

  ppAllFilesDtls() {
    return this.http.get(environment.baseUrl + 'api/digital/games/pp/files')
  }
  getPpGameDtls(data: any) {
    return this.http.get(environment.baseUrl + `api/digital/games/pp/view?program_id=${data}&game_id=4`)
  }
  createPPGame(data: any) {
    return this.http.post(environment.baseUrl + 'api/digital/games/pp/create', data)
  }
  getCalenderData() {
    return this.http.get(environment.baseUrl + 'api/bsl/calender/scheduled/sessions')
  }





  //Archive Page
  getAllCompletedGame(page: any, per_page_item: any, startDate: any, endDate: any, status: any) {
    let endPoint = 'page=' + page + '&per_page_item=' + per_page_item
    if (startDate != null && endDate != null) {
      endPoint = endPoint.concat('&start_date=', startDate, '&end_date=', endDate)
    }
    if (status) endPoint = endPoint.concat('&status=', status)
    return this.http.get(environment.baseUrl + 'api/digital/games/session/archived/list?' + endPoint)
  }









  //Slots 
  getAllSlots(player: any, time: any) {
    return this.http.get(environment.baseUrl + 'api/kairos/scheduler/sessions?players=' + player + '&epoch_datetime=' + time)
  }
  bookSlot(data: any) {
    return this.http.post(environment.baseUrl + 'api/kairos/scheduler/sessions/create', data)
  }
  bookAdminSlot(data: any) {
    return this.http.post(environment.baseUrl + 'api/admin/scheduler/sessions/create', data)
  }
  deleteBookedSlot(data: any) {
    return this.http.put(environment.baseUrl + 'api/kairos/scheduler/sessions/delete', data)
  }
  deleteGameOnEdit(id: any) {
    return this.http.delete(environment.baseUrl + `api/digital/games/session/game/delete/${id}`)
  }
  changeSlotStatus(data: any) {
    return this.http.put(environment.baseUrl + 'api/kairos/scheduler/sessions/change/activeStatus', data)
  }



  // Super Admin Apis 
  superAdminLogin(data: any) {
    return this.http.post(environment.baseUrl + 'api/login', data)
  }
  getAllUserList() {
    return this.http.get(environment.baseUrl + 'api/user/list')
  }
  // getAllGameList(){
  //   return this.http.get(environment.baseUrl+'api/admin/gameList')
  // }
  addNewUser(data: any) {
    return this.http.post(environment.baseUrl + 'api/user/create', data)
  }
  updateUser(id: any, data: any) {
    return this.http.put(environment.baseUrl + 'api/user/edit/' + id, data)
  }
  getUserInfoWithSubcription(user_id: any) {
    return this.http.get(environment.baseUrl + 'api/user/info/game/dtls?user_id=' + user_id)
  }
  getAllGameList() {
    return this.http.get(environment.baseUrl + 'api/all/game/list')
  }
  assignPhysicalGames(data: any) {
    return this.http.post(environment.baseUrl + 'api/subscriptions/create', data)
  }
  updatePhysicalGames(data: any) {
    return this.http.post(environment.baseUrl + 'api/subscriptions/update', data)
  }
  uploadGameResouce(data: any) {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    return this.http.post(environment.baseUrl + 'api/game/resources/upload', data)
  }
  getGameResouce(id: any) {
    return this.http.get(environment.baseUrl + 'api/game/resources/list?game_id=' + id)
  }
  deleteResource(legend_id: any, resource_id: any) {
    return this.http.delete(environment.baseUrl + 'api/game/resources/delete/' + legend_id + '/' + resource_id)
  }
  editGameResource(data: any) {
    var headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    return this.http.post(environment.baseUrl + 'api/game/resources/edit', data)
  }
  editPhysicalGame(data: any) {
    return this.http.post(environment.baseUrl + 'api/edit/physicalGame', data)
  }
  updatePhysicalGameDate(data: any) {
    return this.http.post(environment.baseUrl + 'api/edit/physicalGameDate', data)
  }
  updateReachoutQuery(data: any) {
    return this.http.post(environment.baseUrl + 'api/game/reachout/resolve', data)
  }
  getReachoutUserCount() {
    return this.http.get(environment.baseUrl + 'api/admin/reachout/status')
  }
  updateUserSuspendActivate(data: any) {
    return this.http.post(environment.baseUrl + 'api/user/edit/suspendActivate', data)
  }
  getFacilitatorNotificationCount() {
    return this.http.get(environment.baseUrl + 'api/notification/count/facilitator')
  }
  getAdminNotificationAdmin() {
    return this.http.get(environment.baseUrl + 'api/notification/count/admin')
  }
  getFacNotification() {
    return this.http.get(environment.baseUrl + 'api/user/notifications')
  }
  sendEmailForSessionSetup(program_id: any) {
    return this.http.get(environment.baseUrl + 'api/sendLicenseInfo/email?programs_id=' + program_id)
  }
  getGameDetails() {
    return this.http.get(environment.baseUrl + 'api/getGameDetails/')
  }
  getUserListDetails() {
    return this.http.get(environment.baseUrl + 'api/admin/report/users/list/dropdown')
  }
  getUserDetails(id: any) {
    return this.http.get(environment.baseUrl + 'api/admin/report/users/details?user_id=' + id)
  }
  getUserSessionDetails(id: any, from: any, to: any) {
    return this.http.get(environment.baseUrl + 'api/admin/report/sessions?user_id=' + id + '&from_date=' + from + '&to_date=' + to)
  }
  getUserLicenseDetails(id: any, from: any, to: any) {
    return this.http.get(environment.baseUrl + 'api/admin/report/licenses?user_id=' + id + '&from_date=' + from + '&to_date=' + to)
  }
  getSignedUrl(data: any) {
    return this.http.get(environment.baseUrl + 'api/getSignedURL?key=' + data)
  }
  getServerEpochTime() {
    return this.http.get(environment.baseUrl + 'api/getEpochTime')
  }
  getSubscriptionHistory(user_id: any, game_type: any) {
    return this.http.get(environment.baseUrl + 'api/admin/subscription/history?user_id=' + user_id + '&game_type=' + game_type)
  }
}
