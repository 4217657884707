<div style="height: 100vh;">
    <!-- <app-header [first]=NewSession [secound]=Digital [third]=Name></app-header> -->
    <div class="main-div">

        <div class="side-nav-bar">
            <div><img src="../../../assets/kairos_logo-transparent_bg.svg" alt="" width="100%" (click)="moveToHomePage()"
                style="cursor: pointer;"></div>
            <div class="session_dtls_div">Session Details</div>
            <div class="side-nav-info-div">
                <div>
                    {{sessionData.sessionName}}
                </div>
                <div>
                    <ul class="filled-rhombus-list">
                        <li>{{sessionData.clientName}}</li>
                        <li>{{sessionData.sessionDate | date:' d MMMM yyyy'}}</li>
                        <li>{{sessionData.sessionSlot}}</li>
                        <li>{{sessionData.sessionPlayer}} Players</li>
                    </ul>
                </div>
            </div>
            <div style="flex: 1;">&nbsp;</div>
            <div class="selected-game-main-div">
                <div class="slected_games_main_div" style="width: 95%;">
                    <span>Selected Games</span>
                    <span (click)="goToStep2Edit()" *ngIf="editFlagger" class="edit_btn_span">Edit</span>
                </div>
                <div class="selected_game_view_main_div">
                    <div *ngFor="let item of selectedGames;let i =index" [id]="item.name+i"
                        (click)="handleGameCofigClick(item,item.name+i,i)" class="game_ele_main_div">
                        <img [src]="item.imgUrl" alt="">
                        <div>{{item.name}}</div>
                        <img src="../../../assets/select.svg" alt="" class="tick-icon"
                            *ngIf="tickMarkFlag[item.gameId] == true">
                    </div>
                </div>
            </div>
        </div>
        <div style="width: 100%; overflow-y: scroll; background-color: #f2f6fa;">
            <app-new-vc *ngIf="configSelect == '1'"></app-new-vc>
            <app-new-bottleneck *ngIf="configSelect == '2'"></app-new-bottleneck>
            <app-new-warehouse *ngIf="configSelect == '3'"></app-new-warehouse>
            <app-new-picture-perfect *ngIf="configSelect == '4'"></app-new-picture-perfect>
            <app-new-animalfarm *ngIf="configSelect == '5'"></app-new-animalfarm>
            <app-new-bigpicture *ngIf="configSelect == '6'"></app-new-bigpicture>
            <app-new-greatOcean *ngIf="configSelect == '14'"></app-new-greatOcean>
            <app-new-peopleOfWorld *ngIf="configSelect == '15'"></app-new-peopleOfWorld>


        </div>
    </div>
</div>