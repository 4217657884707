<div style="height: 100vh;">
    <div class="bigp-headers">
        <img src="../../../assets/img/bigPicture_withtout_bg.svg" alt="">
        <div style="margin-left: 15px; text-align: center;color: #FFFFFF">
            <div style="font-weight: 500;font-size: 40px;line-height: 32px;">BIG</div>
            <div style="font-size: 16px;">PICTURE</div>
        </div>
    </div>
    <div style="height: 87.2%; display: flex;">
        <div class="side-nav-bar">
            <div>
                <img src="../../../assets/img/big-picture-side-nav.png" alt="">
            </div>
        </div>
        <div style=" background: #f2f6fa;width: 100%;text-align: -webkit-center;">
            <div class="main-container">
                <div style="margin: auto;">
                    <div>
                        <div class="img-privew-div">
                            <div class="img-privew-sub-div">
                                <div *ngIf="!previewSrc" class="blank-img-div"></div>
                                <div *ngIf="previewSrc" class="filled-img-div">
                                    <img [src]="previewSrc" alt="" class="preview-img">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div style="font-weight: 500;margin: 10px 0px;text-align: initial;">Codes</div>
                    <div class="code-main-div">
                        <div class="code-sub-div">
                            <div *ngFor="let item of codes" class="code">
                                {{item.code}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="rightContainer">
                <div class="playerLink" matTooltip="Link Copied" #tooltip="matTooltip" matTooltipPosition="above"
                    matTooltipClass="above" (mouseenter)="$event.stopImmediatePropagation()"
                    (mouseleave)="$event.stopImmediatePropagation()" (click)="tooltip.show()"
                    [cdkCopyToClipboard]="playLink">
                    <div><img style="width: 20px;
              " src="../../../assets/img/qr.svg"></div>
                    <div><b>PlayLink</b></div>
                </div>
            </div>
        </div>
    </div>