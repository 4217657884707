<ng-http-loader 
    [backdrop]="true"
    [backgroundColor]="'#ff4d6f'"
    [debounceDelay]="100"
    [extraDuration]="300"
    [minDuration]="300"
    [opacity]="'.6'"
    [backdropBackgroundColor]="'#777777'"
    [spinner]="spinkit.skThreeBounce">
</ng-http-loader>
<router-outlet></router-outlet>
<!-- ff0000 -->