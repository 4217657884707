import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardService } from '../Shared/Services/Dashboard.service';
import { Title } from "@angular/platform-browser";
import { ToastrService } from 'ngx-toastr';
import { DataSharingServiceService } from '../services/data-sharing-service.service';
import { ReachOutComponent } from '../components/reach-out/reach-out.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnInit {
  Games: any = [];
  todayCount = 0
  is_questico_access: any = 0;
  // Games:any=[];
  digitalGames = ['Animal Farm', 'Ware House', 'Value Continuum'];
  physicalGames = ['Human Matrix', 'Stranded', 'Home Shelter'];
  questico = ['Game 1', 'Game 2', 'Game 3'];
  newSession = false;
  constructor(
    private router: Router,
    private dashboardService: DashboardService,
    private toster: ToastrService,
    private titleService: Title,
    private dataShare: DataSharingServiceService,
    private ngZone: NgZone,
    public dialog: MatDialog
  ) {
    this.titleService.setTitle('Kairos | Dashboard')
  }
  userName: any = ''
  userDlts: any[] = []
  digitalGameCount: number = 0
  physicalGameCount: number = 0
  digitalGame: any[] = []
  physicalGame: any[] = []
  errorMsg: string = ''
  flag: boolean = false
  ngOnInit(): void {
    sessionStorage.removeItem('uuid')
    this.dataShare.selectedGameForConfig = []
    this.dataShare.sessionData = {}
    this.dataShare.currentSelect = null
    this.dataShare.totalConfigDone = []
    this.dataShare.sessionTempEditData = {}
    this.dataShare.newSetup = true
    this.dataShare.lastGameConfigId = null
    this.dataShare.exploreGameSelection = null
    this.dataShare.lastUrlForGameResource = null
    this.dataShare.myGames = null
    this.dataShare.dashBoradRedir = null
    this.dashboardService.selectedSession = null;
    this.dashboardService.selectedArchiveSession = null;
    this.dashboardService.editStepFalg = null;
    this.dashboardService.archiveFlag = false;
    this.dashboardService.editStep3 = null;
    // this.flag = false
    // if (sessionStorage.getItem('expireToday') == 'true') {
    //   this.flag = true
    // }
    if (sessionStorage.getItem('userName')) {
      this.userName = sessionStorage.getItem('userName')
    }
    setTimeout(()=>{
      this.allSubcriptionDtls()
    },1000)
  }

  questicoNavigate() {
    if (this.is_questico_access == 0) {
      this.toster.error("You don't have the Access, Please contact the Support", '', { timeOut: 2000 })
    }
    else {
      this.router.navigate(['/configure/questico']);
    }
  }
  getMonthName(monthNumber: any) {
    const date = new Date();
    date.setMonth(monthNumber - 1);
    return date.toLocaleString('en-US', { month: 'long' });
  }

  openNewSessionSetup() {
    // if(this.digitalGameCount != 0){
    //   // this.toster.warning('','',{timeOut:2000})
    //   return
    // }
    if (+this.LicienceLeft < 2) {
      this.dialog.open(LowLicenseAlertDialog,{
        hasBackdrop:true,
        disableClose:true,
        data:this.LicienceLeft
      })
      // this.toster.warning('Minimum 2 licience is required to setup gmaes', '', { timeOut: 2000 })
      return
    }
    this.router.navigate(['/configure'])
  }
  closeNewSession(event: any) {

    if (event.target.className == 'newSessionEntire')
      this.newSession = false;
  }
  closePopup() {
    this.newSession = false;
  }
  scheduledPage() {
    this.router.navigate(['/scheduled']);
  }
  openSessionDashboard(game: any) {
    this.dashboardService.selectedSession = game;
    // if (game.program_games[0].game_id == 6) {
    //   this.router.navigate(['/bigPicture'])
    // }
    // else {
    this.router.navigate(["/sessionDashboard"]);
    this.dataShare.dashBoradRedir = true
    // }
  }
  goForShedule() {
    window.open('https://calendly.com/playkairos/connect')
  }
  goToResoure(id: any) {
    this.dataShare.lastUrlForGameResource = '/dashboard'
    this.router.navigate(['/game/resources'], { queryParams: { id: id } })
  }
  goToExplorePage() {
    this.router.navigate(['/exploreGames'])
  }
  goToGameResource(gameName: any) {
    if (gameName == 'digital') {
      this.dataShare.exploreGameSelection = 'digital'
      this.router.navigate(['/exploreGames'])
      this.dataShare.myGames = 'dashboard'
    }
    else if (gameName == 'physical') {
      this.dataShare.exploreGameSelection = 'physical'
      this.router.navigate(['/exploreGames'])
      this.dataShare.myGames = 'dashboard'
    }
    else if (gameName == 'questico') {
      this.router.navigate(['/game/questico'])
      this.dataShare.myGames = 'questico'
      this.dataShare.lastUrlForGameResource = '/dashboard'
    }
  }
  digitalMyGames() {
    this.dataShare.myGames = 'digital'
    sessionStorage.removeItem('is_physical')
    sessionStorage.setItem('is_digital','true')
    this.router.navigate(['/myGames'])
  }
  physicalMyGames() {
    this.dataShare.myGames = 'physical'
    sessionStorage.removeItem('is_digital')
    sessionStorage.setItem('is_physical','true')
    this.router.navigate(['/myGames'])
  }
  goToExplore() {
    this.dataShare.exploreGameSelection = ''
    this.router.navigate(['/exploreGames'])
  }

  get LicienceLeft() {
    return this.userDlts.filter((ele: any) => ele.game_type == 1)[0].no_of_licenses_left
  }
  get DigitalValidTill() {
    let date = new Date(this.userDlts.filter((ele: any) => ele.game_type == 1)[0].end_date * 1000)
    let dateTemp = date.toLocaleString("en-GB", { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/\//g, '-')
    return dateTemp.split('-').reverse().join('-')
  }
  allSubcriptionDtls() {
    const self = this;
    this.dashboardService.getDashboardDtls().subscribe((res: any) => {
      if (res.status) {
        if(sessionStorage.getItem('role'))
          sessionStorage.setItem('super_userName',`${res.results.userDetails.first_name} ${res.results.userDetails.last_name}`)
        else
          sessionStorage.setItem('userName',`${res.results.userDetails.first_name} ${res.results.userDetails.last_name}`)
          res.results.session.forEach((val: any, index: any) => {
          val.type = 'Digital';
          let programDate = new Date(val.formatted_date).getDate();
          let today = new Date().getDate();
          if (programDate == today && new Date(val.formatted_date).getMonth() == new Date().getMonth()) {
            val.schedulesStatus = true;
            this.todayCount += 1;
          }
          val.program_date = val.formatted_date;
        })
        this.Games = res.results.session;
        this.userDlts = res.results.subcriptionDtls
        this.digitalGame = this.userDlts.filter((ele: any) => ele.game_type == 1)
        this.physicalGame = this.userDlts.filter((ele: any) => ele.game_type == 2)
        this.digitalGameCount = this.digitalGame.length
        this.physicalGameCount = this.physicalGame.length
        
        this.ngZone.run(() => {
          //Error Validation For Subcription
          let todayDate = new Date()
          todayDate.setHours(0, 0, 0, 0)
          let todayWithHours = todayDate;
          todayWithHours.setHours(23,59,59,0)
          let tenthFutureDate = new Date(todayDate)
          tenthFutureDate.setDate(todayDate.getDate() + 9)
          let pyExpiredGameAaray: any[] = []
          if (this.physicalGameCount != 0) {
            this.physicalGame.forEach((ele: any) => {
              if (ele.end_date * 1000 <= tenthFutureDate.getTime()) {
                pyExpiredGameAaray.push({ name: this.dashboardService.pyGameIdName[ele.game_id], end_date: ele.end_date })
              }
            })
          }
          // let tenPercentageOfLicense = Math.round(this.digitalGame[0]?.no_of_licenses * 0.1)
          // Logic changed to 25 count 
          let tenPercentageOfLicense = 25
          if(this.digitalGame[0]?.end_date * 1000 <= todayWithHours.getTime()){
            this.errorMsg = `LAST DAY FOR RENEWAL. You will lose your games and resources if not renewed Today!. Please visit <span class="error_mag_span_ele">MyGames</span> page and Reachout to us for Renewal.`
            this.flag = true
          }
          else if(pyExpiredGameAaray.length > 0 || this.digitalGame[0]?.no_of_licenses_left <= tenPercentageOfLicense || this.digitalGame[0]?.end_date * 1000 <= tenthFutureDate.getTime()){
            this.errorMsg = `Your game subscription is going to expire soon!. Please visit <span class="error_mag_span_ele">MyGames</span> and Reachout to us for Renewal.`
            this.flag = true
          }

        })
      }
      else {
        this.Games = [];
      }
    })
  }
  openReachOut() {
    const dialogRef = this.dialog.open(ReachOutComponent, {
      panelClass: 'reachout',
      hasBackdrop: true,
    })
  }
}

@Component({
  selector: 'low-license-dialog',
  templateUrl: './license_low_dialog.html',
})
export class LowLicenseAlertDialog implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<LowLicenseAlertDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
     private router: Router,
  ) { }
  ngOnInit(): void {
    this.currentLicense = this.data
  }
  currentLicense:any = ''
  navigateToMyGames(){
    this.router.navigate(['/myGames'])
    this.close()
  }
  close() {
    this.dialogRef.close()
  }
}