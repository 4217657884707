import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Location } from '@angular/common';
import { Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, QueryList, Renderer2, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataSharingServiceService } from 'src/app/services/data-sharing-service.service';
import { HttpService } from 'src/app/services/http.service';
import * as AWS from 'aws-sdk';
import { environment } from 'src/environments/environment';
import Hls from 'hls.js';
@Component({
  selector: 'app-game-content',
  templateUrl: './game-content.component.html',
  styleUrls: ['./game-content.component.css']
})
export class GameContentComponent implements OnInit {
  private s3: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toster: ToastrService,
    public sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private httpSv: HttpService,
    private location: Location,
    private dataShare: DataSharingServiceService
  ) {
    AWS.config.update({
      accessKeyId: environment.aws.accessKeyId,
      secretAccessKey: environment.aws.secretAccessKey,
      region: environment.aws.region
    });
    this.s3 = new AWS.S3({
      params: { Bucket: environment.aws.bucketName }
    });
  }
  gameType: any = null;
  ngOnInit(): void {
    this.httpSv.getAllGameList().subscribe((res: any) => {
      if (res['status']) {
        this.gameList = res['results']
        if (this.dataShare.gameContentData) {
          this.currentGame = this.gameList.filter((ele: any) => ele.id == this.dataShare.gameContentData)
          this.currentGame = this.currentGame[0]
          this.dataShare.gameContentData = null
          this.getAllGameResouce()
        } else {
          this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 });
          this.location.back()
        }
      } else {
        this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 });
        this.location.back()
      }
    }, (err: any) => { this.toster.error(err.error.message, '', { timeOut: 2000 }); this.location.back() })

    this.gameDtlIndex = 0;
    this.src = this.gamesDtl[this.gameDtlIndex]['files'][0]['src'];
    this.name = "Facilitator Guide"
  }

  DigitalGames: any = 'Digital Games';
  gameList: any;
  description: any
  currentGame: any = {
    id: null,
    game_name: null,
    game_type: null,
  };
  src: any;
  fileName: any
  name: any;
  type: any = 'pdf'
  allFiles: any
  downloadSrc: any

  openLegendDialog: boolean = true;

  zoomScale: any = 'page-height'
  zoomFlag: boolean = false

  addLegend() {
    // this.openLegendDialog = true;
    const dialogRef = this.dialog.open(addLegendDialogComponent, {
      disableClose: false,
      panelClass: 'no-padding-dialog',
    })
    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {

      }
    }, (err: any) => {
      this.toster.error(err.error.message)
    })
  }

  zoom() {
    if (!this.zoomFlag) {
      this.zoomFlag = true
      this.zoomScale = 'page-width'
    }
    else if (this.zoomFlag) {
      this.zoomFlag = false
      this.zoomScale = 'page-height'
    }
  }
  gameDtlIndex: any

  gamesDtl: any = [
    {
      id: 1,
      name: 'Value Continuum',
      description: 'Value Continuum is a activity that helps explore individual’s mindsets & preferences and perceptions of others and pushes them to reflect on their own thinking and preferences, thereby promoting open conversations on diversity, empathy and interpersonal interactions.',
      files: [
        { fileName: 'Facilitator Guide', des_name: 'Value Continuum', type: 'pdf', src: '../../../../assets/pdf/Kairos_Value_Continuum_ Guide.pdf' },
        { fileName: 'Learning to Action Tool', des_name: 'Value Continuum-Empathy Affirmations & Stakeholder Map', type: 'ppt', src: '../../../../assets/ppt/Value-Continuum-Empathy-Affirmations-_-Stakeholder-Map.html', downloadSrc: '../../../assets/ppt/Value Continuum - Empathy Affirmations & Stakeholder Map.pptx' },
      ]
    }
  ]
  legendsDataArray: any = [
    {
      id: null,
      name: 'Intro & Game Set up',
      files: [],
      tempId: 0,
    },
    // {
    //   id: null,
    //   name: 'Game Breifing',
    //   files: [],
    //   tempId: 1,
    // },
    {
      id: null,
      name: 'Game Run',
      files: [],
      tempId: 2,
    },
    {
      id: null,
      name: 'Learning Connect',
      files: [],
      tempId: 3,
    },
    {
      id: null,
      name: 'Learning to action',
      files: [],
      tempId: 4,
    },
    {
      id: null,
      name: 'Gallery',
      files: [],
      tempId: 5,
    },
    {
      id: null,
      name: 'Additional Resources',
      files: [],
      tempId: 6,
    },
  ];
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.legendsDataArray, event.previousIndex, event.currentIndex);
  }
  movies1 = [];
  drop1(event: CdkDragDrop<string[]>, index: any) {
    moveItemInArray(this.legendsDataArray[index].files, event.previousIndex, event.currentIndex);
  }
  addFileFlag: boolean = false
  passOnData: any
  publishFlag: boolean = false
  currentLedName: any
  addFileToLegend(legend: any, flag: boolean = false) {
    if (this.currentLedName == legend.name && !flag) return
    this.currentLedName = legend.name
    this.addFileFlag = false
    this.passOnData = null
    setTimeout(() => {
      this.addFileFlag = true
      this.passOnData = legend
      this.publishFlag = false
    }, 0)
  }
  back() {
    this.location.back()
  }
  gameFileDataHandler(event: any) {
    if (event?.closeFlag) {
      event.data.map((ele: any, i: any) => {
        ele['tempId'] = i
      });
      const indx = this.legendsDataArray.findIndex((ele: any) => ele.tempId == event.tempId)
      this.legendsDataArray[indx]['files'] = event.data
      if (event.index == null) this.legendsDataArray[indx].id = null
      this.publishFlag = true
      this.addFileFlag = false
      this.currentLedName = null
    } else if (event?.closeFlag == false) {
      const indx = this.legendsDataArray.findIndex((ele: any) => ele.tempId == event.tempId)
      const resourceInx = this.legendsDataArray[indx]['files'].findIndex((ele: any) => ele.tempId == event.data.tempId)
      this.legendsDataArray[indx]['files'].splice(resourceInx, 1)
    } else return
  }
  preview(data: any) {
    const dialogRef = this.dialog.open(resourcePreviewDialog, {
      hasBackdrop: true,
      disableClose: true,
      data: data,
    })
  }
  deleteFile(legend_id: any, resource_id: any, legend_index: any, resource_index: any) {
    const dialogRef = this.dialog.open(resourceConfirmDialog, {
      hasBackdrop: true,
      disableClose: true,
      data: 'Are you sure you want to delete this file'
    })
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        if (resource_id == undefined) {
          this.legendsDataArray[legend_index].files.splice(resource_index, 1)
          // console.log(this.passOnData)
          // this.passOnData[legend_index].files.splice(resource_index, 1)
          return
        }
        this.httpSv.deleteResource(legend_id, resource_id).subscribe((res: any) => {
          if (res['status']) {
            let legendRefreshFlag = false
            if (this.legendsDataArray[legend_index].files.length == 1) {
              this.legendsDataArray[legend_index].id = null
              legendRefreshFlag = true
            }
            this.legendsDataArray[legend_index].files.splice(resource_index, 1)
            if (legendRefreshFlag) {
              this.addFileToLegend(this.legendsDataArray[legend_index], true)
            }
          }
          else {
            this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
          }
        }, (err: any) => {
          this.toster.error(err.error.message, '', { timeOut: 2000 })
        })
      }
    })
  }
  showProgressBar: boolean = false
  newTotalFileCount: number = 0
  uplodedFileCount: number = 0
  progressPercent: number = 0
  async publishContent() {
    this.newTotalFileCount = 0
    this.uplodedFileCount = 0
    this.progressPercent = 0
    const uploadPromises: any = [];
    if (!this.description) {
      this.toster.warning('Please fill game description', '', { timeOut: 2000 })
      return
    }
    const dialogRef = this.dialog.open(resourceConfirmDialog, {
      hasBackdrop: true,
      disableClose: true,
      data: 'Are you sure you publish the content.'
    })
    dialogRef.afterClosed().subscribe(async (result: boolean) => {
      const data: any = new FormData();
      data.append('game_id', this.currentGame.id);
      data.append('description', this.description);
      let filterResouce = this.legendsDataArray.filter((ele: any) => ele.files.length != 0);
      filterResouce.forEach((ele: any, index: number) => {
        ele['files'].forEach((item: any, ind: number) => {
          if (item['main_resources_file'] instanceof File) this.newTotalFileCount++
          if (item['html_resources_file'] instanceof File) this.newTotalFileCount++
        });
      });

      if (this.newTotalFileCount > 0) this.showProgressBar = true
      filterResouce.forEach((ele: any, index: any) => {
        ele['position'] = index + 1;

        // Append fields from the outer object
        Object.keys(ele).forEach(key => {
          data.append(`legends[${index}][${key}]`, ele[key]);
        });
        ele.files.forEach(async (item: any, inx: any) => {
          // Append fields from the 'files' array
          item.is_downloadable = item.is_downloadable ? 1 : 0
          let newFileFlag: boolean = false
          await Object.keys(item).forEach(fileKey => {
            if (item[fileKey] instanceof File) {
              newFileFlag = true
              uploadPromises.push(this.uploadFileToS3(item[fileKey]).then(progress => {
                // this.uplodedFileCount++
                // if (this.uplodedFileCount < this.newTotalFileCount)
                //   this.progressPercent = 0
                data.append(`legends[${index}][files][${inx}][${fileKey}]`, progress.Key);
              }).catch(error => {
                console.error('Error uploading file:', error);
              }));
            } else {
              data.append(`legends[${index}][files][${inx}][${fileKey}]`, item[fileKey]);
            }
          });
          if (newFileFlag) {
            newFileFlag = false
            data.append(`legends[${index}][files][${inx}][new_file]`, 1);
          } else data.append(`legends[${index}][files][${inx}][new_file]`, 0);
          // Append 'main_resources_file' as a file
          if (item.main_resources_file instanceof File) {
            // data.append(`legends[${index}][files][${inx}][main_resources_file]`, item.main_resources_file);
            // data.append(`legends[${index}][files][${inx}][is_downloadable]`, item.is_downloadable);
            data.append(`legends[${index}][files][${inx}][position]`, inx + 1);
          }
          // Append 'main_resources_file' as a file
          // if (item.html_resources_file instanceof File) {
          //   data.append(`legends[${index}][files][${inx}][html_resources_file]`, item.html_resources_file);
          // }
        });
      });



      try {
        await Promise.all(uploadPromises);
        this.showProgressBar = false
        this.httpSv.editGameResource(data).subscribe((res: any) => {
          if (res['status']) {
            this.dialog.open(resourcePublishDialog, {
              hasBackdrop: true,
              disableClose: true,
              data: this.currentGame.id
            })
            this.location.replaceState('/admin-dashboard')
            // this.toster.success('Resource(s) is added sucessfully', '', { timeOut: 2000 })
          } else {
            this.toster.error(res['message'], '', { timeOut: 2000 })
          }
        }, (err: any) => {
          this.toster.error(err.error.message, '', { timeOut: 2000 })
        })
        // this.httpSv.uploadGameResouce(data).subscribe((res: any) => {
        //   if (res['status']) {
        //     this.location.back()
        //     this.toster.success('Resource(s) is added sucessfully', '', { timeOut: 2000 })
        //   } else {
        //     this.toster.error(res['message'], '', { timeOut: 2000 })
        //   }
        // }, (err: any) => {
        //   this.toster.error(err.error.message, '', { timeOut: 2000 })
        // })
      } catch (error) {
        console.error('Error uploading files:', error);
        // Handle error
      }
    })

  }

  async uploadFileToS3(file: any): Promise<any> {
    this.progressPercent = 0
    // console.log(file.type.includes('video'))
    const params = {
      Bucket: environment.aws.bucketName,
      Key: file.type.includes('video') ? `${window.btoa((Math.floor(new Date().getTime())).toLocaleString())}.mp4` : window.btoa((Math.floor(new Date().getTime())).toLocaleString()),
      Body: file,
      // ACL: 'public-read' // Set ACL as per your requirement
    };

    return new Promise((resolve, reject) => {
      this.s3.upload(params)
        .on('httpUploadProgress', (progress: any) => {
          const percentage = Math.round((progress.loaded / progress.total) * 100);
          if (percentage > this.progressPercent)
            this.progressPercent = percentage
          else if (percentage < this.progressPercent && this.progressPercent == 100) {
            if (this.uplodedFileCount < this.newTotalFileCount) {
              this.uplodedFileCount++
              // if (this.uplodedFileCount < this.newTotalFileCount)
              //       this.progressPercent = 0
              this.progressPercent = 0

              setTimeout(() => {
                this.progressPercent = percentage
              }, 100)
            }
          }
          // You can emit an event or update a progress bar here
        })
        .send((err: any, data: any) => {
          if (err) {
            reject(err);
          } else {
            resolve(data);
          }
        });
    });
  }






  getAllGameResouce() {
    this.httpSv.getGameResouce(this.currentGame.id).subscribe((res: any) => {
      if (res['status']) {
        const result = res['results'][0]
        const legendResult = result.legends
        this.description = result.description
        legendResult.forEach((element: any) => {
          element.resources.map((ele: any) => {
            ele.is_downloadable = ele.is_downloadable ? true : false
          })
          const tempInd = this.legendsDataArray.findIndex((ele: any) => ele.name == element.name)
          if (tempInd != -1) {
            this.legendsDataArray[tempInd]['id'] = element.legend_id
            this.legendsDataArray[tempInd]['files'] = element.resources
            this.legendsDataArray[tempInd]['position'] = element.position
          }
        });
        this.legendsDataArray.sort((a: any, b: any) => {
          if (a.position > b.position) return 1
          else if (a.position < b.position) return -1
          else return 1
        })
        this.legendsDataArray.forEach((element: any) => {
          if (element.files.length > 0) {
            element.files.sort((a: any, b: any) => {
              if (a.position > b.position) return 1
              else if (a.position < b.position) return -1
              else return 0
            })
          }
        });
        this.addFileToLegend(this.legendsDataArray[0])
        // console.log(this.legendsDataArray)
      }
      else {
        this.addFileToLegend(this.legendsDataArray[0])
      }
    }, (err: any) => { this.addFileToLegend(this.legendsDataArray[0]) })
  }
}

@Component({
  selector: 'add-legend-dialog',
  templateUrl: './dialog-legend.component.html',
  styleUrls: ['./game-content.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class addLegendDialogComponent implements OnInit {
  @ViewChildren('targetElement') targetElement!: QueryList<ElementRef>;
  constructor(
    private toster: ToastrService,
    private dialog: MatDialog,
    private httpSv: HttpService,
    private renderer: Renderer2
    // public dialogRef: MatDialogRef<addLegendDialogComponent>,
    // @Inject(MAT_DIALOG_DATA) public data: any

  ) { }
  @Input() data: any
  @Output() dataEmitter = new EventEmitter<any>();
  ngOnInit(): void {
    if (this.data) {
      this.legendName = this.data.name
      if (this.legendName == 'Gallery') this.galaryFlag = true
      if (this.data.files.length > 0) {
        this.legendDtl = [...this.data.files]
      } else
        this.legendDtl.push({
          type: this.galaryFlag ? 'images' : null,
          name: null,
          main_resources_file: null,
          html_resources_file: null,
          is_downloadable: false,
        })
    }
    else
      this.legendDtl.push({
        type: this.galaryFlag ? 'images' : null,
        name: null,
        main_resources_file: null,
        html_resources_file: null,
        is_downloadable: false,
      })
  }
  convertedPPTtoolTipTxt: string = 'Upload the PDF converted from the PowerPoint file, which will be used for previewing.'
  galaryFlag: boolean = false
  legendName: string = ''
  fileTypes: any = {
    1: 'pdf',
    2: 'video',
    3: 'ppt',
    4: 'images'
  }
  accpetedFileType: any = {
    pdf: '.pdf',
    video: '.mp4, .avi, .mov, .wmv, .flv, .mkv, .webm, .3gp, .3g2',
    // video: 'video/mp4,video/webm,video/ogg,video/avi,video/mov,video/wmv,video/flv,video/mkv,video/webm,video/3gp,video/3g2',
    ppt: '.ppt,.pptx',
    image: 'image/png,image/jpeg'
  }
  onOptionClick(option: string, legend: any) {
    // Handle the option click here
    if (option == 'PDF') {
    } else if (option == 'Video') {
    } else if (option == 'PPT') {
    } else if (option == 'Images') {
    }
  }


  onSelectChange(event: any, i: number) {
    if (this.legendDtl.length > 0) {
      // this.legendDtl[0].type = 'ppt';
      // this.legendDtl[i].type = event.target.key;
    }

  }
  toggleDropdown(legend: any) {
    // Toggle the dropdown state for the user
    legend.isDropdownOpen = !legend.isDropdownOpen;
  }

  legendDtl: any[] = [];

  close() {
    // this.dialogRef.close(false)
  }
  confirm() {
    // this.dialogRef.close(true)
  }
  onImageChange(evt: any, legend: any) {
    let width
    let height
    let image: any = evt.target.files[0];
    if (legend.resource_id) {
      // delete legend.resource_id
      delete legend.html_resources_file
    }
    legend.main_resources_file = evt.target.files[0];
    // const fsize = evt.target.files[0].size;
    // const fileSize = Math.round((fsize / 1024));
    const name = evt.target.files[0].name;
    // if (fileSize > 8192) {
    //   this.toster.error('Sorry image size is too big', '', { timeOut: 2000 })
    //   return
    // }
    (<HTMLInputElement>document.getElementById('hiddenImgInput')).value = ""

    // imgType.nativeElement.value = "";
  }
  onPPTChange(evt: any, legend: any) {
    let width
    let height
    let image: any = evt.target.files[0];
    legend.html_resources_file = evt.target.files[0];
    const fsize = evt.target.files[0].size;
    const fileSize = Math.round((fsize / 1024));
    const name = evt.target.files[0].name;
    // if (fileSize > 8192) {
    //   this.toster.error('Sorry image size is too big', '', { timeOut: 2000 })
    //   return
    // }
    (<HTMLInputElement>document.getElementById('hiddenImgInput')).value = ""

    // imgType.nativeElement.value = "";
  }
  showErr(err: string) {
    this.toster.warning(err, '', { timeOut: 2000 })
  }
  getFileName(data: any) {
    if (typeof (data.main_resources_file) == 'object') {
      let name
      const file = data.main_resources_file
      if (file) {
        //   const reader = new FileReader();
        name = file.name
        //   reader.readAsDataURL(file);
      }
      return name
    } else {
      let extension
      switch (data.type) {
        case 'pdf':
          extension = 'pdf'
          break;
        case 'ppt':
          extension = 'pptx'
          break;
        case 'video':
          extension = 'mp4'
          break;
        case 'images':
          extension = 'jpeg'
          break;
      }
      return `file.${extension}`
    }
  }
  getPPTConvertedName(file_obj: any) {
    if (typeof (file_obj) == 'object') {
      let name
      const file = file_obj
      if (file) {
        const reader = new FileReader();
        name = file.name
        reader.readAsDataURL(file);
      }
      return name
    }
  }
  addMoreFileToLegend() {
    this.legendDtl.push({
      type: this.galaryFlag ? 'images' : null,
      name: null,
      main_resources_file: null,
      html_resources_file: null,
      is_downloadable: false,
    })
  }
  getFileValidation(ledend: any) {
    if (typeof (ledend.main_resources_file) == 'object' || ledend.main_resources_file == null) return true
    else return false
  }
  closeResourceFile(index: any) {
    this.legendDtl.splice(index, 1);
  }
  deleteFile(data: any, index: any) {
    // console.log(data)
    const dialogRef = this.dialog.open(resourceConfirmDialog, {
      hasBackdrop: true,
      disableClose: true,
      data: 'Are you sure you want to delete this file'
    })
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        if ((data.resource_id == undefined || !data.resource_id) && data.tempId == undefined) {
          // this.legendDtl.splice(index, 1)
          this.legendDtl[index].main_resources_file = null
          this.legendDtl[index].html_resources_file = null
          // if (this.legendDtl.length == 0) this.addMoreFileToLegend()
        }
        else if (data.tempId != undefined && data.resource_id == undefined) {
          this.legendDtl[index].main_resources_file = null
          this.legendDtl[index].html_resources_file = null
          // if (this.legendDtl.length == 0) this.addMoreFileToLegend()
          this.dataEmitter.emit({ closeFlag: false, data: data, index: this.data.id, tempId: this.data.tempId })
        }
        else {
          this.httpSv.deleteResource(this.data.id, data.resource_id).subscribe((res: any) => {
            if (res['status']) {
              this.legendDtl[index].main_resources_file = null
              this.legendDtl[index].html_resources_file = null
              this.legendDtl[index].resource_id = null
              // this.legendDtl.splice(index, 1)
              if (this.legendDtl.length == 0) this.data.id = null
              this.dataEmitter.emit({ closeFlag: false, data: data, index: this.data.id, tempId: this.data.tempId })
            }
            else {
              this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
            }
          }, (err: any) => {
            this.toster.error(err.error.message, '', { timeOut: 2000 })
          })
        }

      }
    })
  }
  saveToLocal() {
    if (this.legendDtl.some((ele: any) => ele.type == null)) {
      this.toster.warning('Please select the resource type.', '', { timeOut: 2000 })
      return
    }
    else if (this.legendDtl.some((ele: any) => ele.name == null || ele.name == '')) {
      this.toster.warning('Please fill the resource name.', '', { timeOut: 2000 })
      return
    }
    else if (this.legendDtl.some((ele: any) => ele.main_resources_file == null)) {
      this.toster.warning('Please upload the resource file.', '', { timeOut: 2000 })
      return
    }
    else {
      let dataValidFlag = true
      this.legendDtl.forEach((ele: any) => {
        if (ele.type == 'ppt') {
          if (ele.html_resources_file == null) {
            this.toster.warning('Please add converted pdf of ppt file', '', { timeOut: 2000 })
            dataValidFlag = false
            return
          }
        }
      })
      if (!dataValidFlag) return
      const dialogRef = this.dialog.open(resourceConfirmDialog, {
        hasBackdrop: true,
        disableClose: true,
        data: 'Are you sure you want to save the edits'
      })
      dialogRef.afterClosed().subscribe((result: boolean) => {
        if (result) {
          this.dataEmitter.emit({ closeFlag: true, data: this.legendDtl, index: this.data.id, tempId: this.data.tempId })
        }
      })
    }
  }
}








@Component({
  selector: 'resouce-confirm-dialog',
  templateUrl: './resouce_confirm_dialog.html',
  encapsulation: ViewEncapsulation.None
})
export class resourceConfirmDialog implements OnInit {
  constructor(
    private toster: ToastrService,
    public dialogRef: MatDialogRef<resourceConfirmDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
  ngOnInit(): void {
    this.dialogTxt = this.data
  }

  dialogTxt: string = ''
  close() {
    this.dialogRef.close(false)
  }
  confirm() {
    this.dialogRef.close(true)
  }
}


@Component({
  selector: 'resouce-preview-dialog',
  templateUrl: './preview-dialog.html',
  encapsulation: ViewEncapsulation.None
})
export class resourcePreviewDialog implements OnInit {
  constructor(
    private toster: ToastrService,
    public dialogRef: MatDialogRef<resourcePreviewDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sanitizer: DomSanitizer
  ) { }
  hls?: Hls;
  // ngOnInit(): void {
  //   this.viewFlag[this.data.type] = true
  //   if (this.data.type == 'ppt') {
  //     this.spinLoader = true
  //     if (typeof (this.data.main_resources_file) == 'object') {
  //       const file = this.data.html_resources_file
  //       if (file) {
  //         const reader = new FileReader();
  //         reader.onload = (e: any) => {
  //           this.src = e.target.result
  //         }
  //         reader.readAsDataURL(file);
  //       }
  //     } else
  //       this.src = this.data.html_resources_file
  //   }
  //   else {
  //     if (this.data.type == 'pdf') this.spinLoader = true
  //     if (typeof (this.data.main_resources_file) == 'object') {
  //       const file = this.data.main_resources_file
  //       if (file) {
  //         const reader = new FileReader();
  //         let src
  //         reader.onload = (e: any) => {
  //           this.src = e.target.result
  //         }
  //         reader.readAsDataURL(file);
  //       }
  //     } else
  //       this.src = this.data.main_resources_file
  //   }
  // }
  @ViewChild('videoPlayer') videoPlayer!: ElementRef<HTMLVideoElement>;

  ngOnInit(): void {
    this.viewFlag[this.data.type] = true;
    this.spinLoader = (this.data.type === 'ppt' || this.data.type === 'pdf');

    if (typeof this.data.main_resources_file === 'object') {
      this.loadFile(this.data.main_resources_file);
    } else {
      this.src = this.data.main_resources_file;
    }
  }

  ngAfterViewInit(): void {
    if (this.data.type === 'video' && this.src) {
      if (Hls.isSupported() && typeof this.src === 'string') {
        this.hls = new Hls();
        this.hls.loadSource(this.src);
        this.hls.attachMedia(this.videoPlayer.nativeElement);
      } else {
        this.videoPlayer.nativeElement.src = this.src as string;
      }
    }
  }
  videoSizeFlag:boolean = false
  private loadFile(file: File): void {
    const reader = new FileReader();
    const fileSize = file.size / (1024 * 1024);
    // console.error((file.size / (1024 * 1024)))
    if (fileSize <= 250) {
      reader.onload = (e: any) => {
        if (this.data.type === 'video') {
          this.src = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file));
        } else {
          this.src = e.target.result;
        }
        this.spinLoader = false;
      };

      if (this.data.type === 'video') {
        reader.readAsArrayBuffer(file);
      } else {
        reader.readAsDataURL(file);
      }
    }
    else{
      this.videoSizeFlag = true
    }
  }

  ngOnDestroy(): void {
    if (this.hls) {
      this.hls.destroy();
    }
    if (this.data.type === 'video' && typeof this.src === 'string') {
      URL.revokeObjectURL(this.src as string);
    }
  }
  spinLoader: boolean = false
  src: any
  zoomScale: any = 'page-height'
  zoomScale1: any = 'page-width'
  viewFlag: any = {
    pdf: false,
    video: false,
    ppt: false,
    images: false
  }
  close() {
    this.dialogRef.close(false)
  }
}


@Component({
  selector: 'resouce-publish-dialog',
  templateUrl: './publish-success-dialog.html',
  encapsulation: ViewEncapsulation.None
})
export class resourcePublishDialog implements OnInit {
  constructor(
    private toster: ToastrService,
    public dialogRef: MatDialogRef<resourcePreviewDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router
  ) { }
  ngOnInit(): void {
  }
  goToHomePage() {
    this.dialogRef.close()
    this.router.navigate(['/admin-dashboard'])
  }
  goToPublishPreview() {
    this.dialogRef.close()
    sessionStorage.setItem('content-preview', '1')
    this.router.navigate(['/game/resources'], { queryParams: { id: this.data } })
  }
}