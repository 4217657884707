import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataSharingServiceService } from '../services/data-sharing-service.service';
import { HttpService } from '../services/http.service';
import { DashboardService } from '../Shared/Services/Dashboard.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @Input() first: any = null
  @Input() secound: any
  @Input() third: any
  @Input() oneHeader: any
  @Input() adminFlag: boolean = false
  @Output() notifyParent: EventEmitter<void> = new EventEmitter();
  constructor(private router: Router, private dataShare: DataSharingServiceService, private httpSv: HttpService, private dashboardService: DashboardService, private toster: ToastrService, private route: ActivatedRoute) {
  }
  toggleFlag: boolean = false
  notificationToggle: boolean = false
  username: string | null = ''
  notification_count: string | null = '0'
  contentPreview: boolean = false
  ngOnInit(): void {
    // if(sessionStorage.getItem("role") == "1"){
    //   this.adminFlag = true
    // }
    if (sessionStorage.getItem('content-preview') == '1') {
      this.contentPreview = true
    }
    setTimeout(() => {
      this.username = sessionStorage.getItem('role') ? sessionStorage.getItem('super_userName') : sessionStorage.getItem('userName')
    }, 800)
    // this.notification_count = sessionStorage.getItem('notification_count')
  }
  contentAdminFlag: boolean = true
  ngAfterContentInit(): void {
    //Called after ngOnInit when the component's or directive's content has been initialized.
    //Add 'implements AfterContentInit' to the class.
    if (this.oneHeader == 'Content Admin Dashboard') {
      this.contentAdminFlag = true
    } else {
      this.contentAdminFlag = false
    }
    if (this.adminFlag) {
      this.getNotificationCount(1)
    } else if (!this.adminFlag && !this.contentPreview) {
      this.getNotificationCount(3)
    }
  }
  routeLandingPage() {
    if (this.adminFlag == true || this.contentPreview == true) {
      this.router.navigate(['/admin-dashboard'])
    } else {
      this.router.navigate(['/dashboard'])
      this.dataShare.exploreGameSelection = null
    }
  }
  goToExplore() {
    this.dataShare.exploreGameSelection = ''
    this.router.navigate(['/exploreGames'])
  }
  logout() {
    // this.router.navigate(['/login'])
    this.httpSv.logOut().subscribe((res: any) => {
      var role = sessionStorage.getItem('role')
      if (role == '1')
        sessionStorage.removeItem('super_token')
      else if (role == '2')
        sessionStorage.removeItem('content_token')
      else
        sessionStorage.removeItem('token')
      sessionStorage.clear()
      if (this.adminFlag == true) {
        this.router.navigate(['/admin'])
      } else
        this.router.navigate(['/login'])
    })
  }
  goToMyGame() {
    this.dataShare.myGames = null
    this.router.navigate(['myGames'])
  }
  goGameReport() {
    if (this.route.snapshot.url[0].path == 'scheduled'){
      this.notifyParent.emit();
      this.toggleFlag = false
    }
    else {
      this.dashboardService.archiveFlag = true
      this.router.navigate(['/scheduled'])
    }
  }
  goGameTrack(){
    this.router.navigate(['/track-games'])
  }
  goToTCPage(){
    this.router.navigate(['/t&c'])
  }
  goToAccountSetting() {
    this.toggleFlag = false
    this.router.navigate(['/accountSetting'])
  }
  tempCount: any = 0
  tempNotificationCount: any = 0
  toggleFunc() {
    this.toggleFlag = !this.toggleFlag
    if (!this.toggleFlag)
      this.tempCount = 0
  }
  notificationToggleFunc() {
    this.notification_count = '0'
    sessionStorage.setItem('notification_count', '0')
    this.getAllNotification()
    this.notificationToggle = !this.notificationToggle
    if (!this.notificationToggle)
      this.tempNotificationCount = 0
  }
  outSideClickHandle(event: any) {
    if (event) {
      if (this.toggleFlag) {
        if (this.tempCount == 1) {
          this.toggleFlag = false
          this.tempCount = 0
        }
        else
          this.tempCount = 1
      }
    }
  }
 
  outSideClickHandleNotification(event: any) {
    if (event) {
      if (this.notificationToggle) {
        if (this.tempNotificationCount == 1) {
          this.notificationToggle = false
          this.tempNotificationCount = 0
        }
        else
          this.tempNotificationCount = 1
      }
    }
  }
  goToCalendar() {
    this.router.navigate(['/config/status'])
  }
  allNotificationArr: any[] = []
  getAllNotification() {
    this.httpSv.getFacNotification().subscribe((res: any) => {
      if (res['status']) {
        this.allNotificationArr = res.results
      } else {
        this.allNotificationArr = []
      }
    }, (err: any) => {
      this.toster.error(err.error.message, '', { timeOut: 2000 })
    })

  }
  getNotificationCount(type: number) {
    if (type == 1) {
      this.httpSv.getAdminNotificationAdmin().subscribe((res: any) => {
        if (res['status']) {
          this.notification_count = res.results[0].notification_count
        }
        else {
          this.toster.warning('Something gone wrong', '', { timeOut: 2000 })
        }
      }, (err: any) => {
        this.toster.error(err.error.message, '', { timeOut: 2000 })
      })
    } else if (type == 3) {
      this.httpSv.getFacilitatorNotificationCount().subscribe((res: any) => {
        if (res['status']) {
          this.notification_count = res.results[0].notification_count
        }
        else {
          this.toster.warning('Something gone wrong', '', { timeOut: 2000 })
        }
      }, (err: any) => {
        this.toster.error(err.error.message, '', { timeOut: 2000 })
      })
    }
  }
}
