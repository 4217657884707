import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
@Component({
  selector: 'app-term-condition-page',
  templateUrl: './term-condition-page.component.html',
  styleUrls: ['./term-condition-page.component.css']
})
export class TermConditionPageComponent implements OnInit {

  constructor(
    private location: Location,
    private router: Router
  ) { }

  ngOnInit(): void {
  }
  goBack() {
    if (sessionStorage.getItem('token')) {
      this.location.back()
    } else {
      this.router.navigate(['/login'])
    }
  }
}
