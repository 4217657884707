import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataSharingServiceService } from 'src/app/services/data-sharing-service.service';
import { HttpService } from 'src/app/services/http.service';
import { DatePipe } from "@angular/common";


@Component({
  selector: 'app-game-assignment',
  templateUrl: './game-assignment.component.html',
  styleUrls: ['./game-assignment.component.css'],
  providers: [DatePipe]
})
export class GameAssignmentComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private httpSv: HttpService,
    private router: Router,
    public toster: ToastrService,
    private location: Location,
    private dataService: DataSharingServiceService,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    const datePipe = new DatePipe('en-Us');
    this.todayDates = datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.route.queryParams.subscribe((result: any) => {
      if (result.id)
        this.user_id = result.id
      else {
        this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
        this.location.back();
      }
    })
    this.getUserDetail()
    this.role_name = this.dataService.role_id_name
  }
  navigateBack() {
    this.location.back();
  }
  changeDate(event: any) {
    const datePipe = new DatePipe('en-Us');
    this.updateDate = datePipe.transform(new Date(event.target.value), 'yyyy-MM-dd');
  }
  header: string = 'Users'
  selectedExplore: any = 'digital'
  user_id: any
  todayDates: any
  user_dtls: any
  user_subcription: any[] = []
  user_physical_subcription: any[] = []
  user_active_physical_subcription: any[] = []
  user_digital_subcription: any[] = []
  user_digital_subcription_active_flag: boolean = true;
  // user_inactive_digital_subcription: any[] = []
  unique_user_digital_subcription: any[] = []
  new_user_digital_subcription: any[] = []
  license_left: any = 0
  licence_total: any = 0
  checkDigitalGame: any = false
  updateDate: any;
  updateKits: any;
  role_name: any
  addOrUpdate: any = 0
  py_assign_flag: boolean = false
  di_assign_flag: boolean = false
  selectedId: any = 0
  updateCheck: boolean = false
  todayDate: any = new Date()
  selectedRightCard: string = 'Assign Game'
  updateGame(item: any) {

    this.updateKits = item.game_kits
    this.updateCheck = true
    setTimeout(() => {
      document.getElementById("datePicker")?.setAttribute('value', this.todayDates);
    }, 10)
  }
  submit(item: any) {
    let data = {
      // endDate: Math.floor(new Date(this.updateDate).getTime() / 1000),
      game_kits: this.updateKits ? this.updateKits : 0,
      game_id: item.game_id,
      id: item.id,
      user_id: this.user_id
    }
    this.httpSv.editPhysicalGame(data).subscribe((res: any) => {
      this.updateCheck = false
      this.ngOnInit()
    })
  }
  validityUpdateFlag: boolean = false
  changeValidty() {
    this.validityUpdateFlag = true
    this.updateDate = this.minEndDate;
  }
  updateValidity() {
    let data = {
      user_id: this.user_id,
      end_date: Math.floor(new Date(this.updateDate).getTime() / 1000)
    }
    this.httpSv.updatePhysicalGameDate(data).subscribe((res:any)=>{
      if(res['status']){
        this.validityUpdateFlag = false
        this.ngOnInit()
      }else{
        this.toster.info(res['message'])
      }
    })
  }
  counterClick: any = 0
  outSideValidtyBoxClickHandle(event: any) {
    if (event && this.validityUpdateFlag) {
      if (this.counterClick == 1) {
        this.validityUpdateFlag = false
        this.counterClick = 0
      } else {
        this.counterClick++
      }
    }
  }
  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Backspace' || event.key === 'Delete' || event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
      return;
    }
    // Check if the pressed key is a digit
    const isDigit = /^\d$/.test(event.key);

    // If not a digit, prevent the input
    if (!isDigit) {
      event.preventDefault();
    }
  }
  editUserProfile() {
    this.router.navigate(['/edit-user'], { queryParams: { id: this.user_id } });
  }
  changeKits(event: any) {
    // console.log(event)
    this.updateKits = event.target.innerText
  }
  getQueryDateTime(epoch_time: any) {
    if (epoch_time) {
      const date = new Date(epoch_time * 1000); // Convert seconds to milliseconds

      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based
      const year = String(date.getFullYear()).slice(2);
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');

      return `${day}/${month}/${year} | ${hours}:${minutes}`;
    }
    else {
      return ''
    }
  }
  selectedExploreGame(data: any) {
    if (this.selectedRightCard == 'Assign Game') {
      if (data == this.selectedExplore) return
      // else if(data == 'digital') this.getGameAccessList(1)
      // else if(data == 'physical') this.getGameAccessList(2)
      this.selectedExplore = data;
      this.py_assign_flag = false
      this.di_assign_flag = false
    } else if (this.selectedRightCard == 'Subscription Details') {
      this.selectedExplore = data;
      if (data == 'digital') this.getSubscriptionHistory(1)
      else this.getSubscriptionHistory(2)
    }
  }
  changeRightCard(data: any) {
    if (this.selectedRightCard == data) return
    else {
      this.selectedRightCard = data
      this.selectedExplore = 'digital'
      if (data == 'Subscription Details') {
        this.getSubscriptionHistory(1)
      }
    }
  }
  digitalSubscriptionHistory: any[] = []
  physicalSubscriptionHistory: any[] = []
  getSubscriptionHistory(game_type: any) {
    this.httpSv.getSubscriptionHistory(this.user_id, game_type).subscribe((res: any) => {
      if (res['status']) {
        if (game_type == 1) this.digitalSubscriptionHistory = res['results']
        else this.physicalSubscriptionHistory = res['results']
      } else {
        if (game_type == 1) this.digitalSubscriptionHistory = []
        else this.physicalSubscriptionHistory = []
      }
    }, (err: any) => this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 }))
  }
  convertEpochToDate(epoch: any) {
    const date = new Date(epoch * 1000);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }
  differenceInDays(epoch1: number, epoch2: number): number {
    const date1 = new Date(epoch1 * 1000);
    const date2 = new Date(epoch2 * 1000);

    const time1 = date1.getTime();
    const time2 = date2.getTime();

    const diffInMilliseconds = Math.abs(time2 - time1);

    const millisecondsInOneDay = 24 * 60 * 60 * 1000;
    const diffInDays = Math.ceil(diffInMilliseconds / millisecondsInOneDay);

    return diffInDays;
  }

  getRole(role_id: any) {
    return this.role_name[role_id];
  }
  getDate(date: any) {
    return new Date(date * 1000);
  }
  setOpen(id: any) {
    if (!this.updateCheck)
      this.selectedId = id
  }
  assignNewGame(game_type: string, type: any = 0) {
    this.addOrUpdate = type
    if (game_type == 'digital') this.di_assign_flag = true
    else if (game_type == 'physical') this.py_assign_flag = true
  }
  receiveMessage(event: any) {
    if (event == true) {
      this.py_assign_flag = false
      this.di_assign_flag = false
    }
    else if (event.reload == true) {
      this.getUserDetail()
      this.py_assign_flag = false
      this.di_assign_flag = false
    }
  }
  resolveHandle(user_query: any, index: any) {
    const data = {
      reachout_id: user_query.id
    }
    this.httpSv.updateReachoutQuery(data).subscribe((res: any) => {
      if (res['status']) {
        this.user_dtls.reach_out.splice(index, 1)
        this.toster.success('Reach out resolved sucessfully!', '', { timeOut: 2000 })
      } else {
        this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
      }
    }, (err: any) => { this.toster.error(err.error.message, '', { timeOut: 2000 }) })
  }
  minEndDate: any
  noActiveGameFlag:boolean =true
  getUserDetail() {
    this.user_digital_subcription_active_flag = true
    this.httpSv.getUserInfoWithSubcription(this.user_id).subscribe((res: any) => {
      if (res['status']) {
        this.user_dtls = res['results'].userDtls
        this.user_subcription = res['results'].subcriptionDtls
        this.user_physical_subcription = this.user_subcription.filter((ele: any) => ele.game_type == 2)
        this.user_active_physical_subcription = this.user_physical_subcription.filter((ele: any) => ele.is_active == 1)
        this.user_digital_subcription = this.user_subcription.filter((ele: any) => ele.game_type == 1 && ele.game_id != 4)
        if(this.user_active_physical_subcription.length > 0) this.noActiveGameFlag = false

        let epochEndDate = 0
        this.user_physical_subcription.map((ele: any) => {
          if (epochEndDate < ele.end_date * 1000)
            epochEndDate = ele.end_date * 1000
        })
        if (epochEndDate) {
          this.minEndDate = this.datePipe.transform(epochEndDate, 'yyyy-MM-dd');
        }

        if (this.user_digital_subcription.length > 0) {
          if (this.user_digital_subcription.every((ele: any) => ele.is_active == 0))
            this.user_digital_subcription_active_flag = false
        }
        let temp = 0
        if (this.user_digital_subcription.length) {
          let endDate = this.user_digital_subcription[0].end_date;
          this.license_left = this.user_digital_subcription[0].no_of_licenses_left
          this.licence_total = this.user_digital_subcription[0].no_of_licenses
          const unique = this.user_digital_subcription.filter((obj: any, index: any) => {
            return index === this.user_digital_subcription.findIndex(o => obj.game_id === o.game_id);
          });
          this.unique_user_digital_subcription = unique
          this.user_digital_subcription.map((data: any) => {
            // console.log(data.no_of_licenses_left, data.end_date, endDate)
            if (data.end_date != endDate) {
              this.checkDigitalGame = true
              endDate = data.end_date
              this.license_left += data.no_of_licenses_left
              this.licence_total += data.no_of_licenses
            }
          })
          if (this.checkDigitalGame) {
            this.new_user_digital_subcription = [...this.user_digital_subcription].reverse()
          }

        }
        else {
          this.user_digital_subcription = []
        }
        if (sessionStorage.getItem('expiredSubcription') == 'physical') {
          this.selectedExploreGame('physical')
        }
        sessionStorage.removeItem('expiredSubcription')
        // if(this.user_digital_subcription[0].no_of_licenses_left == 0){
        //   this.checkDigitalGame = true
        //   let end_date = this.user_digital_subcription[0].end_date
        //   this.user_digital_subcription.map((data:any)=>{
        //     if(end_date != data.end_date && data.no_of_licenses_left != 0){
        //       this.new_user_digital_subcription.push(data)
        //     }
        //   })
        //   // this.new_user_digital_subcription = [...this.user_digital_subcription].reverse()
        //   if(this.new_user_digital_subcription.length > 0)
        //     this.license_left = this.new_user_digital_subcription[0].no_of_licenses_left
        //   else 
        //     this.license_left = 0
        //   temp = 1
        // }
        // if(temp == 0)
        //   this.license_left = this.unique_user_digital_subcription[0].no_of_licenses_left

      } else {
        this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
        this.location.back();
      }
    }, (err: any) => {
      this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
      this.location.back();
    })
  }
}











// PHYSICAL GAME ASSIGNMENT 
// PHYSICAL GAME ASSIGNMENT 
// PHYSICAL GAME ASSIGNMENT 
// PHYSICAL GAME ASSIGNMENT 
// PHYSICAL GAME ASSIGNMENT 


@Component({
  selector: 'physical-game-assignment',
  templateUrl: './physical-game-assign.html',
  styleUrls: ['./game-assignment.component.css']
})
export class PhysicalGameAssignmentComponent implements OnInit {
  @Input() gameAccess: any
  @Input() user_id: any
  @Input() addOrUpdate: any
  @Output() backEmitter = new EventEmitter<any>();
  constructor(
    private route: ActivatedRoute,
    private httpSv: HttpService,
    private router: Router,
    public toster: ToastrService,
    private location: Location,
    private dataService: DataSharingServiceService,
  ) { }
  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Backspace' || event.key === 'Delete' || event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
      return;
    }
    // Check if the pressed key is a digit
    const isDigit = /^\d$/.test(event.key);

    // If not a digit, prevent the input
    if (!isDigit) {
      event.preventDefault();
    }
  }
  ngOnInit(): void {
    const datePipe = new DatePipe('en-Us');
    this.todayDate = datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.startDate = datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.start_date = new Date()
    let data: any = document.getElementById("datePicker")?.setAttribute('value', this.todayDate);
    this.getAllGame()
    this.gameAccess.forEach((ele: any) => {
      this.gameAcessArray.push(ele.game_id)
      if (this.minEndDate < ele.end_date * 1000)
        this.minEndDate = ele.end_date * 1000
    });
    console.log(this.gameAccess)
  }
  minEndDate: any = 0;
  gameList: any[] = []
  todayDate: any;
  selectedGames: any = {}
  selectedGamesList: any[] = []
  selectedGamesListId: any[] = []
  start_date: any = null
  selectedTimeline: any = 'months'
  startDate: any;
  end_date: any = null
  validity: any = null
  gameAcessArray: any[] = []
  goBack() {
    this.backEmitter.emit(true)
  }
  handleGameSelection(game_id: any) {
    if (this.selectedGames[game_id]) {
      this.selectedGamesListId.splice(this.selectedGamesListId.indexOf(game_id), 1)
      this.selectedGamesList.splice(this.selectedGamesList.findIndex((ele: any) => ele.id == game_id), 1)
    } else {
      this.selectedGamesListId.push(game_id)
      this.selectedGamesList.push(this.gameList.find((ele: any) => ele.id == game_id))
      this.selectedGamesList[this.selectedGamesList.length - 1]['game_kit'] = null
    }
    this.selectedGames[game_id] = !this.selectedGames[game_id]
  }
  backDateErrorFlag: boolean = false
  dateValidityChangeHandler() {
    if (this.backDateErrorFlag) this.backDateErrorFlag = false
    if (this.startDate && this.validity) {
      let temp = new Date(this.startDate);
      if (this.selectedTimeline == 'months')
        this.end_date = temp.setMonth(temp.getMonth() + this.validity);
      else
        this.end_date = temp.setDate(temp.getDate() + this.validity);

      if (this.gameAccess.length > 0) {
        if (this.end_date / 1000 < this.gameAccess[0].end_date)
          this.backDateErrorFlag = true
      }


    } else {
      this.end_date = null
    }

  }
  getAllGame() {
    this.httpSv.getAllGameList().subscribe((res: any) => {
      if (res['status']) {
        this.gameList = res['results'].filter((ele: any) => {
          if (ele.game_type == 2)
            this.selectedGames[ele.id] = false
          return ele.game_type == 2
        })
        this.gameAcessArray.forEach((ele: any) => {
          if (this.selectedGames[ele] == false) delete this.selectedGames[ele]
        })
        // console.log(this.selectedGames)
      } else {
        this.toster.warning('No Game Found', '', { timeOut: 2000 })
      }
    }, (err: any) => this.toster.error('Oops! Something went wrong.', '', { timeOut: 2000 }))
  }
  assignGame() {
    if (this.backDateErrorFlag) {
      this.toster.warning('Please fill correct end date', '', { timeOut: 2000 })
      return
    }
    if (Object.keys(this.selectedGames).length == 0) {
      this.toster.warning('Already user have all games access', '', { timeOut: 2000 })
    } else {
      let booleanArray = Object.values(this.selectedGames)
      if (booleanArray.every((ele: any) => ele === false)) {
        this.toster.warning('Please select any game(s)', '', { timeOut: 2000 })
      } else {
        if (this.selectedGamesList.some((ele: any) => ele.game_kit == null)) {
          this.toster.warning('Please fill number of game kit(s)', '', { timeOut: 2000 })
        } else {
          if (!this.start_date || !this.end_date) {
            this.toster.warning('Please fill start date and validity', '', { timeOut: 2000 })
          } else {
            const epochStartTime = Math.floor(new Date(this.start_date).getTime() / 1000)
            const epochEndTime = Math.floor(this.end_date / 1000)
            // const epochEndTime = new Date(this.end_date.split('/').reverse().join('-')).getTime()/1000
            let data: any = {
              user_id: this.user_id,
              game_type: 2,
              start_date: epochStartTime,
              end_date: epochEndTime,
              validity: this.validity,
              games: [],
            }
            this.selectedGamesList.forEach((ele: any) => {
              data.games.push({ id: ele.id, game_kits: ele.game_kit })
            })
            this.httpSv.assignPhysicalGames(data).subscribe((res: any) => {
              if (res['status']) {
                this.backEmitter.emit({ reload: true })
                this.toster.success('Game(s) assigned sucessfully', '', { timeOut: 2000 })
              }
              else {
                this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
              }
            }, (err: any) => this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 }))
          }
        }
      }
    }
  }
}
// DIGITAL GAME ASSIGNMENT 
// DIGITAL GAME ASSIGNMENT 
// DIGITAL GAME ASSIGNMENT 
// DIGITAL GAME ASSIGNMENT 
// DIGITAL GAME ASSIGNMENT 


@Component({
  selector: 'digital-game-assignment',
  templateUrl: './digital-game-assign.html',
  styleUrls: ['./game-assignment.component.css']
})
export class DigitalGameAssignmentComponent implements OnInit {
  @Input() gameAccess: any
  @Input() user_id: any
  @Input() addOrUpdate: any
  @Input() user_digital_subcription: any
  @Input() licence_left: any
  @Input() licence_total: any
  @Output() backEmitter = new EventEmitter<any>();
  constructor(
    private route: ActivatedRoute,
    private httpSv: HttpService,
    private router: Router,
    public toster: ToastrService,
    private location: Location,
    private dataService: DataSharingServiceService,
  ) { }
  game_id: any;

  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Backspace' || event.key === 'Delete' || event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
      return;
    }
    // Check if the pressed key is a digit
    const isDigit = /^\d$/.test(event.key);

    // If not a digit, prevent the input
    if (!isDigit) {
      event.preventDefault();
    }
  }
  ngOnInit(): void {
    const datePipe = new DatePipe('en-Us');
    this.todayDate = datePipe.transform(new Date(), 'dd-MM-yyyy');
    this.start_date = datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.startDate = datePipe.transform(new Date(), 'yyyy-MM-dd');
    setTimeout(() => {
      document.getElementById("datePicker")?.setAttribute('value', this.start_date);
    }, 10)
    this.getAllGame()
    this.gameAccess.forEach((ele: any) => {
      this.gameAcessArray.push(ele.game_id)
      this.game_id = ele.id
    });
  }
  gameList: any[] = []
  startDate: any;
  selectedGames: any = {}
  selectedTimeline: any = 'months'
  selectedGamesList: any[] = []
  start_date: any = null
  todayDate: any;
  end_date: any = null
  validity: any = null
  noOfLicenses: any = null
  gameAcessArray: any[] = []
  goBack() {
    this.backEmitter.emit(true)
  }
  handleGameSelection(game_id: any) {
    if (this.selectedGames[game_id]) {
      this.selectedGamesList.splice(this.selectedGamesList.findIndex((ele: any) => ele.id == game_id), 1)
    } else {
      this.selectedGamesList.push(this.gameList.find((ele: any) => ele.id == game_id))
      this.selectedGamesList[this.selectedGamesList.length - 1]['game_kit'] = null
    }
    this.selectedGames[game_id] = !this.selectedGames[game_id]
  }
  dateValidityChangeHandler() {
    // console.log("aaaa")
    if (this.startDate && this.validity) {
      // console.log(this.start_date, this.validity)
      // let temp = this.start_date.split('-').reverse()
      // temp[1] = (+temp[1] + +this.validity).toString().padStart(2, "0")
      const datePipe = new DatePipe('en-Us');
      let temp = new Date(this.startDate);
      // console.log(this.selectedTimeline)
      if (this.selectedTimeline == 'months')
        this.end_date = temp.setMonth(temp.getMonth() + this.validity);
      else
        this.end_date = temp.setDate(temp.getDate() + this.validity);

      // this.end_date = datePipe.transform(this.end_date,'dd-MM-yyyy')
      // this.end_date = `${this.end_date.getDate()}/${this.end_date.getMonth()}/${this.end_date.getFullYear()}`
      // console.log(this.end_date)
    } else {
      this.end_date = null
    }

  }
  getAllGame() {
    this.httpSv.getAllGameList().subscribe((res: any) => {
      if (res['status']) {
        this.gameList = res['results'].filter((ele: any) => {
          if (ele.game_type == 1)
            this.selectedGames[ele.id] = true
          return ele.game_type == 1
        })
        this.gameAcessArray.forEach((ele: any) => {
          if (this.selectedGames[ele] == false) delete this.selectedGames[ele]
        })
        // console.log(this.selectedGames)
      } else {
        this.toster.warning('No Game Found', '', { timeOut: 2000 })
      }
    }, (err: any) => this.toster.error('Oops! Something went wrong.', '', { timeOut: 2000 }))
  }
  assignGame() {
    if (Object.keys(this.selectedGames).length == 0) {
      this.toster.warning('Already user have all games access', '', { timeOut: 2000 })
    } else {
      let booleanArray = Object.values(this.selectedGames)
      if (booleanArray.every((ele: any) => ele === false)) {
        this.toster.warning('Please select any game(s)', '', { timeOut: 2000 })
      } else {
        if (this.selectedGamesList.some((ele: any) => ele.game_kit == null)) {
          this.toster.warning('Please fill number of game kit(s)', '', { timeOut: 2000 })
        } else {
          if (!this.start_date || !this.end_date) {
            this.toster.warning('Please fill start date and validity', '', { timeOut: 2000 })
          } else {
            const epochStartTime = Math.floor(new Date(this.start_date).getTime() / 1000)
            const epochEndTime = Math.floor(this.end_date / 1000)
            // const epochEndTime = new Date(this.end_date.split('/').reverse().join('-')).getTime()/1000
            let data: any = {
              user_id: this.user_id,
              game_type: 1,
              start_date: epochStartTime,
              end_date: epochEndTime,
              validity: this.validity,
              no_of_licenses: this.noOfLicenses ? this.noOfLicenses : 0,
              games: [],
              update_game: this.addOrUpdate ? 1 : 0,
              game_id: this.game_id
            }
            this.gameList.forEach((ele: any) => {
              data.games.push({ id: ele.id })
            })
            if (!this.addOrUpdate) {
              this.httpSv.assignPhysicalGames(data).subscribe((res: any) => {
                if (res['status']) {
                  this.backEmitter.emit({ reload: true })
                  this.toster.success('Games assigned sucessfully', '', { timeOut: 2000 })
                }
                else {
                  this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
                }
              }, (err: any) => this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 }))
            }
            else {
              this.httpSv.updatePhysicalGames(data).subscribe((res: any) => {
                if (res['status']) {
                  this.backEmitter.emit({ reload: true })
                  this.toster.success('Games updated sucessfully', '', { timeOut: 2000 })
                }
                else {
                  this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
                }
              }, (err: any) => this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 }))
            }
          }
        }
      }
    }
  }
}