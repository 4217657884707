<div class="slotAlertMainDiv">
    <div class="note_heading_main_div">
        <div class="note_heading_div">Note</div>
    </div>
    <div class="note_content_div">
        <div>
            Scheduling a session in a slot that includes the present time will limit your duration to the time left till
            the end of the slot, not the total duration.
        </div>
        <br>
        <div [innerHTML]="data"></div>
    </div>
    <div class="slotAlertCloseBtnDiv">
        <button type="button" (click)="close()">OK</button>
    </div>
</div>


<style>
    ::ng-deep .mat-dialog-container {
        padding: 0 !important;
        border-radius: 10px;
    }

    .note_heading_main_div {
        width: 100%;
        height: 51px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #F8F8F8;
        border-bottom: 1px solid #E8E8E8;
    }

    .note_heading_div {
        font-size: 20px;
        font-weight: 500;
        color: #FF4D6F;
    }

    .slotAlertMainDiv {
        width: 667px;
        text-align: center;

    }

    .note_content_div {
        padding: 20px;
        text-align: left;
        font-size: 15px;
        color: #616060;
        font-weight: 400;
    }

    .slotAlertCloseBtnDiv {
        text-align: center;
        margin-top: 1rem;
        margin-bottom: 2rem;
    }

    .slotAlertCloseBtnDiv button {
        width: 156px;
        height: 32px;
        border: 1px solid #353535;
        color: #353535;
        font-size: 15px;
        font-weight: 500;
        border-radius: 50px;
        background-color: transparent;
    }
</style>