<div style="height: 100vh;">
    <app-header></app-header>
    <div class="main-div-container" id="mainDivContainer">

        <div class="" style="height: 100%;">

            <div class="bg-side-div">
                <img src="../../../assets/img/bg-curve.svg" alt="" srcset="">
            </div>
            <div class="container-div">
                <div class="upper-section">
                    <div class="upper-left-section">
                        <div class="backBtn"> <img style="padding-left: 12px;width: 80%;height: auto;" (click)="goBack()"
                                src="../../../assets/img/arrow_back_ios_FILL0_wght400_GRAD0_opsz48.svg"></div>
                        <img src="../../../assets/img/questico_logo.svg" alt="" class="questico-logo">
                    </div>
                    <div class="upper-middle-section">
                        <img src="../../../assets/img/qustico_comp.png" alt="">
                    </div>
                    <div class="upper-right-section">
                        <div class="upper-right-text">
                            Questico is a fully customizable race themed platform for gamified learning. It allows to
                            integrate multiple challenges built on any of your existing authoring tools or content
                            platforms, and works as both single-player game for self paced learning or team based games.
                        </div>
                        <div class="upper-right-button" (click)="goForShedule()">
                            <button>Schedule a Demo</button>
                        </div>
                    </div>
                </div>
                <div class="lower-section">
                    <div class="lower-card">
                        <div class="lower-logo-div">
                            <img src="../../../assets/img/quetico_useCase.svg" alt="">
                        </div>
                        <div class="lower-heading-div">
                            Use Cases
                        </div>
                        <div class="lower-dtl-div">
                            Questico can be used for Onboarding & Induction, Mandatory trainings such as POSH, DEI, Safety training, Values immersion, team engagament and more.
                        </div>
                    </div>
                    <div class="lower-card">
                        <div class="lower-logo-div">
                            <img src="../../../assets/img/quetico_programs.svg" alt="">
                        </div>
                        <div class="lower-heading-div">
                            Programs
                        </div>
                        <div class="lower-dtl-div">
                            Questico is ideal for both instructor-led and self-paced learning needs. It can be used for live sessions as a multiplayer game or can be assigned to individuals to play for a certain window of time. 
                        </div>
                    </div>
                    <div class="lower-card">
                        <div class="lower-logo-div">
                            <img src="../../../assets/img/quetico_raceTheme.svg" alt="">
                        </div>
                        <div class="lower-heading-div">
                            Race Theme
                        </div>
                        <div class="lower-dtl-div">
                            It has exciting race themes like- The Great Ocean Race and The Keeper of Kingdoms. Also available are- generic theme or customized skin option.
                        </div>
                    </div>
                    <div class="lower-card">
                        <div class="lower-logo-div">
                            <img src="../../../assets/img/quetico_challanges.svg" alt="">
                        </div>
                        <div class="lower-heading-div">
                            Challenges
                        </div>
                        <div class="lower-dtl-div">
                            Build your own challenges or choose from a list of available challenges across themes. Allows import of existing content in various formats- image, video, embed code, html etc to create custom challenges 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>