<div class="term_conditon_main_div">
    <div class="term_condtion_header_main_div">
        <div class="term_condtion_header">
            Terms & Conditions
        </div>
        <div>
            <img src="../../../assets/carbon_close-outline.svg" alt="" style="cursor: pointer;" (click)="close()">
        </div>
    </div>

    <div class="term_condtion_content_main_div">
        <div class="term_condtion_content_sub_div">
            <div class="clause_heading">License Deduction:</div>
            <div class="clause_description">
                <span class="clause_description_sub_heading">Per Player Deduction: </span> 1 license unit is deducted per player per game.
                <br>
                <br>
                <span class="clause_description_sub_heading">If the session is completed with the planned number of players: </span> Licenses used = Number of
                players who joined the game.
                <br>
                <br>
                <span class="clause_description_sub_heading">If the session is completed with fewer players than planned: </span> Licenses used = The higher of
                either the number of players who joined the game or 50% of the number of players planned.
                <br>
                <br>
                <span class="clause_description_sub_heading">Examples:</span>
                <br>
                Planned players = 100, Players who joined = 80  &rarr; Licenses used = 80
                <br>
                Planned players = 100, Players who joined = 43 → Licenses used = 50
            </div>
        </div>
        <div class="term_condtion_content_sub_div">
            <div class="clause_heading">License Credit for Cancelled Sessions:</div>
            <div class="clause_description">
                <span>To receive a full credit of the licenses, the session must be deleted at least 1 hour before the
                    scheduled start time.</span>
                <br>
                <br>
                <span>If a session is scheduled to start immediately (in the current time slot), it cannot be edited or
                    deleted.</span>
            </div>
        </div>
        <div class="term_condtion_content_sub_div">
            <div class="clause_heading">Partial License Credit for Unused Sessions:</div>
            <div class="clause_description">
                <span>If the session is not deleted or has been run, only 50% of the blocked licenses will be credited
                    back.</span>
            </div>
        </div>
    </div>
</div>











<style>
    .mat-dialog-container {
        padding: 0 !important;
        border-radius: 8px;
    }

    .term_conditon_main_div {
        width: 710px;
        height: 551px;
        display: flex;
        flex-direction: column;
    }

    .term_condtion_header_main_div {
        background-color: #F4F4F4;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 62px;
        padding: 0 30px;
    }

    .term_condtion_header {
        color: #0E43DB;
        font-size: 20px;
        font-weight: 500;
    }

    .term_condtion_content_main_div {
        padding: 15px;
        padding-left: 30px;
        padding-right: 20px;
        margin-right: 10px;
        display: flex;
        flex-direction: column;
        gap: 15px;
        flex: 1;
        overflow-y: auto;
    }

    .clause_heading {
        font-size: 16px;
        color: #494949;
        font-weight: 700;
        margin-bottom: 10px;
    }

    .clause_description {
        color: #494949;
        font-size: 15px;
        font-weight: 400;
    }



    .term_condtion_content_main_div::-webkit-scrollbar {
        width: 8px;
        height: 50px;
        border-radius: 4px;
        opacity: 0.6;

    }


    .term_condtion_content_main_div::-webkit-scrollbar-track {
        background: #F0F6F7;
        width: 8px;
        margin: 14px 0;
    }


    .term_condtion_content_main_div::-webkit-scrollbar-thumb {
        background: #868686;
        background: #868686 0% 0% no-repeat padding-box;
        width: 8px;
        height: 100px;
        border-radius: 4px;
        opacity: 0.6;
    }
    .clause_description_sub_heading{
        font-weight: 500;
    }
</style>