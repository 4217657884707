<div class="dialog_main_div">
    <div class="img_div">
        <img src="../../../../assets/warning_icon.svg" alt="">
    </div>
    <div class="dialog_txt">{{dialogTxt}}</div>
    <div class="btn_main_div">
        <button class="btn_custom" (click)="confirm()">Yes</button>
        <button class="no_btn" (click)="close()">No</button>
    </div>
</div>



<style>
    .dialog_main_div {
        width: 340px;
    }

    .img_div {
        text-align: center;
    }

    .img_div img {
        width: 64px;
    }

    .dialog_txt {
        text-align: center;
        margin: 10px 0;
    }

    .btn_main_div {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }

    .btn_custom {
        font-size: 1rem;
        border: none;
        outline: none;
        background: transparent;
        background-image: url(../../../../assets/btn_green_bg.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position-x: center;
        padding: 0px 34px;
        font-weight: 500;
    }

    .no_btn {
        font-size: 1rem;
        outline: none;
        background: transparent;
        background-size: contain;
        background-repeat: no-repeat;
        background-position-x: center;
        padding: 0px 34px;
        font-weight: 500;
        border: 1px solid #bfbfbf;
        border-radius: 27px;
    }
</style>