<div class="main-dialog-div-conitainer">
    <div style="text-align: center; margin-top: 22px;">
        <img src="../../../assets/img/Group_9673.svg" alt="" style="width: 15%;">
    </div>
    <div style="text-align: center; margin: 20px 0px;" >
        <!-- <div>The game has been</div> -->
        <div *ngIf="data != false" style="font-weight: 600;">This game is configured!</div>
        <div *ngIf="data == false" style="font-weight: 600;">The game is <br> successfully edited</div>
    </div>
    <div style="text-align: center;">
        <input style="width: 54%;" type="image" src="../../../assets/img/next_config.svg" (click)="close(true)">
    </div>
</div>
<style>
    .mat-dialog-container{
        padding: 0 !important;
        border-radius: 10px !important;
    }
    .main-dialog-div-conitainer {
        border: 2px solid #00D39A !important;
        border-radius: 10px;
        background-color: white;
        padding: 20px;
        min-width: 400px;
    }
</style>