<div class="main_video_player_div">
    <div class="close_dilog_div"><img src="../../../assets/close_preview.svg" alt="" (click)="closeVideoPlayer()"></div>
    <div class="video_main_div">
        <video controls [muted]="'muted'" id="videoPlayer" controlsList="nodownload" disablePictureInPicture
            #videoPlayer style="width: 100%;" autoplay [src]="src" type="video/mp4">
            Browser not supported
        </video>
    </div>
</div>



<style>
    ::ng-deep .mat-dialog-container {
        padding: 0 !important;
        border-radius: 10px;
    }

    .main_video_player_div {
        padding: 20px;
        position: relative;
    }

    .close_dilog_div {
        position: absolute;
        right: 10px;
        top: 7px;
    }

    .close_dilog_div img {
        width: 22px;
        cursor: pointer;
    }

    .video_main_div {
        max-height: 80vh;
        width: 70vw;
    }

    .video_main_div video {
        max-height: 75vh;
    }
</style>