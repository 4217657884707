import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-reach-out',
  templateUrl: './reach-out.component.html',
  styleUrls: ['./reach-out.component.css']
})
export class ReachOutComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ReachOutComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toster: ToastrService,
    private httpSv: HttpService
  ) { }

  ngOnInit(): void {
    if (sessionStorage.getItem('ids')) this.user_id = sessionStorage.getItem('ids')
    else {
      this.toster.error('Oops! Something Breaks!!', '', { timeOut: 2000 })
      this.dialogRef.close(false)
    }
  }
  user_id: any
  reachReasons: any[] = [
    {
      id: 0,
      txt: "I want to buy more Licenses",
      selected: false
    },
    {
      id: 1,
      txt: "I want to do a game demo",
      selected: false
    },
    {
      id: 2,
      txt: "I want to buy a digital game",
      selected: false
    },
    {
      id: 3,
      txt: "I want to extend my time duration",
      selected: false
    },
    {
      id: 4,
      txt: "I want to buy a physical game",
      selected: false
    },
    {
      id: 5,
      txt: "I want to buy more game kits",
      selected: false
    }
  ];
  queryTxt: any = null
  errFlag: boolean = false
  submitReachout() {
    if (!this.reachReasons.every((ele: any) => ele.selected == false) || (this.queryTxt && this.queryTxt.trim() != '')) {
      let data: any = {
        user_id: this.user_id,
        reachout_reason: []
      }
      this.reachReasons.forEach((ele: any) => {
        if (ele.selected) data.reachout_reason.push(ele.txt)
      })
      if (this.queryTxt && this.queryTxt.trim() != '') data.querie = this.queryTxt
      this.httpSv.raiseReachoutRequest(data).subscribe((res: any) => {
        if (res['status']) {
          this.dialogRef.close(true)
          this.toster.success('Thank you for the Reachout. Kairos team will get in touch with you soon.','',{timeOut:2000})
        } else {
          this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
        }
      }, (err: any) => this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 }))
    }
    else {
      this.errFlag = true
      setTimeout(() => { this.errFlag = false }, 2000)
    }
  }
}
