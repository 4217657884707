import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-track-game',
  templateUrl: './track-game.component.html',
  styleUrls: ['./track-game.component.css']
})
export class TrackGameComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator | null = null
  constructor(
    private toster: ToastrService,
    private httpSv: HttpService,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.getAllSessionDetails()
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.dataSource.paginator = this.paginator;
    },);
  }
  navigateBack(){
    this.location.back();
  }
  header: string = 'Super Admin Dasboard';
  displayedColumns: string[] = ['sl_no', 'user_name', 'email', 'program_name', 'client_name', 'date_time', 'players', 'game_status', 'platform', 'game_plan'];
  dataSource: any = new MatTableDataSource();
  pageSizes = [10, 25, 50, 100];
  mid_alert_vlaue: number = 40;
  high_alert_vlaue: number = 80;
  sessionRecords: any[] = []
  getDateTimeByEpoch(start_time: any, end_time: any) {
    if (start_time && end_time) {
      function formatDateTime(epoch: any) {
        const date = new Date(epoch);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // The hour '0' should be '12'
        const formattedHours = String(hours).padStart(2, '0');

        return `${day}/${month}/${year} ${formattedHours}:${minutes} ${ampm}`;
      }

      // Format the start and end epoch times
      const startDateTime = formatDateTime(start_time*1000);
      const endDateTime = formatDateTime(end_time*1000);

      return `
             ${startDateTime}
             <br />
             to
             <br />
              ${endDateTime}
           `
    }
    else{
      return '--'
    }
  }
  getAllSessionDetails() {
    this.httpSv.getCalenderData().subscribe((res: any) => {
      if (res['status']) {
        this.sessionRecords = res['results']
        this.dataSource.data = res['results']
        setTimeout(() => {
          this.dataSource.paginator = this.paginator;
        }, 10);
      }
      else {
        this.toster.error("No Data found.", '', { timeOut: 2000 })
        this.sessionRecords = []
      }
    }, (err: any) => {
      this.toster.error("No Data found.", '', { timeOut: 2000 })
    })
  }
}
