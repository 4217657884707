import { Component, Injectable, OnInit } from '@angular/core';
import {
  NgbCalendar,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDatepickerModule,
  NgbDateStruct,
  NgbTimepickerModule,
} from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { JsonPipe } from '@angular/common';
import { HttpService } from 'src/app/services/http.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {
  readonly DELIMITER = '-';

  fromModel(value: string | null): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  toModel(date: NgbDateStruct | null): string | null {
    return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : null;
  }
}

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : '';
  }
}
@Component({
  selector: 'app-quik-game-config',
  templateUrl: './quik-game-config.component.html',
  styleUrls: ['./quik-game-config.component.css'],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})
export class QuikGameConfigComponent implements OnInit {
  model1: any;
  todaysDate: any;
  // time = { hour: null, minute: null };
  // model2: string | undefined;
  constructor(
    private ngbCalendar: NgbCalendar,
    private dateAdapter: NgbDateAdapter<string>,
    private httpSv: HttpService,
    private toster: ToastrService,
    private http: HttpClient
  ) {

  }

  ngOnInit(): void {
    const today = new Date();

    // Extract day, month, and year
    const day = today.getDate();
    const month = today.getMonth() + 1; // Note: January is 0, so we add 1
    const year = today.getFullYear();

    // Format the date as "day-month-year"
    this.model1 = `${day}-${month}-${year}`;
    this.todaysDate = { year, month, day };
    this.allDigitalGame.forEach((ele: any) => {
      ele.date = this.model1
    })
    this.getAllShedualedGame()
  }
  allDigitalGame: any[] = [
    { id: 1, name: 'Value Continum', src: '../../../../assets/img/value_continum.svg', showCalender: false, showTimepicker: false, date: null, time: { hour: null, minute: null } },
    { id: 2, name: 'Bottle Neck Challenge', src: '../../../../assets/img/bottleneck.svg', showCalender: false, showTimepicker: false, date: null, time: { hour: null, minute: null } },
    { id: 3, name: 'Warehouse', src: '../../../../assets/img/warehouse.svg', showCalender: false, showTimepicker: false, date: null, time: { hour: null, minute: null } },
    { id: 4, name: 'Picture Perfect', src: '../../../../assets/img/picturePerfect.svg', showCalender: false, showTimepicker: false, date: null, time: { hour: null, minute: null } },
    { id: 5, name: 'Animal Farm', src: '../../../../assets/img/animal_faram.svg', showCalender: false, showTimepicker: false, date: null, time: { hour: null, minute: null } },
    { id: 6, name: 'Big Picture', src: '../../../../assets/img/bigPicture.svg', showCalender: false, showTimepicker: false, date: null, time: { hour: null, minute: null } },
  ]

  get today() {
    return this.dateAdapter.toModel(this.ngbCalendar.getToday())!;
  }
  showCalender: boolean = false
  showTimepicker: boolean = false
  showCalenderFunc(item: any) {
    this.allDigitalGame.map((ele: any) => ele.showCalender = false);
    this.tempCount = 0
    this.tempCount1 = 0
    item.date = `${this.todaysDate.day}-${this.todaysDate.month}-${this.todaysDate.year}`;
    item.showCalender = true;
  }
  onDateSelect(event: any, item: any) {
    item.showCalender = false
    item.showTimepicker = true
    // this.tempCount = 0;
  }
  tempCount: any = 0
  calenderOutSideClickHandle(event: any, item: any) {
    if (event) {
      if (item.showCalender) {
        if (this.tempCount == 1) {
          item.showCalender = false
          this.tempCount = 0
        }
        else
          this.tempCount = 1
      }
    }
  }
  tempCount1: any = 0
  timeOutSideClickHandle(event: any, item: any) {
    if (event) {
      if (item.showTimepicker) {
        if (this.tempCount1 == 1) {
          item.showTimepicker = false
          this.tempCount1 = 0
        }
        else
          this.tempCount1 = 1
      }
    }
  }
  configure(game_id: any, item: any) {
    // console.log(this.model1)
    // console.log(this.time)
    if (item.time.hour != null && item.time.minute != null) {
      // Split date components
      const [day, month, year] = item.date.split('-').map(Number);

      // Extract time components
      const { hour, minute } = item.time;

      // Construct new Date object
      const dateTime = new Date(year, month - 1, day, hour, minute); // Note: Month is zero-indexed

      // Calculate epoch time (Unix timestamp)
      const epochTimeStart = dateTime.getTime() / 1000;
      const epochTimeEnd = epochTimeStart + (24 * 3600);

      // console.log(epochTimeStart);
      // console.log(epochTimeEnd);

      let parts = item.date.split("-");
      let programCofigData = {
        program_name: "BSL DEMO",
        program_date: `${parts[2]}-${parts[1].padStart(2, "0")}-${parts[0].padStart(2, "0")}`,
        client_name: "BSL",
        game_id: [
          game_id
        ],
        program_id: null,
        language: "en"
      }
      this.httpSv.sessionPost(programCofigData).subscribe((res: any) => {
        if (res['status']) {
          let program_id = res['result'].id;
          let timeSlotData = {
            program_id: res['result'].id,
            program_date: `${parts[2]}-${parts[1].padStart(2, "0")}-${parts[0].padStart(2, "0")}`,
            players: 10,
            start_time: epochTimeStart,
            expiry_time: epochTimeEnd,
          }
          this.httpSv.bookAdminSlot(timeSlotData).subscribe((res: any) => {
            if (res['status']) {

              if (game_id == 1) {
                let vc_default_data = {
                  "program_id": program_id,
                  "game_id": 1,
                  "max_player": 10,
                  "game_duration": 60,
                  "pair_id": [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
                }
                this.httpSv.configVcGame(vc_default_data).subscribe((res: any) => {
                  if (res['status']) {
                    this.toster.success('VC configured successfully', '', { timeOut: 2000 })
                    item.showCalender = false
                    item.showTimepicker = false
                    this.ngOnInit()
                  } else {
                    this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
                  }
                }, (err: any) => this.toster.error(err.error.message, '', { timeOut: 2000 }))
              } else if (game_id == 2) {
                let bn_default_data = {
                  program_id: program_id,
                  game_id: 2,
                  max_player: 10,
                  no_of_teams: 1,
                  max_player_per_team: 10,
                  session_duration: 60,
                  board_type: "5 Linear"
                }
                this.httpSv.configBnGame(bn_default_data).subscribe((res: any) => {
                  if (res['status']) {
                    this.toster.success('Bottle neck configured successfully', '', { timeOut: 2000 })
                    item.showCalender = false
                    item.showTimepicker = false
                    this.ngOnInit()
                  } else {
                    this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
                  }
                }, (err: any) => this.toster.error(err.error.message, '', { timeOut: 2000 }))
              } else if (game_id == 3) {
                let wh_default_data = {
                  program_id: program_id,
                  game_id: 3,
                  max_player: 10,
                  no_of_teams: 5,
                  no_of_player_per_team: 2,
                  game_time: 59,
                  session_duration: 60,
                  theme_type: "Computer Hardware",
                  rounds: [
                    {
                      round: "Round 1",
                      level: "Order 1",
                      order: "Simple",
                      order_id: "1",
                      value: "Simple1"
                    },
                    {
                      round: "Round 2",
                      level: "Order 1",
                      order: "Simple",
                      order_id: "1",
                      value: "Simple1"
                    },
                    {
                      round: "Round 3",
                      level: "Order 2",
                      order: "Simple",
                      order_id: "2",
                      value: "Simple2"
                    },
                    {
                      round: "Round 4",
                      level: "Order 2",
                      order: "Simple",
                      order_id: "2",
                      value: "Simple2"
                    }
                  ]
                }
                this.httpSv.configWhGame(wh_default_data).subscribe((res: any) => {
                  if (res['status']) {
                    this.toster.success('Warehouse configured successfully', '', { timeOut: 2000 })
                    item.showCalender = false
                    item.showTimepicker = false
                    this.ngOnInit()
                  } else {
                    this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
                  }
                }, (err: any) => this.toster.error(err.error.message, '', { timeOut: 2000 }))
              }
              else if (game_id == 4) {
                let pp_default_data = {
                  program_id: program_id,
                  game_id: 4,
                  no_of_player: 10,
                  no_of_team: 1,
                  max_player_per_team: 10,
                  no_of_guides: 1,
                  no_of_rounds: "5",
                  session_duration: 60,
                  rating_elements: [
                    "Self",
                    "Facilitator"
                  ],
                  rounds: [
                    {
                      file_id: 1,
                      time: 10,
                      round: "Round 1"
                    },
                    {
                      file_id: 2,
                      time: 10,
                      round: "Round 2"
                    },
                    {
                      file_id: 3,
                      time: 10,
                      round: "Round 3"
                    },
                    {
                      file_id: 4,
                      time: 10,
                      round: "Round 4"
                    },
                    {
                      file_id: 5,
                      time: 10,
                      round: "Round 5"
                    }
                  ]
                }
                this.httpSv.createPPGame(pp_default_data).subscribe((res: any) => {
                  if (res['status']) {
                    this.toster.success('Picture perfect configured successfully', '', { timeOut: 2000 })
                    item.showCalender = false
                    item.showTimepicker = false
                    this.ngOnInit()
                  } else {
                    this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
                  }
                }, (err: any) => this.toster.error(err.error.message, '', { timeOut: 2000 }))
              }
              else if (game_id == 5) {
                let af_default_data = {
                  program_id: program_id,
                  game_id: 5,
                  max_player: 10,
                  no_of_teams: 5,
                  no_of_player_per_team: 2,
                  game_time: 59,
                  session_duration: 60,
                  rounds: [
                    {
                      round: "Round 1",
                      level: "Order 1",
                      order: "Simple",
                      order_id: "1",
                      time: "6",
                      value: "1"
                    },
                    {
                      round: "Round 2",
                      level: "Order 1",
                      order: "Simple",
                      order_id: "1",
                      time: "7",
                      value: "1"
                    },
                    {
                      round: "Round 3",
                      level: "Order 2",
                      order: "Simple",
                      order_id: "2",
                      time: "7",
                      value: "2"
                    },
                    {
                      round: "Round 4",
                      level: "Order 3",
                      order: "Simple",
                      order_id: "3",
                      time: "8",
                      value: "3"
                    }
                  ]
                }
                this.httpSv.configAfGame(af_default_data).subscribe((res: any) => {
                  if (res['status']) {
                    this.toster.success('Animal Farm configured successfully', '', { timeOut: 2000 })
                    item.showCalender = false
                    item.showTimepicker = false
                    this.ngOnInit()
                  } else {
                    this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
                  }
                }, (err: any) => this.toster.error(err.error.message, '', { timeOut: 2000 }))
              } else if (game_id == 6) {
                this.http.get('../../../../assets/img/big_picture_resource1.png',{ responseType: 'blob' }).subscribe((blob:any)=>{
                  const imgFile = new File([blob],'image.png',{type:'image/png'})
                  // console.log(imgFile)
                  const bp_default_data = new FormData()
                  bp_default_data.append('program_id',program_id)
                  bp_default_data.append('game_id','6')
                  bp_default_data.append('rows','10')
                  bp_default_data.append('columns','5')
                  bp_default_data.append('no_of_codes','50')
                  bp_default_data.append('image',imgFile)
                  this.httpSv.configBpGame(bp_default_data).subscribe((res: any) => {
                    if (res['status']) {
                      this.toster.success('Big Picture configured successfully', '', { timeOut: 2000 })
                      item.showCalender = false
                      item.showTimepicker = false
                      this.ngOnInit()
                    } else {
                      this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
                    }
                  }, (err: any) => this.toster.error(err.error.message, '', { timeOut: 2000 }))
                })
              }
            }
            else {
              this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
            }
          }, (err: any) => this.toster.error(err.error.message, '', { timeOut: 2000 }))
        }
        else {
          this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
        }
      }, (err: any) => this.toster.error(err.error.message, '', { timeOut: 2000 }))
    }


  }

  convertEpochTime(epochTime: number) {
    const date = new Date(epochTime * 1000);

    // Get the date components
    const day = date.getDate();
    const month = date.getMonth() + 1; // Month is zero-based, so we add 1
    const year = date.getFullYear() % 100; // Get last two digits of the year
    let hours = date.getHours();
    const minutes = date.getMinutes();

    // Determine AM/PM
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours %= 12;
    hours = hours || 12; // Handle midnight (0 hours)
    return `${day.toString().padStart(2, '0')}-${month.toString().padStart(2, '0')}-${year} ${hours}:${minutes.toString().padStart(2, '0')} ${ampm}`;
  }
  copyToClipboard(text: string) {
    // Create a temporary input element
    const input = document.createElement('textarea');
    input.value = text;
    document.body.appendChild(input);

    // Select the text in the input element
    input.select();
    input.setSelectionRange(0, 99999); // For laptops and desktops

    // Copy the selected text to the clipboard
    document.execCommand('copy');

    // Remove the temporary input element
    document.body.removeChild(input);
    this.toster.success('Link Copied', '', { timeOut: 2000 })
  }
  allShedualedGames: any[] = []
  getAllShedualedGame() {
    this.httpSv.getScheduledData().subscribe((res: any) => {
      if (res['status']) {
        this.allShedualedGames = res.result
      } else {
        this.allShedualedGames = []
      }
    }, (err: any) => this.toster.error(err.error.message, '', { timeOut: 2000 }))
  }
}
