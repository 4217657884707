import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataSharingServiceService } from 'src/app/services/data-sharing-service.service';

@Component({
  selector: 'app-questico-resource',
  templateUrl: './questico-resource.component.html',
  styleUrls: ['./questico-resource.component.css']
})
export class QuesticoResourceComponent implements OnInit {

  constructor(private router:Router,private dataShare: DataSharingServiceService) { }

  ngOnInit(): void {
  }
  goBack(){
    if (this.dataShare.lastUrlForGameResource) {
      this.router.navigate([this.dataShare.lastUrlForGameResource])
      this.dataShare.lastUrlForGameResource = null
      this.dataShare.myGames = null
    }
    else {
      this.router.navigate(['/exploreGames'])
      this.dataShare.myGames = null
    }
  }
  goForShedule(){
    window.open('https://calendly.com/playkairos/connect')
  }
}
