import { trigger, state, style, transition, animate } from '@angular/animations';
import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, NavigationEnd, Router, Event as NavigationEvent } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { filter, flatMap } from 'rxjs';
import { HttpService } from 'src/app/services/http.service';
import { DashboardService } from 'src/app/Shared/Services/Dashboard.service';

@Component({
  selector: 'app-scheduled',
  templateUrl: './scheduled.component.html',
  styleUrls: ['./scheduled.component.css'],
  changeDetection: ChangeDetectionStrategy.Default,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('525ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class ScheduledComponent implements OnInit {

  selectedSession = 'scheduled'
  // selectedSession = 'archive'
  isDelete = false;
  columnsToDisplay = ["back", 'date', 'program', 'game', 'actions'];
  expandedElement: any = {
    id: -1
  };
  dataSource: any;
  openSessionData: any = {
    id: -2
  };
  allGameList: any
  constructor(
    private dashboardService: DashboardService,
    private router: Router,
    private toster: ToastrService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private httpSv: HttpService
  ) {
  }
  ngOnInit(): void {
    this.tableBuilder();
    if (this.dashboardService.archiveFlag) {
      this.dashboardService.archiveFlag = false
      this.selectedSession = 'archive'
    }
  }
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.

  }
  private getLastRoute(url: string): string {
    const segments = url.split('/');
    // Filter out empty segments and return the last one
    // console.log(segments.filter(segment => segment !== '').pop() || '')
    return segments.filter(segment => segment !== '').pop() || '';
  }

  tableBuilder() {
    this.dashboardService.getScheduledData().subscribe((res: any) => {
      if (res.result) {
        res.result.forEach((element: any) => {
          element.status = this.getOneProgramStatus(element);
          // let newdate = element.program_date.split("-").reverse();  
          // let dd = newdate[0];
          // let mm = this.getMonthName(newdate[1]);
          // let yy = newdate[2];
          // var finalDateFormated = `${dd} ${mm} ${yy}`;
          element.program_date = element.formatted_date;
          var status;
          element.program_games.forEach((game: any) => {
            if (game.status == 'Draft')
              game.status = 'Draft'
            if (game.status == 'Completed')
              game.status = 'Completed'
            if (game.status == 'Running')
              game.status = 'Live'
          })
        });
        this.dataSource = new MatTableDataSource(res.result);
        this.allGameList = res.result
        // console.log(this.allGameList)
      }
      else {
        this.dataSource.data = [];
        this.allGameList = []
        // this.ngOnInit()
      }
    })
  }
  setid() {
    this.openSessionData = {
      id: -1
    }
  }
  getMonthName(monthNumber: any) {
    const date = new Date();
    date.setMonth(monthNumber - 1);
    return date.toLocaleString('en-US', { month: 'long' });
  }
  session(element: any) {
    this.openSessionData = element;
    this.isDelete = false;
  }
  changeSession(data: any) {
    this.selectedSession = data;
  }
  deleteSession(val: boolean, event: Event, id = null) {
    event.stopPropagation()
    this.isDelete = val;
    if (val) {
      if (id != null) {
        this.dashboardService.deleteSession(id).subscribe((res: any) => {
          if (res.status) {
            this.tableBuilder();
            this.isDelete = false
          }
        })
      }
    }
  }
  openSession(data: any) {
    this.dashboardService.selectedSession = data;
    // if(data.program_games[0].game_name!='Big Picture')
    this.router.navigate(['/sessionDashboard'])
    // else{
    //   this.router.navigate(['/bigPicture'])
    // }
  }
  sessionDeleteHandler(event: Event, data: any) {
    event.stopPropagation();
    if (data.status == 'Live') {
      this.toster.info("Live session can't be deleted", '', { timeOut: 2000 })
    }
    else {
      this.timeValidatorForEditOnStart(data.slots.start_time).then((isValid: boolean) => {
        if (isValid) this.toster.info("This game can't be delete because the session start time has already passed.", '', { timeOut: 3000 })
        else {

          const dialogRef = this.dialog.open(SessionDeleteDialog, {
            hasBackdrop: true,
            data
          })
          dialogRef.afterClosed().subscribe((res: any) => {
            if (res) {
              const dialogRef = this.dialog.open(LicesenseReturnInfoDialog, {
                hasBackdrop: true,
                disableClose: true,
                data: res
              })
              dialogRef.afterClosed().subscribe((result: any) => {
                this.tableBuilder();
              })
            }
          })

          this.expandedElement = null
          // this.isDelete = true
        }
      })
    }

  }
  async timeValidatorForEditOnStart(start_time: any) {
    try {
      const res: any = await this.httpSv.getServerEpochTime().toPromise()
      if (res['status']) {
        const currentEpochTime = res.epoch_time;
        return start_time <= currentEpochTime;
      } else {
        this.toster.error('Unable to connect to the server!!', '', { timeOut: 2000 });
        return false;
      }
    } catch (err) {
      this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 });
      return false
    }
  }
  goToDashBoard() {
    this.router.navigate(['/dashboard'])
  }
  getOneProgramStatus(data: any) {
    if (data.status != null) {
      if (data.status == 'Running') {
        return 'Live'
      }
      else {
        return data.status
      }
    } else {
      if (data.program_games.every((ele: any) => ele.status == "Completed")) {
        return "Completed"
      }
      else if (data.program_games.every((ele: any) => ele.status == "Configured")) {
        return "Configured"
      }
      else {
        return "Live"
      }
    }
  }
  handleToggleClick(id: any, type: any) {
    this.isDelete = false
    if (type == 'dropDown') {
      if (document.getElementsByClassName('acrive-drop').length > 0) {
        let tempId: string = document.getElementsByClassName('acrive-drop')[0].id.slice(8)
        document.getElementById('oneLine' + tempId)?.classList.add('display-block')
        if (tempId != '0') {
          document.getElementById('borderLine' + (parseInt(tempId) - 1))?.classList.add('display_block_border')
        }
        document.getElementById('oneLine' + tempId)?.classList.remove('display-none')
        document.getElementsByClassName('acrive-drop')[0].classList.add('display-none')
        document.getElementsByClassName('acrive-drop')[0].classList.remove('display-block')
        document.getElementsByClassName('acrive-drop')[0].classList.remove('acrive-drop')
      }
      document.getElementById(type + id)?.classList.remove('display-none')
      document.getElementById(type + id)?.classList.add('display-block')
      document.getElementById(type + id)?.classList.add('acrive-drop')
      document.getElementById('oneLine' + id)?.classList.remove('display-block')
      document.getElementById('oneLine' + id)?.classList.add('display-none')
      if (id != 0) {
        if (document.getElementsByClassName('display_block_border').length > 0)
          document.getElementById('borderLine' + (id - 1))?.classList.remove('display_block_border')
        document.getElementById('borderLine' + (id - 1))?.classList.add('display_none_border')
      }
    }
    else if (type == 'oneLine') {
      document.getElementById(type + id)?.classList.remove('display-none')
      document.getElementById(type + id)?.classList.add('display-block')
      document.getElementById('dropDown' + id)?.classList.remove('display-block')
      document.getElementById('dropDown' + id)?.classList.add('display-none')
      if (id != 0) {
        document.getElementById('borderLine' + (id - 1))?.classList.remove('display_none_border')
        document.getElementById('borderLine' + (id - 1))?.classList.add('display_block_border')
      }
    }
  }
  stopParent(event: Event) {
    event.stopPropagation()
  }
  timeSlot(start_time: any, end_time: any) {
    if (!start_time) return ''
    const temp = start_time
    const temp1 = end_time * 1000
    // const temp1 = this.programDetails.slots.end_time * 1000
    return `${new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }).format(new Date(temp * 1000))} - ${new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }).format(new Date(temp1))}`
  }
}
export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
  description: string;
}




@Component({
  selector: 'session-delete-dialog',
  templateUrl: './session_delete_dialog.html',
  encapsulation: ViewEncapsulation.None
})
export class SessionDeleteDialog implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<SessionDeleteDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private dashboardService: DashboardService,
    private toster: ToastrService
  ) { }
  ngOnInit(): void {
    this.programData = this.data
    console.log(this.programData)
  }
  programData: any
  convertEpochToDate(epoch: any) {
    const timestamp = epoch * 1000;
    const date = new Date(timestamp);

    const options: any = { day: 'numeric', month: 'long', year: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-GB', options);

    return formattedDate;
  }
  convertEpochToTime(epoch: any) {
    const timestamp = epoch;

    const date = new Date(timestamp * 1000);

    let hours: any = date.getHours();
    let minutes: any = date.getMinutes();

    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;

    return `${hours}:${minutes} ${ampm}`;


  }

  licenseRefundCalFunc() {
    let currentTime = Math.floor(new Date().getTime() / 1000.0)
    let graceTimeStartTime = this.programData.program_config_time

  }
  close(flag: boolean) {
    if (!flag) {
      this.dialogRef.close(false)
    }
    else if (flag) {
      this.dashboardService.deleteSession(this.programData.id).subscribe((res: any) => {
        if (res.status) {
          this.dialogRef.close(res['results'])
        } else {
          this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
        }
      }, (err: any) => {
        this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
      })
    }
  }
}


@Component({
  selector: 'license_return_info_dialog',
  templateUrl: './license_return_info_dialog.html',
  encapsulation: ViewEncapsulation.None
})
export class LicesenseReturnInfoDialog implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<LicesenseReturnInfoDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private dashboardService: DashboardService,
    private toster: ToastrService
  ) { }
  ngOnInit(): void {
    this.configLicense = this.data.programDtls[0].players
    this.returnedLicense = this.data.no_of_licenses_added
    this.availabelLicese = this.data.no_of_licenses_left
    this.convertEpochToTime(this.data.program_delete_epochtime)
  }
  configLicense: any
  returnedLicense: any
  availabelLicese: any
  deletedTime: any
  close() {
    this.dialogRef.close()
  }
  convertEpochToTime(epoch: any) {
    // Convert epoch to milliseconds and create a Date object
    const date = new Date(epoch * 1000);

    // Extract hours and minutes
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');

    // Determine AM or PM
    const ampm = hours >= 12 ? 'pm' : 'am';

    // Convert hours from 24-hour to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    // Format the time as hh:mm AM/PM
    this.deletedTime = `${hours}:${minutes} ${ampm}`;
  }
}