<div class="sa-login-page-outer-container">
    <div class="inner-container">
        <div class="leftLogo">
            <img src="../../../../assets/admin-login.svg">
        </div>
        <div class="rightLogin">
            <div class="rightContainer">
                <div class="kairosName">
                    <img src="../../../../assets/kairosName.svg">
                </div>
                <p class="loginTxt">Admin Login</p>
                <form #loginform="ngForm" (ngSubmit)="submit(loginform.value)">
                    <input class="input" placeholder="Email" type="text" name="email" ngModel>
                    <input class="input" placeholder="Password" type="password" name="password" ngModel>
                    <!-- <p style="align-self: end;margin-right: 11%;font-family: MyriadPro-Regular;">Forgot Password</p> -->
                    <input style="min-width: 10%; max-width: 35%;" type="image" src="../../../../assets/loginSignIn.svg">
                </form>
            </div>
        </div>
    </div>
</div>
