import { DataSharingServiceService } from 'src/app/services/data-sharing-service.service';
import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { DashboardService } from 'src/app/Shared/Services/Dashboard.service';
import { Router } from '@angular/router';
import { PageEvent, MatPaginatorModule } from '@angular/material/paginator';
import { FormGroup, FormControl } from '@angular/forms';
@Component({
  selector: 'app-archive-schedule',
  templateUrl: './archive-schedule.component.html',
  styleUrls: ['./archive-schedule.component.css'],
  providers: [DatePipe],
})
export class ArchiveScheduleComponent implements OnInit {

  constructor(
    private dataShare: DataSharingServiceService,
    private dashboardService: DashboardService,
    private httpSv: HttpService,
    private toster: ToastrService,
    private router: Router
  ) { }

  ngOnInit(): void {
    if (sessionStorage.getItem('filterData')) {
      const cookieData = JSON.parse(sessionStorage.getItem('filterData') ?? '{}')
      this.startDateFilter = cookieData.startDateFilter;
      this.endDateFilter = cookieData.endDateFilter;
      this.epochStartTime = cookieData.epochStartTime;
      this.epochEndTime = cookieData.epochEndTime;
      this.length = cookieData.length;
      this.pageSize = cookieData.pageSize;
      this.pageIndex = cookieData.pageIndex;
      this.statusFilterArray = cookieData.statusFilterArray;
      this.statusString = cookieData.statusString;
      this.getAllGameData(this.pageIndex + 1, this.pageSize, this.epochStartTime, this.epochEndTime, this.statusString)
    } else
      this.getAllGameData(1, 10, null, null, null)
  }
  startDateFilter: any = null
  endDateFilter: any = null
  epochStartTime: any = null
  epochEndTime: any = null
  clearStartDate(eleRef: any) {
    this.startDateFilter = null;
    this.endDateFilter = null;
    this.epochStartTime = null;
    this.epochEndTime = null;
    eleRef.close()
    this.getAllGameData(this.pageIndex + 1, this.pageSize, this.epochStartTime, this.epochEndTime, this.statusString)
  }
  addClearButton(calenderRef: any) {
    setTimeout(() => {
      const eleRef = document.getElementsByClassName('mat-calendar-spacer')[0]
      if (eleRef) {
        let node = document.createElement("button");
        node.innerHTML = 'Clear Filter'
        node.style.border = 'none'
        node.style.background = 'transparent'
        node.style.textAlign = 'center'
        node.style.marginTop = '7px'
        node.addEventListener('click', () => this.clearStartDate(calenderRef));
        eleRef.appendChild(node)
      }
    }, 0)

  }
  range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });
  statusCompletedToggleFlag: boolean = false
  statusCompletedToggleCount: any = 0
  allGameListApiData: any
  allGameList: any
  toggleStatusFilterDropdwon() {
    if (!this.statusCompletedToggleFlag) {
      this.statusCompletedToggleFlag = true
    }
  }
  outSideClickHandleStatusFilter(event: any) {
    if (event) {
      if (this.statusCompletedToggleFlag) {
        if (this.statusCompletedToggleCount == 1) {
          this.statusCompletedToggleFlag = false
          this.statusCompletedToggleCount = 0
        }
        else
          this.statusCompletedToggleCount = 1
      }
    }
  }
  handleToggleClick(id: any, type: any) {
    if (type == 'dropDown') {
      if (document.getElementsByClassName('acrive-drop').length > 0) {
        let tempId: string = document.getElementsByClassName('acrive-drop')[0].id.slice(8)
        document.getElementById('oneLine' + tempId)?.classList.add('display-block')
        if (tempId != '0') {
          document.getElementById('borderLine' + (parseInt(tempId) - 1))?.classList.add('display_block_border')
        }
        document.getElementById('oneLine' + tempId)?.classList.remove('display-none')
        document.getElementsByClassName('acrive-drop')[0].classList.add('display-none')
        document.getElementsByClassName('acrive-drop')[0].classList.remove('display-block')
        document.getElementsByClassName('acrive-drop')[0].classList.remove('acrive-drop')
      }
      document.getElementById(type + id)?.classList.remove('display-none')
      document.getElementById(type + id)?.classList.add('display-block')
      document.getElementById(type + id)?.classList.add('acrive-drop')
      document.getElementById('oneLine' + id)?.classList.remove('display-block')
      document.getElementById('oneLine' + id)?.classList.add('display-none')
      if (id != 0) {
        if (document.getElementsByClassName('display_block_border').length > 0)
          document.getElementById('borderLine' + (id - 1))?.classList.remove('display_block_border')
        document.getElementById('borderLine' + (id - 1))?.classList.add('display_none_border')
      }
    }
    else if (type == 'oneLine') {
      document.getElementById(type + id)?.classList.remove('display-none')
      document.getElementById(type + id)?.classList.add('display-block')
      document.getElementById('dropDown' + id)?.classList.remove('display-block')
      document.getElementById('dropDown' + id)?.classList.add('display-none')
      if (id != 0) {
        document.getElementById('borderLine' + (id - 1))?.classList.remove('display_none_border')
        document.getElementById('borderLine' + (id - 1))?.classList.add('display_block_border')
      }
    }
  }

  openSession(data: any) {
    this.dashboardService.selectedArchiveSession = data;
    // if(data.program_games[0].game_name!='Big Picture')
    this.router.navigate(['/sessionArchive'])
    // else{
    //   this.router.navigate(['/bigPicture'])
    // }
  }

  goToDashBoard() {
    this.router.navigate(['/dashboard'])
  }

  filterGameReport(val: any) {
    let temp = this.allGameListApiData.filter((ele: any) => ele.program_name.toLowerCase().includes(val.value.toLowerCase()) | ele.client_name?.toLowerCase().includes(val.value.toLowerCase()))
    // if(temp.length == 0) this.allGameListApiData = this.allGameList
    // else
    this.allGameList = temp
  }

  length = 0;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [10, 25, 50];

  hidePageSize = false;
  showPageSizeOptions = true;
  showFirstLastButtons = true;
  disabled = false;

  pageEvent: any;

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.length = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.getAllGameData(this.pageIndex + 1, this.pageSize, this.epochStartTime, this.epochEndTime, this.statusString)
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
    }
  }

  filterBasedOnDate() {
    if (this.startDateFilter != null && this.endDateFilter != null) {
      this.epochStartTime = Math.floor((new Date(this.startDateFilter).getTime()) / 1000)
      this.epochEndTime = Math.floor((new Date(this.endDateFilter).getTime()) / 1000)
      this.firstFilterCheck = true
      this.getAllGameData(this.pageIndex + 1, this.pageSize, this.epochStartTime, this.epochEndTime, this.statusString)
    }
  }

  statusFilterArray: any[] = []
  statusString: any = null
  filterBasedOnStatus(event: any, value: string) {
    if (event.target.checked) {
      this.statusFilterArray.push(value)
    } else {
      const index = this.statusFilterArray.indexOf(value);
      if (index !== -1) {
        this.statusFilterArray.splice(index, 1);
      }
    }
    if (this.statusFilterArray.length > 0) {
      this.statusString = this.statusFilterArray.join('_')
    }
    else this.statusString = null
    this.firstFilterCheck = true
    this.getAllGameData(this.pageIndex + 1, this.pageSize, this.epochStartTime, this.epochEndTime, this.statusString)
  }
  checkStatusIsChecked(value: string) {
    return this.statusFilterArray.includes(value);
  }


  temp_search_store: any
  firstFilterCheck: boolean = false
  getAllGameData(page: any, per_page_item: any, startDate: any, endDate: any, status: any) {
    if (((startDate != null && endDate != null) || status != null) && this.firstFilterCheck) {
      this.pageIndex = 0
      page = this.pageIndex + 1
      this.firstFilterCheck = false
    }
    sessionStorage.setItem('filterData', JSON.stringify({
      startDateFilter: this.startDateFilter,
      endDateFilter: this.endDateFilter,
      epochStartTime: this.epochStartTime,
      epochEndTime: this.epochEndTime,
      length: this.length,
      pageSize: this.pageSize,
      pageIndex: this.pageIndex,
      statusFilterArray: this.statusFilterArray,
      statusString: this.statusString
    }))
    this.httpSv.getAllCompletedGame(page, per_page_item, startDate, endDate, status).subscribe((res: any) => {
      if (res['status']) {
        this.temp_search_store = null
        this.allGameListApiData = res['result']
        this.allGameList = res['result']
        this.length = res['page_dtls'].total_record;
        this.pageSize = res['page_dtls'].per_page_dtls;
        // this.pageIndex = res['page_dtls'].total_record;
        // this.openSession(this.allGameList[15])
      }
      else {
        this.allGameListApiData = []
        this.allGameList = []
        this.length = 0
        this.toster.info('No Game Data Found', '', { timeOut: 2000 })
      }
    }, (err: any) => {
      this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
    })
  }
  covertEpochToISOTime(epoch: any) {
    if (epoch) {
      epoch = epoch * 1000
      let date = new Date(epoch);
      let formattedDate = date.toISOString().split('T')[0];
      return formattedDate;
    } else return 'N/A'
  }
}
