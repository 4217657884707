<div style="height: 100vh;">
    <app-header [first]=NewSession [secound]=Digital [third]=Name></app-header>
    <div class="main-div-container" id="mainDivContainer">

        <div class="" style="height: 100%;">
            <div class="step-main-div">
                <div [ngClass]="step==1? 'step-active-css':''">STEP 1</div>
                <div [ngClass]="step==2? 'step-active-css':''">STEP 2</div>
            </div>
            <div class="bg-side-div">
                <img src="../../../assets/img/physical_bg.svg" alt="" srcset="">
            </div>
            <div class="container-div" *ngIf="step == 1">
                <div class="left-container">
                    <div>
                        <img src="../../../assets/img/pysical_cofig_side.svg" alt="">
                    </div>
                </div>
                <div class="right-container">
                    <div>
                        <label for="session-name">Session Name</label>
                        <input type="text" class="form-control" [(ngModel)]="sessionName">
                    </div>
                    <div>
                        <label for="session-date">Session Date</label>
                        <input type="date" class="form-control" id="datePicker" [(min)]="todayDate" [(ngModel)]="sessionDate"
                            (change)="dateChangeHadler()">
                    </div>
                    <div>
                        <label for="client-name">Client Name</label>
                        <input type="text" class="form-control" [(ngModel)]="clientName">
                    </div>
                    <div style="text-align: center;">
                        <input style="width: 76%;" type="image" src="../../../assets/img/save&conti-btn.svg"
                            (click)="goToStep2()">
                    </div>
                    <!-- <div>
                        <button class="submit-btn" (click)="goToStep2()">Save & Continue</button>
                    </div> -->
                </div>
            </div>
             <div class="container-div remove-grid" *ngIf="step == 2">
                <div>
                    <div class="st2-quik-info">
                        <div>
                            <img src="../../../assets/img/arrow_back_ios_FILL0_wght400_GRAD0_opsz48.svg" alt="back"
                                srcset="" (click)="goToStep1()">
                            <span>{{sessionName}}</span>
                        </div>
                        <div>
                            {{clientName}} | {{finalDateFormated}}
                        </div>
                    </div>


                    <!-- temp-img-css -->
                    <!-- temp-div-css -->
                    <div>
                        <!-- <div cdkDropListGroup class="st2-drag-drop-main-div">
                            <div class="example-container example-container-left">
                                <div class="st2-left-text"> Select games (Drag & drop) and rearrange as per the playing
                                    order </div>

                                <div cdkDropList [cdkDropListData]="todo1" class="drag-drop-left"
                                    (cdkDropListDropped)="drop1($event)">
                                    <div class="example-box" *ngFor="let item of todo1" cdkDrag>
                                        <div class="">
                                            <img [src]="getJSONparse(item,'imgUrl')" alt="" srcset="" class="temp-img-css">
                                        </div>
                                        <div>
                                            {{getJSONparse(item,'name')}}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="right-final-div">
                                <div class="example-container example-container-right">

                                    <div cdkDropList [cdkDropListData]="done1" class="example-list example-list-right"
                                        (cdkDropListDropped)="drop1($event)">
                                        <div class="example-box-right" *ngFor="let item of done1; let i = index" cdkDrag>
                                            <div>
                                                <span>{{i+1}}</span>
                                                <span><img src="../../../assets/img/3Lines.svg" alt=""></span>

                                            </div>
                                            <div>
                                                <span>{{getJSONparse(item,'name')}}</span>
                                                <img src="../../../assets/img/Delete Button.svg" alt=""
                                                    style="cursor: pointer;" (click)="deleteFromRight(item,i)">
                                            </div>
                                        </div>
                                        <div class="bottom-text-main-div">
                                            <div>+ Drag & Drop here</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="last-btn-div">
                                    <input style="width: 35%;" type="image" src="../../../assets/img/save_finosh.svg"
                                        (click)="saveAndMoveToNew()">
                                </div>
                            </div>
                        </div> -->
                        <div class="st2-drag-drop-main-div">
                            <div class="example-container example-container-left">
                                <div class="st2-left-text"> Select games (Drag & drop) and rearrange as per the playing
                                    order </div>

                                <div class="drag-drop-left">
                                    <div class="example-box" *ngFor="let item of todo1;let i = index" draggable="true" (dragstart)="drag($event,i)" (dragend)="dragEnd()">
                                        <div>
                                            <img [id]="'img'+i" [src]="getJSONparse(item,'imgUrl')" alt="" srcset=""
                                            draggable="true" class="temp-img-css">
                                        </div>
                                        <div>
                                            {{getJSONparse(item,'name')}}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="right-final-div">
                                <div class="example-container example-container-right">

                                    <div class="example-list example-list-right" id="dropDiv" (drop)="drop1($event)"
                                        (dragover)="allowDrop($event)" cdkDropList [cdkDropListData]="done1" (cdkDropListDropped)="drop($event)">
                                        <div class="example-box-right" *ngFor="let item of done1; let i = index"
                                        cdkDrag>
                                            <!-- draggable="true" (dragstart)="dragInside($event,i)" -->
                                            <!-- (dragend)="dragInsideEnd()" (drop)="insideDrop(i)" -->
                                            <div>
                                                <span>{{i+1}}</span>
                                                <span><img src="../../../assets/img/3Lines.svg" alt=""
                                                        ></span>

                                            </div>
                                            <div>
                                                <span>{{getJSONparse(item,'name')}}</span>
                                                <img src="../../../assets/img/Delete Button.svg" alt=""
                                                    style="cursor: pointer;" (click)="deleteFromRight(item,i)">
                                            </div>
                                        </div>
                                        <div class="bottom-text-main-div">
                                            <div>+ Drag & Drop here</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="last-btn-div">
                                    <input style="width: 35%;" type="image" src="../../../assets/img/save_finosh.svg"
                                        (click)="saveAndMoveToNew()">
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>