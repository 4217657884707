<div style="min-width: 670px; max-width: 800px;">
    <div style="padding: 24px;">
        <div style="text-align: center; margin-bottom: 10px;">
            <img src="../../../assets/delete-icon_session.svg" alt="img">
        </div>
        <div class="center_align delete_heading">Are you sure you want to delete this game session</div>
        <div class="program_dtls_mail_div">
            <div class="program_dtls_img_div">
                <img class="gameImage" *ngIf="programData.program_games[0].game_id=='1'"
                    src="../../../assets/img/value_continum.svg">
                <img class="gameImage" *ngIf="programData.program_games[0].game_id=='2'"
                    src="../../../assets/img/bottleneck.svg">
                <img class="gameImage" *ngIf="programData.program_games[0].game_id=='3'"
                    src="../../../assets/img/warehouse.svg">
                <img class="gameImage" *ngIf="programData.program_games[0].game_id=='5'"
                    src="../../../assets/img/animal_faram.svg">
                <img class="gameImage" *ngIf="programData.program_games[0].game_id=='6'"
                    src="../../../assets/img/bigPicture_withtout_bg.svg">
                <img class="gameImage" *ngIf="programData.program_games[0].game_id=='4'"
                    src="../../../assets/img/picturePerfect.svg">
                <img class="gameImage" *ngIf="programData.program_games[0].game_id=='14'"
                    src="../../../assets/great_ocean_kingdom_logo.svg">
                <img class="gameImage" *ngIf="programData.program_games[0].game_id=='15'"
                    src="../../../assets/people_of_world_logo.svg">
            </div>
            <div class="program_dtls_sub_div">
                <div class="program_dtls_session_div">
                    <span>{{programData.program_name}}</span>
                    <span> | </span>
                    <span>{{programData.client_name}}</span>
                </div>
                <div class="program_dtls_time_div">
                    <span>{{convertEpochToDate(programData.slots.start_time)}}</span>
                    <span>, </span>
                    <span>{{convertEpochToTime(programData.slots.start_time)}}</span>
                    <span> - </span>
                    <span>{{convertEpochToTime(programData.slots.end_time)}}</span>
                </div>
                <div class="program_dtls_player_div">{{programData.maxPlayerNum}} Players</div>
            </div>
        </div>


        <div class="licences_deduction_msg">
            <div>&nbsp;</div>
        </div>

        <div class="center_align deletion_main_div">
            <button class="no_btn_deletion_div" (click)="close(false)">No, Go Back</button>
            <button class="yes_btn_deletion_div" (click)="close(true)">YES, Continue</button>
        </div>
    </div>
</div>








<style>
    .mat-dialog-container {
        border-radius: 8px !important;
        padding: 0;
        
    }

    .center_align {
        text-align: center;
    }

    .delete_heading {
        color: #FF4D6F;
        font-size: 16px;
        font-weight: 500;
    }

    .program_dtls_mail_div {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 15px;
        gap: 15px;
    }

    .program_dtls_img_div img {
        width: 70px;
        height: 70px;
    }

    .program_dtls_session_div {
        color: #212121;
        font-size: 15px;
        font-weight: 500;
    }

    .program_dtls_time_div {
        color: #4E4E4E;
        font-size: 14px;
    }

    .program_dtls_player_div {
        color: #4E4E4E;
        font-size: 14px;
    }

    .licences_deduction_msg {
        text-align: center;
        margin-top: 2rem;
        /* margin-bottom: 3rem; */
        color: #4E4E4E;
        font-size: 14px;
        font-weight: 500;
    }

    .no_btn_deletion_div {
        border: 1px solid #585353;
        border-radius: 50px;
        color: #585353;
        font-weight: 500;
        font-size: 16px;
        width: 173px;
        height: 32px;
        background-color: transparent;
    }

    .yes_btn_deletion_div {
        border-radius: 50px;
        color: #000000;
        font-weight: 500;
        font-size: 16px;
        width: 173px;
        height: 32px;
        background-color: #33DE81;
        border: none;
    }

    .deletion_main_div {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 3rem;
    }
</style>