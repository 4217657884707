<div>
  <div class="header-info-main-div">
    <div><img src="../../../assets/img/back-svgrepo-com.svg" alt="" srcset=""
        style="max-width: 25px; cursor: pointer; margin: 0 24px;" (click)="goToDashBoard()"></div>
    <div class="header-text-div">
      DATE
      <div style="display: inline;position: relative;">
        <img src="../../../assets/filter_icon.svg" alt="" style="width: 24px;cursor: pointer;"
          (click)="picker.open();addClearButton(picker)">
        <span
          class="position-absolute top-0 start-100 translate-middle p-1 bg-danger border border-light rounded-circle"
          *ngIf="startDateFilter != null && endDateFilter != null">
          <span class="visually-hidden">Filter</span>
        </span>
      </div>
      <mat-form-field appearance="fill"
        style="visibility: hidden;width: 0px;height: 0;position: absolute;left: 40px;top: -22px;">
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate placeholder="Start date" [(ngModel)]="startDateFilter">
          <input matEndDate placeholder="End date" [(ngModel)]="endDateFilter" (ngModelChange)="filterBasedOnDate()">
        </mat-date-range-input>
        <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
    </div>
    <div class="header-text-div">SESSION</div>
    <div class="header-text-div">CLIENT</div>
    <div class="header-text-div">
      STATUS
      <div style="display: inline;position: relative;">
        <img src="../../../assets/filter_icon.svg" alt="" style="width: 24px;cursor: pointer;"
          (click)="toggleStatusFilterDropdwon()">
        <span
          class="position-absolute top-0 start-100 translate-middle p-1 bg-danger border border-light rounded-circle"
          *ngIf="statusFilterArray.length > 0">
          <span class="visually-hidden">Filter</span>
        </span>
        <div class="status_filter_dropdown" *ngIf="statusCompletedToggleFlag"
          (appOutsideClick)="outSideClickHandleStatusFilter($event)">
          <div>
            <input type="checkbox" class="btn-check" id="completed-check-outlined" autocomplete="off"
              (change)="filterBasedOnStatus($event,'Completed')" [checked]="checkStatusIsChecked('Completed')">
            <label class="btn btn-outline-primary" for="completed-check-outlined">
              Completed
            </label>
          </div>
          <div>
            <input type="checkbox" class="btn-check" id="incompleted-check-outlined" autocomplete="off"
              (change)="filterBasedOnStatus($event,'Incomplete')" [checked]="checkStatusIsChecked('Incomplete')">
            <label class="btn btn-outline-primary" for="incompleted-check-outlined">
              Incomplete
            </label>
          </div>
          <div>
            <input type="checkbox" class="btn-check" id="deleted-check-outlined" autocomplete="off"
              (change)="filterBasedOnStatus($event,'Deleted')" [checked]="checkStatusIsChecked('Deleted')">
            <label class="btn btn-outline-primary" for="deleted-check-outlined">
              Deleted
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="search_box_div">
      <img src="../../../assets/img/search-svgrepo-com.svg" alt="">
      <input type="text" name="search" id="" placeholder="Search archived sessions"
        (input)="filterGameReport($event.target)" [(ngModel)]="temp_search_store">
    </div>
  </div>
  <div class="archived_all_list_wrapper_main_div">
    <div class="single-game-main-div" *ngFor="let item of allGameList;let i=index">
      <div [id]="'oneLine'+i" class="display-block">
        <div class="single-game-sub-div" (click)="handleToggleClick(i,'dropDown')">
          <div><b>{{covertEpochToISOTime(item.first_start_time)}}</b></div>
          <div>{{item.program_name}}</div>
          <div>{{item.client_name}}</div>
          <div class="completed-status" style="min-width: 6rem;">{{item.isdeleted == 1 ? 'Deleted' : !item.is_incompleted ? 'Completed' : 'Incomplete' }}</div>
        </div>
        <div class="border-div" [id]="'borderLine'+i">&nbsp;</div>
      </div>
      <div [id]="'dropDown'+i" class="display-none" style="font-size: 14px;">
        <div class="dropdwon" (click)="handleToggleClick(i,'oneLine')">
          <div class="m-w-13_5">
            <b *ngIf="covertEpochToISOTime(item.first_start_time) != 'N/A'; else naDateTemplate">{{covertEpochToISOTime(item.first_start_time) | date:' d MMMM yyyy'}}</b>
            <ng-template #naDateTemplate>
              <b>N/A</b>
            </ng-template>
            
          </div>
          <div class="m-w-13_5">{{item.program_name}}</div>
          <div class="m-w-13_5">{{item.client_name}}</div>
          <div class="completed-status" style="min-width: 7rem;">{{item.isdeleted == 1 ? 'Deleted' : !item.is_incompleted ? 'Completed' : 'Incomplete' }}</div>
          <div style="width: -webkit-fill-available;  width: -moz-available; width: fill-available;margin-left: 17px;">
            <div style="display: grid; grid-template-columns: 1fr 1fr;">
              <div>
                <div class="dropdwon-list expand-game">
                  <div class="expand-game-details" *ngFor="let subject of item.program_games let j = index">
                    <div class="drop-game-list game-detail">
                      <div class="game-detail-each-names">
                        <img class="gameImage" *ngIf="subject.game_name=='Value Continuum'"
                          src="../../../assets/img/value_continum.svg">
                        <img class="gameImage" *ngIf="subject.game_name=='Bottle Neck Challenge'"
                          src="../../../assets/img/bottleneck.svg">
                        <img class="gameImage" *ngIf="subject.game_name=='Warehouse'"
                          src="../../../assets/img/warehouse.svg">
                        <img class="gameImage" *ngIf="subject.game_name=='Animal Farm'"
                          src="../../../assets/img/animal_faram.svg">
                        <img class="gameImage" *ngIf="subject.game_name=='Big Picture'"
                          src="../../../assets/img/bigPicture_withtout_bg.svg">
                        <img class="gameImage" *ngIf="subject.game_name=='Picture Perfect'"
                          src="../../../assets/img/picturePerfect.svg">
                        <img class="gameImage" *ngIf="subject.game_name=='Great Ocean Race'"
                          src="../../../assets/great_ocean_kingdom_logo.svg">
                        <img class="gameImage" *ngIf="subject.game_name=='People of the world'"
                          src="../../../assets/people_of_world_logo.svg">
                        <img class="gameImage" *ngIf="subject.game_name=='Human Matrix'"
                          src="../../../assets/img/human_matrix.jpg" class="pyGameImg">
                        <img class="gameImage" *ngIf="subject.game_name=='String Theory'"
                          src="../../../assets/img/string_theory.jpg" class="pyGameImg">
                        <img class="gameImage" *ngIf="subject.game_name=='Stromshelter'"
                          src="../../../assets/img/strom.jpg" class="pyGameImg">
                        <img class="gameImage" *ngIf="subject.game_name=='Assemblyline'"
                          src="../../../assets/img/assembley_line.jpg" class="pyGameImg">
                        <img class="gameImage" *ngIf="subject.game_name=='Magic Mats'"
                          src="../../../assets/img/magic_mats.jpg" class="pyGameImg">
                        <img class="gameImage" *ngIf="subject.game_name=='Stranded'"
                          src="../../../assets/img/stander.jpg" class="pyGameImg">
                        <div>{{subject.game_name}}</div>
                      </div>
                    </div>
                    <!-- <div class="drop-game-list">
                                                          <img class="gameImage" src="../../../assets/img/bottleneck.svg"
                                                              class="game-icon-img">
                                                          <div>Bottleneck Challenge</div>
                                                      </div> -->
                  </div>
                </div>
              </div>
              <div style="margin: auto;">
                <div class="sessionbtn" (click)="openSession(item)"><img class="sessionImg"
                    src="../../../assets/img/Edit Button.svg"><b>Session Dashboard</b></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div class="mat_paginator_main_div_wrapper">
    <mat-paginator #paginator class="demo-paginator" (page)="handlePageEvent($event)" [length]="length"
      [pageSize]="pageSize" [disabled]="disabled" [showFirstLastButtons]="showFirstLastButtons"
      [pageSizeOptions]="showPageSizeOptions ? pageSizeOptions : []" [hidePageSize]="hidePageSize"
      [pageIndex]="pageIndex" aria-label="Select page">
    </mat-paginator>
  </div>
</div>