<div class="entireDeletePopup">
    <div class="deletePopup">
        <!-- <div style="text-align: center; width: 40%;">
            <img style="width: 50%;" src="../../../assets/img/omE5s3.tif.svg" alt="">
        </div> -->
        <div style="text-align: center; margin: 10px 0px;">
            <div>If you choose to edit the Selected Games now, the game configuration done till now will be removed.</div>
            <!-- <div style="font-weight: 500;"><b>delete</b> this session</div> -->
            <div style="display: flex; justify-content: center ; gap: 20px;margin-top: 20px;">

                <input style="width: 66px;" class="deleteBtn" type="image" src="../../../assets/img/Group 7967.svg" (click)="close(true)">
                <input style="width: 66px;" class="deleteBtn" type="image" src="../../../assets/img/Group 7968.svg" (click)="close(false)">
            </div>
        </div>

    </div>
</div>
<style>
    ::ng-deep .mat-dialog-container {
        padding: 0;
        border-radius: 14px;
    }

    .entireDeletePopup {
        padding: 24px;
        border: 2px solid #7b7b7b;
        border-radius: 13px;
    }
    .deletePopup{
        display: flex;
    }
</style>