<div>
    <app-header [oneHeader]='headerMsg'></app-header>
    <div class="sdEntire" [ngClass]="programDetails.program_games[0].game_id == 7?'bgQustico':pyFlag?'bgPhysical':''">
        <div class="leftContainer">
            <div style="margin-bottom: 15px;display: flex;">
                <img style="width: 26px;cursor: pointer;"
                    src="../../../assets/img/arrow_back_ios_FILL0_wght400_GRAD0_opsz48.svg"
                    (click)="openScheduledPage()">
                <span style="color: #0B477B;font-size: 22px;">Session Details</span>
            </div>
            <div class="side-nav-info-div">
                <div style="display: flex;justify-content: space-between;align-items: flex-start;">
                    <div style="margin-left:0;margin-top: 16px;">{{programDetails.program_name}}</div>
                    <div class="editProgarmDtls" (click)="editSession(programDetails)"
                        *ngIf="programDetails.program_games[0]['game_id'] != 7 && programDetails['status'] != 'Running' && programDetails.program_games[0]['game_id'] != 14 && programDetails.program_games[0]['game_id'] != 15">
                        Edit</div>
                </div>
                <div>
                    <ul>
                        <li>{{programDetails.client_name}}</li>
                        <li>
                            {{sessionConfigDate | date:' d MMMM yyyy'}}
                        </li>
                        <li>{{sessionConfigTime}}</li>
                        <li>{{programDetails.maxPlayerNum}} Players</li>
                    </ul>
                </div>
            </div>

        </div>

        <div class="middleContainer">
            <div class="gameConatiner"
                [ngClass]="programDetails['status'] == 'Running' || programDetails['status'] == 'Completed' || programDetails['status'] == 'Live'?'activeLiveCss':''">
                <div *ngIf="programDetails['status'] == 'Running' || programDetails['status'] == 'Completed' || programDetails['status'] == 'Live'"
                    class="blinker">Live</div>
                <div class="gameDetails" *ngFor="let game of programDetails.program_games">
                    <img class="gameImage" *ngIf="game.game_name=='Value Continuum'"
                        src="../../../assets/img/value_continum.svg">
                    <img class="gameImage" *ngIf="game.game_name=='Bottle Neck Challenge'"
                        src="../../../assets/img/bottleneck.svg">
                    <img class="gameImage" *ngIf="game.game_name=='Warehouse'" src="../../../assets/img/warehouse.svg">
                    <img class="gameImage" *ngIf="game.game_name=='Animal Farm'"
                        src="../../../assets/img/animal_faram.svg">
                    <img class="gameImage" *ngIf="game.game_name=='Big Picture'"
                        src="../../../assets/img/bigPicture_withtout_bg.svg">
                    <img class="gameImage" *ngIf="game.game_name=='Picture Perfect'"
                        src="../../../assets/img/picturePerfect.svg">
                    <img class="gameImage" *ngIf="game.game_name=='Great Ocean Race'"
                        src="../../../assets/great_ocean_kingdom_logo.svg">
                    <img class="gameImage" *ngIf="game.game_name=='People of the world'"
                        src="../../../assets/people_of_world_logo.svg">
                    <img class="gameImage" *ngIf="game.game_name=='Human Matrix'"
                        src="../../../assets/img/human_matrix.jpg" class="pyGameImg">
                    <img class="gameImage" *ngIf="game.game_name=='String Theory'"
                        src="../../../assets/img/string_theory.jpg" class="pyGameImg">
                    <img class="gameImage" *ngIf="game.game_name=='Stromshelter'" src="../../../assets/img/strom.jpg"
                        class="pyGameImg">
                    <img class="gameImage" *ngIf="game.game_name=='Assemblyline'"
                        src="../../../assets/img/assembley_line.jpg" class="pyGameImg">
                    <img class="gameImage" *ngIf="game.game_name=='Magic Mats'" src="../../../assets/img/magic_mats.jpg"
                        class="pyGameImg">
                    <img class="gameImage" *ngIf="game.game_name=='Stranded'" src="../../../assets/img/stander.jpg"
                        class="pyGameImg">

                    <b style="width: 12rem;">{{game.game_name}}</b>
                    <div class="gameEdit" (click)="editGame(programDetails,game)"
                        *ngIf="game.game_id != 7 && !pyFlag && programDetails['status'] != 'Running'">
                        <img style="vertical-align: sub;" class="sessionImg" src="../../../assets/img/esit.svg"
                            style="width:23px;">
                    </div>
                    <div class="gameEdit" style="opacity: 25%;background-color: #9F9F9F;cursor: not-allowed;"
                        *ngIf="game.game_id != 7 && !pyFlag && programDetails['status'] == 'Running'">
                        <img style="vertical-align: sub;" class="sessionImg" src="../../../assets/img/esit.svg"
                            style="width:23px;">
                    </div>
                    <div class="gameEdit gameDelete" (click)="deleteGame(programDetails,game,'py')"
                        *ngIf="pyFlag && programDetails['status'] != 'Running'">
                        <img style="vertical-align: sub;" class="sessionImg" src="../../../assets/img/delete_icon.svg">
                    </div>
                    <div class="gameEdit" (click)="deleteGame(programDetails,game,'di')"
                        *ngIf="game.game_id != 7 && !pyFlag && programDetails['status'] != 'Running'"
                        style="background-color: #ffa8a8;">
                        <img style="vertical-align: sub; width: 19px;" class="sessionImg"
                            src="../../../assets/img/delete_icon.svg">
                    </div>
                    <div class="gameEdit" *ngIf="game.game_id != 7 && !pyFlag && programDetails['status'] == 'Running'"
                        style="opacity: 25%;background-color: #9F9F9F;cursor: not-allowed;">
                        <img style="vertical-align: sub; width: 19px;" class="sessionImg"
                            src="../../../assets/img/delete_icon.svg">
                    </div>
                    <div class="gameResource" (click)="redirectToResource(game.game_id)">
                        <b>Game Resources</b>
                    </div>
                </div>
            </div>
        </div>
        <div class="rightContainer" *ngIf="!pyFlag">
            <div (click)="showQrCode(tinyUrl,programDetails.id,programDetails.program_games[0].game_id)" *ngIf="sessionStartFlag">
                <div class="playerLink" matTooltip="Link Copied" #tooltip="matTooltip" matTooltipPosition="above"
                    matTooltipClass="above" (mouseenter)="$event.stopImmediatePropagation()"
                    (mouseleave)="$event.stopImmediatePropagation()" (click)="tooltip.show()"
                    [cdkCopyToClipboard]="tinyUrl">
                    <div><img style="width: 20px;" src="../../../assets/img/qr.svg"></div>
                    <div><b>PlayLink</b></div>
                </div>
            </div>
            <div *ngIf="!sessionStartFlag" (click)="playLinkFuture()">
                <div class="playerLink">
                    <div><img style="width: 20px;" src="../../../assets/img/qr.svg"></div>
                    <div><b>PlayLink</b></div>
                </div>
            </div>
            <!-- <div *ngIf="bigPictureExist" style="margin-top: 2rem;" (click)="showCode(programDetails.id)">
                <div class="playerLink"
                    style="text-align: center;padding-right: 0px;padding-left: 0;background-color: #fcbc2b;">
                    <div style="width: 100%;"><b>Show Codes</b></div>
                </div>
            </div> -->
            <div *ngIf="programDetails['status'] == 'Configured' || programDetails['status'] == null">
                <div class="startSession" style="margin-top: 2rem;"
                    (click)="openpopup(programDetails.facilitator_url,programDetails.id, programDetails.slots,programDetails.program_games[0].game_id)"
                    *ngIf="sessionStartFlag">
                    <div> <img style="width: 20px;
               " src="../../../assets/img/play.svg"></div>
                    <div><b>Game Control Room</b></div>
                </div>
                <div class="startSession" style="margin-top: 2rem;" (click)="playLinkFuture()"
                    *ngIf="!sessionStartFlag">
                    <div> <img style="width: 20px;
               " src="../../../assets/img/play.svg"></div>
                    <div><b>Game Control Room</b></div>
                </div>
            </div>
            <div
                *ngIf="programDetails['status'] == 'Running' || programDetails['status'] == 'Completed' || programDetails['status'] == 'Live'">
                <div class="startSession" style="margin-top: 2rem;" *ngIf="sessionStartFlag"
                    (click)="openGCR(programDetails.facilitator_url)">
                    <div> <img style="width: 20px;
               " src="../../../assets/img/play.svg"></div>
                    <div><b>Game Control Room</b></div>
                </div>
                <div class="startSession" style="margin-top: 2rem;" (click)="playLinkFuture()"
                    *ngIf="!sessionStartFlag">
                    <div> <img style="width: 20px;
               " src="../../../assets/img/play.svg"></div>
                    <div><b>Game Control Room</b></div>
                </div>
            </div>
            <!-- <div *ngIf="questicoExist" style="margin-top: 2rem;" (click)="showKeys(programDetails.program_games[0].game_id)">
                <div class="playerLink"
                    style="text-align: center;padding-right: 0px;padding-left: 0;background-color: #fcbc2b;">
                    <div style="width: 100%;"><b>Keys</b></div>
                </div>
            </div> -->
        </div>
        <div class="rightContainer" *ngIf="pyFlag">&nbsp;</div>
    </div>
</div>