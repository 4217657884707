<div style="display: flex;
justify-content: center;
align-items: center;
gap: 32px;
font-size: 18px;
font-weight: 500;">
    <div>
        <img src="../assets/img/carbon_version-minor.svg" alt="" srcset="" style="width: 54px;">
    </div>
    <div style="    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;">
        <div>
            We have updated the dashboard. <br> Please reload to access the new version.

        </div>
        <button type="button" class="btn btn-success" (click)="close()" style=" border-radius: 40px;
        padding: 3px 46px;">OK</button>
    </div>


</div>


<style>
.mat-dialog-container{
    border-radius: 20px;
    padding: 22px;
}
</style>
