import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataSharingServiceService } from 'src/app/services/data-sharing-service.service';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-game-dtls',
  templateUrl: './game-dtls.component.html',
  styleUrls: ['./game-dtls.component.css']
})
export class GameDtlsComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private httpSv: HttpService,
    private router: Router,
    public toster: ToastrService,
    private location: Location,
    private dataShare: DataSharingServiceService
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((result: any) => {
      if (result.game == 1)
        this.selectedExplore = 'digital'
      else if (result.game == 2)
        this.selectedExplore = 'physical'
      else {
        this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
        this.location.back();
      }
    })
  }
  navigateBack(){
    this.location.back();
  }
  selectedExplore: any = 'digital'
  digitalGamesAllowed: any[] = [
    { name: 'Value Continuum' ,id:1},
    { name: 'Bottle Neck Challenge' ,id:2},
    { name: 'Ware House' ,id:3},
    { name: 'Animal Farm' ,id:5},
    { name: 'Big Picture' ,id:6},
    { name: 'Picture Perfect' ,id:4},
    { name: 'Great Ocean Race' ,id:14},
    { name: 'People of the world' ,id:15},
  ]
  physicalGamesAllowed: any[] = [
    { game_id: 8 }, { game_id: 9 }, { game_id: 10 }, { game_id: 11 }, { game_id: 12 }, { game_id: 13 } , { game_id: 16 }, { game_id: 17 }, { game_id: 18 }, { game_id: 19 }
  ]
  selectedExploreGame(data: any) {
    if(data == this.selectedExplore) return
    else if(data == 'digital') this.updateQueryParams(1)
    else if(data == 'physical') this.updateQueryParams(2)
    this.selectedExplore = data;
  }
  goToGameResource(id: any) {
  //  console.log(id)
    if (id) {
      this.dataShare.gameContentData = id
      this.router.navigate(['game-content'])
    } else return
  }
  updateQueryParams(id:any) {
    // Get the current query parameters
    const currentParams = { ...this.route.snapshot.queryParams };
    // Update or add new query parameters
    currentParams['game'] = id;

    this.location.replaceState(this.router.createUrlTree([], {
      relativeTo: this.route,
      queryParams: currentParams,
      queryParamsHandling: 'merge', // Use 'merge' to keep existing parameters
    }).toString());
  }
}
