<div>
    <app-header [oneHeader]='headerMsg'></app-header>
    <div class="main_wrapper">
        <div class="session_game_dtls">
            <div class="session_game_sub_dtls">
                <div class="session_game_dtls_heading">Session Plan</div>
                <div class="session_plan_main_div">
                    <span>Session :</span><span>{{sessionName}}</span>
                </div>
                <div class="session_plan_main_div">
                    <span>Client :</span><span>{{clientName}}</span>
                </div>
                <div class="session_plan_main_div">
                    <span>Date :</span><span>{{sessionDate}}</span>
                </div>
                <div class="session_plan_main_div">
                    <span>Time Slot :</span><span>{{timeSlotData}}</span>
                </div>
                <div class="session_plan_main_div">
                    <span>Players :</span><span>{{maxPlyr}}</span>
                </div>
            </div>

            <div class="session_game_sub_dtls" *ngIf="!isDeleted && !isIncomplete">
                <div class="session_game_dtls_heading">Session Run</div>

                <!-- Valuecontinum -->
                <ng-container *ngIf="selectedGame == 1">
                    <div class="session_plan_main_div">
                        <span>Session time :</span>
                        <span style="display: flex; gap: 20px;align-items: center;">
                            <div>Start</div>
                            <div
                                style="background: #A6DBD9 0% 0% no-repeat padding-box;border-radius: 8px;padding: 4px 8px;">
                                {{formateEpoch(vcArchiveData?.vc_info.start_time)}}</div>
                            <div>End</div>
                            <div
                                style="background: #A6DBD9 0% 0% no-repeat padding-box;border-radius: 8px;padding: 4px 8px;">
                                {{formateEpoch(vcArchiveData?.vc_info.expiry_time)}}</div>
                        </span>
                    </div>
                    <div class="session_plan_main_div">
                        <span>#Active players :</span><span>{{vcArchiveData?.vc_info.no_of_players_played}}</span>
                    </div>
                    <div class="session_plan_main_div">
                        <span>#Statement Pairs :</span><span>{{vcArchiveData?.vc_pair.length}}</span>
                    </div>
                </ng-container>

                <!-- Bottleneck -->
                <ng-container *ngIf="selectedGame == 2">
                    <div class="session_plan_main_div">
                        <span>Session time :</span>
                        <span style="display: flex; gap: 20px;align-items: center;">
                            <div>Start</div>
                            <div
                                style="background: #A6DBD9 0% 0% no-repeat padding-box;border-radius: 8px;padding: 4px 8px;">
                                {{formateEpoch(bnArchiveData.bn_info?.start_time)}}</div>
                            <div>End</div>
                            <div
                                style="background: #A6DBD9 0% 0% no-repeat padding-box;border-radius: 8px;padding: 4px 8px;">
                                {{formateEpoch(bnArchiveData.bn_info?.expiry_time)}}</div>
                        </span>
                    </div>
                    <div class="session_plan_main_div">
                        <span>#Active players :</span><span>{{bnArchiveData.bn_info?.no_of_players_played}}</span>
                    </div>
                    <div class="session_plan_main_div">
                        <span>#Teams :</span><span>{{bnArchiveData.bn_info?.no_of_teams}}</span>
                    </div>
                    <div class="session_plan_main_div">
                        <span>Gameboard Type :</span><span>{{bnArchiveData.bn_info.board_type}}</span>
                    </div>
                    <div class="session_plan_main_div">
                        <span>#Rounds :</span><span>3</span>
                    </div>
                </ng-container>

                <!-- Warehouse -->
                <ng-container *ngIf="selectedGame == 3">
                    <div class="session_plan_main_div">
                        <span>Session time :</span>
                        <span style="display: flex; gap: 20px;align-items: center;">
                            <div>Start</div>
                            <div
                                style="background: #A6DBD9 0% 0% no-repeat padding-box;border-radius: 8px;padding: 4px 8px;">
                                {{formateEpoch(whArchiveData?.wh_info?.start_time)}}</div>
                            <div>End</div>
                            <div
                                style="background: #A6DBD9 0% 0% no-repeat padding-box;border-radius: 8px;padding: 4px 8px;">
                                {{formateEpoch(whArchiveData?.wh_info?.expiry_time)}}</div>
                        </span>
                    </div>
                    <div class="session_plan_main_div">
                        <span>#Active players :</span><span>{{whArchiveData?.wh_info?.no_of_players_played}}</span>
                    </div>
                    <div class="session_plan_main_div">
                        <span>#Teams :</span><span>{{whArchiveData?.leaderboard?.length}}</span>
                    </div>
                    <div class="session_plan_main_div">
                        <span>Warehouse Theme :</span><span>{{whArchiveData?.wh_info.theme_type}}</span>
                    </div>
                    <div class="session_plan_main_div">
                        <span>#Rounds :</span><span>{{whArchiveData?.wh_info?.wh_rounds}}</span>
                    </div>
                </ng-container>

                <!-- Animal Farm  -->
                <ng-container *ngIf="selectedGame == 5">
                    <div class="session_plan_main_div">
                        <span>Session time :</span>
                        <span style="display: flex; gap: 20px;align-items: center;">
                            <div>Start</div>
                            <div
                                style="background: #A6DBD9 0% 0% no-repeat padding-box;border-radius: 8px;padding: 4px 8px;">
                                {{formateEpoch(afArchiveData.af_info?.start_time)}}</div>
                            <div>End</div>
                            <div
                                style="background: #A6DBD9 0% 0% no-repeat padding-box;border-radius: 8px;padding: 4px 8px;">
                                {{formateEpoch(afArchiveData.af_info?.expiry_time)}}</div>
                        </span>
                    </div>
                    <div class="session_plan_main_div">
                        <span>#Active players :</span><span>{{afArchiveData.af_info?.no_of_players_played}}</span>
                    </div>
                    <div class="session_plan_main_div">
                        <span>#Teams :</span><span>{{afArchiveData.leaderboard?.length}}</span>
                    </div>
                    <!-- <div class="session_plan_main_div">
                        <span>Warehouse Theme :</span><span>{{whArchiveData?.wh_info.theme_type}}</span>
                    </div> -->
                    <div class="session_plan_main_div">
                        <span>#Rounds :</span><span>{{afArchiveData.af_info.af_rounds}}</span>
                    </div>
                </ng-container>

            </div>
        </div>


         <!-- Valuecontinum -->
        <div style="flex: 1;max-height: 100%; overflow: hidden;" *ngIf="selectedGame == 1">
            <div *ngIf="vcPlayedFlag" style="display: flex;flex-direction: column;height: 100%;">
                <div *ngIf="!isDeleted && !isIncomplete"
                    style="background-color: #F2F6FA; flex:1;display: flex;flex-direction: column;align-items: center;justify-content: center;padding: 0 0 2rem 0;height: 100%;">
                    <div class="vc_archive_table_main_div" style="position: relative;">
                        <div style=" display: flex; background: #CBE4FE;align-items: stretch;border-radius: 10px 10px 0
                        0;font-weight: 500; position: sticky;top: 0;">
                            <div style="flex: 1;display: flex;justify-content: center;align-items: center;">
                                <div>Sl No.</div>
                            </div>
                            <div
                                style="flex: 1.5;border-right: 1px solid #A3A3A3;display: flex;justify-content: center;align-items: center;">
                                <div>Active Players</div>
                            </div>
                            <div
                                style="flex: 1.5; text-align: center;border-right: 1px solid #A3A3A3;display: flex;flex-direction: column;align-items: stretch;justify-content: space-between;">
                                <div style="padding-top: 6px;padding-bottom: 6px;">Left Pair</div>
                            </div>
                            <div
                                style="flex: 1.5;text-align: center;display: flex;flex-direction: column;align-items: stretch;justify-content: space-between;">
                                <div style="padding-top: 6px;padding-bottom: 6px;">Right Pair</div>
                            </div>
                        </div>

                        <div style="display: flex;align-items: stretch;cursor: pointer;" (click)="openVCDialogue(item)"
                            *ngFor="let item of vcArchiveData?.vc_pair;let i = index" class="wh_ele_div">
                            <div style="flex: 1;display: flex;justify-content: center;align-items: center;">
                                <div>{{i+1}}</div>
                            </div>
                            <div
                                style="flex: 1.5;border-right: 1px solid #A3A3A3;display: flex;justify-content: center;align-items: center;">
                                <div style="display:flex; align-items: center;justify-content: center;">
                                    Active Players #<span>{{item.active_player}}</span>
                                </div>
                            </div>
                            <div
                                style="flex: 1.5; text-align: center;border-right: 1px solid #A3A3A3;display: flex;flex-direction: column;align-items: stretch;justify-content: space-between;">
                                <!-- <div style="padding-top: 4px;">Left Pair</div> -->
                                <div style="display: flex;flex-direction:column;height: 100%;">
                                    <div style="flex: 1;border-bottom: 1px solid #A3A3A3;padding: 6px;">
                                        {{item.left_pair.pair_name}}
                                    </div>
                                    <div style="flex: 1;padding: 6px;">Avg
                                        <span>{{Math.floor(item.left_pair.avg)}}</span> Count#
                                        <span>{{item.left_pair.count}}</span>
                                    </div>
                                </div>
                            </div>
                            <div
                                style="flex: 1.5;text-align: center;display: flex;flex-direction: column;align-items: stretch;justify-content: space-between;">
                                <!-- <div style="padding-top: 4px;">Right Pair</div> -->
                                <div style="display: flex;flex-direction:column;height: 100%;">
                                    <div style="flex: 1;border-bottom: 1px solid #A3A3A3;padding: 6px;">
                                        {{item.right_pair.pair_name}}
                                    </div>
                                    <div style="flex: 1;padding: 6px;">Avg
                                        <span>{{Math.floor(item.right_pair.avg)}}</span> Count#
                                        <span>{{item.right_pair.count}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div *ngIf="isDeleted" class="data_not_found_div" style="text-align: center;
                    font-size: 25px;
                    font-weight: 500;">Game Deleted</div>
                <div *ngIf="isIncomplete" class="data_not_found_div" style="text-align: center;
                     font-size: 25px;
                     font-weight: 500;">Game Not Played</div>
            </div>
            <div *ngIf="!vcPlayedFlag" class="data_not_found_div">
                <div style="text-align: center;
            font-size: 25px;
            font-weight: 500;">Data Not found</div>
            </div>
        </div>

        <!-- Bottleneck -->
        <div style="flex: 1; max-height: 100%; overflow: hidden;" *ngIf="selectedGame == 2">
            <div *ngIf="bnPlayedFlag" style="height: 100%;">
                <div *ngIf="!isDeleted && !isIncomplete"
                    style=" height: 100%;display: flex;flex-direction: column;justify-content: flex-start;align-items: center;">
                    <div style="width: 98vw;border: 1px solid #A3A3A3;border-radius: 10px;" class="bnTabel">
                        <div class="bnHeader">
                            <div class="bnHeaderElements">
                                <div class="bnRoundTab" style="min-width: 10rem;">
                                    <img (click)="changeRound(0)"
                                        src="../../../assets/img/arrow_back_ios_FILL0_wght400_GRAD0_opsz48.svg" />
                                    <div>Round - {{bnRound}}</div>
                                    <img (click)="changeRound(1)" class="bnRight"
                                        src="../../../assets/img/arrow_back_ios_FILL0_wght400_GRAD0_opsz48.svg" />
                                </div>
                            </div>
                            <div class="bnHeaderElements"> POSITION</div>
                            <div class="bnHeaderElements" style="flex:1.5"> TEAM NAME</div>
                            <div class="bnHeaderElements"> PLAYERS IN TEAM</div>
                            <div class="bnHeaderElements"> RESETS</div>
                            <div class="bnHeaderElements"> TIME TO SUCCESS</div>
                        </div>
                        <div class="bnBody">
                            <div class="bnBodyContainer" *ngFor="let item of bnArchiveData.leaderboard">
                                <div class="bnBodyElements" style="flex:1;min-width: 10rem;">&nbsp;&nbsp;&nbsp;</div>
                                <div class="bnBodyElements bnAward">
                                    <img src="../../../assets/img/bnGold.svg" *ngIf="item.position == 1" />
                                    <img src="../../../assets/img/bnSilver.svg" *ngIf="item.position == 2" />
                                    <img src="../../../assets/img/bnBronze.svg" *ngIf="item.position == 3" />
                                    <img src="../../../assets/img/bnAll.svg"
                                        *ngIf="item.position != 1 && item.position != 2 && item.position != 3 " />
                                    {{item.position}}
                                </div>
                                <div class="bnBodyElements" style="flex:1.5">{{item.new_team.length > 0 ? item.new_team : item.team}}</div>
                                <div class="bnBodyElements">{{item.players_in_team}}</div>
                                <div class="bnBodyElements">{{item.isreset}}</div>
                                <div class="bnBodyElements bnAward">
                                    <img src="../../../assets/bnClock.svg" />
                                    {{getSecToMin(item.time)}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="isDeleted" class="data_not_found_div" style="text-align: center;
                font-size: 25px;
                font-weight: 500;">Game Deleted</div>
                <div *ngIf="isIncomplete" class="data_not_found_div" style="text-align: center;
                 font-size: 25px;
                 font-weight: 500;">Game Not Played</div>
            </div>
            <div *ngIf="!whPlayedFlag">
                <div *ngIf="!isDeleted && !isIncomplete" style="text-align: center;
                        font-size: 25px;
                        font-weight: 500;">Data Not found
                </div>
                <div *ngIf="isDeleted" class="data_not_found_div" style="text-align: center;
                        font-size: 25px;
                        font-weight: 500;">Game Deleted
                </div>
                <div *ngIf="isIncomplete" class="data_not_found_div" style="text-align: center;
                        font-size: 25px;
                        font-weight: 500;">Game Not Played
                </div>
            </div>
        </div>

        <!-- Warehouse -->
        <div style="flex: 1;max-height: 100%; overflow: hidden;" *ngIf="selectedGame == 3">
            <div *ngIf="whPlayedFlag" class="whGame" style="height: 100%;">
                <div *ngIf="!isDeleted && !isIncomplete"
                    style="background-color: #F2F6FA; height: 100%;display: flex;flex-direction: column;justify-content: flex-start;align-items: center;">
                    <div
                        style="margin:0 2rem;width: 97vw;border: 1px solid #A3A3A3;overflow-x: auto ;border-radius: 10px;position: relative;margin-bottom: 2rem;position: sticky; top: 0;">
                        <div
                            style="display: flex;align-items: stretch;    border-radius: 10px 10px 0 0;font-weight: 500;">
                            <div class="whHeader1"
                                style="flex: 1;padding: 10px;border-right: 1px solid #A3A3A3;background: #CBE4FE;display: flex;align-items: center; justify-content: center;">
                                <div>Sl No.</div>
                            </div>
                            <div class="whHeader2"
                                style="flex: 3;border-right: 1px solid #A3A3A3;background: #CBE4FE;    padding: 10px 0;display: flex;align-items: center; justify-content: center;">
                                <div>Team Name</div>

                            </div>
                            <div class="whHeader2"
                                style="flex: 2;padding: 10px; text-align: center;border-right: 1px solid #A3A3A3;background: #CBE4FE;display: flex;align-items: center; justify-content: center;">
                                #Players in Team
                            </div>
                            <div class="whHeader2"
                                style="flex: 1.5;padding: 10px;text-align: center;border-right: 1px solid #A3A3A3;background: #CBE4FE;display: flex;align-items: center; justify-content: center;">
                                <div>#Rounds Played</div>
                            </div>
                            <div class="whHeader2"
                                style="flex: 3;padding: 10px;text-align: center;border-right: 1px solid #A3A3A3;background: #CBE4FE;display: flex;align-items: center; justify-content: center;">
                                <div>#Disqualifications</div>
                            </div>
                            <div class="whHeader2"
                                style="flex: 1.5;padding: 10px;text-align: center;border-right: 1px solid #A3A3A3;background: #CBE4FE;display: flex;align-items: center; justify-content: center;">
                                <div>Practice time</div>
                            </div>
                            <div class="whHeader2"
                                style="flex: 1.5;padding: 10px;text-align: center;border-right: 1px solid #A3A3A3;background: #CBE4FE;display: flex;align-items: center; justify-content: center;">
                                <div>Game time</div>
                            </div>
                            <div class="whRounds whHeader2"
                                *ngFor="let round of whArchiveData?.wh_info?.round_info; let j = index"
                                style="flex: 3;padding: 10px 0 0 0;text-align: center;display: flex;align-items: center;background: #CBE4FE; justify-content: center;flex-direction: column; min-width: 200px;">
                                <div style="border-bottom: 1px solid #A3A3A3">Round {{j+1}}</div>
                                <div class="wh_round_sub_heading">
                                    <div>Active <br> Players</div>
                                    <div style="border-left: 1px solid #A3A3A3;align-self: stretch;">&nbsp;</div>
                                    <div>{{round.order}} <br> {{round.level}}</div>
                                </div>
                            </div>
                        </div>

                        <div style="display: flex;align-items: stretch;width: 100%;"
                            *ngFor="let item of whArchiveData?.leaderboard;let i = index"
                            class="wh_ele_div remove_rt_border">
                            <div class="whHeader1"
                                style="flex: 1;padding: 10px;border-right: 1px solid #A3A3A3;display: flex;align-items: center; justify-content: center;">
                                <div> <img class="whTropy" *ngIf="item.is_winner == 'yes'"
                                        src="../../../assets/afArchive.svg" /> {{i+1}}</div>
                            </div>
                            <div class="whHeader2"
                                style="flex: 3;border-right: 1px solid #A3A3A3;    padding: 10px;display: flex;align-items: center; justify-content: center;">
                                <div>{{item.new_team ? item.new_team : item.team}}</div>
                            </div>
                            <div class="whHeader2"
                                style="flex: 2;padding: 10px; text-align: center;border-right: 1px solid #A3A3A3;display: flex;align-items: center; justify-content: center;">
                                <div>{{item.players_in_team}}</div>
                            </div>
                            <div class="whHeader2"
                                style="flex: 1.5;padding: 10px;text-align: center;border-right: 1px solid #A3A3A3;display: flex;align-items: center; justify-content: center;">
                                <div>{{item.rounds_played}}</div>
                            </div>
                            <div class="whHeader2"
                                style="flex: 3;padding: 10px;text-align: center;border-right: 1px solid #A3A3A3;display: flex;align-items: center; justify-content: center;">
                                <div>{{item.disqualification}}</div>
                            </div>
                            <div class="whHeader2"
                                style="flex: 1.5;padding: 10px;text-align: center;border-right: 1px solid #A3A3A3;display: flex;align-items: center; justify-content: center;">
                                <div>{{getSecToMin(item.game_practie_time)}}</div>
                            </div>
                            <div class="whHeader2"
                                style="flex: 1.5;padding: 10px;text-align: center;border-right: 1px solid #A3A3A3;display: flex;align-items: center; justify-content: center;">
                                <div>{{getSecToMin(item.game_time)}}</div>
                            </div>
                            <div class="whHeader2" *ngIf="item.round1 && whArchiveData?.wh_info.round_info.length>0"
                                [ngClass]="item.round1[0]?.isqualified == 'Disqualified' ? 'disqualified': item?.best_time == item.round1[0]?.playtime && item.round1.length > 0 ? 'bestTime' : '' "
                                style="flex: 3;text-align: center;border-right: 1px solid #A3A3A3;display: flex;align-items: center; justify-content: center; min-width: 200px;">
                                <div class="wh_round_sub_heading">
                                    <div>{{item.round1.length > 0 ? item.round1[0].active_player :'N/A'}}</div>
                                    <div style="border-left: 1px solid #A3A3A3;align-self: stretch;">&nbsp;</div>
                                    <div>{{getSecToMin(item.round1[0]?.playtime? item.round1[0]?.playtime :
                                        'N/A')}}{{item.round1[0]?.isqualified == 'Disqualified' ? 'D':''}}
                                    </div>
                                </div>
                            </div>
                            <div class="whHeader2" *ngIf="item.round2 && whArchiveData?.wh_info.round_info.length>1"
                                [ngClass]="item.round2[0]?.isqualified == 'Disqualified' ? 'disqualified': '0'+item?.best_time == item.round2[0]?.playtime && item.round2.length > 0 ? 'bestTime' : ''"
                                style="flex: 3;text-align: center;border-right: 1px solid #A3A3A3;display: flex;align-items: center; justify-content: center; min-width: 200px;">

                                <div class="wh_round_sub_heading">
                                    <div>{{item.round2.length > 0 ? item.round2[0].active_player :'N/A'}}</div>
                                    <div style="border-left: 1px solid #A3A3A3;align-self: stretch;">&nbsp;</div>
                                    <div>{{getSecToMin(item.round2[0]?.playtime? item.round2[0]?.playtime :
                                        'N/A')}}{{item.round2[0]?.isqualified == 'Disqualified' ? 'D':''}}</div>
                                </div>


                            </div>


                            <div class="whHeader2" *ngIf="item.round3 && whArchiveData?.wh_info.round_info.length>2"
                                [ngClass]="item.round3[0]?.isqualified == 'Disqualified' ? 'disqualified': '0'+item?.best_time == item.round3[0]?.playtime && item.round3.length > 0 ? 'bestTime' : ''"
                                style="flex: 3;min-width: 200px;text-align: center;border-right: 1px solid #A3A3A3;display: flex;align-items: center; justify-content: center;">
                                <div class="wh_round_sub_heading">
                                    <div>{{item.round3.length > 0 ? item.round3[0].active_player :'N/A'}}</div>
                                    <div style="border-left: 1px solid #A3A3A3;align-self: stretch;">&nbsp;</div>
                                    <div>{{getSecToMin(item.round3[0]?.playtime? item.round3[0]?.playtime :
                                        'N/A')}}{{item.round3[0]?.isqualified == 'Disqualified' ? 'D':''}}</div>
                                </div>
                            </div>


                            <div class="whHeader2" *ngIf="item.round4 && whArchiveData?.wh_info.round_info.length>3"
                                [ngClass]="item.round4[0]?.isqualified == 'Disqualified' ? 'disqualified': '0'+item?.best_time == item.round4[0]?.playtime && item.round4.length > 0 ? 'bestTime' : ''"
                                style="flex: 3;min-width: 200px;text-align: center;border-right: 1px solid #A3A3A3;display: flex;align-items: center; justify-content: center;">
                                <div class="wh_round_sub_heading">
                                    <div>{{item.round4.length > 0 ? item.round4[0].active_player :'N/A'}}</div>
                                    <div style="border-left: 1px solid #A3A3A3;align-self: stretch;">&nbsp;</div>
                                    <div>{{getSecToMin(item.round4[0]?.playtime? item.round4[0]?.playtime :
                                        'N/A')}}{{item.round4[0]?.isqualified == 'Disqualified' ? 'D':''}}</div>
                                </div>
                            </div>


                            <div class="whHeader2" *ngIf="item.round5 && whArchiveData?.wh_info.round_info.length>4"
                                [ngClass]="item.round5[0]?.isqualified == 'Disqualified' ? 'disqualified': '0'+item?.best_time == item.round5[0]?.playtime && item.round5.length > 0 ? 'bestTime' : ''"
                                style="flex: 3;min-width: 200px;text-align: center;border-right: 1px solid #A3A3A3;display: flex;align-items: center; justify-content: center;">
                                <div class="wh_round_sub_heading">
                                    <div>{{item.round5.length > 0 ? item.round5[0].active_player :'N/A'}}</div>
                                    <div style="border-left: 1px solid #A3A3A3;align-self: stretch;">&nbsp;</div>
                                    <div>{{getSecToMin(item.round5[0]?.playtime? item.round5[0]?.playtime :
                                        'N/A')}}{{item.round5[0]?.isqualified == 'Disqualified' ? 'D':''}}</div>
                                </div>
                            </div>

                            <div class="whHeader2" *ngIf="item.round6 && whArchiveData?.wh_info.round_info.length>5"
                                [ngClass]="item.round6[0]?.isqualified == 'Disqualified' ? 'disqualified': '0'+item?.best_time == item.round6[0]?.playtime && item.round6.length > 0 ? 'bestTime' : ''"
                                style="flex: 3;min-width: 200px;text-align: center;border-right: 1px solid #A3A3A3;display: flex;align-items: center; justify-content: center;">
                                <div class="wh_round_sub_heading">
                                    <div>{{item.round6.length > 0 ? item.round6[0].active_player :'N/A'}}</div>
                                    <div style="border-left: 1px solid #A3A3A3;align-self: stretch;">&nbsp;</div>
                                    <div>{{getSecToMin(item.round6[0]?.playtime? item.round6[0]?.playtime :
                                        'N/A')}}{{item.round6[0]?.isqualified == 'Disqualified' ? 'D':''}}</div>
                                </div>
                            </div>
                            <div class="whHeader2" *ngIf="item.round7 && whArchiveData?.wh_info.round_info.length>6"
                                [ngClass]="item.round7[0]?.isqualified == 'Disqualified' ? 'disqualified': '0'+item?.best_time == item.round7[0]?.playtime && item.round7.length > 0 ? 'bestTime' : ''"
                                style="flex: 3;min-width: 200px;text-align: center;border-right: 1px solid #A3A3A3;display: flex;align-items: center; justify-content: center;">
                                <div class="wh_round_sub_heading">
                                    <div>{{item.round7.length > 0 ? item.round7[0].active_player :'N/A'}}</div>
                                    <div style="border-left: 1px solid #A3A3A3;align-self: stretch;">&nbsp;</div>
                                    <div>{{getSecToMin(item.round7[0]?.playtime? item.round7[0]?.playtime :
                                        'N/A')}}{{item.round7[0]?.isqualified == 'Disqualified' ? 'D':''}}</div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                <div *ngIf="isDeleted" class="data_not_found_div" style="text-align: center;
                font-size: 25px;
                font-weight: 500;">Game Deleted</div>
                <div *ngIf="isIncomplete" class="data_not_found_div" style="text-align: center;
                 font-size: 25px;
                 font-weight: 500;">Game Not Played</div>
            </div>
            <div *ngIf="!whPlayedFlag">
                <div *ngIf="!isDeleted && !isIncomplete" style="text-align: center;
                        font-size: 25px;
                        font-weight: 500;">Data Not found
                </div>
                <div *ngIf="isDeleted" class="data_not_found_div" style="text-align: center;
                        font-size: 25px;
                        font-weight: 500;">Game Deleted
                </div>
                <div *ngIf="isIncomplete" class="data_not_found_div" style="text-align: center;
                        font-size: 25px;
                        font-weight: 500;">Game Not Played
                </div>
            </div>
        </div>

        <!-- Animal Farm  -->
        <div style="flex: 1;max-height: 100%; overflow: hidden;" *ngIf="selectedGame == 5">
            <div *ngIf="afPlayedFlag" style="height: 100%;">

                <div *ngIf="!isDeleted && !isIncomplete"
                    style="background-color: #F2F6FA;display: flex;flex-direction: column;justify-content: flex-start;align-items: center; height: 100%;">
                    <div class="af_table_main_div">
                        <div class="afTotalHeader">
                            <img src="../../../assets/afArchive.svg" /> FINAL LEADERBOARD
                        </div>
                        <div class="afHeader" style="position: sticky;top: 0;">
                            <div class="afHeaderElement" style="flex: 2.5;"> Team No.
                            </div>
                            <div class="afHeaderElement"> No of Players</div>
                            <div class="afHeaderElement"> Sub Team</div>
                            <div class="afHeaderElement" style="display: flex;flex-direction: column;"
                                *ngFor="let val of totalRounds;let j = index">
                                <div>Round {{j+1}}</div>
                                <div style="font-size: 13px;">{{RoundOrder[j].level}} | {{RoundOrder[j].order}}</div>
                            </div>
                            <div class="afHeaderElement"> Total Points</div>
                            <div class="afHeaderElement" style="flex: 1.5;"> Team Score</div>
                        </div>
                        <div class="afBody" *ngFor="let item of afArchiveData.leaderboard">
                            <div class="afElement" style="flex: 2.5;border-right: 1px solid #A3A3A3;">
                                {{item.team}}
                            </div>
                            <div class="afElement" style="flex:1;border-right: 1px solid #A3A3A3;">
                                {{item.players_in_team}}
                            </div>
                            <div class="afElement" style="display: flex;flex-direction: column;">
                                <div> {{item.subteam[0].sub_team}}</div>
                                <div> {{item.subteam[1].sub_team}}</div>
                            </div>
                            <div class="afElement" style="display: flex;flex-direction: column;"
                                *ngFor="let data of item.TeamA;let i = index">
                                <div> {{data.points ?? 'N/A' }} </div>
                                <div> {{item.TeamB[i].points ??  'N/A'}} </div>
                            </div>
                            <div class="afElement" style="display: flex;flex-direction: column;">
                                <div> {{item.subteam[0]?.total_points}} </div>
                                <div> {{item.subteam[1]?.total_points}} </div>
                            </div>
                            <div class="afElement" style="flex:1.5">
                                {{item.teamscore}}
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="isDeleted" class="data_not_found_div" style="text-align: center;
                font-size: 25px;
                font-weight: 500;">Game Deleted</div>
                <div *ngIf="isIncomplete" class="data_not_found_div" style="text-align: center;
                 font-size: 25px;
                 font-weight: 500;">Game Not Played</div>
            </div>
            <div *ngIf="!whPlayedFlag">
                <div *ngIf="!isDeleted && !isIncomplete" style="text-align: center;
                        font-size: 25px;
                        font-weight: 500;">Data Not found
                </div>
                <div *ngIf="isDeleted" class="data_not_found_div" style="text-align: center;
                        font-size: 25px;
                        font-weight: 500;">Game Deleted
                </div>
                <div *ngIf="isIncomplete" class="data_not_found_div" style="text-align: center;
                        font-size: 25px;
                        font-weight: 500;">Game Not Played
                </div>
            </div>
        </div>
        
    </div>
</div>