import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatTableModule } from '@angular/material/table'

import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent, NewVersionDialog } from './app.component';
import { LoginComponentComponent } from './login-component/login-component.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardComponent, LowLicenseAlertDialog } from './dashboard/dashboard.component';
import { HeaderComponent } from './header/header.component';
import { ConfigureComponent,  SchedulerConfigureComponent, DeleteSessionConfirmDialog, CurrentSlotAlertDialog, LicenseInfoDialog, TermsConditionsDialog } from './components/configure/configure.component';
import { MatNativeDateModule } from '@angular/material/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NewGamesComponent, SuccessDialog, VcDeleteDialog, VcNewGamesComponent, WarehouseNewGamesComponent, AnimalfarmNewGamesComponent, BottleneckNewGamesComponent, BigPictureNewGamesComponent, SessionCompleteDialog, PicturePerfectNewGamesComponent, DeleteEditConfirmDialog, GreatOceanNewGamesComponent, PeopleOfWorldNewGamesComponent } from './components/new-games/new-games.component'
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from "@angular/material/dialog";
import { KariosInterceptorInterceptor } from './interceptor/karios-interceptor.interceptor';
import { ToastrModule } from "ngx-toastr";
import { LicesenseReturnInfoDialog, ScheduledComponent, SessionDeleteDialog } from './components/scheduled/scheduled.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BigPictureCodeDialog, OneGameDeleteDialog, QrCodeDialog, SessionDashboardComponent, TimeSlotDialog, questicoKeysDialog } from './components/session-dashboard/session-dashboard.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ExploreAllGamesComponent, ExploreVideoPlayerDialog } from './components/explore-all-games/explore-all-games.component';
import { ConfigureQuesticoComponent } from './components/configure-questico/configure-questico.component';
import { BigPicDashboardComponent } from './components/big-pic-dashboard/big-pic-dashboard.component';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { GameResourcesComponent } from './components/game-resources/game-resources.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MyGamesComponent } from './components/my-games/my-games.component';
import { QuesticoResourceComponent } from './components/questico-resource/questico-resource.component';
import { QRCodeModule } from 'angularx-qrcode';
import { AccountSettingComponent, ChangePasswordComponent } from './components/account-setting/account-setting.component';
import { CompletePhysicalComponent, ConfigurePhysicalComponent } from './components/configure-physical/configure-physical.component';
import { OutsideClickDirective } from './outside-click.directive';
import { IgluLabsUserComponent } from './components/iglu-labs-user/iglu-labs-user.component';
import { ArchiveScheduleComponent } from './components/archive-schedule/archive-schedule.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CommomDialogComponent, VCCommomDialogComponent, WHCommomDialogComponent } from './components/commom-dialog/commom-dialog.component';
import { MatSelectModule } from '@angular/material/select'; 
import { ArchiveDashboardComponent, DialogueVCInfo } from './components/archive-dashboard/archive-dashboard.component';
import { AdminDashboardComponent } from './components/superadmin/admin-dashboard/admin-dashboard.component';
import { UserAddComponent, UserManagementComponent, suspendActivateUserDialog } from './components/superadmin/user-management/user-management.component';
import { GameManagementComponent } from './components/superadmin/game-management/game-management.component';
import { ReachOutComponent } from './components/reach-out/reach-out.component';
import { AdminLoginComponent } from './components/superadmin/admin-login/admin-login.component';
import { DigitalGameAssignmentComponent, GameAssignmentComponent, PhysicalGameAssignmentComponent } from './components/superadmin/game-assignment/game-assignment.component';
import { GameDtlsComponent } from './components/superadmin/game-dtls/game-dtls.component';
import { GameContentComponent, addLegendDialogComponent, resourceConfirmDialog, resourcePreviewDialog, resourcePublishDialog } from './components/superadmin/game-content/game-content.component';
import { QuikGameConfigComponent } from './components/superadmin/quik-game-config/quik-game-config.component';
import {MatPaginatorModule} from '@angular/material/paginator'
import { GameTrackingComponent } from './components/superadmin/game-tracking/game-tracking.component';
import { MatSelectSearchModule } from 'mat-select-search';
import { TrackGameComponent } from './components/superadmin/track-game/track-game.component';
import { ActivityReportComponent } from './components/activity-report/activity-report.component';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbDropdownConfig, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { TermConditionPageComponent } from './components/term-condition-page/term-condition-page.component';
@NgModule({
  declarations: [
    DeleteSessionConfirmDialog,
    AppComponent,
    LoginComponentComponent,
    DashboardComponent,
    HeaderComponent,
    ConfigureComponent,
    NewGamesComponent,
    VcNewGamesComponent,
    VcDeleteDialog,
    SuccessDialog,
    WarehouseNewGamesComponent,
    AnimalfarmNewGamesComponent,
    ScheduledComponent,
    SessionDashboardComponent,
    BottleneckNewGamesComponent,
    ExploreAllGamesComponent,
    ConfigureQuesticoComponent,
    BigPictureNewGamesComponent,
    BigPicDashboardComponent,
    GameResourcesComponent,
    MyGamesComponent,
    QuesticoResourceComponent,
    QrCodeDialog,
    AccountSettingComponent,
    ConfigurePhysicalComponent,
    ChangePasswordComponent,
    OutsideClickDirective,
    CompletePhysicalComponent,
    OneGameDeleteDialog,
    SessionCompleteDialog,
    PicturePerfectNewGamesComponent,
    IgluLabsUserComponent,
    ArchiveScheduleComponent,
    BigPictureCodeDialog,
    SchedulerConfigureComponent,
    TimeSlotDialog,
    CommomDialogComponent,
    VCCommomDialogComponent,
    WHCommomDialogComponent,
    ArchiveDashboardComponent,
    DeleteEditConfirmDialog,
    AdminDashboardComponent,
    UserManagementComponent,
    GameManagementComponent,
    UserAddComponent,
    ReachOutComponent,
    AdminLoginComponent,
    GameAssignmentComponent,
    GameDtlsComponent,
    PhysicalGameAssignmentComponent,
    DigitalGameAssignmentComponent,
    GameContentComponent,
    addLegendDialogComponent,
    resourceConfirmDialog,
    resourcePreviewDialog, 
    resourcePublishDialog,
    suspendActivateUserDialog,
    QuikGameConfigComponent,
    GreatOceanNewGamesComponent,
    PeopleOfWorldNewGamesComponent,
    CurrentSlotAlertDialog,
    questicoKeysDialog,
    CurrentSlotAlertDialog,
    ExploreVideoPlayerDialog,
    GameTrackingComponent,
    LowLicenseAlertDialog,
    DialogueVCInfo,
    TrackGameComponent,
    ActivityReportComponent,
    NewVersionDialog,
    SessionDeleteDialog,
    LicenseInfoDialog,
    LicesenseReturnInfoDialog,
    TermsConditionsDialog,
    TermConditionPageComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatNativeDateModule,
    DragDropModule,
    HttpClientModule,
    MatDialogModule,
    MatTableModule,
    ToastrModule.forRoot(),
    NgbModule,
    ClipboardModule,
    MatTooltipModule,
    NgHttpLoaderModule.forRoot(),
    PdfViewerModule,
    QRCodeModule,
    MatDatepickerModule,
    MatSelectModule,
    MatPaginatorModule,MatSelectSearchModule,
    MatPaginatorModule,
    NgbPaginationModule,
    NgbDropdownModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true,
      registrationStrategy: 'registerWhenStable:30000'
    }),
    
  ],
  providers: [
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: KariosInterceptorInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  entryComponents: [VcDeleteDialog, SuccessDialog],

})
export class AppModule { }
