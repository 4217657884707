import { Component, Inject, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataSharingServiceService } from 'src/app/services/data-sharing-service.service';
import { HttpService } from 'src/app/services/http.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-configure-physical',
  templateUrl: './configure-physical.component.html',
  styleUrls: ['./configure-physical.component.css']
})
export class ConfigurePhysicalComponent implements OnInit {

  constructor(
    private dataService: DataSharingServiceService,
    private router: Router,
    private toster: ToastrService,
    private httpSv: HttpService,
    private titleService: Title,
    private dialog: MatDialog,
  ) { }
  editProgramId:any = null;
  ngOnInit(): void {
    if (this.dataService.sessionData && !this.dataService.newSetup) {
      this.NewSession = 'Edit Session'
      const temp = this.dataService.sessionData
      this.sessionName = temp.sessionName
      this.clientName = temp.clientName
      this.editProgramId = temp.programId
      let date = new Date(temp.sessionDate)
      let dateTemp = date.toLocaleDateString("en-GB", { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/\//g, '-')
      this.sessionDate = dateTemp.split('-').reverse().join('-')
      this.dateChangeHadler()


      // step -2 
      const alreadySelected = this.dataService.selectedGameForConfig
      if (alreadySelected.length > 0) {
        alreadySelected.forEach((ele: any) => {
          const game = JSON.parse(ele)
          const index = this.todo1.findIndex((ele: any) => JSON.parse(ele).gameId == game.gameId)
          if (index != -1) {
            this.done1.push(this.todo1[index])
            this.todo1.splice(index, 1)
          }
          else {
            this.toster.error('Oops!! Something went wrong')
            this.router.navigate(['/dashboard'])
          }
        })
      }
      else {
        this.toster.error('Oops!! Something went wrong')
        this.router.navigate(['/dashboard'])
      }
    }
  }
  todayDate: any
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.todayDate = new Date().toLocaleDateString("en-GB").split('T')[0].split('/').reverse().join('-');
    document.getElementById("datePicker")?.setAttribute('min', this.todayDate);
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.todo1 =[]
    this.done1 =[]
  }
  NewSession: any = 'New Session'
  Digital: any = 'Physical'
  Name: any = 'Name & Date'
  step: number = 1
  sessionName: string = ''
  sessionDate: string = ''
  finalDateFormated: string = ''
  clientName: string = ''
  goToStep1() {
    this.step = 1
    var today = new Date().toLocaleDateString("en-GB").split('T')[0].split('/').reverse().join('-');
    document.getElementById("datePicker")?.setAttribute('min', today);
    this.Name = 'Name & Date'
  }
  goToStep2() {
    if (this.sessionName == '' || this.sessionDate == '' || this.clientName == '') {
      this.toster.error('All fields are mandantory', '', { timeOut: 2000 })
    }
    else if (Date.parse(this.todayDate) > Date.parse(this.sessionDate)) {
      this.toster.error('Game can not be configure for previous dates', '', { timeOut: 2000 })
    }
    else {
      this.Name = 'Select Games'
      this.step = 2
    }
  }
  dateChangeHadler() {
    let date = this.sessionDate
    let newdate = date.split("-").reverse()
    let dd = newdate[0]
    let mm = this.getMonthName(newdate[1])
    let yy = newdate[2]
    this.finalDateFormated = `${dd} ${mm} ${yy}`
  }
  getMonthName(monthNumber: any) {
    const date = new Date();
    date.setMonth(monthNumber - 1);

    return date.toLocaleString('en-US', { month: 'long' });
  }


  //step 2
  todo1 = [
    `{"name":"Human Matrix","gameId":"8","imgUrl":"../../assets/img/human_matrix.jpg"}`,
    `{"name":"String Theory","gameId":"9","imgUrl":"../../assets/img/string_theory.jpg"}`,
    `{"name":"Stromshelter","gameId":"10","imgUrl":"../../assets/img/strom.jpg"}`,
    `{"name":"Assemblyline","gameId":"11","imgUrl":"../../assets/img/assembley_line.jpg"}`,
    `{"name":"Magic Mats","gameId":"12","imgUrl":"../../assets/img/magic_mats.jpg"}`,
    `{"name":"Stranded","gameId":"13","imgUrl":"../../assets/img/stander.jpg"}`,
  ];
  // `{"name":"Hunan Matrix","gameId":"py7","imgUrl":"../../assets/img/matrix_config.png"}`,
  // `{"name":"String Theory","gameId":"py11","imgUrl":"../../assets/img/string_config.png"}`,
  // `{"name":"Stromshelter","gameId":"py9","imgUrl":"../../assets/img/strom_config.png"}`,
  // `{"name":"Assemblyline","gameId":"py10","imgUrl":"../../assets/img/line_config.png"}`,
  // `{"name":"Magic Mats","gameId":"py12","imgUrl":"../../assets/img/mats_config.png"}`,
  // `{"name":"Stranded","gameId":"py8","imgUrl":"../../assets/img/stand_config.png"}`,

  done1: string[] = [];

  drop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      // moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {

      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }
  getJSONparse(item: any, type: any) {
    return JSON.parse(item)[type]
  }
  deleteFromRight(data: any, index: any) {
    // this.todo1.push(data)
    // this.done1.splice(index, 1)
       this.todo1.splice(parseInt(JSON.parse(data).gameId) - 1, 0, data);
       // this.todo[] = 
       this.done1.splice(index, 1)
       this.todo1 = this.todo1.sort((a: any, b: any) => {
         if (parseInt(JSON.parse(a)['gameId'] )> parseInt(JSON.parse(b)['gameId'])) {
           return 1
         }
         else if (parseInt(JSON.parse(a)['gameId']) < parseInt(JSON.parse(b)['gameId'])) {
           return -1
         }
         else return 0
       })
       this.done1 = this.done1
  }
  saveAndMoveToNew() {

    if (this.done1.length == 0) {
      this.toster.error('Please drag any game', '', { timeOut: 2000 })
    }
    else {
      let data = {
        program_name: this.sessionName,
        program_date: this.sessionDate,
        client_name: this.clientName,
        game_id: this.done1.map(ele => JSON.parse(JSON.parse(ele)['gameId'])),
        program_id: this.editProgramId
      }
      this.httpSv.configPhysicalGame(data).subscribe((res:any)=>{
        if(res['status']){
          const dialogRef = this.dialog.open(CompletePhysicalComponent, {
            disableClose: true,
            minWidth: '400px'
          })
          dialogRef.afterClosed().subscribe((res:any)=>{
            if(res){
              this.router.navigate(['/scheduled'])
            }
          })
        }
        else{
          this.toster.error('Opps! Something went wrong','',{timeOut:2000})
        }
      },(err:any)=>{
        this.toster.error(err.error.message,'',{timeOut:2000})
      })
    }
  }
  selectedItem: any = null
  drag(ev: any, data: any) {
    this.selectedItem = data
  }
  allowDrop(ev: any) {
    ev.preventDefault();
  }
  drop1(ev: any) {
    if (this.selectedItem != null) {
      this.done1.push(this.todo1[this.selectedItem])
      this.todo1.splice(this.selectedItem, 1)
    }
    this.selectedItem = null
  }
  dragEnd() {
    this.selectedItem = null
  }
  insideIndex: any = null
  dragInside(ev: any, data: any) {
    this.insideIndex = data
  }
  insideDrop(index:any){
    if(this.insideIndex != null){
      const temp = this.done1[this.insideIndex]
      this.done1.splice(this.insideIndex,1)
      this.done1.splice(index,0,temp)
    }
    
  }
  dragInsideEnd() {
    this.insideIndex = null
  }
}




@Component({
  selector: 'app-complete-physical',
  templateUrl: './cofig-complete.html',
})
export class CompletePhysicalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<CompletePhysicalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ){}
  ngOnInit(): void {
    
  }
  close(){
    this.dialogRef.close(true)
  }
}