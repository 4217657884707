<div class="main-div">
    <div class="text">All games saved</div>
    <div class="text">Session set up completed</div>
    <div class="btn-div">
        <input style="width: 27%;" type="image" src="../../../assets/img/ok_btn_red.svg" (click)="close()">
    </div>
</div>

<style>
    ::ng-deep .mat-dialog-container {
        padding: 0 !important;
        border-radius: 10px;
    }

    .main-div {
        padding: 20px;
        border-radius: 20px;
        border: 1px solid #797979;
    }

    .text {
        text-align: center;
    }

    .btn-div {
        text-align: center;
        margin-top: 1rem;
    }
</style>