import { Component, Injectable, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataSharingServiceService } from 'src/app/services/data-sharing-service.service';
import { HttpService } from 'src/app/services/http.service';
import * as AWS from 'aws-sdk';
// const awsSdk: any = AWS;
// const awsSdk: any = require('aws-sdk');
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css']
})
@Injectable({
  providedIn: 'root'
})
export class AdminDashboardComponent implements OnInit {
  private s3:any;
  constructor(
    private dataService: DataSharingServiceService,
    private router: Router,
    private toster: ToastrService,
    private httpSv: HttpService
  ) { 
    AWS.config.update({
      accessKeyId: environment.aws.accessKeyId,
      secretAccessKey: environment.aws.secretAccessKey,
      region: environment.aws.region
    });
    this.s3 = new AWS.S3({
      params: { Bucket: environment.aws.bucketName }
    });
  }
  compRoute: string = 'home'
  adminFlag: boolean = false
  ngOnInit(): void {
    if (sessionStorage.getItem('role') == '1') {
      this.header = 'Super Admin Dashboard';
      this.adminFlag = true
      this.getReachoutUserCount()
    }
    else if (sessionStorage.getItem('role') == '2') {
      this.header = 'Content Admin Dashboard';
      this.adminFlag = false
    } else {
      this.toster.error('Login fail', '', { timeOut: 2000 })
      this.router.navigate(['/admin'])
    }
  }
  handleClick(event: Event): void {
    const target = event.target as HTMLElement;

    // Check if the clicked element has the 'child' class
    if (target.classList.contains('child')) {
      event.stopPropagation();
      // console.log('Click on child blocked');
    }
  }
  header: string = '';

  changeCompRoute(route: any) {
    if (route == 'user-list') {
      if (this.adminFlag)
        this.router.navigate(['/user-list']);
    } else if (route == 'addUser') {
      if (this.adminFlag) {
        this.dataService.addEditUser = 'addUser';
        this.router.navigate(['/add-user']);
      }
    }
    else if (route == 'digital') {
      this.router.navigate(['/admin-games'], { queryParams: { game: 1 } });
    } else if (route == 'physical') {
      this.router.navigate(['/admin-games'], { queryParams: { game: 2 } });
    }
    else if (route == 'gameTracking'){
      this.router.navigate(['/game-tracking'], { queryParams: { tracking : 'entire' } });
    }
  }
  userReachoutFlag:boolean = false
  getReachoutUserCount(){
    this.httpSv.getReachoutUserCount().subscribe((res:any)=>{
      if(res['status']){
        if(res['results'] > 0){
          this.userReachoutFlag = true
        }
      }else{
        this.toster.error('Oops! Something went wrong','',{timeOut:2000})
      }
    },(err:any)=>{this.toster.error(err.error.message,'',{timeOut:2000})})
  }
  onImageChange(evt: any) {
    let image: any = evt.target.files[0];
    // console.log(image)
    // legend.main_resources_file = evt.target.files[0];
    // this.uploadFile(image)
    //   .then(data => {
    //     console.log('File uploaded successfully:', data);
    //     // Handle success
    //   })
    //   .catch(error => {
    //     console.error('Error uploading file:', error);
    //     // Handle error
    //   });
    const fsize = evt.target.files[0].size;
    const fileSize = Math.round((fsize / 1024));
    const name = evt.target.files[0].name;
    // if (fileSize > 8192) {
    //   this.toster.error('Sorry image size is too big', '', { timeOut: 2000 })
    //   return
    // }
    // let fr = new FileReader;
    // fr.onload = () => { // when file has loaded
    //   var img: any = new Image();

    //   img.onload = () => {

    //   };

    //   img.src = fr.result; // This is the data URL 
    // };

    // fr.readAsDataURL(image);
    // (<HTMLInputElement>document.getElementById('hiddenImgInput')).value = ""

    // imgType.nativeElement.value = "";
  }
  uploadFile(file: any): Promise<any> {
    // console.log(file)
    const params = {
      Bucket: environment.aws.bucketName,
      Key: file.name,
      Body: file,
      ACL: 'public-read',
      
    };

    return new Promise((resolve, reject) => {
      this.s3.upload(params)
        .on('httpUploadProgress', (progress:any) => {
          const percentage = Math.round((progress.loaded / progress.total) * 100);
          // console.log('Upload percentage:', percentage);
          // You can emit an event or update a progress bar here
        })
        .send((err:any, data:any) => {
          if (err) {
            reject(err);
          } else {
            resolve(data);
          }
        });
    });
  }
}
