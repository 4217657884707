<div style="min-width: 350px;padding: 1rem;">
    <div class="heading">Game Summary</div>
    <div>
        <div class="overview_dtls"><span>Date:</span> {{data.slotDtl.program_date}}</div>
        <div class="overview_dtls"><span>Time:</span> {{getSlotTime(data.slotDtl.timeslots[0].start_time)}} - {{getSlotTime(data.slotDtl.timeslots[data.slotDtl.timeslots.length -1].expiry_time)}}</div>
        <div class="overview_dtls"><span>No of Player:</span> {{data.slotDtl.players}}</div>
        <!-- <div class="overview_dtls"><span>No of teams:</span> {{data.noOfTeam}}</div> -->
        <div class="overview_dtls"><span>Total selected pairs:</span> {{data.noOfOrder}}</div>
    </div>
    <div class="btn-div">
        <button class="btn btn-success" (click)="confirm()">CONFIRM</button>
        <button class="btn btn-light" (click)="close()">GO BACK</button>
    </div>
</div>