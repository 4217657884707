import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-commom-dialog',
  templateUrl: './commom-dialog.component.html',
  styleUrls: ['./commom-dialog.component.css']
})
export class CommomDialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
@Component({
  selector: 'vc-commom-dialog',
  templateUrl: './vc_dialog.html',
  styleUrls: ['./commom-dialog.component.css']
})
export class VCCommomDialogComponent implements OnInit {

  constructor(
    private toster: ToastrService,
    public dialogRef: MatDialogRef<VCCommomDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    
  ) { }

  ngOnInit(): void {
  }
  getSlotTime(time: any) {
    const epochTime = time;
    const epochTimeMilliseconds = epochTime * 1000;
    const date = new Date(epochTimeMilliseconds);
    const localTime = date.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      // second: 'numeric',
      hour12: true,
      // timeZoneName: 'short',
    });
    return localTime
  }
  close(){
    this.dialogRef.close(false)
  }
  confirm(){
    this.dialogRef.close(true)
  }

}



@Component({
  selector: 'wh-commom-dialog',
  templateUrl: './wh_dialog.html',
  styleUrls: ['./commom-dialog.component.css']
})
export class WHCommomDialogComponent implements OnInit {

  constructor(
    private toster: ToastrService,
    public dialogRef: MatDialogRef<VCCommomDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    
  ) { }

  ngOnInit(): void {
  }
  getSlotTime(time: any) {
    const epochTime = time;
    const epochTimeMilliseconds = epochTime * 1000;
    const date = new Date(epochTimeMilliseconds);
    const localTime = date.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      // second: 'numeric',
      hour12: true,
      // timeZoneName: 'short',
    });
    return localTime
  }
  close(){
    this.dialogRef.close(false)
  }
  confirm(){
    this.dialogRef.close(true)
  }
}
