import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataSharingServiceService } from 'src/app/services/data-sharing-service.service';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-configure-questico',
  templateUrl: './configure-questico.component.html',
  styleUrls: ['./configure-questico.component.css']
})
export class ConfigureQuesticoComponent implements OnInit {

  constructor(
    private dataService: DataSharingServiceService,
    private router: Router,
    private toster: ToastrService,
    private httpSv: HttpService
  ) { }

  ngOnInit(): void {
  }
  todayDate:any
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    // var today = new Date().toISOString().split('T')[0];
    this.todayDate = new Date().toLocaleDateString("en-GB").split('T')[0].split('/').reverse().join('-');
    document.getElementById("datePicker")?.setAttribute('min', this.todayDate);
  }
  NewSession:any = 'New Session'
  Digital:any = 'Questico'
  Name:any = 'Name & Date'

  sessionName: string = ''
  sessionDate: string = ''
  finalDateFormated: string = ''
  clientName: string = ''

  dateChangeHadler() {
    let date = this.sessionDate
    let newdate = date.split("-").reverse()
    let dd = newdate[0]
    let mm = this.getMonthName(newdate[1])
    let yy = newdate[2]
    this.finalDateFormated = `${dd} ${mm} ${yy}`
  }
  getMonthName(monthNumber: any) {
    const date = new Date();
    date.setMonth(monthNumber - 1);

    return date.toLocaleString('en-US', { month: 'long' });
  }
  redirectToQuestico(){
    if (this.sessionName == '' || this.sessionDate == '' || this.clientName == '') {
      this.toster.error('All fields are mandantory', '', { timeOut: 2000 })
    }
    else if(Date.parse(this.todayDate)> Date.parse(this.sessionDate)){
      this.toster.error('Game can not be configure for previous dates', '', { timeOut: 2000 })
    }
    else{
      let data = {
        program_name:this.sessionName,
        program_date:this.sessionDate,
        client_name:this.clientName
      }

      this.httpSv.redirectQuestico(data).subscribe((res:any) => {
        if(res['status']){
          window.open(res['result'])
          this.router.navigate(['/scheduled'])
        }
      })
    }
  }

 
}
