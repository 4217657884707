<div style="position: relative;min-width: 300px;">
    <div style="position: absolute;right: 21px;cursor: pointer;display: flex;justify-content: center;align-items: center;gap: 10px;background: #4BE7A1 0% 0% no-repeat padding-box;border-radius: 7px;padding: 5px 10px;font-weight: 500;font-size: 15px;" (click)="genPDF()">
        <span>Download</span>
        <img src="../../../assets/img/download-cloud-svgrepo-com.svg" alt="" style="width: 26px;" >
    </div>


    <div style="font-weight: 500;margin: 10px 10px;text-align: initial;font-size: 20px;">Game Keys</div>
    <div class="code-main-div">
        <div class="code-sub-div">
            <div *ngFor="let item of codes" class="code">
                {{item.answer}}
            </div>
        </div>
    </div>
</div>


<style>
    ::ng-deep .mat-dialog-container{
        padding: 0;
    }
    .code-main-div {
        padding: 10px;
        background-color: #FFFFFF;
        border-radius: 10px;
        height: 86%;
    }

    .code-sub-div {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start;
    }

    .code {
        font-weight: 500;
        color: #057983;
        border: 1px solid #93C1BD;
        background-color: #F4FEFF;
        padding: 5px;
        min-width: 65px;
        text-align: center;
    }
</style>