<div class="vcContainer">
    <div class="vcTable">
        <div class="vcHeader">
            <div class="vcElements header">Players/Statement Pairs</div>
            <div  class="vcElements header">{{data.left_pair.pair_name}}</div>
            <div  class="vcElements header">{{data.right_pair.pair_name}}</div>
        </div>
        <div class="vcBody">
            <div class="vcCells" *ngFor="let item of data.statement_info">
                <div  class="vcElements">{{item.name}}</div>
                <div  class="vcElements">{{item.pair_arm != "RightArm" ? item.position: null}}</div>
                <div  class="vcElements">{{item.pair_arm == "RightArm" ? item.position: null}}</div>
            </div>
        </div>
        <div class="vcFooter">
            <div class="vcCells">
                <div  class="vcElements header">Average</div>
                <div  class="vcElements header">{{Math.floor(data.left_pair.avg)}}</div>
                <div  class="vcElements header">{{Math.floor(data.right_pair.avg)}}</div>
            </div>
            <div class="vcCells">
                <div  class="vcElements header">Count#</div>
                <div  class="vcElements header">{{data.left_pair.count}}</div>
                <div  class="vcElements header">{{data.right_pair.count}}</div>
            </div>
        </div>
    </div>
</div>