<div style="margin-right: 3vw;" class="main_py_assign_container">
    <div class="physical_game_main_div">
        <div class="phsical_heading" style="display: flex;">
            <img src="../../../../assets/img/arrow_back_ios_FILL0_wght400_GRAD0_opsz48.svg " alt=""
                style="width: 22px;cursor: pointer;" (click)="goBack()">
            Physical Games
        </div>
        <div class="physical_body_main_div assign_physical_body_main_div">
            <div class="physical_body_sub_div">
                <div class="assign_heading">Select Games</div>
                <div class="py_game_list_main_div" style="max-width: 45rem;">
                    <div class="grid_2fr">
                        <div *ngFor="let item of gameList" class="py_game_list_div"
                            style="grid-template-columns: repeat(auto-fill, minmax(7rem, 1fr));"
                            [ngClass]="[selectedGamesListId.includes(item.id)? 'checked':'' ,gameAcessArray.includes(item.id)? 'disabled':'']">
                            <img (click)="handleGameSelection(item.id)" *ngIf="item.id == 8"
                                src="../../../../assets/img/matrix.png" />
                            <img (click)="handleGameSelection(item.id)" *ngIf="item.id == 9"
                                src="../../../../assets/img/string_therory.png" />
                            <img (click)="handleGameSelection(item.id)" *ngIf="item.id == 10"
                                src="../../../../assets/img/strom_selter.png" />
                            <img (click)="handleGameSelection(item.id)" *ngIf="item.id == 11"
                                src="../../../../assets/img/assebembly_line.png" />
                            <img (click)="handleGameSelection(item.id)" *ngIf="item.id == 12"
                                src="../../../../assets/img/magic_mats.png" />
                            <img (click)="handleGameSelection(item.id)" *ngIf="item.id == 13"
                                src="../../../../assets/img/stranded.png" />
                            <img (click)="handleGameSelection(item.id)"
                                src="../../../../assets/friction_action_game.png" alt="" *ngIf="item.id == 16">
                            <img (click)="handleGameSelection(item.id)" src="../../../../assets/lean_up_game.png" alt=""
                                *ngIf="item.id == 17">
                            <img (click)="handleGameSelection(item.id)" src="../../../../assets/traficjam_game.png"
                                alt="" *ngIf="item.id == 18">
                            <img (click)="handleGameSelection(item.id)" src="../../../../assets/vc_game_py.svg" alt=""
                                *ngIf="item.id == 19">
                            <!-- <input type="checkbox" name="" id="" (click)="handleGameSelection(item.id)" [checked]="gameAcessArray.includes(item.id)" [disabled]="gameAcessArray.includes(item.id)"> -->
                            <!-- <span>{{item.game_name}}</span> -->
                        </div>
                    </div>
                </div>

            </div>

        </div>
        <div class="white_card">
            <div class="py_expiry_main_div">
                <div class="py_expiry_start_end_div">
                    <div class="py_expiry_element_div">
                        <div>Start Date</div>
                        <input type="date" id="datePicker" name="" class="date_inp dg_inp" [(ngModel)]="startDate"
                            (change)="dateValidityChangeHandler()" style="margin-right: 10px;">
                    </div>
                    <div class="py_expiry_element_div">
                        <div>End Date</div>
                        <div>{{end_date | date:'dd/MM/yyyy'}}</div>

                    </div>
                </div>
                <div class="py_expiry_start_end_div" style="margin-top: 20px;">
                    <div class="py_expiry_element_div">
                        <div>Validity</div>
                        <input type="number" name="" id="" class="date_inp dg_inp" [(ngModel)]="validity"
                            (keydown)="onKeyDown($event)" (input)="dateValidityChangeHandler()">

                    </div>
                    <div class="py_expiry_element_div">
                        <div class="custom-select-wrapper custom-select-container addUserInput">
                            <select class="form-control addUserInput dropdownArrow" [(ngModel)]="selectedTimeline"
                                (change)="dateValidityChangeHandler()">
                                <option value="months" selected>Months</option>
                                <option value="days">Days</option>
                            </select>

                        </div>
                    </div>
                </div>
                <!-- <div class="py_expiry_validity_div">
                    <div class="py_expiry_validity_sub_div">
                        <div>Validity</div>
                      <div class="py_expiry_validity_inp_div" style="display: flex; align-items: center;">
                                <input type="number" name="" id="" [(ngModel)]="validity" (keydown)="onKeyDown($event)"  (input)="dateValidityChangeHandler()" class="dg_inp">
                                <div class="custom-select-wrapper custom-select-container addUserInput"  >
                                    <select class="form-control addUserInput" [(ngModel)]="selectedTimeline" (change)="dateValidityChangeHandler()">
                                      <option value="months" selected>Months</option>
                                      <option value="days" >Days</option>
                                    </select>
                                    <i class="dropdown-icon">
                                        <img src="../../../../assets/downArrow.svg" alt="Dropdown Icon">
                                    </i>
                                </div>  
                            </div>                        
                    </div>  
                    <div>&nbsp;</div>
                </div> -->
            </div>
            <div style="display:flex; justify-content:left; align-items: center; font-size: 14px;margin-top: 2px;" *ngIf="backDateErrorFlag">
                <span style="color:#DB0000">
                    <img src="../../../assets/error_icon.svg" alt="" style="margin-right:5px">
                    End date can't be less than {{minEndDate| date:'dd/MM/yyyy'}}
                </span>
            </div>
            <div style="min-width: 300px;margin-bottom: 2rem;" *ngIf="selectedGamesList.length > 0">
                <div class="physicalGameKits">
                    <div class="grid_2fr_center selected_game_heading">
                        <div style="width: 10rem;">Select Game Kits</div>
                    </div>
                    <div *ngFor="let item of selectedGamesList" class="grid_2fr_center selected_game_list">
                        <div>{{item.game_name}}</div>
                        <div><input type="number" [(ngModel)]="item.game_kit" (keydown)="onKeyDown($event)"></div>
                    </div>
                </div>         
            </div>
            
        </div>
        <div class="assign_new_btn_div">
            <button class="btn_custom" (click)="assignGame()">Assign Games</button>
        </div>
    </div>
</div>