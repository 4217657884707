<div class="alert alert-danger login-ended" role="alert" *ngIf="falg">
    Your trial account has expired. Please reach out to Kairos team at <a href="mailto:connect@playkairos.com">connect@playkairos.com</a>
    <img src="../../assets/img/close-md-svgrepo-com.svg" alt="" srcset="" (click)="falg = false">
</div>
<div class="container">

    <div class="leftLogo">
        <img src="../../assets/kairosLogo.svg">
    </div>
    <div class="rightLogin">
        <form #loginform="ngForm" (ngSubmit)="submit(loginform.value)">
            <div class="rightContainer">
                <img class="kairosName" src="../../assets/kairosName.svg">
                <p class="loginTxt">Login</p>
                <input class="input" placeholder="Email" type="text" name="email" ngModel>
                <input class="input" placeholder="Password" type="password" name="password" ngModel>
                <!-- <p style="align-self: end;margin-right: 11%;font-family: MyriadPro-Regular;">Forgot Password</p> -->
                <input style="width: 23%;" type="image" src="../../assets/loginSignIn.svg">
                <!-- <img style="width: 25%;" type="submit" src="../../assets/loginSignIn.svg"> -->
            </div>
        </form>
    </div>
</div>
