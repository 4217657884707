// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // baseUrl: 'https://stagingapi.playkairos.com/',

  // preprod base url below

  baseUrl:'https://apipreprod.playkairos.com/',


  //local base url
  // baseUrl: 'http://127.0.0.1:8000/',


  nodeUrl: 'https://pretiny.playkairos.com/',

  questicoUrl: 'https://dev.blueskylearning.co/',

  aws: {
    region: 'ap-south-1',
    accessKeyId: 'AKIAT2IRMJOF7BWUXC6R',
    secretAccessKey: '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',
    bucketName: 'kairostestbucket/resources'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
