import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, catchError, retry, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {EMPTY} from 'rxjs';
import { DataSharingServiceService } from '../services/data-sharing-service.service';


@Injectable()
export class KariosInterceptorInterceptor implements HttpInterceptor {

  constructor(private router: Router, private toast:ToastrService,private service:DataSharingServiceService) {
    if(sessionStorage.getItem("role") == "1" || sessionStorage.getItem("role") == "2"){
        this.adminFlag = true
      }
  }
  adminFlag :any = false
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //   if(this.token){
    //   const reqCopy = request.clone({
    //     setHeaders:{
    //       Authorization:"Bearer "+sessionStorage.getItem('token')
    //     }
    //   })
    //   return next.handle(reqCopy);
    //   }
    //   else{
    //     const reqCopy = request.clone({});
    //     this.router.navigate(['/login']);
    //     return next.handle(reqCopy);
    //   }
    // }
    let role = sessionStorage.getItem('role') ;
    let token;
    if(role == '1'){
      token = sessionStorage.getItem('super_token')
      this.adminFlag = true
    }
    else if(role == '2'){
      token = sessionStorage.getItem('content_token')
      this.adminFlag = true
    }
    else{
      token = sessionStorage.getItem('token')
      this.adminFlag = false
    }
    const reqCopy = request.clone({
      setHeaders: {
        Authorization: "Bearer " +token
      }
    })
    return next.handle(reqCopy).pipe(retry(0),
    catchError((error: HttpErrorResponse) => {
      if((error.error.message == 'Unauthenticated Access!' || error.error.message ==  'Your account is suspended. Please contact to admin' )&& error.status === 401){
        if(this.service.toastCount == 0){
          this.toast.error(error.error.message == 'Unauthenticated Access!' ? "Seems like you have logged in from other machine" : "Your account is suspended. Please contact to admin",'',{timeOut:3000})
        }
        this.service.toastCount = 1
        if(this.adminFlag)
          this.router.navigate(['/admin'])
        else
          this.router.navigate(['/login'])
      }
      if(this.service.toastCount == 0)
        return throwError(error);
      else{
        return EMPTY;
      }
     
    }));
  }
}

