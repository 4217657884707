import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
@Injectable({ providedIn: 'root' })
export class LoginService {
    constructor(private http:HttpClient){}

    //Login 
    public login(data:any)
    {
        return this.http.post(environment.baseUrl+'api/login',data);
    }
}