<div>
    <div style="flex: 1;">
        <!-- <div class="step-main-div" *ngIf="steperFlag">
            <div>STEP 1</div>
            <div>STEP 2</div>
            <div class="step-active-css">STEP 3</div>
        </div> -->
        <div class="pp_header_main_div">
            <div>
                <div style="font-weight: 600;font-size: 22px;">Game set-up</div>
                <div>Please review the game details before you finish the setup !</div>
            </div>
            <div class="pp_header_right_sub">
                <div *ngIf="timerFlag">
                    <span>Time left to complete the set-up </span>
                    <span>{{('0'+remainingMinutes).slice(-2)}}:{{('0'+remainingSeconds).slice(-2)}}</span>
                </div>
                <div><img src="../../../assets/profile.png" alt="" *ngIf="!showSideOrder" (click)="toggleFunc()"
                        style="cursor: pointer;"></div>
            </div>
            <div class="header-drowdwon" *ngIf="toggleFlag" (appOutsideClick)="outSideClickHandle($event)">
                <div class="header-drowdwon-content" (click)="goToAccountSetting()">
                    My Profile
                </div>
                <div class="header-drowdwon-content" (click)="goToMyGame()">
                    My Games
                </div>
                <div class="header-drowdwon-content" (click)="goGameReport()">
                    Games Reports
                </div>
                <div class="header-drowdwon-content" (click)="goToTCPage()">
                    Terms and conditions
                </div>
                <div class="header-drowdwon-content" (click)="logout()">
                    Logout
                </div>
            </div>
        </div>

        <div class="pp_main_body_conintaner">
            <div class="pp_header_div">&nbsp;</div>
            <div>
                <div class="pp_sub_body_main_div">
                    <div class="pp_left_body_div">
                        <div class="ele_main_div">
                            <li>Number of players</li>
                            <span><img placement="bottom" [ngbTooltip]="maxplayers" tooltipClass="tooltip"
                                    src="../../../assets/img/tooltip.svg" style="margin-left: 8px;height: 15px;"></span>
                            <span class="pl_10">{{maxPlayerData}}</span>
                        </div>
                        <div class="ele_main_div">
                            <li>Players in a teams

                            </li>
                            <span> <img placement="bottom" [ngbTooltip]="maxplayers" tooltipClass="tooltip"
                                    src="../../../assets/img/tooltip.svg" style="margin-left: 8px;height: 15px;"></span>
                            <span style="display: flex;align-items: flex-start;gap: 10px;">
                                Upto 10
                                <!-- <input type="number" min="1" oninput="javascript: if(this.value == 0) this.value=''"
                                    [(ngModel)]="noOfTeamsData" class="ele_input" (change)="fillNoPlyr()"
                                    (keydown)="validateNumber($event)" [ngClass]="noOfTeamsData > 10?'colorRedCss':''">
                                <span class="err_ele_span" *ngIf="noOfTeamsData > 10">
                                    <img src="../../../assets/error_icon.svg" alt="">
                                    <span>Players in a team can not be more than 10</span>
                                </span>
                                <span class="err_ele_span" *ngIf="perTeamPlyData > 20 && noOfTeamsData < 10">
                                    <img src="../../../assets/error_icon.svg" alt="">
                                    <span>No of team can not be more than 20</span>
                                </span> -->
                            </span>
                        </div>
                        <div class="ele_main_div">
                            <li>No. of teams

                            </li>
                            <span><img placement="bottom" [ngbTooltip]="maxplayers" tooltipClass="tooltip"
                                    src="../../../assets/img/tooltip.svg" style="margin-left: 8px;height: 15px;"></span>
                            <span class="pl_10">{{perTeamPlyData}}</span>
                        </div>
                        <div class="ele_main_div">
                            <li>Number of guides

                            </li>
                            <span> <img placement="bottom" [ngbTooltip]="maxplayers" tooltipClass="tooltip"
                                    src="../../../assets/img/tooltip.svg" style="margin-left: 8px;height: 15px;"></span>
                            <span>
                                <select name="noOfRound" id="" class="form-select custom-select"
                                    style="width: 5rem;height: 31px;padding: 3px 14px;" [(ngModel)]="noOfGuides">
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                </select>
                            </span>
                        </div>
                        <div class="blank_spacing">&nbsp;</div>
                        <div>
                            <div>
                                <span style="font-weight: 600;">Rating Elements</span>
                                <img placement="bottom" [ngbTooltip]="maxplayers" tooltipClass="tooltip"
                                    src="../../../assets/img/tooltip.svg" style="margin-left: 8px;height: 15px;">
                            </div>
                            <div class="pp_rating_element_checkbox_main_div">
                                <div>
                                    <input type="checkbox" name="" id="" [(ngModel)]="selfRating">
                                    <label for=""></label>
                                    <span>Self</span>
                                    <img placement="bottom" [ngbTooltip]="maxplayers" tooltipClass="tooltip"
                                        src="../../../assets/img/tooltip.svg" style="margin-left: 8px;height: 15px;">
                                </div>
                                <div>
                                    <input type="checkbox" name="" id="" [(ngModel)]="facRating">
                                    <label for=""></label>
                                    <span>Facilitator</span>
                                    <img placement="bottom" [ngbTooltip]="maxplayers" tooltipClass="tooltip"
                                        src="../../../assets/img/tooltip.svg" style="margin-left: 8px;height: 15px;">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="pp_right_body_div" cdkDropListGroup>
                        <div class="ele_main_div" style="display: flex;gap: 30px;">
                            <span>Number of rounds</span>
                            <span>&nbsp;</span>
                            <span>
                                <select name="noOfRound" id="" class="form-select custom-select"
                                    style="width: 5rem;height: 31px;padding: 3px 14px;"
                                    (change)="handleNumberOfRound($event)" [(ngModel)]="noOfRound">
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                </select>
                            </span>
                        </div>
                        <div class="pp_task_heading">Task for each round</div>
                        <div class="pp_task_list_main_div">
                            <div class="pp_task_list_file_main_div" cdkDropList>
                                <div class="pp_task_list_file_sub_div" cdkDrag *ngFor="let item of ppAllFilesDtls"
                                    (click)="previewImg(item,item.id == previewItemArr[0].id )"
                                    [ngClass]="item.id == previewItemArr[0].id ? 'preview_activeCss':''">
                                    <div>{{item.name}}</div>
                                </div>
                            </div>
                            <div class="pp_task_img_preview">
                                <div *ngIf="previewItemArr[0].id == null">Preview</div>
                                <img [src]="previewItemArr[0].file_url" alt="" *ngIf="previewItemArr[0].id != null"
                                    style="width: 100%;height: 100%;">
                            </div>
                        </div>
                        <div>
                            <div class="pp_round_dtls_main_div" id="pp_round_card_container">
                                <div class="pp_round_dtls_sub_div" *ngFor="let item of roundTempArray;let i = index">
                                    <div class="pp_round_dtls_card_heading">Round {{i+1}}</div>
                                    <div class="pp_round_dtls_card_time_div">
                                        <div>Round Time (mins)</div>
                                        <input type="number" name="" id=""
                                            oninput="javascript: if (this.value == 0) this.value = null;"
                                            [(ngModel)]="allRoundData[i].time" (keydown)="validateRoundInput($event)">
                                    </div>
                                    <div class="pp_round_dtls_drag_drop_main_div">
                                        <div class="pp_round_dtls_drag_drop_div" cdkDropList
                                            (cdkDropListDropped)="drop($event, i)"
                                            (cdkDropListEntered)="dragEntered($event,i)"
                                            (cdkDropListExited)="dragExited($event, i)">
                                            <div *ngIf="!roundCardEleExist[i] && roundCardData[i]== null">
                                                <span>Drag &
                                                    Drop <br /> from task
                                                    list</span>

                                            </div>
                                            <img [src]="roundCardData[i].file_url" alt=""
                                                *ngIf="roundCardData[i] != null && !roundCardEleExist[i]"
                                                style="width: 100%;height: 100%;">
                                        </div>
                                        <img src="../../../assets/img/delete_icon.svg" alt=""
                                            class="pp_delete_round_icon" (click)="deleteCardFile(i)">
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="pp_round_pagintor_main_div">
                            <div>
                                <img src="../../../assets/triangular-light-left-aarow.png" alt=""
                                    *ngIf="totalCardVisible[0]" style="cursor: default;">
                                <img src="../../../assets/triangular-dark-left-arrow.png" alt=""
                                    *ngIf="!totalCardVisible[0]" (click)="roundCardPrevious()">
                            </div>
                            <div class="pp_circle_paginator_div" [ngClass]="totalCardVisible[i] ? 'cardActive' :''"
                                *ngFor="let item of roundTempArray;let i = index"
                                (click)="roundCardCircle(totalCardVisible[i],i)">&nbsp;</div>
                            <div>
                                <img src="../../../assets/triangular-light-right-arow.png" alt=""
                                    *ngIf="totalCardVisible[roundTempArray.length-1]" style="cursor: default;">
                                <img src="../../../assets/triangular-dark-right-arrow.png" alt=""
                                    *ngIf="!totalCardVisible[roundTempArray.length - 1]" (click)="roundCardNext()">
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
        <div class="last-btn-div" style="text-align: center; margin-top: 20px;">
            <input style="width: 27%; max-width: 200px" type="image" src="../../../assets/img/save&conti-btn.svg"
                (click)="savePicturePerfectConfig()">
        </div>



        <!-- <div class="main-container">
            <div style="display: flex;justify-content: center;flex-direction: column;align-items: center;">
                <div class="team-config-main-div">
                    <div class="element-input-div">
                        <div>
                            <img placement="bottom" [ngbTooltip]="maxplayers" tooltipClass="tooltip"
                                src="../../../assets/img/tooltip.svg">
                            <li>Maximum Players</li>
                        </div>
                        <input type="number" [(ngModel)]="maxPlayerData" min="1"
                            oninput="javascript: if(this.value == 0) this.value=''" [disabled]="disabledFlag"
                            [ngClass]="disabledFlag ? 'disabledInput':''">
                    </div>
                    <div class="element-input-div">
                        <div>
                            <img placement="bottom" [ngbTooltip]="noteams" tooltipClass="tooltip"
                                src="../../../assets/img/tooltip.svg">
                            <li>Number of Teams</li>
                        </div>
                        <input type="number" [(ngModel)]="noOfTeamsData" min="1"
                            oninput="javascript: if(this.value == 0) this.value=''" [disabled]="disabledFlag"
                            [ngClass]="disabledFlag ? 'disabledInput':''">
                    </div>
                    <div class="element-input-div">
                        <div>
                            <img placement="bottom" [ngbTooltip]="sessionduration" tooltipClass="tooltip"
                                src="../../../assets/img/tooltip.svg">
                            <li>Session Duration (mins)</li>
                        </div>
                        <input type="number" [(ngModel)]="sessionTimeData" min="1"
                            oninput="javascript: if(this.value == 0) this.value=''">
                    </div>
                </div>
                <div>
                    <div class="last-btn-div" style="text-align: center; margin-top: 10px;">
                        <input style="width: 65%; max-width: 200px;" type="image" src="../../../assets/img/save&conti-btn.svg"
                            (click)="savePicturePerfectConfig()">
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</div>