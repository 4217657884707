<div>
    <div class="heading"><span>Total session time left </span><span style="font-weight: 600;">{{hours}} hours {{mins}} mins</span></div>
    <div class="loader_img_div"><img src="../../../assets/img/loading.gif" alt="" srcset=""></div>
    <div class="redirec_text">Redirecting to session within <span style="font-weight: 500;">{{count}}s</span> </div>
</div>


<style>
    .heading {
        text-align: center;
        margin: 1rem;
        font-size: 19px;
        font-weight: 500;
    }

    .loader_img_div {
        text-align: center;
    }

    .loader_img_div img {
        width: 40px;
    }
    .redirec_text{
        text-align: center;
        margin-bottom: 1rem;
        font-style: italic;

    }
</style>