<app-header [oneHeader]=header [adminFlag]="true"></app-header>
<div style="min-height: 90vh;width: 100%; background-color:#F8F8F8; display: flex;justify-content: center;">
    <div class="sessionRecord">
        <div class="sessionRecordTable" *ngIf="sessionRecords.length != 0">
            <div style="    display: flex;justify-content: space-between;align-items: center;margin-top: 5px;">
                <img (click)="navigateBack()" src="../../../../assets/img/arrow_back_ios_FILL0_wght400_GRAD0_opsz48.svg"
                    style="width: 36px;cursor: pointer;" />
                <div class="track_game_alert_info_main_div">
                    <div class="track_game_alert_info_sub_div">
                        <div style="height: 10px;width: 10px;" class="mid_alert">&nbsp;</div>
                        <div>40 players or more</div>
                    </div>
                    <div class="track_game_alert_info_sub_div">
                        <div style="height: 10px;width: 10px;" class="high_alert">&nbsp;</div>
                        <div>80 players or more</div>
                    </div>
                </div>
            </div>
            <table style="width: 98vw;border-radius: 20px 20px 0 0; position: relative; margin-top: 10px;" mat-table
                [dataSource]="dataSource" class="mat-elevation-z8">

                <!-- SL No Column -->
                <ng-container matColumnDef="sl_no" style="width:20%;">
                    <th mat-header-cell *matHeaderCellDef class="center_align track_game_table_th"> Sl No. </th>
                    <td mat-cell *matCellDef="let element;let i =index;" class="center_align"
                        [ngClass]="element.players >= high_alert_vlaue ? 'high_alert':element.players >= mid_alert_vlaue ?'mid_alert' :''">
                        {{i+1}} </td>
                </ng-container>

                <!-- User Name Column -->
                <ng-container matColumnDef="user_name" style="width:20%;">
                    <th mat-header-cell *matHeaderCellDef class="center_align track_game_table_th"> User Name </th>
                    <td mat-cell *matCellDef="let element" class="center_align"
                        [ngClass]="element.players >= high_alert_vlaue ? 'high_alert':element.players >= mid_alert_vlaue ?'mid_alert' :''">
                        {{element.name}} </td>
                </ng-container>

                <!-- Email Column -->
                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef class="center_align track_game_table_th"> Email </th>
                    <td mat-cell *matCellDef="let element" class="center_align"
                        [ngClass]="element.players >= high_alert_vlaue ? 'high_alert':element.players >= mid_alert_vlaue ?'mid_alert' :''">
                        {{element.email}}</td>
                </ng-container>

                <!-- Progaram Name Column -->
                <ng-container matColumnDef="program_name">
                    <th mat-header-cell *matHeaderCellDef class="center_align track_game_table_th">Program Name </th>
                    <td mat-cell *matCellDef="let element" class="center_align"
                        [ngClass]="element.players >= high_alert_vlaue ? 'high_alert':element.players >= mid_alert_vlaue ?'mid_alert' :''">
                        {{element.program_name}} </td>
                </ng-container>

                <!-- Client Name Column -->
                <ng-container matColumnDef="client_name">
                    <th mat-header-cell *matHeaderCellDef class="center_align track_game_table_th">Client Name </th>
                    <td mat-cell *matCellDef="let element" class="center_align"
                        [ngClass]="element.players >= high_alert_vlaue ? 'high_alert':element.players >= mid_alert_vlaue ?'mid_alert' :''">
                        {{element.client_name}} </td>
                </ng-container>

                <!-- Date and Time Column -->
                <ng-container matColumnDef="date_time">
                    <th mat-header-cell *matHeaderCellDef class="center_align track_game_table_th">Date & Time</th>
                    <td mat-cell *matCellDef="let element" class="center_align"
                        [ngClass]="element.players >= high_alert_vlaue ? 'high_alert':element.players >= mid_alert_vlaue ?'mid_alert' :''"
                        [innerHTML]="getDateTimeByEpoch(element.start_datetime, element.expiry_datetime)"
                        style="padding:10px 0;"></td>
                </ng-container>

                <!-- Players Column -->
                <ng-container matColumnDef="players">
                    <th mat-header-cell *matHeaderCellDef class="center_align track_game_table_th"> Players </th>
                    <td mat-cell *matCellDef="let element" class="center_align"
                        [ngClass]="element.players >= high_alert_vlaue ? 'high_alert':element.players >= mid_alert_vlaue ?'mid_alert' :''">
                        {{element.players}}
                    </td>
                </ng-container>

                <!-- Game Satus Column -->
                <ng-container matColumnDef="game_status">
                    <th mat-header-cell *matHeaderCellDef class="center_align track_game_table_th"> Game Status </th>
                    <td mat-cell *matCellDef="let element" class="center_align"
                        [ngClass]="element.players >= high_alert_vlaue ? 'high_alert':element.players >= mid_alert_vlaue ?'mid_alert' :''">
                        {{element.status}}
                    </td>
                </ng-container>

                <!-- Platform Column -->
                <ng-container matColumnDef="platform">
                    <th mat-header-cell *matHeaderCellDef class="center_align track_game_table_th"> Platform </th>
                    <td mat-cell *matCellDef="let element" class="center_align"
                        [ngClass]="element.players >= high_alert_vlaue ? 'high_alert':element.players >= mid_alert_vlaue ?'mid_alert' :''">
                        {{element.platform}} </td>
                </ng-container>

                <!-- Game Plan Column -->
                <ng-container matColumnDef="game_plan">
                    <th mat-header-cell *matHeaderCellDef class="center_align track_game_table_th"> Game Plan </th>
                    <td mat-cell *matCellDef="let element" class="center_align"
                        [ngClass]="element.players >= high_alert_vlaue ? 'high_alert':element.players >= mid_alert_vlaue ?'mid_alert' :''">
                        <div *ngFor="let game of element.config_games;let game_index = index;"
                            class="track_game_plan_main_div">
                            <div>
                                <span>{{game_index+1}}.</span>
                                <span>&nbsp;</span>
                                <span>{{game.game_name}}</span>
                            </div>
                        </div>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator #paginatorPageSize [pageSizeOptions]="pageSizes" showFirstLastButtons></mat-paginator>


        </div>
        <div *ngIf="sessionRecords.length == 0" style="margin-top: 100px;">
            No Session Configured
        </div>
    </div>
</div>