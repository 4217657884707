
<div style="margin-right: 3vw;" class="main_py_assign_container">
    <div class="physical_game_main_div">
        <div class="phsical_heading" style="display: flex;">
            <img src="../../../../assets/img/arrow_back_ios_FILL0_wght400_GRAD0_opsz48.svg " alt="" style="width: 22px;cursor: pointer;" (click)="goBack()">
            Digital Games
        </div>
        <div class="digital_game_info_div" *ngIf="addOrUpdate==1">
            <div>
                <div class="assign_heading">No. of Licenses</div>
                <div style="margin: 10px 0;">Left : {{licence_left}}</div>
                <div>Purchased : {{licence_total}}</div>
            </div>
            <div>
                <div class="assign_heading">Validity of Licenses</div>
                <div style="margin: 10px 0;">Valid till : {{user_digital_subcription[0].end_date*1000 | date:'dd/MM/yyyy'}}</div>
                <div>Purchased on : {{user_digital_subcription[0].start_date*1000 | date:'dd/MM/yyyy'}}</div>
            </div>
        </div>
        <div class="py_expiry_main_div">
            <div class="phsical_heading" style="margin-top: 3rem;"  *ngIf="addOrUpdate == 1">
                New Licenses
            </div>
            <div class="py_expiry_validity_div">
                <div class="py_expiry_validity_sub_div">
                    <div>No. of Licenses</div>
                    <div class="py_expiry_validity_inp_div">
                        <input type="number" name="" id="" [(ngModel)]="noOfLicenses" (keydown)="onKeyDown($event)"  (input)="dateValidityChangeHandler()" class="dg_inp">
                        <!-- <span>Months</span> -->
                    </div>
                </div>  
                <div>&nbsp;</div>
            </div>
            <div class="py_expiry_start_end_div" style="margin-top: 1rem;">
                <div class="py_expiry_element_div">
                    <div>Start Date </div>
                    <input type="date"  id="datePicker" name=""  class="date_inp dg_inp" [(ngModel)]="startDate"  (change)="dateValidityChangeHandler()"  style="margin-right: 10px;">
                </div>
                <div class="py_expiry_element_div" style="width: 12rem;">
                    <div>End Date</div>
                    <div>{{end_date | date:'dd-MM-yyyy'}}</div>
                </div>
            </div>
            <div class="py_expiry_start_end_div" style="margin-top: 1rem;">
                <div class="py_expiry_element_div">
                    <div>Validity</div>
                    <input type="number" name="" id="" class="date_inp dg_inp" [(ngModel)]="validity" (keydown)="onKeyDown($event)"  (input)="dateValidityChangeHandler()">
                </div>
                <div class="py_expiry_element_div" style="width: 12rem;">
                    <div class="custom-select-wrapper custom-select-container addUserInput"  >
                        <select class="form-control addUserInput dropdownArrow" [(ngModel)]="selectedTimeline" (change)="dateValidityChangeHandler()">
                          <option value="months" selected>Months</option>
                          <option value="days" >Days</option>
                        </select>
                        <!-- <i class="dropdown-icon">
                            <img src="../../../../assets/downArrow.svg" alt="Dropdown Icon">
                        </i> -->
                    </div> 
                </div>
            </div>
            <!-- <div class="py_expiry_validity_div" style="grid-template-columns:1fr">
                <div class="py_expiry_validity_sub_div" >
                    <div>Validity</div>
                    <div class="py_expiry_validity_inp_div" style="display: flex; align-items: center;width: 100%;">
                        <input type="number" name="" id="" [(ngModel)]="validity" (keydown)="onKeyDown($event)"  (input)="dateValidityChangeHandler()" class="dg_inp">
                        <div class="custom-select-wrapper custom-select-container addUserInput"  >
                            <select class="form-control addUserInput" [(ngModel)]="selectedTimeline" (change)="dateValidityChangeHandler()">
                              <option value="months" selected>Months</option>
                              <option value="days" >Days</option>
                            </select>
                            <i class="dropdown-icon">
                                <img src="../../../../assets/downArrow.svg" alt="Dropdown Icon">
                            </i>
                        </div>    
                    </div>
                </div>  
                         </div> -->
        </div>
        <!-- <div class="physical_body_main_div assign_physical_body_main_div" style="width: 100%;align-items: flex-start;">
            <div class="physical_body_sub_div" style="display: flex;gap: 15px;">
                <div class="assign_heading">Select Games</div>
                <div class="py_game_list_main_div" style="background: #FFFFFF;margin-top: 10px;">
                    <div class="grid_2fr" style="grid-template-columns: 1fr;">
                        <div *ngFor="let item of gameList" class="py_game_list_div">
                            <input type="checkbox" name="" id=""  checked="true" disabled ="true">
                            <span>{{item.game_name}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="assign_new_btn_div" style="margin-top: 5rem;">
            <button class="btn_custom" *ngIf="addOrUpdate == 0" (click)="assignGame()">Add License</button>
            <div class="assign_new_btn_div" style="margin: 3rem 0;" *ngIf="addOrUpdate == 1"> 
                <button class="assign_new_btn" (click)="assignGame()">Update Licenses</button>
            </div>      
      </div>
    </div>
</div>