import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-game-management',
  templateUrl: './game-management.component.html',
  styleUrls: ['./game-management.component.css']
})
export class GameManagementComponent implements OnInit {
  @Output() backEmitter = new EventEmitter<any>();
  selectedExplore: any = 'digital'
  constructor(
    private httpSv: HttpService,
    private toster: ToastrService
  ) {
  }

  digitalGames:any = [];
  physicalGames:any = [];
  ngOnInit(): void {
    this.getAllListedGame()
  }
  selectedExploreGame(data: any) {
    this.selectedExplore = data;
  }
  goBack(){
    this.backEmitter.emit('home')
  }
  allGameList:any[]=[]
  getAllListedGame() {
    this.httpSv.getAllGameList().subscribe((res: any) => {
      if (res['status']) {
        this.allGameList = res['results']
        this.digitalGames = this.allGameList.filter((ele:any) => ele.game_type == 'digital')
        this.physicalGames = this.allGameList.filter((ele:any) => ele.game_type == 'physical')
      } else {
        this.toster.error('Oops!! Something went wrong', '', { timeOut: 2000 })
      }
    }, (err: any) => {
      this.toster.error('Oops!! Something went wrong', '', { timeOut: 2000 })
    })
  }
}
