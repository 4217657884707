<div style="height: 100vh; display: flex;flex-direction: column; position: relative;">
    <app-header [oneHeader]="'Quick Setup'" [adminFlag]="true"></app-header>
    <div style="flex:1;height: 100%;" class="q_s_main_container">
        <div class="q_s_left_container">
            <div class="q_s_sub_heading">Quick Setup</div>
            <div class="q_s_game_list_div" *ngFor="let item of allDigitalGame">
                <img [src]="item.src" alt="">
                <div>{{item.name}}</div>
                <button class="q_s_setip_btn" (click)="showCalenderFunc(item)">Set up</button>
                <div class="row" class="q_s_calender_main_div" *ngIf="item.showCalender"
                    (appOutsideClick)="calenderOutSideClickHandle($event,item)">
                    <div class="q_s_datetime_heading">Select Date</div>
                    <div class="col-6">
                        <ngb-datepicker #d1 [(ngModel)]="model1" #c1="ngModel" [minDate]="todaysDate"
                            (dateSelect)="onDateSelect($event,item)"></ngb-datepicker>
                    </div>
                    <div style="text-align: center;margin-top: 5px;"><img src="../../../../assets/time-svgrepo-com.svg"
                            alt="" style="width: 1.5rem;cursor: pointer;" (click)="onDateSelect('',item)"></div>
                </div>
                <div class="q_s_calender_main_div" *ngIf="!item.showCalender && item.showTimepicker"
                    (appOutsideClick)="timeOutSideClickHandle($event,item)">
                    <div class="q_s_datetime_heading">Game Start Time <br /> (24-hours)</div>
                    <ngb-timepicker [(ngModel)]="item.time"></ngb-timepicker>
                    <div style="text-align: center;"><button class="q_s_setip_btn" (click)="configure(item.id,item)"
                            style="width: 100%;">Configure</button></div>
                </div>
            </div>
        </div>


        <div class="q_s_right_container">
            <div class="q_s_sub_heading">Game List</div>
            <div class="q_s_configured_game_list_main_div" *ngIf="allShedualedGames.length > 0">
                <div *ngFor="let item of allShedualedGames">
                    <div class="q_s_configured_game_list_ele_div">
                        <div class="q_s_configured_game_list_icon_div">
                            <img src="../../../../assets/img/value_continum.svg" alt="" *ngIf="item.program_games[0].game_id == 1">
                            <img src="../../../../assets/img/bottleneck.svg" alt="" *ngIf="item.program_games[0].game_id == 2">
                            <img src="../../../../assets/img/warehouse.svg" alt="" *ngIf="item.program_games[0].game_id == 3">
                            <img src="../../../../assets/img/picturePerfect.svg" alt="" *ngIf="item.program_games[0].game_id == 4">
                            <img src="../../../../assets/img/animal_faram.svg" alt="" *ngIf="item.program_games[0].game_id == 5">
                            <img src="../../../../assets/img/bigPicture.svg" alt="" *ngIf="item.program_games[0].game_id == 6">
                        </div>
                        <div>
                            {{item.program_name}} | {{item.client_name}}
                        </div>
                        <div>
                            <span>{{convertEpochTime(item.slots.start_time)}}</span> 
                            <br /> 
                            <span>{{convertEpochTime(item.slots.end_time)}}</span> 
                        </div>
                        <div class="q_s_configured_game_list_btn_div">
                            <button (click)="copyToClipboard(item.participants_url)">Player Link</button>
                            <button (click)="copyToClipboard(item.facilitator_url)">GCR Link</button>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="allShedualedGames.length == 0" class="q_s_configured_game_list_main_div">
                <div class=q_s_no_game>No game found</div>
            </div>

        </div>
    </div>













</div>