<div class="licenses_return_main_div">
    <div class="licenses_return_heading">Session deleted succesfully</div>
    <div class="licenses_return_sub_heading">Since the session is deleted at {{deletedTime}} here is the update on the licenses
    </div>
    <div class="licenses_return_content_main_div">
        <div class="licenses_return_content_main_warraper">
            <div class="licenses_return_content_sub_div">
                <div class="licenses_return_content_heading">LICENSES USED UP</div>
                <div class="licenses_return_content_value">: {{(+configLicense) - (+returnedLicense)}}</div>
            </div>
            <div class="licenses_return_content_sub_div">
                <div class="licenses_return_content_heading">Licenses CREDITED BACK</div>
                <div class="licenses_return_content_value">: {{returnedLicense}}</div>
            </div>
            <div class="licenses_return_content_sub_div">
                <div class="licenses_return_content_heading">LICENSES LEFT</div>
                <div class="licenses_return_content_value">: {{availabelLicese}}</div>
            </div>
        </div>
    </div>
    <div class="licenses_return_btn_main_div">
        <button (click)="close()">OK</button>
    </div>
</div>


<style>
    .mat-dialog-container {
        border-radius: 8px !important;
        max-width: 800px !important;
        padding: 0;
        min-width: 502px;
    }

    .licenses_return_main_div {
        padding: 15px;
        padding-top: 25px;
    }

    .licenses_return_heading {
        font-size: 20px;
        font-weight: 500;
        color: #1A1A1A;
        text-align: center;
    }

    .licenses_return_sub_heading {
        font-size: 15px;
        font-weight: 500;
        color: #373737;
        margin-top: 2rem;
        padding-left: 1.5rem;
        width: 80%;
    }

    .licenses_return_content_main_div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 1rem;
        padding-left: 1.5rem;
    }

    .licenses_return_content_main_warraper {
        display: flex;
        flex-direction: column;
        gap: 18px;
    }

    .licenses_return_content_main_div .licenses_return_content_sub_div {
        display: flex;
        color: #5B5B5B;
    }

    .licenses_return_content_sub_div .licenses_return_content_heading {
        width: 224px;
        font-size: 15px;
        font-weight: 400;
    }

    .licenses_return_content_sub_div .licenses_return_content_value {
        font-size: 15px;
        font-weight: 700;
    }

    .licenses_return_btn_main_div {
        text-align: center;
        margin-top: 2.3rem;
        margin-bottom: 2rem;
    }

    .licenses_return_btn_main_div button {
        width: 227px;
        height: 32px;
        background-color: #33DE81;
        border: none;
        border-radius: 50px;
        font-size: 15px;
        color: #000000;
        font-weight: 500;
    }
</style>