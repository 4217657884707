<div style="text-align: center;">
    <div>
        <img src="../../../../assets/sucess.svg" alt="" style="width: 50px;">
    </div>
    <div class="publish_d_txt">Your game content is successfully Published.</div>
    <div class="publish_d_btn_div">
        <button (click)="goToPublishPreview()">View it as a facilitator</button>
        <button (click)="goToHomePage()">Go back to dashboard</button>
    </div>
</div>

<style>
    .publish_d_txt {
        font-family: 'Roboto';
        font-size: 19px;
        margin: 1rem 0;
    }

    .publish_d_btn_div {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 15px;
    }

    .publish_d_btn_div button {
        outline: none;
        border: none;
        background: none;
        font-family: 'Roboto';
        font-weight: 600;
        border-radius: 12px;
        padding: 6px 16px;
    }

    .publish_d_btn_div button:first-of-type {
        background: #FCBC2B;
    }
    .publish_d_btn_div button:last-of-type {
        background: #DDDDDD;
    }
</style>