<div class="low_license_main_div">
    <div class="low_license_content_div">
        You have {{currentLicense}} Licenses left. You cannot set up any session. Please reach out to us for support.
    </div>
    <div class="low_license_btn_div">
        <button type="button" class="btn btn-success" (click)="navigateToMyGames()">MY GAMES</button>
        <button type="button" class="btn btn-danger" (click)="close()">CLOSE</button>
    </div>
</div>



<style>
    ::ng-deep .mat-dialog-container {
        padding: 0;
        border-radius: 14px;
    }

    .low_license_main_div {
        padding: 24px;
        border: 2px solid #7b7b7b;
        border-radius: 13px;
        width: 28vw;
    }

    .low_license_content_div {
        font-size: 17px;
        text-align: justify;
        font-weight: 500;
        margin-bottom: 15px;
    }

    .low_license_btn_div {
        display: flex;
        justify-content: center;
        gap: 50px;
    }
</style>