import { Router } from '@angular/router';
import { Component, Inject, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/Shared/Services/Dashboard.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DeleteSessionConfirmDialog } from '../configure/configure.component';

@Component({
  selector: 'app-archive-dashboard',
  templateUrl: './archive-dashboard.component.html',
  styleUrls: ['./archive-dashboard.component.css']
})
export class ArchiveDashboardComponent implements OnInit {

  constructor(
    private dashboardService: DashboardService,
    private router: Router,
    private dialogue: MatDialog
  ) { }

  ngOnInit(): void {
    if (this.dashboardService.selectedArchiveSession != null) {
      const data = this.dashboardService.selectedArchiveSession

      this.sessionName = data.program_name
      if (data.isdeleted == 1) {
        this.isDeleted = true
      }
      else {
        this.isIncomplete = data.is_incompleted ? true : false
      }
      this.clientName = data.client_name
      this.sessionDate = data.program_date
      this.maxPlyr = data.time_slots.players
      this.timeSlotData = `${new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }).format(new Date(data.time_slots.first_start_time * 1000))} - ${new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }).format(new Date(data.time_slots.last_expiry_time * 1000))}`
      this.gamesConfig = data.program_games
      this.program_id = data.id
      this.programStartTime = this.formateEpoch(data.start_time)
      this.programEndTime = this.formateEpoch(data.expiry_time)
      switch (this.gamesConfig[0].game_id) {
        case 1:
          this.selectedGame = 1
          this.getVCDtls()
          this.headerMsg = 'Game Report: VALUE CONTINUUM'
          break;
        case 2:
          this.selectedGame = 2
          this.getBNDtls()
          this.headerMsg = 'Game Report: BOTTLENECK'
          break
        case 3:
          this.selectedGame = 3
          this.getWHDtls()
          this.headerMsg = 'Game Report: WAREHOUSE'
          break
        case 4:
          this.selectedGame = 4
          // PENDING API CALL
          this.headerMsg = 'Game Report: PICTURE PERFECT'
          break
        case 5:
          this.selectedGame = 5
          this.getAFDtls()
          this.headerMsg = 'Game Report: ANIMAL FARM'
          break
        case 6:
          this.selectedGame = 6
          // PENDING API CALL
          this.headerMsg = 'Game Report: BIG PICTURE'
          break
        case 14:
          this.selectedGame = 14
          // PENDING API CALL
          this.headerMsg = 'Game Report: THE GREAT OCEAN RACE'
          break
        case 15:
          this.selectedGame = 15
          // PENDING API CALL
          this.headerMsg = 'Game Report: PEOPLE OF THE WORLD'
          break
      }
    }
    else {
      this.router.navigate(['/scheduled'])
    }
    this.dashboardService.archiveFlag = true
  }
  programStartTime: any
  isIncomplete: boolean = false
  isDeleted: boolean = false
  programEndTime: any
  Math = Math
  headerMsg: string = "Game Report"
  sessionName: any
  clientName: any
  sessionDate: any
  maxPlyr: any
  timeSlotData: any
  gamesConfig: any = []
  program_id: any
  vcPlayedFlag: boolean = true
  whPlayedFlag: boolean = true
  afPlayedFlag: boolean = true
  bnPlayedFlag: boolean = true
  selectedGame: any = 0

  vcArchiveData: any = { wh_info: {}, leaderboard: {} }
  getVCDtls() {
    this.dashboardService.getArchiveVCDtls(this.program_id).subscribe((res: any) => {
      if (res['status']) {
        this.vcArchiveData = res['result']
      }
      else {
        if (!this.isDeleted && !this.isIncomplete)
          this.vcPlayedFlag = false
      }
    })
  }
  whArchiveData: any
  whBestTime: any;
  getWHDtls() {
    this.dashboardService.getArchiveWHDtls(this.program_id).subscribe((res: any) => {
      if (res['status']) {
        this.whArchiveData = res['result']
      }
      else {
        if (!this.isDeleted && !this.isIncomplete)
          this.whPlayedFlag = false
      }
    })
  }
  afArchiveData: any;
  totalRounds: any = [];
  RoundOrder: any;
  getAFDtls() {
    this.dashboardService.getArchiveAFDtls(this.program_id).subscribe((res: any) => {
      if (res['status']) {
        this.afArchiveData = res['result']
        this.totalRounds = []
        this.RoundOrder = this.afArchiveData.af_info?.round_info
        this.afArchiveData.leaderboard[0]?.TeamA.map((res: any) => {
          this.totalRounds.push(1)
        })
      }
      else {
        if (!this.isDeleted && !this.isIncomplete)
          this.afPlayedFlag = false
      }
    })
  }
  bnArchiveData: any;
  bnRound: any = 1
  getBNDtls() {
    this.dashboardService.getArchiveBNDtls(this.program_id, this.bnRound).subscribe((res: any) => {
      if (res['status']) {
        this.bnArchiveData = res['result']
      }
      else {
        if (!this.isDeleted && !this.isIncomplete)
          this.bnPlayedFlag = false
      }
    })
  }
  changeRound(val: any) {
    if (val) {
      if (this.bnRound < 3) {
        this.bnRound += 1
        this.getBNDtls()
      }
    }
    else {
      if (this.bnRound > 1) {
        this.bnRound -= 1
        this.getBNDtls()
      }
    }
  }
  formateEpoch(epoch: any) {
    if (epoch)
      return new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }).format(new Date(epoch * 1000))
    else return 'N/A'
  }
  switchGame(id: any) {
    if (this.selectedGame == id) return
    else {
      this.selectedGame = id
      switch (this.selectedGame) {
        case 1:
          this.getVCDtls()
          break;
        case 2:
          this.getBNDtls()
          break;
        case 3:
          this.getWHDtls()
          break
        case 5:
          this.getAFDtls();
          break
      }
    }

  }
  getSecToMin(sec: any) {
    if (sec != 'N/A') {
      let min = (Math.floor(sec / 60)).toString()
      let s = (`0` + sec % 60).slice(-2)
      return `${min}:${s}`
    } else {
      return 'N/A'
    }
  }
  getGameDuration(sec: any) {
    if (sec) {
      let min = (Math.floor(sec / 60)).toString()
      min = min.length > 1 ? min : `0` + min
      let s = (`0` + sec % 60).slice(-2)
      return `${min}`
    } else {
      return '00'
    }
  }
  openVCDialogue(item: any) {
    this.dialogue.open(DialogueVCInfo, {
      data: item,
      hasBackdrop: true,
      disableClose: false,
    })
  }
  routeLandingPage() {
    this.router.navigate(['/dashboard'])
  }
}







@Component({
  selector: 'app-dialogue-vcinfo-dashboard',
  templateUrl: './dialogue.vc-info.component.html',
  styleUrls: ['./archive-dashboard.component.css']
})
export class DialogueVCInfo implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DialogueVCInfo>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
  }
  Math = Math
  ngOnInit(): void {

  }
}