import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from 'src/app/services/http.service';
import { Location } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-account-setting',
  templateUrl: './account-setting.component.html',
  styleUrls: ['./account-setting.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AccountSettingComponent implements OnInit {

  constructor(
    private toster: ToastrService,
    private httpSv: HttpService,
    private router: Router,
    private location: Location,
    private dialog: MatDialog,
  ) { }
  headerTxt:string = 'My Profile'
  adminFlag:boolean = false
  ngOnInit(): void {
    if(sessionStorage.getItem('role')=='1' || sessionStorage.getItem('role')=='2') this.adminFlag = true
    if (sessionStorage.getItem('ids')) {
      this.id = sessionStorage.getItem('ids')
    } else {
      this.toster.error('Opps!! Something went wrong', '', { timeOut: 2000 })
      if(this.adminFlag) this.router.navigate(['/admin'])
      else this.router.navigate(['/login'])
    }
    this.getUserData()
  }
  id: any
  disabledFlag: boolean = true
  name: any = null
  last_name: any = null
  emailId: any = null
  contact: any = null
  organiztion: any = null
  userName: any = null
  editHandler() {
    this.headerTxt = 'My Profile - EDIT'
    this.disabledFlag = false
  }
  back() {
    if (!this.disabledFlag) {
      this.ngOnInit()
      this.disabledFlag = true
      this.headerTxt = 'My Profile'
    }
    else {
      this.location.back()
    }
  }

  getUserData() {
    this.httpSv.userDetails(this.id).subscribe((res: any) => {
      if (res['status']) {
        const temp = res['result'][0]
        this.name = temp.first_name
        this.last_name = temp.last_name
        this.emailId = temp.email
        this.contact = temp.contact
        this.organiztion = temp.organisation
        this.userName = temp.email
      }
    })
  }
  updateUserData() {
    if (this.name.length > 0) {
      const data: any = {
        first_name: this.name,
        user_type: 3
      }
      if (this.contact != null) data['contact'] = this.contact
      if (this.last_name != null) {
        data['last_name'] = this.last_name
        if (this.organiztion != null) {
          data['organisation'] = this.organiztion
          this.httpSv.updateUserDetails(this.id, data).subscribe((res: any) => {
            if (res['status']) {
              this.headerTxt = 'My Profile'
              this.ngOnInit()
              this.disabledFlag = true
            }
          }, (err: any) => {
            this.toster.error(err.error.message, '', { timeOut: 2000 })
          })
        } else {
          this.toster.error('Please fill the organization name', '', { timeOut: 2000 })
        }
      }
      else {
        this.toster.error('Please fill the last name', '', { timeOut: 2000 })
      }
    }
    else {
      this.toster.error('Please fill the first name', '', { timeOut: 2000 })
    }
  }
  handlePasswordChange() {
    // const role: any = sessionStorage.getItem('role')
    // if (role == 1) {
    const dialogRef = this.dialog.open(ChangePasswordComponent, {
      panelClass: 'change_password',
      hasBackdrop: true,
      // minWidth: '300px',
    })
  }
  //   else {
  //     this.toster.info('Please contact connect@playkairos.com to change the password', '', { timeOut: 3000 })
  //   }
  // }
}




@Component({
  selector: 'app-password-change',
  templateUrl: './change-password-dialog.html',
})
export class ChangePasswordComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ChangePasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toster: ToastrService,
    private httpSv: HttpService,
    private router: Router
  ) { }
  ngOnInit(): void {
    setTimeout(()=>{
      this.currentPass = ''
    },100)
  }
  currentPass: any = ''
  newPass: any = ''
  confirmPass: any = ''
  passwordFlag: boolean = false
  close() {
    this.dialogRef.close()
  }
  allBlankFlag: boolean = false
  passwordLenFlag: boolean = false
  apiErrFlag: boolean = false
  errTxt: string = ''
  updatePassword() {
    if (this.currentPass != '' && this.newPass != '' && this.confirmPass != '') {
      if (this.newPass.length > 5) {
        if (this.newPass == this.confirmPass) {
          const data = {
            old_password: this.currentPass,
            new_password: this.newPass,
            new_password_confirmation: this.confirmPass
          }
          this.httpSv.changePassword(data, sessionStorage.getItem('ids')).subscribe((res: any) => {
            if (res['success']) {
              this.httpSv.logOut().subscribe((res: any) => {
                this.close()
                if(sessionStorage.getItem('role') == '1' || sessionStorage.getItem('role') == '2') this.router.navigate(['/admin'])
                else
                this.router.navigate(['/login'])
              })
            }
          }, (err: any) => {
            console.log(err.error)
            this.currentPass = ''
            this.newPass = ''
            this.confirmPass = ''
            this.apiErrFlag = true
            this.errTxt = err.error.message
            setTimeout(() => { this.apiErrFlag = false; this.errTxt = '' }, 2000)
          })
        } else {
          this.passwordFlag = true
          this.errTxt = "Those password doesn't match. Try again."
          setTimeout(() => { this.passwordFlag = false; this.errTxt = '' }, 2000)
        }
      }
      else {
        this.passwordLenFlag = true
        this.errTxt = 'Password must be 6 or more characters'
        setTimeout(() => { this.passwordLenFlag = false; this.errTxt = '' }, 2000)
      }
    } else {
      this.allBlankFlag = true
      this.errTxt = 'All fields are mandantory'
      setTimeout(() => { this.allBlankFlag = false; this.errTxt = '' }, 2000)
    }
  }
}