import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from 'src/app/services/http.service';
import { DatePipe, Location } from "@angular/common";
import {MatPaginator, MatPaginatorModule} from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import * as Papa from 'papaparse'
import { DataSharingServiceService } from 'src/app/services/data-sharing-service.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-game-tracking',
  templateUrl: './game-tracking.component.html',
  styleUrls: ['./game-tracking.component.css'],
})
export class GameTrackingComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator|null= null;

  header: string = 'Super Admin Dasboard';
  allGames: any = [];
  userList : boolean = false
  sessionData:any = []
  userListArray :any;
  selected:any = {name : 'Harsha',id:1}
  displayedColumns: string[] = ['session', 'client', 'd&t','players' ,'sDuration','games','status','time','licenses','playersplayed','gtime','gSessiontime'];
  fromDate:any;
  toDate:any;
  filteredUserListArray :any ;
  sessionDetails:any
  sessionRecords:any = []
  selectedName:any ;
  dataSource:any = new MatTableDataSource();
  pageSizes = [10, 25,50,100];
  constructor(
    private httpService:HttpService,
    private toast:ToastrService,
    private dataShare:DataSharingServiceService,
    private router:Router,
    private location: Location
  ) { }

  ngOnInit(): void {
    if(this.dataShare.selected){
      this.selected = this.dataShare.selected
      // this.getUserDetails()
    }
    else{
      this.router.navigate(['/user-list']);
    }
  }
  userName:any = ''
  nameHandler(event:any){
    if(event){
      this.userName = event
    }
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.dataSource.paginator = this.paginator;
    }, );
  }
  navigateBack(){
    this.location.back();
    // this.router.navigate(['/user-list']);
  }
  onPageChange(event: any) {
    // Handle page change event here
    // console.log(event);
  }
  getUserList(){
    this.httpService.getUserListDetails().subscribe((res:any)=>{
      if(res['status']){
        this.userListArray = res['results']
      }
      else{
        this.toast.error("Something went Wrong! Please try Again.",'',{timeOut:2000})
      }
    },(err:any)=>{
      this.toast.error("Something went Wrong! Please try Again.",'',{timeOut:2000})
    })
  }
  getUserDetails(){
    this.userList = false;
    this.header = 'Activity Report : '+this.selected.name
    this.httpService.getUserDetails(this.selected.id).subscribe((res:any)=>{
      if(res['status']){
        const datePipe = new DatePipe('en-Us');
        this.sessionDetails = res['results']
        const date = new Date(this.sessionDetails.purchased_date*1000);
        this.sessionDetails.purchased_date = date.toLocaleString()
        const parts = date.toLocaleString().split(',')[0].split('/');
        const day = parseInt(parts[0]);
        const month = parseInt(parts[1]) - 1; // Months are zero-based in JavaScript Date object
        const year = parseInt(parts[2]);   
        const newDate = new Date(year, month, day);
        this.fromDate =  datePipe.transform(newDate,'yyyy-MM-dd');
        const date1 = new Date(this.sessionDetails.valid_till*1000);
        this.sessionDetails.valid_till = date1.toLocaleString()
        const date2 = new Date();
        const parts1 = date2.toLocaleString().split(',')[0].split('/');
        const day1 = parseInt(parts1[0]);
        const month1 = parseInt(parts1[1]) - 1; // Months are zero-based in JavaScript Date object
        const year1 = parseInt(parts1[2]);   
        const newDate1 = new Date(year1, month1, day1);
        this.toDate = datePipe.transform(newDate1,'yyyy-MM-dd');
        // console.log(this.sessionDetails)
      }
      else{
        this.toast.error("Something went Wrong! Please try Again.",'',{timeOut:2000})
      }
    },(err:any)=>{
      this.toast.error("Something went Wrong! Please try Again.",'',{timeOut:2000})
    })
  }
  getSessionDetails(){
    this.httpService.getUserSessionDetails(this.selected.id,this.fromDate,this.toDate).subscribe((res:any)=>{
      if(res['status']){
        this.sessionRecords = res['results']
        this.dataSource.data = res['results']
        // console.log(this.sessionRecords)
        setTimeout(() => {
          this.dataSource.paginator = this.paginator;
        }, 10);


      }
      else{
        this.toast.error("No Data found.",'',{timeOut:2000})
        this.sessionRecords = []
      }
    },(err:any)=>{
      this.toast.error("No Data found.",'',{timeOut:2000})
    })
  }
  getDate(epochTime:any){
    const date = new Date(epochTime*1000);
    return date.toLocaleString()
  }
  getHrs(mns:any,check = false){
    if(check){
      const seconds = Math.floor(mns / 1000)
      const minutes = Math.floor(seconds / 60);
      // console.log(seconds,minutes,'aaaaa')
      return Math.floor(minutes / 60);
    }
    return Math.floor(mns/60)
  }
  getMinutes(mns:any, check = false){
    if(check){
      const seconds = Math.floor(mns / 1000)
      return Math.floor(seconds / 60);
    }
    return Math.floor(mns%60)
  }
  getSessionHrs(start:any,expiry:any){
    const startTime = new Date(start * 1000);
    const endTime = new Date(expiry * 1000);
    const timeDifference = (endTime.getTime() - startTime.getTime())/1000;
    const hours = Math.floor((timeDifference)/3600);
    const rem = timeDifference % 3600
    const minutes = Math.floor((rem /60));
    return hours+' : '+minutes + ' Hrs'
  }
  dateValidityChangeHandler(){
   if(this.fromDate && this.toDate){
    this.getSessionDetails()
   }
  }
  jsonToCsv(items:any) {
    const csvData = [];
    csvData.push(['Session Name', 'Client Name', 'Scheduled Date','Time Slots', 'No of Players', 'Duration(hrs)', 'Games Planned', 'Session Status', 'Session Run Time', 'License Used Up','Players Played', 'Games Played']);
    items.forEach((item:any) => {
      let hrs = this.getHrs(item.session_plan.session_duration)
      let mins = this.getMinutes(item.session_plan.session_duration)
      let games:any = []
      item.session_plan.games.map((res:any)=>{
        games.push(res.game_name)
      })
      if(games.length == 0)
        games.push('NA')
      let ranGames:any = []
      item.session_run.games_played.map((res:any)=>{
        let dataa =  this.getSessionHrs(res.start_time ? res.start_time : 0, res.expiry_time ? res.expiry_time : 0)
        ranGames.push(res.game_name + ' - '+dataa)
      })
      let runTime = this.getSessionHrs(item.session_run.session_run_starttime,item.session_run.session_run_expirytime)
      const rowData = [
        item.session_plan.session_name,
        item.session_plan.client_name,
        item.session_plan.session_date,
        this.timeSlot(item.session_plan.slots.start_time,item.session_plan.slots.end_time),
        item.session_plan.no_of_players?item.session_plan.no_of_players:0,
        hrs+'.'+mins,
        [games],
        item.session_run.session_run_expirytime && item.session_run.session_run_starttime ? item.session_run.session_status : item.session_run.is_deleted ? 'Deleted' : 'Incomplete',
        runTime,
        item.session_run.license_used?item.session_run.license_used:'NA',
        item.session_run.players_count? item.session_run.players_count : 'NA',
        [ranGames]
      ];
      // console.log(rowData)
      csvData.push(rowData);
    });
    return csvData
  }
  exportData(){
    let csvData = this.jsonToCsv(this.sessionRecords);
    const csv = Papa.unparse(csvData);

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', this.selected.name+'.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
  }
  timeSlot(start_time:any = 0 , end_time:any = 0){
    if(!start_time) return ''
    const temp = start_time
    const temp1 = end_time * 1000
    // const temp1 = this.programDetails.slots.end_time * 1000
    return `${new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }).format(new Date(temp * 1000))} - ${new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }).format(new Date(temp1))}`
  }
}
