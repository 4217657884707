import { filter } from 'rxjs';
import { query } from '@angular/animations';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DataSharingServiceService } from 'src/app/services/data-sharing-service.service';
import { ReachOutComponent } from '../reach-out/reach-out.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-explore-all-games',
  templateUrl: './explore-all-games.component.html',
  styleUrls: ['./explore-all-games.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ExploreAllGamesComponent implements OnInit {
  selectedExplore: any = ''
  afSlider = 0;
  vcSlider = 0;
  bnSlider = 0;
  whSlider = 0;
  ppSlider = 0;
  bpSlider = 0;
  gorSlider = 0;
  powSlider = 0;
  mSlider = 0;
  strSlider = 0;
  ssSlider = 0;
  alSlider = 0;
  stSlider = 0;
  mmSlider = 0;
  fASlider = 0;
  lASlider = 0;
  tJSlider = 0;
  vcPySlider = 0;
  physicalGameContentData: any = [
    {
      name: 'Human Matrix',
      description: "Human Matrix offers a deep exploration of collaboration, trust, and working with others. The game creates an environment that fosters interactions, trust-building, and win-win solutions. In the game, 4 sub-teams must work together to each enter and exit 3 quadrants out of a 2x2 matrix of blocks while working with rule and constraints that force the team to move from a siloed to collaborative working style to achieve a shared outcome.",
      img: "../../assets/img/matrix.png",
      learningConnnect: ["Collaboration ", "Leading cross-functional teams ", "Influence over Authority", "Trust in teams"],
      grpSize: "16-40 People",
      timeGuration: "40 - 50 mins",
      spaceReq: "25ft X 25ft",
      id: 8,
      video_src:'kairosThumbnailImages/Human_Matrix_NEW.mp4' 
    },
    {
      name: 'String Theory ',
      description: "String Theory is a captivating game that explores various fundamental principles related to Problem Solving and its various aspects, offering a multifaceted and highly engaging experience. The objective of the activity is to work as a team and use a set of strings to drop as many balls as you can into containers placed inside a perimeter, with a few members standing outside and inside the perimeter at designated spots or areas.",
      img: "../../assets/img/string_therory.png",
      learningConnnect: ["Process Orientation", "Continuous Improvement", "Planning & Organizing", "Execution Excellence"],
      grpSize: "10-30 People",
      timeGuration: "30 - 45 mins",
      spaceReq: "25ft X 25ft",
      id: 9,
      video_src:'kairosThumbnailImages/Sting_Theory.mp4' 
    },
    {
      name: 'Storm Shelter',
      description: "The Storm Shelter is an intense and layered activity that explores the journey of moving from managing to leading teams. This multifaceted, highly engaging game covers several fundamental behaviours that govern leadership. In the activity, a set of blindfolded team members need to work with their leader to imagine and build a shelter with the given material while operating under strict rules.",
      img: "../../assets/img/strom_selter.png",
      learningConnnect: ["Managing to Leading teams", "Situational leadership in action", "Leadership Communication ", "Shared Ownership"],
      grpSize: "12-30 People",
      timeGuration: "40 - 50 mins",
      spaceReq: "20ft X 20ft",
      id: 10,
      video_src:'kairosThumbnailImages/Storm_shelter_NEWmp4_1.mp4' 
    },
    {
      name: 'Assembly Line',
      description: "Assembly Line is a captivating game that explores fundamental principles related to Growth Mindset, Innovation, and Continuous Improvement, offering a multifaceted and engaging experience. Competing teams have to create and continuously improve a production process to deliver maximum finished products over multiple rounds in a given time frame. Constraints by way of does and don’ts could pose barriers for the team or act as a catalyst for innovation and out-of-the-box thinking.",
      img: "../../assets/img/assebembly_line.png",
      learningConnnect: ["Innovation- Mindset to Action", "Continuous Improvement /Kaizen", "Agility", "Growth Mindset", "Goal setting (Setting stretch goals)"],
      grpSize: "10-15 People",
      timeGuration: "50 - 60 mins",
      spaceReq: "25ft X 25ft",
      id: 11,
      video_src:'kairosThumbnailImages/Assembly_Line.mp4' 
    },

    {
      name: 'Magic Mats',
      description: "Magic Mats as a game lends itself very powerfully to learning conversations around the underlying behaviours that drive result orientation",
      img: "../../assets/img/magic_mats.png",
      learningConnnect: ["Result Orientation ", "Analytical thinking & Problem-Solving", "Managing Change "],
      grpSize: "10-40 People",
      timeGuration: "40 - 50 mins",
      spaceReq: "20ft X 40ft",
      id: 12,
      video_src:'kairosThumbnailImages/Magic_Mat.mp4' 
    },
    {
      name: 'Stranded',
      description: "Strand'ed multifaceted, highly engaging game covers several fundamental behaviours that govern communication and leadership. ",
      img: "../../assets/img/stranded.png",
      learningConnnect: ["Communication for Results  ", "The Art of Feedback & Feedforward ", "Leading with Empathy", "Team Motivation (For leaders)"],
      grpSize: "10-30 People",
      timeGuration: "40 - 50 mins",
      spaceReq: "25ft X 40ft",
      id: 13,
      video_src:'kairosThumbnailImages/Stranded.mp4' 
    },
    {
      name: 'Fraction Action',
      description: "In Fraction Action, the participants, as a team must stamp on cards numbered from 1-40 in a sequence, in the fastest time possible. There are certain rules and constraints that the participants must operate within and still show excellence in execution. Teams are given multiple attempts to demonstrate reduced time of completion if they focus on excellence beyond mere execution.",
      img: "../../assets/friction_action_game.png",
      learningConnnect: ["Ownership Mindset", "Task Vs Goal Orientation", "Process Orientation", "Critical Thinking & Problem Solving","Execution Excellence"],
      grpSize: "8-10 People",
      timeGuration: "30 - 35 mins",
      spaceReq: "20ft X 20ft",
      id: 16,
      video_src:'kairosThumbnailImages/Action_Fraction.mp4' 
    },
    {
      name: 'Lean Up',
      description: "Lean Up is an activity where the teams must work together to place rings on a pole with hooks. There are points corresponding to each of the hooks which are positioned at a fair distance from the start line. Each member takes turns to place the rings as high as he/she can, with the support of the team. There are also certain rules and constrains that the teams have to work with in this activity.",
      img: "../../assets/lean_up_game.png",
      learningConnnect: ["Trust in teams", "Respecting Interdependencies", "Teamwork & Team Motivation", "Setting Stretch Goals"],
      grpSize: "5-8 People",
      timeGuration: "30 - 45 mins",
      spaceReq: "20ft X 15ft",
      id: 17,
      video_src:'kairosThumbnailImages/Lean_Up.mp4' 
    },
    {
      name: 'Traffic Jam',
      description: "In this simulated activity of a traffic jam, the team is spilt into two and made to stand in two rows facing each other with one open spot in the middle. The challenge for the team is to get to the other side by using the empty spot in between. Teams are given a preparation time to chalk out their strategies and practice their movements. Once they are ready, they need to demonstrate the movements to change their direction but in complete silence.",
      img: "../../assets/traficjam_game.png",
      learningConnnect: ["Ownership Mindset", "Task Vs Goal Orientation", "Process Orientation", "Critical Thinking & Problem Solving"],
      grpSize: "8-10 People",
      timeGuration: "45 - 60 mins",
      spaceReq: "20ft X 20ft",
      id: 18,
      video_src:'kairosThumbnailImages/TRAFFIC_Jam.mp4' 
    },
    {
      name: 'Value Continuum',
      description: "Value Continuum is a powerful activity that highlights the contours of diversity that exist within a team. The members of the team must place themselves at various points of a ‘U‘-shaped layout - The Continuum, depending on the degree of agreement to statements placed at the 2 ends of the ‘U’. These statements are designed to be seemingly divergent and will be placed on either sides of the layout.",
      img: "../../assets/vc_game_py.svg",
      learningConnnect: ["Leading with Empathy", "Emotional Intelligence", "Diversity & Inclusion"],
      grpSize: "25 as one group",
      timeGuration: "30 - 45 mins",
      spaceReq: "20ft X 20ft",
      id: 19,
      video_src:'kairosThumbnailImages/value_continuum_1.mp4' 
    },
  ];
  physicalGameContent: any[] = this.physicalGameContentData
  digitalGamesCOntentData: any = [
    {
      name: 'Value Continuum',
      description: "A unique activity where people participate as individuals and make choices between opposing positions, in a series of statement pairs, on a continuum and see their choices in relation to others in their environment. Value Continuum is an activity that helps explore individuals' mindsets, preferences, and perceptions of others, promoting open conversations on diversity, empathy, and interpersonal interactions around collaboration, trust, values and leadership.",
      img: "../../assets/img/explore_vc.svg",
      learningConnnect: ["Appreciating Diversity & Inclusion ", "Trust & Respect", "Emotional Intelligence ", "Developing Empathy to drive interactions"],
      grpSize: "5-100 People",
      timeGuration: "30 - 60 mins",
      platform: "Laptop, Desktop & Mobile",
      id: 1,
      video_src:'kairosThumbnailImages/Value_Continuum.mp4'
    },
    {
      name: 'Warehouse',
      description: "Warehouse is a multifaceted, highly engaging team activity that challenges teams and individuals to think critically and work collaboratively to transport a given target order of items from a warehouse to their team store in the shortest time possible. Multiple teams compete over multiple rounds and aim to improve efficiency and effectiveness to deliver the exact requirement and deliver incremental results.",
      img: "../../assets/img/explore_wh.svg",
      learningConnnect: ["Importance of planning and organizing", "Continuous Improvement ", "Managing & Leading Change", "Execution Excellence", "Ownership & Accountability", "Goal Setting"],
      grpSize: "8-100 People",
      timeGuration: "30 - 60 mins",
      platform: "Laptop, Desktop & Mobile",
      id: 3,
      video_src:'kairosThumbnailImages/warehouse_1.mp4'
    },
    {
      name: 'Bottleneck Challenge',
      description: "In the Bottleneck Challenge, participants find themselves on a mountain road, stuck in a peculiar jam. The objective is for the traffic from both sides (represented by team members) to get to the other side and resolve the jam while playing by certain rules governing movement.",
      img: "../../assets/img/explore_bn.svg",
      learningConnnect: ["Ownership & Initiative", "Critical thinking", "Problem Solving & Decision Making", "Team Motivation", "Process Orientation"],
      grpSize: "8-100 People",
      timeGuration: "45 - 60 mins",
      platform: "Laptop, Desktop & Mobile",
      id: 2,
      video_src:'kairosThumbnailImages/bottleneck_challenge_1.mp4'
    },
    {
      name: 'Animal Farm',
      description: "In Animal Farm, teams compete to transport a specific number of animals & birds into their barn before their rivals. The animals can be taken from the central barn or anywhere else on the farm to meet the target before the other team. The game creates insights and conversations around collaboration, trust, values and leadership.",
      img: "../../assets/img/animalFarm-logo.png",
      learningConnnect: ["Teamwork & Collaboration", "Exploring Trust", "Integrity Managing stakeholder expectations"],
      grpSize: "8-100 People",
      timeGuration: "30 - 60 mins",
      platform: "Laptop, Desktop & Mobile",
      id: 5,
      video_src:'kairosThumbnailImages/animal_farm_1.mp4'
    },
    {
      name: 'Big Picture',
      description: "Big Picture is a versatile and highly customizable jigsaw-themed group activity that works for virtual and in-person settings. Smaller teams have to complete challenges to earn codes. With each code, certain pieces of a giant jigsaw get revealed. When all teams complete all challenges and enter all codes, the entire Big Picture is unveiled. The Challenges and the Image that makes up the jigsaw are customizable to the theme of your session or event.",
      img: "../../assets/img/explore_bp.svg",
      learningConnnect: ["Collaboration, Goal alignment", "Big Picture thinking", "Vision, Mission Immersion", "Competencies Immersion"],
      grpSize: "20-300 People",
      timeGuration: "45 - 90 mins",
      platform: "Laptop & Desktop",
      id: 6,
      video_src:'kairosThumbnailImages/big_picture_1.mp4'
    },
    // {
    //   name: 'Picture Perfect ',
    //   description: "A challenging game where teams have to recreate an image on a virtual canvas based on the instructions they receive from a guide/leader who alone has access to the image that has to be replicated. The game mimics the real life challenges of communication to get things done in an interdependent environment, where the ability to humanize ones communication has a direct impact on outcomes.",
    //   img: "../../assets/img/Picture_Perfect_Logo.png",
    //   learningConnnect: ["Impactful Communication", "Communication skills and leadership", "Team Motivation", "Leading with Empathy"],
    //   grpSize: "8-100 People",
    //   timeGuration: "30 - 45 mins",
    //   platform: "Laptop, Desktop & Mobile",
    //   id: 4
    // },
    {
      name: 'Great Ocean Race',
      description: "In this all-out fun ocean themed virtual race, each team of 7-8 people is given a series of challenges, and they have to wade through all of those to make time & teamwork count to be crowned as the winning team. The challenges are in the form of cryptic crosswords, videos based puzzles, mind benders to ensure everyone’s interests and strengths are addressed through the game. The winning team is the one that opens all locks faster than the others, and is crowned CHAMPIONS OF THE OCEANS!",
      img: "../../assets/great_ocean_kingdom_logo.svg",
      learningConnnect: ["Team engagement", "Teamwork", "Bonding and networking", "Celebrating as a team"],
      grpSize: "8-100 People",
      timeGuration: "45 - 60 mins",
      platform: "Laptop & Desktop",
      id: 14,
      video_src:'kairosThumbnailImages/Great_Ocean_Race.mp4'
    },
    {
      name: 'People of the world',
      description: "People of the world is a game where competing teams race to win by opening a series of locks whose keys are embedded in thematic challenges that they need to complete in a timed race. Each of these challenges are built around elements such as unconscious biases, hardened perceptions and sub conscious stereotyping in the form or quizzes, puzzles and scenario based MCQs. The game help learners understand their blind spots, get educated on fundamentals in the non-threatening and fun setting of a game.",
      img: "../../assets/people_of_world_logo.svg",
      learningConnnect: ["Unconscious Bias", "Dimensions of diversity", "Stereotypes", "Inclusion in action"],
      grpSize: "8-100 People",
      timeGuration: "45 - 60 mins",
      platform: "Laptop & Desktop",
      id: 15,
      video_src:'kairosThumbnailImages/People_of_the_world.mp4'
    },
    
  ]
  digitalGamesCOntent: any[] = this.digitalGamesCOntentData
  afImages: any = ["../../../assets/AF_thumbnail.png"];
  vcImages: any = ["../../../assets/VC_thumbnail.png"];
  bnImages: any = ["../../../assets/BN_thumbnail.png"];
  whImages: any = ["../../../assets/WH_thumbnail.png"];
  ppImages: any = ["../../../assets/resource_slider/pp_1.jpg", "../../../assets/resource_slider/pp_2.jpg", "../../../assets/resource_slider/pp_3.jpg", "../../../assets/resource_slider/pp_4.jpg", "../../../assets/resource_slider/pp_5.jpg", "../../../assets/resource_slider/pp_6.jpg", "../../../assets/resource_slider/pp_7.jpg", "../../../assets/resource_slider/pp_8.jpg"];
  bpImages: any = ["../../../assets/BigPic_thumbnail.png"];
  gorImages: any = ["../../../assets/gor_thumbnail.png"];
  powImages: any = ["../../../assets/pow_thumbnail.png"];


  mImages: any = ["../../../assets/Human_Matrix_py_thumbnail .png"];
  stImages: any = ["../../../assets/StringTheory_py_thumbnail.png"];
  ssImages: any = ["../../../assets/Storm_shelter_py_thumbnail.png"];
  alImages: any = ["../../../assets/AssemblyLine_py_thumbnail.png"];
  strImages: any = ["../../../assets/Stranded_py_thumbnail.jpg"];
  mmImages: any = ["../../../assets/Magic_Mats_py_thumbnail.png"];
  fAImages: any = ["../../../assets/FractionAction_py_thumbnail.jpg"];
  lAImages: any = ["../../../assets/Leanup_py_thumbnail.png"];
  tJImages: any = ["../../../assets/trafficjam_py_thumbnail.png"];
  vcPyImages: any = ["../../../assets/VC_py_thumbnail.jpeg"];


  slideIndex = 1;



  constructor(
    private router: Router,
    private dataShare: DataSharingServiceService,
    private dialog: MatDialog,
    private httpSv: HttpService,
  ) { }
  headerTxt: string = ''
  ngOnInit(): void {
    this.selectedExplore = this.dataShare.exploreGameSelection
    if (this.selectedExplore == 'digital') this.getGameAccessList(1)
    else if (this.selectedExplore == 'physical') this.getGameAccessList(2)
    if (!this.selectedExplore) this.headerTxt = 'Explore All Games'
  }
  selectedExploreGame(data: any) {
    if (data == this.selectedExplore) return
    else if (data == 'digital') { this.getGameAccessList(1); this.headerTxt = ''; }
    else if (data == 'physical') { this.getGameAccessList(2); this.headerTxt = ''; }
    this.selectedExplore = data;
  }
  plusSlides(data: any) {
    switch (data) {
      case 1:
        this.vcSlider = (this.vcSlider + 1) % (this.vcImages.length);
        break;
      case 2:
        this.bnSlider = (this.bnSlider + 1) % (this.bnImages.length);
        break;
      case 3:
        this.whSlider = (this.whSlider + 1) % (this.whImages.length);
        break;
      case 4:
        this.ppSlider = (this.ppSlider + 1) % (this.ppImages.length);
        break;
      case 5:
        this.afSlider = (this.afSlider + 1) % (this.afImages.length);
        break;
      case 6:
        this.bpSlider = (this.bpSlider + 1) % (this.bpImages.length);
        break;
      case 8:
        this.mSlider = (this.mSlider + 1) % (this.mImages.length);
        break;
      case 13:
        this.strSlider = (this.strSlider + 1) % (this.strImages.length);
        break;
      case 10:
        this.ssSlider = (this.ssSlider + 1) % (this.ssImages.length);
        break;
      case 11:
        this.alSlider = (this.alSlider + 1) % (this.alImages.length);
        break;
      case 9:
        this.stSlider = (this.stSlider + 1) % (this.stImages.length);
        break;
      case 12:
        this.mmSlider = (this.mmSlider + 1) % (this.mmImages.length);
        break;
      case 14:
        this.gorSlider = (this.gorSlider + 1) % (this.gorImages.length);
        break;
      case 15:
        this.powSlider = (this.powSlider + 1) % (this.powImages.length);
        break;
      case 16:
        this.fASlider = (this.fASlider + 1) % (this.fAImages.length);
        break;
      case 17:
        this.lASlider = (this.lASlider + 1) % (this.lAImages.length);
        break;
      case 18:
        this.tJSlider = (this.tJSlider + 1) % (this.tJImages.length);
        break;
      case 19:
        this.vcPySlider = (this.vcPySlider + 1) % (this.vcPyImages.length);
        break;
    }
    // console.log(this.mSlider)
  }
  minusSlides(data: any) {
    switch (data) {
      case 1:
        this.vcSlider = this.vcSlider - 1;
        if (this.vcSlider < 0)
          this.vcSlider = this.vcImages.length - 1
        break;
      case 2:
        this.bnSlider = this.bnSlider - 1;
        if (this.bnSlider < 0)
          this.bnSlider = this.bnImages.length - 1
        break;
      case 3:
        this.whSlider = this.whSlider - 1;
        if (this.whSlider < 0)
          this.whSlider = this.whImages.length - 1
        break;
      case 4:
        this.ppSlider = this.ppSlider - 1;
        if (this.ppSlider < 0)
          this.ppSlider = this.ppImages.length - 1
        break;
      case 5:
        this.afSlider = this.afSlider - 1;
        if (this.afSlider < 0)
          this.afSlider = this.afImages.length - 1
        break;
      case 6:
        this.bpSlider = this.bpSlider - 1;
        if (this.bpSlider < 0)
          this.bpSlider = this.bpImages.length - 1
        break;
      case 8:
        this.mSlider = this.mSlider - 1;
        if (this.mSlider < 0)
          this.mSlider = this.mImages.length - 1
        break;
      case 13:
        this.strSlider = this.strSlider - 1;
        if (this.strSlider < 0)
          this.strSlider = this.strImages.length - 1
        break;
      case 10:
        this.ssSlider = this.ssSlider - 1;
        if (this.ssSlider < 0)
          this.ssSlider = this.ssImages.length - 1
        break;
      case 11:
        this.alSlider = this.alSlider - 1;
        if (this.alSlider < 0)
          this.alSlider = this.alImages.length - 1
        break;
      case 9:
        this.stSlider = this.stSlider - 1;
        if (this.stSlider < 0)
          this.stSlider = this.stImages.length - 1
        break;
      case 12:
        this.mmSlider = this.mmSlider - 1;
        if (this.mmSlider < 0)
          this.mmSlider = this.mmImages.length - 1
        break;
      case 14:
        this.gorSlider = this.gorSlider - 1;
        if (this.gorSlider < 0)
          this.gorSlider = this.gorImages.length - 1
        break;
      case 15:
        this.powSlider = this.powSlider - 1;
        if (this.powSlider < 0)
          this.powSlider = this.powImages.length - 1
        break;
      case 16:
        this.fASlider = this.fASlider - 1;
        if (this.fASlider < 0)
          this.fASlider = this.fAImages.length - 1
        break;
      case 17:
        this.lASlider = this.lASlider - 1;
        if (this.lASlider < 0)
          this.lASlider = this.lAImages.length - 1
        break;
      case 18:
        this.tJSlider = this.tJSlider - 1;
        if (this.tJSlider < 0)
          this.tJSlider = this.tJImages.length - 1
        break;
      case 19:
        this.vcPySlider = this.vcPySlider - 1;
        if (this.vcPySlider < 0)
          this.vcPySlider = this.vcPyImages.length - 1
        break;
    }
  }
  back() {
    if (this.dataShare.myGames && this.dataShare.myGames != 'dashboard') {
      // console.log(this.dataShare.myGames)
      this.router.navigate(['myGames'])
    }
    else if (this.dataShare.myGames && this.dataShare.myGames == 'dashboard') {
      this.router.navigate(['/dashboard'])
      this.dataShare.myGames = null
    }
    this.dataShare.exploreGameSelection = null
    this.selectedExplore = ''
  }
  goToGameResource(id: any) {
    this.dataShare.exploreGameSelection = this.selectedExplore
    this.router.navigate(['game/resources'], { queryParams: { id: id } })
  }
  goToQuestico() {
    this.router.navigate(['/game/questico'])
  }



  getBgClass(id: any) {
    let cssClass = ''
    switch (id) {
      case 1:
        cssClass = 'diYellow'
        break;
      case 2:
        cssClass = 'diPurple'
        break;
      case 3:
        cssClass = 'diBlue'
        break;
      case 4:
        cssClass = 'digital'
        break;
      case 5:
        cssClass = 'digital'
        break;
      case 6:
        cssClass = 'diPink'
        break;
      case 8:
        cssClass = 'physical'
        break;
      case 9:
        cssClass = 'pyBlue'
        break;
      case 10:
        cssClass = 'pyGreen'
        break;
      case 11:
        cssClass = 'pyYellow'
        break;
      case 12:
        cssClass = 'pyPurple'
        break;
      case 13:
        cssClass = 'pyPink'
        break;
      case 16:
        cssClass = 'diYellow'
        break;
      case 17:
        cssClass = 'pyGreen'
        break;
      case 18:
        cssClass = 'physical'
        break;
      case 19:
        cssClass = 'diPurple'
        break;
    }


    return cssClass
  }
  dgSearchInputText:any = null
  filterDigitalContent(val: any) {
    this.dgSearchInputText = val.value
    let temp = this.digitalGamesCOntentData.filter((ele: any) => ele.description.toLowerCase().includes(val.value.toLowerCase()) | ele.learningConnnect.some((ele: any) => ele.toLowerCase().includes(val.value.toLowerCase())) | ele.name.toLowerCase().includes(val.value.toLowerCase()))
    // if(temp.length == 0) this.digitalGamesCOntent = this.digitalGamesCOntentData
    // else 
    this.digitalGamesCOntent = temp
  }
  pySearchInputText:any = null
  filterPhysicalContent(val: any) {
    this.pySearchInputText = val.value
    let temp = this.physicalGameContentData.filter((ele: any) => ele.description.toLowerCase().includes(val.value.toLowerCase()) | ele.learningConnnect.some((ele: any) => ele.toLowerCase().includes(val.value.toLowerCase())) | ele.name.toLowerCase().includes(val.value.toLowerCase()))
    // if(temp.length == 0) this.physicalGameContent = this.physicalGameContentData
    // else
    this.physicalGameContent = temp
  }
  openReachOut() {
    // console.log(this.gameAccessIds)
    const dialogRef = this.dialog.open(ReachOutComponent, {
      panelClass: 'reachout',
      hasBackdrop: true,
    })
  }
  gameAccessIds: any = null;
  getGameAccessList(game_type: number) {
    this.httpSv.getGameAccessList(game_type).subscribe((res: any) => {
      if (res['status']) {
        this.gameAccessIds = []
        let accessApiData = res['results'].filter((ele:any)=> ele.status == 1 && ele.is_active ==1)
        accessApiData.forEach((ele: any) => {
          this.gameAccessIds.push(ele.game_id)
        });
      }

    })
  }
  openVideoPlayer(url:string){
    this.httpSv.getSignedUrl(url).subscribe((res:any)=>{
      if(res['status']){
        this.dialog.open(ExploreVideoPlayerDialog,{
          hasBackdrop:true,
          data:res.url
        })
      }else{

      }
    })
    
  }
}


@Component({
  selector: 'explore_video_player',
  templateUrl: './explore_video_player.html',
})
export class ExploreVideoPlayerDialog implements OnInit {
  constructor(public dialogRef: MatDialogRef<ExploreVideoPlayerDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,) {
  }
  ngOnInit(): void {
      this.src = this.data
  }
  src:string = '';
  closeVideoPlayer(){
    this.dialogRef.close()
  }
}