<div>
  <div>

    <div><img src="../../../assets/kairosName.svg" alt="" style="width: 10rem;"></div>
    <div><img src="../../../assets/img/logout-svgrepo-com.svg" alt="" srcset=""
        style="width: 2rem;cursor: pointer;margin: 0px 11px;" (click)="goBack()"> Logout</div>
    <h2 style="text-align: center;">Platform Game Calendar</h2>
  </div>
  <div style="position: absolute;right: 10px;top: 10px;">
    <button type="button" class="btn btn-outline-primary" (click)="currentMounth()">Refresh</button>
    <div style="margin-top: 2rem;">
      <div class="info-sub-div">
        <div class="circle-css">&nbsp;</div> <span>Kairos Platform</span>
      </div>
      <div class="info-sub-div">
        <div class="circle-css" style="background-color: #226854;">&nbsp;</div> <span>Gaminar Platform</span>
      </div>
    </div>
  </div>
  <div>
    <div>

    </div>

  </div>
  <div class="container">
    <div class="monthHeader">
      {{allMonths[monthNumber]}} {{currentYear}}
    </div>
    <div class="dayName-main-div">
      <div *ngFor="let item of daysOfWeek" style="font-weight: 500;">{{item}}</div>
    </div>
    <div class="dates-man-div">
      <div *ngFor="let item of allFinalDisplayDates" [ngClass]="item.mm == monthNumber? '' :'disableDates'"
        class="date-div">
        <div *ngIf="item.data.length > 0" class="info-main-div">
          <div class="info-sub-div" *ngIf="platformVarifyFunc('Kairos',item.data)">
            <div class="circle-css">&nbsp;</div>
            <div class="no-of-game">{{gameDataHtmlHandel(item.data,'Kairos')}}</div>

          </div>
          <div class="info-sub-div" *ngIf="platformVarifyFunc('Gaminar',item.data)">
            <div style="background-color: #226854;" class="circle-css">&nbsp;</div>
            <div style="background-color: #226854;" class="no-of-game">{{gameDataHtmlHandel(item.data,'Gaminar')}}</div>
          </div>
        </div>
        {{item.date}}
      </div>
    </div>
    <div (appOutsideClick)="outSideClickHandle($event)">
      <div class="cheat-code" (click)="openChectPassoword()">&nbsp;</div>
      <div class="password-div" *ngIf="passwordDiv">
        <input type="password" (keyup.enter)="verifyCheatPassword()" [(ngModel)]="cheatPassword">
      </div>
    </div>
    <div class="btns-controler-div">
      <button type="button" class="btn btn-outline-primary" (click)="privousMonth()">Previous Month</button>
      <button type="button" class="btn btn-outline-primary" (click)="currentMounth()">Current Month</button>
      <button type="button" class="btn btn-outline-primary" (click)="nextMonth()">Next Month</button>
    </div>
  </div>
</div>