<div class="license_info_main_cointaner">
    <div class="licences_info_header_main_div">
        <div class="licences_info_header_div">License Details</div>
    </div>
    <div style="display: flex; justify-content: center;">
        <div class="licences_info_content_main_div">
            <div class="licences_info_content_sub_div">
                <div class="license_info_content_sub_heading">LICENSES AVAILABLE</div>
                <div class="license_info_content_sub_value">: {{data.total_license}}</div>
            </div>
            <div class="licences_info_content_sub_div">
                <div class="license_info_content_sub_heading">BLOCKED LICENSES</div>
                <div class="license_info_content_sub_value">: {{data.blocking_license}}</div>
            </div>
            <div class="licences_info_content_sub_div">
                <div class="license_info_content_sub_heading">LICENSES LEFT</div>
                <div class="license_info_content_sub_value">: {{(+data.total_license) - (+data.blocking_license)}}</div>
            </div>
        </div>
    </div>



    <div class="licences_info_btn_main_div">
        <button class="go_back_btn" (click)="close(false)">No, Go Back</button>
        <button class="continue_btn" (click)="close(true)">YES, Continue</button>
    </div>



    <!-- TIMER POP AS ABSOLUTE  -->

    <!-- <div class="timer_pop_main_div hide_toggel" id="timer_pop_up">
        <div class="timer_pop_img_div"><img src="../../../assets/img/timer_animation.gif" alt=""></div>
        <div class="timer_pop_txt_div">Time available to complete the session set up 15 minutes</div>
    </div> -->

</div>





<style>
    .mat-dialog-container {
        padding: 0 !important;
        border-radius: 8px;
    }

    .license_info_main_cointaner {
        width: 670px;
        text-align: center;
    }

    .licences_info_header_main_div {
        background-color: #F8F8F8;
        width: 100%;
        height: 51px;
        border-bottom: 1px solid #E8E8E8;
        font-size: 20px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .licences_info_content_main_div {
        margin-top: 1rem;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        gap: 13px;
    }

    .licences_info_content_sub_div {
        display: flex;
        align-items: center;
    }

    .licences_info_content_main_div .license_info_content_sub_heading {
        width: 200px;
        font-size: 15px;
        font-weight: 400;
        color: #5B5B5B;
        text-align: left;
    }

    .licences_info_content_main_div .license_info_content_sub_value {
        font-size: 16px;
        font-weight: 700;
        color: #5B5B5B;
        text-align: left;
    }

    .licences_info_btn_main_div {
        margin-top: 2rem;
        margin-bottom: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }


    .licences_info_btn_main_div .go_back_btn {
        width: 173px;
        height: 32px;
        border: 1px solid #585353;
        font-size: 15px;
        font-weight: 500;
        border-radius: 50px;
        background-color: transparent;
        color: #585353;
    }

    .licences_info_btn_main_div .continue_btn {
        width: 173px;
        height: 32px;
        border: none;
        font-size: 15px;
        font-weight: 500;
        border-radius: 50px;
        background-color: #33DE81;
        color: #000000;
    }
</style>