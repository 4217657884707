<div class="resouce_box_parent_wrapper">
    <div class="addResourceMain_div_content">
        <div class="legend_dialog_header">
            <label>{{legendName}}</label>
        </div>
        <div class="legend_dialog_body">
            <div *ngFor="let legend of legendDtl; let i = index" class="legend_resource_main_div">
                <div class="legend_resource_sub_div">
                    <div class="legend_resource">
                        <img src="../../../../assets/close-circle-svgrepo-com.svg" alt="" *ngIf="legendDtl.length > 1 && i > 0 && getFileValidation(legend)" class="close_icon_le_file" (click)="closeResourceFile(i)">
                        <div class="custom-dropdown" [class.open]="legend.isDropdownOpen" *ngIf="!this.galaryFlag">
                            <label for="resource_type" class="addResourceLabel">Resource Type</label><br>
                            <div class="custom-select-wrapper custom-select-container addResourceInput">
                                <select class="addResourceInput" (change)="onSelectChange($event, i)" [(ngModel)]="legendDtl[i].type" [disabled]="legendDtl[i].main_resources_file" #targetElement>
                                    <option value="" disabled>Resource Type</option>
                                    <option *ngFor="let fileType of fileTypes | keyvalue" [value]="fileType.value">{{
                                        fileType.value
                                        }}</option>
                                </select>
                                <!-- <i class="dropdown-icon" (click)="targetElement.click()">
                                    <img src="../../../../assets/downArrow.svg" alt="Dropdown Icon">
                                </i> -->
                            </div>
                        </div>

                        <div class="resource_name" *ngIf="!this.galaryFlag">
                            <label class="addResourceLabel">Resource Name</label>
                            <input type="text" [(ngModel)]="legendDtl[i].name">
                        </div>
                        <div class="resource_name" *ngIf="this.galaryFlag">
                            <label class="addResourceLabel">Image {{i+1}}</label>
                            <input type="text" [(ngModel)]="legendDtl[i].name" placeholder="Image name">
                        </div>

                    </div>
                    <div class="file_upload_replace_main_div">
                        <input type="file"
                            [accept]="legendDtl[i].type == 'pdf' ? accpetedFileType.pdf : legendDtl[i].type == 'video' ? accpetedFileType.video : legendDtl[i].type == 'ppt' ? accpetedFileType.ppt : accpetedFileType.image"
                            (change)="onImageChange($event,legend)" #fileInput_image hidden id="hiddenImgInput" />
                        <div class="upload_file_btn"
                            (click)="legendDtl[i].type ? fileInput_image.click():showErr('Please select resource type')"
                            *ngIf="!legendDtl[i].main_resources_file">
                            <img src="../../../assets/uplaoded_icon_bp.svg" alt="">
                            <span>Upload file</span>
                        </div>
                        <div class="upload_file_btn" style="cursor: default;"
                            *ngIf="legendDtl[i].main_resources_file">
                            <span style="overflow-wrap: anywhere;">{{getFileName(legendDtl[i])}}</span>
                        </div>
                        <div *ngIf="legendDtl[i].main_resources_file" class="replace_file_btn_main_div">
                            <div (click)="fileInput_image.click()" style="margin-top: 10px;">Replace file</div>
                            <div>
                                <img style="height:20px; width:25px;cursor: pointer;margin-bottom: 4px;"
                                    src="../../../../assets/delete_btn.svg" alt="" (click)="deleteFile(legend,i)">
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="legendDtl[i].type == 'ppt'" class="replace_file_btn_main_div" style="align-items: center;">
                    <input type="file" [accept]="accpetedFileType.pdf" (change)="onPPTChange($event,legend)"
                        #fileInput_image_ppt hidden id="hiddenImgInput" />

                    <div (click)="fileInput_image_ppt.click()" style="margin-bottom: 10px;margin-top: 10px;"
                        *ngIf="!legendDtl[i].html_resources_file">
                        Add Pdf File
                    </div>
                    <span *ngIf="legendDtl[i].html_resources_file" style="max-width: 58%;overflow-wrap: anywhere;display: block;">{{getPPTConvertedName(legendDtl[i].html_resources_file)}}</span>
                    <div (click)="fileInput_image_ppt.click()" style="margin-bottom: 10px;margin-top: 10px;"
                    *ngIf="legendDtl[i].html_resources_file">Replace Pdf File</div>
                    <img [placement]="legendDtl[i].html_resources_file ? 'left' :'bottom'" [ngbTooltip]="convertedPPTtoolTipTxt" tooltipClass="tooltip"
                        src="../../../../assets/img/tooltip.svg" style="margin-left: 5px;" [tooltipClass]="'custom-tooltip'">
                </div>
                <div *ngIf="(legendDtl[i].type == 'pdf' || legendDtl[i].type == 'ppt' || legendDtl[i].type == 'images') && !this.galaryFlag " class="download_main_div">
                    <input type="checkbox" name="" id="" [(ngModel)]="legendDtl[i].is_downloadable">
                    <label for="download">Downloadable</label>
                </div>
            </div>
            <div style="display: flex;">
                <div class="add_more_file_btn_main_div" (click)="addMoreFileToLegend()">
                    <span>Add Files to Legend</span>
                    <img src="../../../../assets/add_icon.svg" alt="">
                </div>
            </div>
            <div class="legend_dialog_footer">
                <button class="btn_custom" (click)="saveToLocal()" style="font-weight: 400;">Save</button>
                <!-- <img src="../../../../assets/btn_done.svg" (click)="saveToLocal()"> -->
            </div>
        </div>

    </div>
</div>