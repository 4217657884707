<div style="height: 100vh;">
    <app-header [first]=NewSession [secound]=Digital [third]=Name></app-header>
    <div class="main-div-container" id="mainDivContainer">

        <div class="" style="height: 100%;">
            
            <div class="bg-side-div">
                <img src="../../../assets/img/bg-curve.svg" alt="" srcset="">
            </div>
            <div class="container-div">
                <div class="left-container">
                    <div>
                        <img src="../../../assets/img/questico-img.svg" alt="">
                    </div>
                </div>
                <div class="right-container">
                    <div>
                        <label for="session-name">Session Name</label>
                        <input type="text" class="form-control" [(ngModel)]="sessionName">
                    </div>
                    <div>
                        <label for="session-date">Session Date</label>
                        <input type="date" class="form-control" id="datePicker"  [(min)]="todayDate" [(ngModel)]="sessionDate" (change)="dateChangeHadler()">
                    </div>
                    <div>
                        <label for="client-name">Client Name</label>
                        <input type="text" class="form-control" [(ngModel)]="clientName">
                    </div>
                    <div style="text-align: center;">
                        <input style="width: 60%;" type="image" src="../../../assets/img/save&conti-btn.svg" (click)="redirectToQuestico()">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>