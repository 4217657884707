import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Inject, Input, NgModule, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { DataSharingServiceService } from 'src/app/services/data-sharing-service.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from 'src/app/services/http.service';
import { Title } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { DashboardService } from 'src/app/Shared/Services/Dashboard.service';
import { Location } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { PendingChangesGuard } from 'src/app/guard/pending-changes.guard.guard';
import { LicesenseReturnInfoDialog, SessionDeleteDialog } from '../scheduled/scheduled.component';





@Component({
  selector: 'app-scheduler',
  templateUrl: './scheduler.component.html',
  styleUrls: ['./configure.component.css']
})
export class SchedulerConfigureComponent implements OnInit {
  @Output() messageEmitter = new EventEmitter<any>();
  @Output() dataSubmit = new EventEmitter<any>();
  @Input() dateSelect: any
  @Input() noOfPlayer: any
  @Input() avaliableLicience: any
  @Input() subcriptionExpiry: any
  @Input() progarmId: any
  @Input() clientName: any
  @Input() sessionName: any
  @ViewChild('calendar_div') div1!: ElementRef;
  @ViewChild('slot_div') div2!: ElementRef;
  @ViewChild('calendar_drop') div3!: ElementRef;
  @HostListener('document:click', ['$event'])
  sessionduration = 'Schedule the date and time slot/ slots. A max of 3 consecutive time slots can be reserved and thus maximum session duration can be 180 minutes.';
  onClick(event: Event): void {
    if (
      !this.div1.nativeElement?.contains(event.target as Node) &&
      !this.div2.nativeElement?.contains(event.target as Node) &&
      !this.div3.nativeElement?.contains(event.target as Node)
    ) {
      // The click occurred outside of div1 and div2.

      this.showCalendar = false
    }
    else {
    }
  }
  curDate: any

  constructor(
    private httpSv: HttpService,
    private router: Router,
    private toster: ToastrService,
    private dialog: MatDialog
  ) {
  }
  allSlots: any = []
  slotsApiData: any = []
  payLoadEpochTime: any
  showSlots: any = true
  currentDate: any
  showCalendar: boolean = false
  ngOnInit(): void {
    this.todayDate = this.dateSelect
    this.sessionDate = this.todayDate
    this.curDate = new Date(this.dateSelect.split('-')[0], this.dateSelect.split('-')[1] - 1, this.dateSelect.split('-')[2])

    if (new Date(this.curDate).setHours(0, 0, 0, 0) == new Date().setHours(0, 0, 0, 0)) {
      const d = new Date()
      this.payLoadEpochTime = d.getTime()
    }
    else {
      this.payLoadEpochTime = this.curDate.getTime()
    }
    const currentDate = this.curDate;
    // const currentDate = new Date();
    this.month = currentDate.toLocaleString('default', { month: 'long' });
    this.year = currentDate.getFullYear();
    this.weeks = this.generateCalendarGrid(currentDate);
    this.timeZones = this.getAllTimeZones();

    // this.fetchAllSlots()
    // if (this.noOfPlayer && this.clientName && this.sessionName)
    // this.handleClander()
  }
  showErr(flag: boolean) {
    if (flag)
      this.showCalendar = false
    //   this.toster.error('Max player is 100 allowed', '', { timeOut: 2000 })
  }
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.


    // this.allSlots.forEach((ele: any) => {
    //   ele['selected'] = false
    // });
  }
  // Properties
  public month: any;
  public year: any;
  public days: string[] = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
  public weeks: any[][] = [];
  preSelectedDate: string = ''
  generateCalendarGrid(date: Date, maxDateData: any = null): number[][] {
    // const currentDate = new Date();
    const currentDate = this.curDate;
    const daysInMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1).getDay();


    const calendarGrid: any[][] = [[]];
    let currentWeek = 0;
    let maxDate: any = new Date(currentDate)

    // if (maxDateData)
    //   maxDate = new Date();
    // else
    //   maxDate = new Date(maxDateData);
    maxDate.setDate(currentDate.getDate() + 9);
    let tempCount = 0
    for (let i = 1; i <= daysInMonth; i++) {
      if (i === 1) {
        // Fill the first row with empty cells for the days before the 1st of the month
        for (let j = 0; j < firstDayOfMonth; j++) {
          calendarGrid[currentWeek].push(0);
        }
      }

      const cellDate = new Date(date.getFullYear(), date.getMonth(), i);
      currentDate.setHours(0, 0, 0, 0);
      maxDate.setHours(0, 0, 0, 0);
      // || cellDate.getTime() > maxDate.getTime()
      if (cellDate.getTime() < currentDate.getTime()) {
        // Disable past dates
        if (cellDate.getTime() < new Date().setHours(0, 0, 0, 0)) {
          calendarGrid[currentWeek].push({ day: i, disabled: true, index: null, allowed: false });
        }
        else if (this.subcriptionExpiry && (cellDate.getTime() > this.subcriptionExpiry * 1000)) {
          calendarGrid[currentWeek].push({ day: i, disabled: true, index: null, allowed: false });
        }
        else {
          calendarGrid[currentWeek].push({ day: i, disabled: true, index: null, allowed: true });
        }
      } else {
        if (this.preSelectedDate == '') this.preSelectedDate = i.toString()
        if (+i - parseInt(currentDate.getDate()) < 0) {
          const diff = new Date(this.windowMaxDate).getDate() - i
          if (this.subcriptionExpiry && (cellDate.getTime() > this.subcriptionExpiry * 1000)) {
            calendarGrid[currentWeek].push({ day: i, disabled: true, index: null, allowed: false });
          }
          else if (!maxDateData)
            calendarGrid[currentWeek].push({ day: i, disabled: false, index: 10 - +diff - 1, allowed: true });
          else {
            calendarGrid[currentWeek].push({ day: i, disabled: false, index: -1, allowed: true });
          }
        }
        else
          if (this.subcriptionExpiry && (cellDate.getTime() > this.subcriptionExpiry * 1000)) {
            calendarGrid[currentWeek].push({ day: i, disabled: true, index: null, allowed: false });
          }
          else if (!maxDateData)
            calendarGrid[currentWeek].push({ day: i, disabled: false, index: tempCount, allowed: true });
          else
            calendarGrid[currentWeek].push({ day: i, disabled: false, index: -1, allowed: true });
        tempCount++
      }

      if ((firstDayOfMonth + i) % 7 === 0) {
        // Start a new week when the day count reaches a multiple of 7
        currentWeek++;
        calendarGrid[currentWeek] = [];
      }
    }
    if (maxDateData) {
      this.shedularClaedarClick('', 0, false)
    }
    return calendarGrid;
  }
  public months: string[] = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  previousMonth(val: any = false) {
    const currentMonth = new Date(this.year, this.months.indexOf(this.month), 1);
    currentMonth.setMonth(currentMonth.getMonth() - 1);
    this.month = currentMonth.toLocaleString('default', { month: 'long' });
    this.year = currentMonth.getFullYear();
    this.weeks = this.generateCalendarGrid(currentMonth);

    if (currentMonth.setHours(0, 0, 0) > new Date(this.windowMaxDate).setHours(0, 0, 0)) {
      this.currentMonth = this.month
      this.currentMonthDate = this.preSelectedDate
      this.currentMonthDate = this.year
      this.weeks = this.generateCalendarGrid(currentMonth, true);
    }
    else {
      this.weeks = this.generateCalendarGrid(currentMonth);
    }
    if (val) {
      setTimeout(() => this.showSlots = false, 0)
      this.preSelectedDate = ''
      this.allSlots = []
    }
  }
  currentMonth: any
  currentMonthDate: any
  currentYearDate: any
  nextMonth(val: any = false) {
    const currentMonth = new Date(this.year, this.months.indexOf(this.month), 1);
    currentMonth.setMonth(currentMonth.getMonth() + 1);
    this.month = currentMonth.toLocaleString('default', { month: 'long' });
    this.year = currentMonth.getFullYear();
    if (currentMonth.setHours(0, 0, 0) > new Date(this.windowMaxDate).setHours(0, 0, 0)) {
      this.currentMonth = this.month
      this.currentMonthDate = this.preSelectedDate
      this.currentMonthDate = this.year
      this.weeks = this.generateCalendarGrid(currentMonth, true);
    }
    else
      this.weeks = this.generateCalendarGrid(currentMonth);
    if (val) {
      setTimeout(() => this.showSlots = false, 0)
      this.preSelectedDate = ''
      this.allSlots = []
    }
  }
  shedularClaedarClick(date: any, index: any, parentIndex: any = null, isDisable: any = null) {
    if ((isDisable && !parentIndex?.allowed) || !date) {
      return
    }
    setTimeout(() => this.showSlots = true, 0)
    if (isDisable == true) {
      this.sessionDate = this.year + '-' + (+this.months.findIndex((ele: any) => ele == this.month) + 1) + '-' + date
      this.sessionDate = this.sessionDate.split('-').map((ele: any) => {
        if (ele.length == 1) return ele = '0' + ele
        else return ele
      }).join('-')
      this.dateChangeHadler(true)
      return
    }
    if (parentIndex) {
      if (parentIndex.index > 9 || parentIndex.index < 0) {
        this.sessionDate = `${this.year}-${this.months.indexOf(this.month) + 1}-${('0' + parentIndex.day).slice(-2)}`
        // alert('2')
        this.dateChangeHadler()
        return
      }
    }
    this.preSelectedDate = date
    if (parentIndex) {
      if (date) {
        let slotData: any;
        let falg;
        this.slotsApiData.map((apiData: any) => {
          let data = apiData.date.split('-');
          if (data[2] == date) {
            slotData = apiData.slots
            falg = data
          }
        })
        this.allSlots = slotData
      }
    }
    else {
      // this.allSlots = this.slotsApiData[index].slots
      if (date) {
        let slotData: any;
        let falg;
        this.slotsApiData.map((apiData: any) => {
          let data = apiData.date.split('-');
          if (data[2] == date) {
            slotData = apiData.slots
            falg = data
          }

        })

        this.allSlots = slotData
      }
    }
    if (this.allSlots == undefined) {
      this.sessionDate = `${this.year}-${this.months.indexOf(this.month) + 1}-${('0' + parentIndex.day).slice(-2)}`
      this.dateChangeHadler(true)
      return
    } else
      this.allSlots.forEach((ele: any) => {
        ele['selected'] = false
      })
    this.selectedSlot = []
    // alert('6')



  }
  getAllTimeZones(): string {
    // const options = ;
    const localTimeZoneName = new Intl.DateTimeFormat('en-US', { timeZoneName: 'short' }).resolvedOptions().timeZone;
    return localTimeZoneName
  }
  timeZones: any;

  getStartSlotTime(time: any) {
    const epochTime = time;
    const epochTimeMilliseconds = epochTime * 1000;
    const date = new Date(epochTimeMilliseconds);
    const localTime = date.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      // second: 'numeric',
      hour12: true,
      // timeZoneName: 'short',
    });
    return localTime
  }
  getEndSlotTime(time: any) {
    const epochTime = time;
    const epochTimeMilliseconds = epochTime * 1000;
    const date = new Date(epochTimeMilliseconds);
    const localTime = date.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      // second: 'numeric',
      hour12: true,
      // timeZoneName: 'short',
    });
    return localTime
  }
  slotValidatar(slot: any[]) {
    slot = slot.sort((a: any, b: any) => {
      if (new Date(a.start_time) > new Date(b.start_time)) return 1
      else if (new Date(a.start_time) < new Date(b.start_time)) return -1
      else return 0
    })
    this.selectedSlot = slot
    const epochTime1 = slot[0].start_time
    const epochTime2 = slot[slot.length - 1].expiry_time
    // Convert epoch times to Date objects
    const date1 = new Date(epochTime1 * 1000);
    const date2 = new Date(epochTime2 * 1000);
    // Calculate the time difference in milliseconds
    const timeDifferenceMs = date2.getTime() - date1.getTime();
    // Calculate the time difference in hours
    const timeDifferenceHours = timeDifferenceMs / (1000 * 60 * 60);
    if (timeDifferenceHours == slot.length) return true
    else return false
  }
  selectedTimeString: string = ''

  slotConsicutiveFlag: boolean = false
  goToStep2() {
    if (this.selectedSlot.length > 0) {
      const currentEpochTime = Math.floor(new Date().getTime() / 1000.0)
      const selectedStartTime = this.selectedSlot[0].start_time


      const sd = this.selectedSlot[0].start_time
      const originalDate = new Date(sd * 1000);
      const year = originalDate.getFullYear();
      const month = String(originalDate.getMonth() + 1).padStart(2, '0');
      const day = String(originalDate.getDate()).padStart(2, '0');
      const formattedDate = `${year}-${month}-${day}`;
      if (+this.noOfPlayer <= 100 && +this.noOfPlayer >= 2) {
        if (this.noOfPlayer <= this.avaliableLicience) {
          if (this.slotValidatar(this.selectedSlot)) {
            let data: any = {
              program_id: this.progarmId,
              program_date: formattedDate,
              players: this.noOfPlayer,
              timeslots: this.selectedSlot,
              language: 'en'
            }
            const timeDate = new Date(data.timeslots[0].start_time * 1000)
            this.selectedTimeString = `${('0' + timeDate.getDate()).slice(-2)}-${timeDate.getMonth()}-${timeDate.getFullYear()} | ${timeDate.toLocaleString('en-US', {
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            })}-${new Date((data.timeslots[data.timeslots.length - 1].expiry_time) * 1000).toLocaleString('en-US', {
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            })}`
            data['stringDateTime'] = this.selectedTimeString.split('|')[1]
            data['inputBoxData'] = this.selectedTimeString
            this.dataSubmit.emit(data)
            this.showCalendar = false
            if (currentEpochTime > selectedStartTime) {
              this.dialog.open(CurrentSlotAlertDialog, {
                hasBackdrop: true,
                disableClose: true,
                data: `Your session will be limited to ${Math.floor((this.selectedSlot[this.selectedSlot.length - 1].expiry_time - currentEpochTime) / 60)} minutes as per your chosen duration.`
              })
            }
            // this.httpSv.bookSlot(data).subscribe((res: any) => {
            //   if (res['status']) {
            //     this.router.navigate(['/configure/games'])
            //   }
            // })
          } else {
            this.slotConsicutiveFlag = true
            setTimeout(() => { this.slotConsicutiveFlag = false }, 2000)
            // this.toster.error('Slots must be without break', '', { timeOut: 2000 })
          }
        }
        else {
          this.toster.error("You did not have sufficient licence", '', { timeOut: 2000 })
        }
      } else {
        this.toster.error("No. of player should be between 2 to 100", '', { timeOut: 2000 })
      }
    } else {
      this.toster.error('Please select the time slot(s)', '', { timeOut: 2000 })
    }

    // this.messageEmitter.emit('TRUE')
  }
  handleScrollTop() {
    const scrollContent = document.querySelector('#scroll_content');
    if (scrollContent) {
      scrollContent.scrollTop -= 50;
      const targetSection = scrollContent.nextElementSibling;
      if (targetSection) {
        targetSection.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }
  handleScrollDown() {
    const scrollContent = document.querySelector('#scroll_content');
    if (scrollContent) {

      scrollContent.scrollTop += 50;
      const targetSection = scrollContent.nextElementSibling;
      if (targetSection) {
        targetSection.scrollIntoView({ behavior: 'smooth' });
      }
    }

  }
  selectedSlot: any[] = []
  maxSlotFlag: boolean = false
  selectedSlotHandlear(data: any, index: any) {
    if (this.selectedSlot.length >= 3 && !data.selected) {
      this.maxSlotFlag = true
      setTimeout(() => { this.maxSlotFlag = false }, 2000)
      // this.toster.error('Maximum 3 slots are allowed', '', { timeOut: 2000 })
    } else {
      data.selected = !data.selected
      if (data.selected) {
        data["id"] = index
        this.selectedSlot.push(data)
      } else {
        const i = this.selectedSlot.findIndex((ele: any) => ele.id == index)
        this.selectedSlot.splice(i, 1)
      }
    }
  }

  windowMinDate: any
  windowMaxDate: any
  fetchAllSlots() {
    // this.todayDate = this.dateSelect
    // this.sessionDate = this.todayDate
    this.sessionDate = this.sessionDate.split('-').map((ele: any) => {
      if (ele.length == 1) {
        return ele = "0" + ele
      }
      return ele
    }).join("-")
    this.curDate = new Date(this.sessionDate + 'T00:00:00').toISOString()
    if (new Date(this.curDate).setHours(0, 0, 0, 0) == new Date().setHours(0, 0, 0, 0)) {
      const d = new Date()
      this.payLoadEpochTime = d.getTime()
    }
    else {
      this.payLoadEpochTime = new Date(this.curDate).getTime()
    }
    this.httpSv.getAllSlots(this.noOfPlayer, Math.floor(this.payLoadEpochTime / 1000)).subscribe((res: any) => {
      if (res['status']) {
        this.selectedSlot = []
        const tempData = res.result
        let tempNewData: any[] = []
        tempData.forEach((ele: any) => {
          const datepayLoad = new Date(this.payLoadEpochTime);
          const yearpayLoad = datepayLoad.getFullYear();
          const monthpayLoad = datepayLoad.getMonth() + 1;
          const formattedMonthPayLoad = monthpayLoad < 10 ? `0${monthpayLoad}` : monthpayLoad.toString();
          const daypayLoad = ("0" + datepayLoad.getDate()).slice(-2);


          const dateEle = new Date(ele.start_time * 1000);
          const yearEle = dateEle.getFullYear();
          const monthEle = dateEle.getMonth() + 1;
          const formattedMonthEle = monthEle < 10 ? `0${monthEle}` : monthEle.toString();
          const dayEle = ("0" + dateEle.getDate()).slice(-2)
          if (tempNewData.length == 0) {
            if (new Date(ele.start_time * 1000).getDate() == new Date(this.payLoadEpochTime).getDate()) {
              tempNewData.push({
                date: yearEle + '-' + formattedMonthEle + '-' + dayEle,
                slots: [ele]
              })
            } else {
              tempNewData.push({
                date: yearpayLoad + '-' + formattedMonthPayLoad + '-' + daypayLoad,
                slots: []
              })
              tempNewData.push({
                date: yearEle + '-' + formattedMonthEle + '-' + dayEle,
                slots: [ele]
              })
            }
          } else {
            const reqIndex = tempNewData.findIndex((element: any) => parseInt(element.date.split('-')[2]) == new Date(ele.start_time * 1000).getDate())
            if (reqIndex != -1) {
              tempNewData[reqIndex].slots.push(ele)
            } else {

              tempNewData.push({
                date: yearEle + '-' + formattedMonthEle + '-' + dayEle,
                slots: [ele]
              })
            }
          }
        });
        tempNewData = tempNewData.sort((a, b) => {
          const dateA: any = new Date(a.date);
          const dateB: any = new Date(b.date);
          // if(dateA - dateB)
          return dateA - dateB; // Sort in descending order
        });
        this.slotsApiData = tempNewData
        this.allSlots = this.slotsApiData[0].slots
        this.windowMinDate = new Date(tempNewData[0].date.split('-')[0], tempNewData[0].date.split('-')[1] - 1, tempNewData[0].date.split('-')[2]).setHours(0, 0, 0, 0)
        // this.windowMinDate = new Date(dateFns.format(new Date(tempNewData[0].date), "yyyy-MM-dd'T'HH:mm:ss") + 'T00:00:00').getTime()
        if (tempNewData[0].slots.length > 0)
          this.windowMaxDate = new Date(tempNewData[tempNewData.length - 1].date).getTime()
        else
          this.windowMaxDate = new Date(tempNewData[tempNewData.length - 2].date).getTime()
        this.curDate = new Date(this.windowMinDate)
        const currentDate = this.curDate;
        this.month = currentDate.toLocaleString('default', { month: 'long' });
        this.year = currentDate.getFullYear();
        this.weeks = this.generateCalendarGrid(currentDate, this.windowMinDate);
        this.preSelectedDate = new Date(this.payLoadEpochTime).getDate().toString()
        let temDate = new Date(this.payLoadEpochTime).getTime()
        const d: any = new Date(this.payLoadEpochTime);
        const preD: any = new Date(this.windowMinDate)
        const differenceInMilliseconds = temDate - preD;
        const differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
        // alert('5')
        this.shedularClaedarClick(d.getDate(), differenceInDays)
        if (+(this.sessionDate.split('-')[2]) <= 3 && +(this.sessionDate.split('-')[1]) - 1 != this.months.findIndex((ele: any) => ele == this.month)) {
          this.nextMonth()
        }
      }
      else {

      }
    }, (err: any) => {

    })
  }

  sessionDate: string = ''
  todayDate: any;
  handleClander() {
    if (!this.noOfPlayer || !this.clientName || !this.sessionName) {
      this.toster.error('Please enter all fields first', '', { timeOut: 2000 })
      this.sessionDate = ''
    }
    else if (this.noOfPlayer > this.avaliableLicience) return
    else {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0'); // Adjust month and pad with 0 if necessary
      const day = String(today.getDate()).padStart(2, '0'); // Pad day with 0 if necessary
      this.sessionDate = `${year}-${month}-${day}`;
      this.dateChangeHadler(true)
      this.tempCount = 0
    }

  }
  dateChangeHadler(condition: boolean = false) {
    if (!this.noOfPlayer || !this.clientName) {
      this.toster.error('Please enter all fields first', '', { timeOut: 2000 })
      this.sessionDate = ''

    }
    else if (+this.noOfPlayer > 100 || this.noOfPlayer < 2) {
      return
    }
    else {
      this.sessionDate = this.sessionDate.split('-').map((ele: any) => {
        if (ele.length == 1) return ele = "0" + ele
        else return ele
      }).join("-")
      let temDate = new Date(this.sessionDate).getTime()
      this.payLoadEpochTime = temDate
      if ((this.windowMinDate <= temDate && temDate <= this.windowMaxDate && !this.slotFilled) && !condition) {
        const d: any = new Date(this.sessionDate + 'T00:00:00');
        const preD: any = new Date(this.dateSelect + 'T00:00:00')
        const differenceInMilliseconds = temDate - preD;
        const differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
        // alert('3')

        this.shedularClaedarClick(d.getDate(), differenceInDays)
      }
      else {
        // alert('4')
        this.fetchAllSlots()
        this.slotFilled = false
      }
    }
    this.showCalendar = true
  }
  slotFilled: boolean = false
  showBookingError() {
    this.slotFilled = true
  }
  getTimeDisplay(time: any, postion: any) {
    if (time) {
      if (postion == 0) {
        let arr = time.split('|')[0].split('-')
        arr.splice(1, 1, +arr[1] + 1)
        return arr.join('-')
      }
      return time.split('|')[postion]
    }
  }
  tempCount: any = 0
  outSideClickHandle(event: any) {
    if (event) {
      if (this.tempCount == 1) {
        this.showCalendar = false
        this.tempCount = 0
      }
      else
        this.tempCount = 1

    }
  }
  clearData() {
    this.selectedTimeString = ''
  }
}





// -------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------

@Component({
  selector: 'app-configure',
  templateUrl: './configure.component.html',
  styleUrls: ['./configure.component.css'],
  providers: [DatePipe],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ConfigureComponent implements OnInit {
  txt: string = 'ABX'
  newSessionTooltip: string = 'Name the session or program.'
  clientNameTooltip: string = 'Name the client for which this session is scheduled.'
  languageTooltip: string = 'The language of game for both players and facilitator.'
  maxplayers = 'The max number of players who can log in on the game link.  The link will not work once this limit is crossed. It cannot exceed 100.';
  sessionduration = 'Schedule the date and time slot/ slots. A max of 3 consecutive time slots can be reserved and thus maximum session duration can be 180 minutes.';
  @ViewChild(SchedulerConfigureComponent, { static: false }) sheadularComp!: SchedulerConfigureComponent;
  @HostListener('window:beforeunload', ['$event'])
  unloadHandler(event: Event) {
    this.ngOnDestroy()
  }
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return this.routeChangeFlag;
  }
  routeChangeFlag: boolean = true
  onKeyDown(event: any) {
    setTimeout(() => {
      if (!this.noOfPlayer || this.noOfPlayer == null || this.noOfPlayer == '') this.noOfPlayerErrFlag = false

      else if (this.noOfPlayer <= 100 && this.noOfPlayer >= 2 && +this.noOfPlayer <= +this.avaliableLicience) {
        this.noOfPlayerErrFlag = false
      }
      else {
        this.noOfPlayerErrFlag = true
      }
    }, 100)
    if (event.key === 'Backspace' || event.key === 'Delete' || event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
      return;
    }
    // Check if the pressed key is a digit
    const isDigit = /^\d$/.test(event.key);

    // If not a digit, prevent the input
    if (!isDigit) {
      event.preventDefault();
    }
  }

  constructor(
    private dataService: DataSharingServiceService,
    private router: Router,
    private toster: ToastrService,
    private httpSv: HttpService,
    private titleService: Title,
    private changeDetect: ChangeDetectorRef,
    private dashboardService: DashboardService,
    private location: Location,
    private dialog: MatDialog,

    private test: PendingChangesGuard
  ) {
    this.titleService.setTitle('Kairos | Configure')
  }
  goBack() {
    this.router.navigate(['sessionDashboard'])
    // this.location.back()
  }
  todayDate: any;
  editProgramId: any = null;
  editDisableDate: any
  gameEditFlag: boolean = true
  ngOnInit(): void {
    if (sessionStorage.getItem('uuid') && this.dashboardService.editStep3 == null) {
      this.ngOnDestroy()
    }

    if (this.dashboardService.editStepFalg) {
      if (this.dashboardService.editStepFalg.step1 && this.dashboardService.editStepFalg.step2) {
        // this.step = 2
        this.NewSession = 'Edit Session'
        this.backEditFlag = false
        this.gameEditFlag = false
      }
    }
    if (this.dataService.sessionData && !this.dataService.newSetup) {
      this.routeChangeFlag = true
      this.NewSession = 'Edit Session'
      this.gameEditFlag = false
      const temp = this.dataService.sessionData
      this.sessionName = temp.sessionName
      this.clientName = temp.clientName
      this.editProgramId = temp.programId
      this.noOfPlayer = temp.maxPlayer
      let date = new Date(temp.sessionDate)
      let dateTemp = date.toLocaleDateString("en-GB", { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/\//g, '-')
      this.sessionDate = dateTemp.split('-').reverse().join('-')
      this.editDisableDate = `${temp.timeSlotDtls?.date.split('-').reverse().join('-')} | ${temp.timeSlotDtls?.time}`
      this.timeSlotData = {
        language: 'en',
        players: temp.maxPlayer,
        program_id: temp.programId,
        stringDateTime: temp.timeSlotDtls?.time,
        timeslots: temp.noOfSlot,
        inputBoxData: this.editDisableDate
      }
      this.dateChangeHadler()


      // step -2 
      const alreadySelected = this.dataService.selectedGameForConfig
      if (alreadySelected.length > 0) {
        alreadySelected.forEach((ele: any) => {
          const game = JSON.parse(ele)
          const index = this.todo.findIndex((ele: any) => JSON.parse(ele).gameId == game.gameId)
          if (index != -1) {
            this.done.push(this.todo[index])
            this.inEditIntialValue.push(this.todo[index])
            // this.todo.splice(index, 1)
          }
          else {
            this.toster.error('Oops!! Something went wrong')
            this.router.navigate(['/dashboard'])
          }

        })
      }
      else {
        this.toster.error('Oops!! Something went wrong')
        this.router.navigate(['/dashboard'])
      }
    }
    console.log(this.dashboardService.editStep3)
    console.log(this.dataService.timeSlotData)
    if (this.dashboardService.editStep3 != null) {
      this.gameEditFlag = !this.dashboardService.editStep3.editOldSession
      if (!this.dashboardService.editStep3.editOldSession)
        this.NewSession = 'New Session'
      else this.backEditFlag = false
      if (this.dataService.timeSlotData == null) {
        this.routeChangeFlag = true
        this.location.back()
        // window.location.reload()
      }
      this.timerEpochTime = this.dataService.timeSlotData.timeLeft.timerEpochTime
      this.schdularProgamId = this.dashboardService.editStep3.programId
      this.editProgramId = this.dashboardService.editStep3.programId
      this.sessionName = this.dashboardService.editStep3.sessionName
      this.clientName = this.dashboardService.editStep3.clientName
      this.sessionDate = this.dashboardService.editStep3.sessionDate
      this.noOfPlayer = this.dashboardService.editStep3.maxPlayer
      this.timeSlotData['program_date'] = this.sessionDate
      this.timeSlotData = this.dashboardService.editStep3.time_slot
      this.done = this.dashboardService.editStep3.done_data
      this.todo = this.dashboardService.editStep3.todo_data
      if (this.dashboardService.editStep3) {
        if (this.dashboardService.editStep3.intialGameValue == false) {
          this.inEditIntialValue = [`{"gameId":"0"}`]
          const parent = this.done.map((ele: any) => {
            return JSON.parse(ele).gameId
          })
          const child = this.inEditIntialValue.map((ele: any) => {
            return JSON.parse(ele).gameId
          })
          if (!this.checkSubset(parent, child)) {
            this.errorFlagForGameDelete = true;
            this.errorFlagMessageForGameDelete = "Previous game configuration will be cleared when proceeding to the next step";
            this.routeChangeFlag = false
          } else {
            this.errorFlagForGameDelete = false;
          }
        } else {
          this.inEditIntialValue = [...this.done]
        }
      }
      this.step = 2
      clearInterval(this.intervalRef)
      if (!this.dashboardService.editStep3.editOldSession) {
        this.intervalRef = setInterval(() => {
          if (this.remainingTime == 0) {
            this.updateRemainingTime();
            for (let i = 1; i <= 10; i++) {
              clearInterval(i);
            }
            clearInterval(this.intervalRef)
          } else {
            this.updateRemainingTime();
          }
        }, 1000);
        this.routeChangeFlag = false
      }

    }

    this.httpSv.getLicenseCount().subscribe((res: any) => {
      if (res['status']) {
        this.avaliableLicience = res['results'].no_of_licenses_left
        this.subcriptionExpiry = res['results'].end_date
      } else {
        this.toster.error('Oops! Something went wrong')
        this.location.back()
      }
    }, (err: any) => {
      this.toster.error('Oops! Something went wrong')
      this.location.back()
    })
  }
  inEditIntialValue: any = []
  avaliableLicience: any = 0
  subcriptionExpiry: any
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.todayDate = new Date().toLocaleDateString("en-GB").split('T')[0].split('/').reverse().join('-');
    document.getElementById("datePicker")?.setAttribute('min', this.todayDate);
    if (this.sessionDate == '')
      this.sessionDate = this.todayDate

  }
  deleteSession() {
    if (this.gameEditFlag) {
      const dialogRef = this.dialog.open(DeleteSessionConfirmDialog, {
        disableClose: true,
        minWidth: '30rem',
        maxWidth: '60rem',
        data: "config_page"
      })
      dialogRef.afterClosed().subscribe((res: any) => {
        if (res) {
          this.dashboardService.deleteCurrentSession(this.timeSlotData.program_id).subscribe((res: any) => {
            if (res.status) {
              this.routeChangeFlag = true
              location.reload()
            }
          })
        }
      })
    } else {
      let gameData = this.dataService.gameDeleteContent
      if (gameData) {
        this.timeValidatorForEditOnStart(gameData.slots.start_time).then((isValid: boolean) => {
          if (isValid) this.toster.info("This game can't be delete because the session start time has already passed.", '', { timeOut: 3000 })
          else {
            const dialogRef = this.dialog.open(SessionDeleteDialog, {
              hasBackdrop: true,
              disableClose: true,
              data: gameData
            })
            dialogRef.afterClosed().subscribe((res: any) => {
              if (res) {
                const dialogRef = this.dialog.open(LicesenseReturnInfoDialog, {
                  hasBackdrop: true,
                  disableClose: true,
                  data: res
                })
                dialogRef.afterClosed().subscribe((result: any) => {
                  this.dataService.gameDeleteContent = null
                  this.routeChangeFlag = true
                  this.router.navigate(['/scheduled'])
                })
              }
            })
          }
        })
      }
    }
  }
  async timeValidatorForEditOnStart(start_time: any) {
    try {
      const res: any = await this.httpSv.getServerEpochTime().toPromise()
      if (res['status']) {
        const currentEpochTime = res.epoch_time;
        return start_time <= currentEpochTime;
      } else {
        this.toster.error('Unable to connect to the server!!', '', { timeOut: 2000 });
        return false;
      }
    } catch (err) {
      this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 });
      return false
    }
  }
  NewSession: any = 'New Session'
  Digital: any = 'Digital'
  Name: any = 'Name & Date'
  step: number = 1
  sessionName: string = ''
  sessionDate: string = ''
  finalDateFormated: string = ''
  clientName: string = ''
  noOfPlayer: any = ''
  deleteFlag: boolean = true;
  async goToStep1() {
    // alert(22223)
    await this.httpSv.deleteBookedSlot({ program_id: this.schdularProgamId }).subscribe((res: any) => {
      if (res['status']) {
        this.deleteFlag = true
        this.step = 1
        var today = new Date().toLocaleDateString("en-GB").split('T')[0].split('/').reverse().join('-');
        document.getElementById("datePicker")?.setAttribute('min', today);
        this.Name = 'Name & Date'
      }
    })
  }
  async ngOnDestroy() {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.deleteFlag) {
      await this.httpSv.deleteBookedSlot({ program_id: sessionStorage.getItem('uuid') }).subscribe((res: any) => {
        if (res['status']) {
          // alert('1')
          this.deleteFlag = true
          this.step = 1
          var today = new Date().toLocaleDateString("en-GB").split('T')[0].split('/').reverse().join('-');
          document.getElementById("datePicker")?.setAttribute('min', today);
          this.Name = 'Name & Date'
          sessionStorage.removeItem('uuid')
          this.dataService.selectedGameForConfig = []
          this.dataService.sessionData = {}
          this.dataService.currentSelect = null
          this.dataService.totalConfigDone = []
          this.dataService.sessionTempEditData = {}
          this.dataService.newSetup = true
          this.dataService.lastGameConfigId = null
          this.dashboardService.editStep3 = null
        }
      })
    }
    if (this.dashboardService.editStepFalg) {
      if (this.dashboardService.editStepFalg.step1 && this.dashboardService.editStepFalg.step2 && !this.routeChangeFlag) {
        await this.httpSv.deleteBookedSlot({ program_id: sessionStorage.getItem('epidt') }).subscribe((res: any) => {
          if (res['status']) {
            // alert(2)
            this.deleteFlag = true
            this.step = 1
            var today = new Date().toLocaleDateString("en-GB").split('T')[0].split('/').reverse().join('-');
            document.getElementById("datePicker")?.setAttribute('min', today);
            this.Name = 'Name & Date'
            sessionStorage.removeItem('epidt')
            this.dataService.selectedGameForConfig = []
            this.dataService.sessionData = {}
            this.dataService.currentSelect = null
            this.dataService.totalConfigDone = []
            this.dataService.sessionTempEditData = {}
            this.dataService.newSetup = true
            this.dataService.lastGameConfigId = null
            this.dashboardService.editStep3 = null
          }
        })
      }
    }
    if (this.routeChangeFlag) {
      if (this.gameEditFlag) {
        this.dataService.newSetup = true
      } else {
        this.done.forEach((ele: any) => {
          this.dataService.sessionTempEditData[JSON.parse(ele)['gameId']] = null
        })
        this.dataService.selectedGameForConfig = this.done
        this.dataService.sessionData = {
          sessionName: this.sessionName,
          clientName: this.clientName,
          programId: this.editProgramId,
          sessionDate: this.NewSession == 'Edit Session' ? this.sessionDate : this.timeSlotData.program_date,
          sessionSlot: this.timeSlotData.stringDateTime,
          sessionPlayer: this.timeSlotData.players
        }
        this.dataService.newSetup = false
      }
      if (this.errorFlagForGameDelete) {
        const parent = this.done.map((ele: any) => {
          return JSON.parse(ele).gameId
        })
        const child = this.inEditIntialValue.map((ele: any) => {
          return JSON.parse(ele).gameId
        })
        if (!this.findCommonElements(parent, child)) {
          this.dataService.gameEditAllDelete = true
          this.dataService.gameEditDeleteId = this.editProgramId
          if (this.gameEditFlag) this.dataService.newSetup = true
          else
            this.dataService.newSetup = false
        }
      }
    }
    clearInterval(this.intervalRef);
  }
  schedularTimeSlot: boolean = false
  changeSteper(event: any) {
    if (event == 'TRUE') {
      this.Name = 'Select Games'
      this.step = 2
      this.schedularTimeSlot = false
    }
  }
  selectedTimeString: string = ''
  btnFlag: boolean = true
  goToStep2() {
    if (!this.sessionName || this.sessionName.trim() == '' || !this.clientName || this.clientName.trim() == '' || this.sessionDate == '' || this.noOfPlayer.toString() == '') {
      this.toster.error('All fields are mandantory', '', { timeOut: 2000 })
    }
    else if (this.noOfPlayer <= 0) {
      this.toster.error('Please ensure the "Player" field contains a valid value, not zero.', '', { timeOut: 2000 })
    }
    else if (Date.parse(this.todayDate) > Date.parse(this.sessionDate)) {
      this.toster.error('Game can not be configure for previous dates', '', { timeOut: 2000 })
    }
    else {
      // this.schedularTimeSlot = true
      this.Name = 'Select Games'
      if (this.NewSession == 'Edit Session') {
        if (this.dashboardService.editStepFalg) {
          if (this.dashboardService.editStepFalg.step1 && !this.dashboardService.editStepFalg.step2) {
            // this.NewSession = 'New Session'
          }
        }
        this.step = 2
      }

      else {
        this.btnFlag = false
      }
    }
  }
  intervalRef: any
  moveNext() {
    if (this.sessionName && this.sessionName.trim() != '' && this.clientName && this.clientName.trim() != '') {
      const dialogRef = this.dialog.open(LicenseInfoDialog, {
        hasBackdrop: true,
        disableClose: true,
        data: {
          total_license: this.avaliableLicience,
          blocking_license: this.noOfPlayer
        }
      })
      dialogRef.afterClosed().subscribe((result: boolean) => {
        if (result) {
          const ele = document.getElementById('timer_pop_up')
          const btnEle = document.getElementById('submition_btn_main_div')
          if (ele && btnEle) {
            ele.classList.remove('hide_toggel')
            btnEle.classList.add('hide_toggel')
          }
          setTimeout(() => {
            let data = {
              program_name: this.sessionName,
              program_date: this.timeSlotData.program_date,
              client_name: this.clientName,
              game_id: [1],
              program_id: this.editProgramId,
              language: this.timeSlotData.language
            }

            this.httpSv.sessionPost(data).subscribe((res: any) => {
              if (res['status']) {
                this.schdularProgamId = res['result'].id
                sessionStorage.setItem('uuid', this.schdularProgamId)

                this.timeSlotData['program_id'] = res['result'].id
                this.httpSv.bookSlot(this.timeSlotData).subscribe((res: any) => {
                  if (res['status']) {
                    if (ele && btnEle) {
                      ele.classList.add('hide_toggel')
                      btnEle.classList.remove('hide_toggel')
                    }
                    this.editProgramId = this.schdularProgamId
                    this.step = 2
                    const tempDate = new Date()
                    this.timerEpochTime = tempDate.getTime()
                    this.timerEpochTime += 15 * 60 * 1000;  // 15 min timer
                    // this.timerEpochTime += 0.5 * 60 * 1000;
                    this.sessionDate = this.timeSlotData.program_date
                    clearInterval(this.intervalRef)
                    this.intervalRef = setInterval(() => {
                      if (this.remainingTime == 0) {
                        this.updateRemainingTime();
                        clearInterval(this.intervalRef)
                      } else {
                        this.updateRemainingTime();
                      }
                    }, 1000);
                    this.routeChangeFlag = false
                  }
                  else {
                    this.sheadularComp.showBookingError()
                    if (ele && btnEle) {
                      ele.classList.add('hide_toggel')
                      btnEle.classList.remove('hide_toggel')
                    }
                  }
                }, (err: any) => this.toster.error(err.error.message, '', { timeOut: 2000 }))
              }
            })
          }, 1200)
        }
      })


    } else {
      this.toster.error('All fields are required', '', { timeOut: 2000 })
    }
  }
  updateRemainingTime() {
    const currentTime = new Date().getTime();
    this.remainingTime = Math.max(0, Math.floor((this.timerEpochTime - currentTime) / 1000));
    if (this.remainingTime == 0) {
      for (let i = 1; i <= 10; i++) {
        clearInterval(i);
      }
      this.goToStep1()
      clearInterval(this.intervalRef)
      setTimeout(() => {
        this.routeChangeFlag = true
        location.reload()
        this.toster.error('Oops! Times Up', '', { timeOut: 2000 })
        this.backEditFlag = false

        this.changeDetect.detectChanges()
      }, 0)
    }
  }
  get remainingMinutes(): number {
    if (this.remainingTime)
      return Math.floor(this.remainingTime / 60);
    else
      return 0
  }

  get remainingSeconds(): number {
    if (this.remainingTime)
      return this.remainingTime % 60;
    else
      return 0
  }
  timerEpochTime: any
  remainingTime: any
  goToStep3() {
    this.step = 3
  }
  dateChangeHadler() {
    let temDate = new Date()
    let date = this.sessionDate
    let newdate = date.split("-").reverse()
    let dd = newdate[0]
    let mm = this.getMonthName(newdate[1])
    let yy = newdate[2]
    this.finalDateFormated = `${dd} ${mm} ${yy}`
  }
  getMonthName(monthNumber: any) {
    const date = new Date();
    date.setMonth(monthNumber - 1);

    return date.toLocaleString('en-US', { month: 'long' });
  }


  //step 2
  todo = [
    `{"name":"Value Continuum","gameId":"1","imgUrl":"../../assets/img/value_continum.svg"}`,
    `{"name":"Bottle Neck","gameId":"2","imgUrl":"../../assets/img/bottleneck.svg"}`,
    `{"name":"Warehouse","gameId":"3","imgUrl":"../../assets/img/warehouse.svg"}`,
    // `{"name":"Picture Perfect","gameId":"4","imgUrl":"../../assets/img/picturePerfect.svg"}`,
    `{"name":"Animal Farm","gameId":"5","imgUrl":"../../assets/img/animal_faram.svg"}`,
    `{"name":"Big Picture","gameId":"6","imgUrl":"../../assets/img/bottleneck_logo.svg"}`,
    `{"name":"Great Ocean Race","gameId":"14","imgUrl":"../../assets/great_ocean_kingdom_logo.svg"}`,
    `{"name":"People of the world","gameId":"15","imgUrl":"../../assets/people_of_world_logo.svg"}`,
  ];

  done: string[] = [];

  drop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    }
    // else {
    //   // temprory code below ------

    //   if (JSON.parse(this.todo[event.previousIndex])['gameId'] == 4) {
    //     this.toster.error("This game is under development, can't configure now", '', { timeOut: 2000 })
    //     return
    //   }

    //   // Main code below ------

    //   // if (this.done.length > 0) {
    //   //   if(JSON.parse(this.todo[event.previousIndex ])['gameId'] == 6){
    //   //     this.toster.error('Please remove all selected game(s) to configure Big Picture','',{timeOut:2000})
    //   //     return
    //   //   }
    //   //   else if(JSON.parse(this.done[0])['gameId'] == 6 ){
    //   //     this.toster.error('Big Picture can not configure with other games','',{timeOut:2000})
    //   //     return
    //   //   }
    //   // }
    //   // cdk-drop-list-0
    //   // if(event.previousContainer.element.nativeElement.id == 'cdk-drop-list-0'){

    //   this.done.push(this.todo[event.previousIndex])
    //   this.todo.splice(event.previousIndex, 1)
    //   this.todo = this.todo
    //   this.changeDetect.detectChanges()

    //   // }
    //   // else if(event.previousContainer.element.nativeElement.id == 'cdk-drop-list-1'){
    //   //   this.deleteFromRight(this.done[event.previousIndex],event.previousIndex)

    //   // }

    //   // transferArrayItem(
    //   //   event.previousContainer.data,
    //   //   event.container.data,
    //   //   event.previousIndex,
    //   //   event.currentIndex,
    //   // );
    // }
  }
  reverse(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      // moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      this.deleteFromRight(this.done[event.previousIndex], event.previousIndex)
    }
  }

  getJSONparse(item: any, type: any) {
    return JSON.parse(item)[type]
  }
  deleteFromRight(data: any, index: any) {
    this.todo.splice(parseInt(JSON.parse(data).gameId) - 1, 0, data);
    // this.todo[] = 
    this.done.splice(index, 1)
    this.todo = this.todo.sort((a: any, b: any) => {
      if (+JSON.parse(a)['gameId'] > +JSON.parse(b)['gameId']) {
        return 1
      }
      else if (+JSON.parse(a)['gameId'] < +JSON.parse(b)['gameId']) {
        return -1
      }
      else return 0
    })
    this.done = this.done
    const parent = this.done.map((ele: any) => {
      return JSON.parse(ele).gameId
    })
    const child = this.inEditIntialValue.map((ele: any) => {
      return JSON.parse(ele).gameId
    })
    if (!this.checkSubset(parent, child)) {
      this.errorFlagForGameDelete = true;
      this.errorFlagMessageForGameDelete = "Previous game configuration will be cleared when proceeding to the next step";
      this.routeChangeFlag = false
      // setTimeout(() => {
      //   this.errorFlag = false
      //   this.errorFlagMessage = ''
      // }, 2000)
    } else {
      this.errorFlagForGameDelete = false;
    }
    this.changeDetect.detectChanges()

  }
  schdularProgamId: any
  saveAndMoveToNew() {
    if (this.done.length == 0) {
      this.toster.error('Please Select Any Game', '', { timeOut: 2000 })
    }
    else {
      let data = {
        program_name: this.sessionName,
        program_date: this.NewSession == 'Edit Session' ? this.sessionDate : this.timeSlotData.program_date,
        client_name: this.clientName,
        game_id: this.done.map(ele => JSON.parse(JSON.parse(ele)['gameId'])),
        program_id: this.editProgramId,
        language: 'en'
      }
      this.httpSv.sessionPost(data).subscribe((res: any) => {
        if (res['status']) {
          this.dataService.selectedGameForConfig = this.done
          this.dataService.sessionData = {
            sessionName: this.sessionName,
            clientName: this.clientName,
            programId: res['result'].id,
            sessionDate: this.NewSession == 'Edit Session' ? this.sessionDate : this.timeSlotData.program_date,
            sessionSlot: this.timeSlotData.stringDateTime,
            sessionPlayer: this.timeSlotData.players
          }
          this.dataService.currentSelect = JSON.parse(this.done[0])['gameId']
          this.done.forEach((ele: any) => {
            this.dataService.sessionTempEditData[JSON.parse(ele)['gameId']] = null
          })
          this.dataService.timeSlotData = this.timeSlotData
          this.dataService.timeSlotData.timeLeft = {
            timerEpochTime: this.timerEpochTime,
            remainingTime: this.remainingTime
          }
          this.deleteFlag = false
          this.routeChangeFlag = true
          this.dashboardService.editStep3 = {
            time_slot: this.timeSlotData,
            done_data: this.done,
            todo_data: this.todo
          }
          if (this.gameEditFlag) {
            this.dataService.newSetup = true
          } else {
            this.dataService.newSetup = false
          }
          if (this.errorFlagForGameDelete) {
            const parent = this.done.map((ele: any) => {
              return JSON.parse(ele).gameId
            })
            const child = this.inEditIntialValue.map((ele: any) => {
              return JSON.parse(ele).gameId
            })
            if (!this.findCommonElements(parent, child)) {
              this.dataService.gameEditAllDelete = true
              this.dataService.gameEditDeleteId = this.editProgramId
            }
          }

          this.router.navigate(['/configure/games'])

          // this.schdularProgamId = res['result'].id

          // if (this.NewSession != 'Edit Session')
          //   this.step = 3
          // else {
          // this.router.navigate(['/configure/games'])
          // }
        }
      })
      // this.router.navigate(['/configure/games'])
    }

  }

  findCommonElements(parent: any, child: any) {
    return parent.some((item: any) => child.includes(item))
  }

  selectedItem: any = null
  errorFlag: boolean = false;
  errorFlagMessage: any = '';
  errorFlagForGameDelete: boolean = false;
  errorFlagMessageForGameDelete: any = '';
  drag(ev: any, data: any) {
    this.selectedItem = data
  }
  allowDrop(ev: any) {
    ev.preventDefault();
  }
  drop1(ev: any) {
    if (this.selectedItem != null) {

      if (JSON.parse(this.todo[this.selectedItem])['gameId'] == 4) {
        this.errorFlag = true;
        this.errorFlagMessage = "This game is under construction, can't configure now.";
        setTimeout(() => {
          this.errorFlag = false
          this.errorFlagMessage = ''
        }, 2000)
        return
      }
      else
        if ((JSON.parse(this.todo[this.selectedItem])['gameId'] == 6 || JSON.parse(this.todo[this.selectedItem])['gameId'] == 4 || JSON.parse(this.todo[this.selectedItem])['gameId'] == 14 || JSON.parse(this.todo[this.selectedItem])['gameId'] == 15) && this.done.length != 0) {
          this.errorFlag = true;
          this.errorFlagMessage = "This game can't configure with other game(s).";
          setTimeout(() => {
            this.errorFlag = false
            this.errorFlagMessage = ''
          }, 2000)
          return
        }
        else if (this.done.length != 0 && JSON.parse(this.done[0])['gameId'] == 6) {
          this.errorFlag = true;
          this.errorFlagMessage = "This game can't configure with big picture.";
          setTimeout(() => {
            this.errorFlag = false
            this.errorFlagMessage = ''
          }, 2000)
          return
        }
        else if (this.done.length != 0 && JSON.parse(this.done[0])['gameId'] == 4) {
          this.errorFlag = true;
          this.errorFlagMessage = "This game can't configure with picture perfect.";
          setTimeout(() => {
            this.errorFlag = false
            this.errorFlagMessage = ''
          }, 2000)
          return
        }
        else if (this.done.length != 0 && JSON.parse(this.done[0])['gameId'] == 14) {
          this.errorFlag = true;
          this.errorFlagMessage = "This game can't configure with picture perfect.";
          setTimeout(() => {
            this.errorFlag = false
            this.errorFlagMessage = ''
          }, 2000)
          return
        }
        else if (this.done.length != 0 && JSON.parse(this.done[0])['gameId'] == 15) {
          this.errorFlag = true;
          this.errorFlagMessage = "This game can't configure with picture perfect.";
          setTimeout(() => {
            this.errorFlag = false
            this.errorFlagMessage = ''
          }, 2000)
          return
        }
        else if (this.done.length == 3) {
          this.errorFlag = true;
          this.errorFlagMessage = "Max 3 games are allowed to configure in one session.";
          setTimeout(() => {
            this.errorFlag = false
            this.errorFlagMessage = ''
          }, 2000)
          return
        }

      this.done.push(this.todo[this.selectedItem])
      this.todo.splice(this.selectedItem, 1)
      const parent = this.done.map((ele: any) => {
        return JSON.parse(ele).gameId
      })
      const child = this.inEditIntialValue.map((ele: any) => {
        return JSON.parse(ele).gameId
      })
      // console.log(this.inEditIntialValue)
      if (!this.checkSubset(parent, child)) {
        this.errorFlagForGameDelete = true;
        this.errorFlagMessageForGameDelete = "This game will deleted. Once moved to next step";
        // setTimeout(() => {
        //   this.errorFlag = false
        //   this.errorFlagMessage = ''
        // }, 2000)
      } else {
        this.errorFlagForGameDelete = false;
      }
      // console.log(this.checkSubset(parent, child))
    }
    this.selectedItem = null
  }
  gameSelectionFunction(data: any) {
    const gameEle = JSON.parse(data)
    if (this.done.length == 0)
      this.done.push(data)
    else {
      if (gameEle.gameId == JSON.parse(this.done[0]).gameId) {
        this.done = []
      } else {
        return
        // this.errorFlagMessage = 'You can schedule the session with maximum of 1 game only'
        // this.errorFlag = true
        // setTimeout(()=>{
        //   this.errorFlag = false
        // },3000)
      }
    }
    console.log(this.done)
  }
  parseElementGameID(data: string) {
    return JSON.parse(data).gameId;
  }
  dragEnd() {
    this.selectedItem = null
  }
  insideIndex: any = null
  dragInside(ev: any, data: any) {
    this.insideIndex = data
  }
  insideDrop(index: any) {
    if (this.insideIndex != null) {
      const temp = this.done[this.insideIndex]
      this.done.splice(this.insideIndex, 1)
      this.done.splice(index, 0, temp)
    }
  }
  dragInsideEnd() {
    this.insideIndex = null
  }
  timeSlotData: any = {}
  makeProgramOnDraft(data: any) {
    // console.log(data)
    if (data) {
      this.timeSlotData = data
      this.goToStep2()
    }
  }
  counter: any = 0
  toggleClander = true
  noOfPlayerErrFlag: boolean = false
  noOfPlayerHandle() {
    this.btnFlag = true
    if (this.counter == 0) {
      this.counter++
    } else {
      // this.toggleClander = false
      // setTimeout(() => {
      //   this.toggleClander = true
      // })
      this.sheadularComp.clearData()
    }

    if (+this.noOfPlayer > 100 || +this.noOfPlayer < 2 || +this.noOfPlayer > +this.avaliableLicience) {
      // this.noOfPlayer = 100
      this.noOfPlayerErrFlag = true
      // this.toster.error('Max player is 100 allowed', '', { timeOut: 2000 })
    }
  }
  backEditFlag: boolean = false
  goBackToStep1() {
    // console.log(this.NewSession)
    if (this.NewSession == 'Edit Session') {
      this.editDisableDate = this.timeSlotData.inputBoxData
      this.step = 1
    } else {
      this.NewSession = 'Edit Session'
      this.step = 1
      this.editDisableDate = this.timeSlotData.inputBoxData
      this.dashboardService.editStepFalg = {
        step1: true,
        step2: false,
        step3: false
      }
      this.backEditFlag = true
      // console.log(this.timeSlotData.inputBoxData, 'dshdsjdskasdjka')
    }

  }
  moveToHomePage() {
    this.router.navigate(['/dashboard'])
  }
  toggleFlag: boolean = false
  tempCount: any = 0
  outSideClickHandle(event: any) {
    if (event) {
      if (this.toggleFlag) {
        if (this.tempCount == 1) {
          this.toggleFlag = false
          this.tempCount = 0
        }
        else
          this.tempCount = 1
      }
    }
  }
  goToAccountSetting() {
    this.toggleFlag = false
    this.router.navigate(['/accountSetting'])
  }
  goToMyGame() {
    this.dataService.myGames = null
    this.router.navigate(['myGames'])
  }
  goGameReport() {
    this.dashboardService.archiveFlag = true
    this.router.navigate(['/scheduled'])
  }
  goToTCPage() {
    this.router.navigate(['/t&c'])
  }
  logout() {
    this.router.navigate(['/login'])
    // this.httpSv.logOut().subscribe((res: any) => {
    //   this.router.navigate(['/login'])
    // })
  }
  toggleFunc() {
    this.toggleFlag = !this.toggleFlag
    if (!this.toggleFlag)
      this.tempCount = 0
  }
  checkSubset(parentArray: any, subsetArray: any) {
    // console.log(parentArray, subsetArray)
    return subsetArray.every((el: any) => {
      return parentArray.includes(el)
    })
  }
}









@Component({
  selector: 'app-delete-scheduler',
  templateUrl: './deleteConfim_dialog.html',
})
export class DeleteSessionConfirmDialog implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DeleteSessionConfirmDialog>,
  ) { }
  ngOnInit(): void {

  }
  close(action: boolean) {
    this.dialogRef.close(action)
  }
}
@Component({
  selector: '',
  templateUrl: './current_slot_alert_dialog.html',
})
export class CurrentSlotAlertDialog implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<CurrentSlotAlertDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
  ngOnInit(): void {

  }
  close() {
    this.dialogRef.close()
  }
}




@Component({
  selector: '',
  templateUrl: './license_info_dialog.html',
  encapsulation: ViewEncapsulation.None
})
export class LicenseInfoDialog implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<LicenseInfoDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog
  ) { }
  ngOnInit(): void {

  }
  ngAfterContentInit(): void {
    //Called after ngOnInit when the component's or directive's content has been initialized.
    //Add 'implements AfterContentInit' to the class.

  }

  close(flag: boolean) {
    this.dialogRef.close(flag)
  }
}




@Component({
  selector: 'terms_conditions_dialog',
  templateUrl: './terms_conditions_dialog.html',
  encapsulation: ViewEncapsulation.None
})
export class TermsConditionsDialog implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<TermsConditionsDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
  ngOnInit(): void {

  }
  close() {
    this.dialogRef.close()
  }
}