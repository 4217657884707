<div class="wh_component_div">
    <div style="flex: 1;">
        <!-- <div class="step-main-div" *ngIf="steperFlag">
            <div>STEP 1</div>
            <div>STEP 2</div>
            <div class="step-active-css">STEP 3</div>
        </div> -->
        <div class="wh_header_main_div">
            <div>
                <div style="font-weight: 600;font-size: 22px;">Game set-up</div>
                <div>Please review the game details before you finish the setup !</div>
            </div>
            <div class="wh_header_right_sub">
                <div *ngIf="timerFlag">
                    <span>Time left to complete the set-up </span>
                    <span>{{('0'+remainingMinutes).slice(-2)}}:{{('0'+remainingSeconds).slice(-2)}}</span>
                </div>
                <div><img src="../../../assets/profile.png" alt="" *ngIf="!showSideOrder" (click)="toggleFunc()"
                        style="cursor: pointer;"></div>
            </div>
            <div class="header-drowdwon" *ngIf="toggleFlag" (appOutsideClick)="outSideClickHandle($event)">
                <div class="header-drowdwon-content" (click)="goToAccountSetting()">
                    My Profile
                </div>
                <div class="header-drowdwon-content" (click)="goToMyGame()">
                    My Games
                </div>
                <div class="header-drowdwon-content" (click)="goGameReport()">
                    Games Reports
                </div>
                <div class="header-drowdwon-content" (click)="goToTCPage()">
                    Terms and conditions
                </div>
                <div class="header-drowdwon-content" (click)="logout()">
                    Logout
                </div>
            </div>
        </div>
        <div class="config_main_div">
            <div class="config_header_div">
                <div><img src="../../../assets/img/explore_wh.svg" alt=""></div>
            </div>
            <div class="config_body_main_div">
                <div class="config_left_main_div">
                    <div class="ele_main_div">
                        <span>No of players
                            <img placement="bottom" [ngbTooltip]="noOfPlayersTooltip" tooltipClass="tooltip"
                                src="../../../assets/img/tooltip.svg" style="margin-left: 5px;">
                        </span>
                        <span>:</span>
                        <span class="pl_10">{{maxPlayerData}}</span>
                    </div>
                    <div class="ele_main_div">
                        <span>Player in a teams
                            <img placement="bottom" [ngbTooltip]="playerInTeamsTooltip" tooltipClass="tooltip"
                                src="../../../assets/img/tooltip.svg" style="margin-left: 5px;">
                        </span>
                        <span>:</span>
                        <span style="display: flex;align-items: flex-start;gap: 10px;">
                            <input type="number" min="1" oninput="javascript: if(this.value == 0) this.value=''"
                                [(ngModel)]="noOfTeamsData" class="ele_input" (change)="fillNoPlyr()"
                                (keydown)="validateNumber($event)" [ngClass]="noOfTeamsData > 10 || noOfTeamsData > maxPlayerData?'colorRedCss':''">
                            <span class="err_ele_span" *ngIf="noOfTeamsData > maxPlayerData">
                                <img src="../../../assets/error_icon.svg" alt="">
                                <span>Players in a team can not be more no of players</span>
                            </span>
                            <span class="err_ele_span" *ngIf="noOfTeamsData > 10 && noOfTeamsData <= maxPlayerData">
                                <img src="../../../assets/error_icon.svg" alt="">
                                <span>Players in a team can not be more than 10</span>
                            </span>
                            <span class="err_ele_span" *ngIf="perTeamPlyData > 20 && noOfTeamsData < 10 && noOfTeamsData <= maxPlayerData">
                                <img src="../../../assets/error_icon.svg" alt="">
                                <span>No of team can not be more than 20</span>
                            </span>
                        </span>
                    </div>
                    <div class="ele_main_div">
                        <span>Number of teams
                            <img placement="bottom" [ngbTooltip]="noOfTeamsTooltip" tooltipClass="tooltip"
                                src="../../../assets/img/tooltip.svg" style="margin-left: 5px;">
                        </span>
                        <span>:</span>
                        <span class="pl_10">{{perTeamPlyData}}</span>
                    </div>
                    <div class="ele_main_div">
                        <span>Warehouse Theme
                            <img placement="bottom" [ngbTooltip]="warehouseThemeTooltip" tooltipClass="tooltip"
                                src="../../../assets/img/tooltip.svg" style="margin-left: 5px;">
                        </span>
                        <span>&nbsp;</span>
                        <span style="position: relative;" (appOutsideClick)="outSideClickHandleTheame($event)">
                            <div class="date_time_div custom-select" (click)="toggle()"
                                style="padding: 5px 14px;height: 34px;">
                                {{selectedTheme}}
                                <div style="position: absolute;right: 13px;top: 36%;"><img
                                        src="../../../assets/arrow-down-339-svgrepo-com.svg" alt=""
                                        style="width: 20px;"></div>
                            </div>
                            <div class="drop_down_type_main_div" *ngIf="dropDwonFlag">
                                <div class="theame_drop_down_ele_div"
                                    [ngClass]="selectedTheme == 'Computer Hardware'?'theameSelectedCss':''">
                                    <div class="theame_drop_down_ele_div_wrap" (mouseenter)="onHover('com')"
                                        (mouseleave)="onHover('removed')"
                                        (click)="handleTheameChange({value:'Computer Hardware'})">
                                        <div class="theame_drop_down_ele_div_sub_wrap">
                                            <div class="ele_div">
                                                <li>
                                                    Computer Hardware
                                                </li>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="hover_main_div" *ngIf="hoverFlag.com">
                                        <div class="hover_sub_div">
                                            <img src="../../../assets/img/warehouse/mobilePhone.svg" alt="">
                                            <img src="../../../assets/img/warehouse/server.svg" alt="">
                                            <img src="../../../assets/img/warehouse/computer.svg" alt="">
                                            <img src="../../../assets/img/warehouse/laptop.svg" alt="">
                                        </div>
                                    </div>
                                </div>
                                <div class="theame_drop_down_ele_div"
                                    [ngClass]="selectedTheme == 'Snacks & Beverages'?'theameSelectedCss':''">
                                    <div class="theame_drop_down_ele_div_wrap" (mouseenter)="onHover('snack')"
                                        (mouseleave)="onHover('removed')"
                                        (click)="handleTheameChange({value:'Snacks & Beverages'})">
                                        <div class="theame_drop_down_ele_div_sub_wrap">
                                            <div class="ele_div">
                                                <li>
                                                    Snacks & Beverages
                                                </li>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="hover_main_div" *ngIf="hoverFlag.snack">
                                        <div class="hover_sub_div">
                                            <img src="../../../assets/img/warehouse/noodles.svg" alt="">
                                            <img src="../../../assets/img/warehouse/juce.svg" alt="">
                                            <img src="../../../assets/img/warehouse/pop.svg" alt="">
                                            <img src="../../../assets/img/warehouse/choco.svg" alt="">
                                            <img src="../../../assets/img/warehouse/ice.svg" alt="">
                                        </div>
                                    </div>
                                </div>
                                <div class="theame_drop_down_ele_div"
                                    [ngClass]="selectedTheme == 'Groceries'?'theameSelectedCss':''">
                                    <div class="theame_drop_down_ele_div_wrap" (mouseenter)="onHover('groceries')"
                                        (mouseleave)="onHover('removed')"
                                        (click)="handleTheameChange({value:'Groceries'})">
                                        <div class="theame_drop_down_ele_div_sub_wrap">
                                            <div class="ele_div">
                                                <li>
                                                    Groceries
                                                </li>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="hover_main_div" *ngIf="hoverFlag.groceries">
                                        <div class="hover_sub_div">
                                            <img src="../../../assets/img/warehouse/soap.svg" alt="">
                                            <img src="../../../assets/img/warehouse/shampoo.svg" alt="">
                                            <img src="../../../assets/img/warehouse/wheat.svg" alt="">
                                            <img src="../../../assets/img/warehouse/salt.svg" alt="">
                                            <img src="../../../assets/img/warehouse/broom.svg" alt="">
                                        </div>
                                    </div>
                                </div>
                                <div class="theame_drop_down_ele_div"
                                    [ngClass]="selectedTheme == 'Apparels & Accessories'?'theameSelectedCss':''">
                                    <div class="theame_drop_down_ele_div_wrap" (mouseenter)="onHover('apparels')"
                                        (mouseleave)="onHover('removed')"
                                        (click)="handleTheameChange({value:'Apparels & Accessories'})">
                                        <div class="theame_drop_down_ele_div_sub_wrap">
                                            <div class="ele_div">
                                                <li>
                                                    Apparels & Accessories
                                                </li>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="hover_main_div" *ngIf="hoverFlag.apparels">
                                        <div class="hover_sub_div">
                                            <img src="../../../assets/img/warehouse/shirt.svg" alt="">
                                            <img src="../../../assets/img/warehouse/dress.svg" alt="">
                                            <img src="../../../assets/img/warehouse/belt.svg" alt="">
                                            <img src="../../../assets/img/warehouse/shoe.svg" alt="">
                                            <img src="../../../assets/img/warehouse/socks.svg" alt="">
                                        </div>
                                    </div>
                                </div>
                                <div class="theame_drop_down_ele_div"
                                    [ngClass]="selectedTheme == 'Vehicles'?'theameSelectedCss':''">
                                    <div class="theame_drop_down_ele_div_wrap" (mouseenter)="onHover('vechicle')"
                                        (mouseleave)="onHover('removed')"
                                        (click)="handleTheameChange({value:'Vehicles'})">
                                        <div class="theame_drop_down_ele_div_sub_wrap">
                                            <div class="ele_div">
                                                <li>
                                                    Vehicles
                                                </li>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="hover_main_div" *ngIf="hoverFlag.vechicle">
                                        <div class="hover_sub_div">
                                            <img src="../../../assets/img/warehouse/truck.svg" alt="">
                                            <img src="../../../assets/img/warehouse/bike.svg" alt="">
                                            <img src="../../../assets/img/warehouse/scooter.svg" alt="">
                                            <img src="../../../assets/img/warehouse/car.svg" alt="">
                                            <img src="../../../assets/img/warehouse/jeep.svg" alt="">
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </span>
                    </div>
                    <div class="theame_dtls_main_div">
                        <div *ngFor="let item of selectedThemeGame" class="themeDtl">
                            <div>{{item.type}}</div>
                            <div>:</div>
                            <div *ngFor="let element of item.items" class="themeDtlSubDiv">
                                <div><img [src]="element.src" alt="" srcset=""
                                        style="max-width: 30px; max-height: 30px;"></div>
                                <div>{{element.name}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="ele_main_div">
                        <span>No. of rounds
                            <img placement="bottom" [ngbTooltip]="noOfRoundsTooltip" tooltipClass="tooltip"
                                src="../../../assets/img/tooltip.svg" style="margin-left: 5px;">
                        </span>
                        <span>&nbsp;</span>
                        <span>
                            <select name="noOfRound" id="" [(ngModel)]="totalOrder"
                                (change)="handleNoOfRound($event.target)" class="form-select custom-select"
                                style="width: 5rem;height: 31px;    padding: 3px 14px;">
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                            </select>
                        </span>
                    </div>
                    <div class="ele_main_div">
                        <span>Game time (Mins)
                            <img placement="bottom" [ngbTooltip]="gameTimeTooltip" tooltipClass="tooltip"
                                src="../../../assets/img/tooltip.svg" style="margin-left: 5px;">
                        </span>
                        <span>&nbsp;</span>
                        <span>
                            <input type="number" min="1" oninput="javascript: if(this.value == 0) this.value=''"
                                [(ngModel)]="gameTimeData" class="ele_input" (keydown)="validateNumber($event)"
                                [ngClass]="gameTimeData >= maxDurationBySlots?'colorRedCss':''">
                            <span class="err_ele_span" style="margin-top: 10px;"
                                *ngIf="gameTimeData >= maxDurationBySlots">
                                <img src="../../../assets/error_icon.svg" alt="">
                                <span>Game time should be less than session duration</span>
                            </span>
                        </span>
                    </div>
                </div>
                <div class="config_right_main_div">
                    <div class="config_right_sub_div">
                        <div>
                            <span class="config_right_heading">Target Orders</span>
                            <img src="../../../assets/img/tooltip.svg" style="cursor: pointer;"
                                (click)="showSideOrder = true">
                        </div>
                        <div>
                            <div class="grid-3" *ngFor="let item of noOfRoundEmptyArray;let i = index">
                                <div>Round {{i+1}} :</div>
                                <div>
                                    <select name="" id="" class="form-select round-select"
                                        [(ngModel)]="roundData[i]['value']"
                                        (change)="handleLevelChange($event.target,i)">
                                        <option value="Simple1" selected>Simple | Order 1</option>
                                        <option value="Simple2" selected>Simple | Order 2</option>
                                        <option value="Advanced1">Advanced | Order 1</option>
                                        <option value="Advanced2">Advanced | Order 2</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div>
            <div class="last-btn-div" style="text-align: center; margin-top: 10px;">
                <input style="width: 27%; max-width: 200px" type="image" src="../../../assets/img/save&conti-btn.svg"
                    (click)="saveWareHouseConfig()">
            </div>
        </div>

    </div>
    <div class="order_side_div" *ngIf="showSideOrder">
        <div><img src="../../../assets/close-circle-svgrepo-com.svg" alt="" style="width: 35px;cursor: pointer;"
                (click)="showSideOrder = false"></div>
        <div style="margin-top: 1rem;" class="sideOrderHeading">
            <span>{{selectedTheme}}</span><span> - Target order</span>
        </div>
        <div class="">
            <div style="width:100% ;">
                <div>
                    <div class="order-number">
                        Simple | Order 1
                    </div>
                    <div class="order-content" *ngIf="selectedTheme=='Computer Hardware'">
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">15 </span> Hand held devices in <span
                                        style="font-weight: 600;"> 3</span> colors </li>
                            </ul>
                        </div>
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">10 </span> Servers in <span
                                        style="font-weight: 600;">
                                        2</span> colors </li>
                            </ul>
                        </div>
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">15 </span> Personal Computers in <span
                                        style="font-weight: 600;"> 3</span> colors </li>
                            </ul>
                        </div>
                    </div>
                    <div class="order-content" *ngIf="selectedTheme=='Vehicles'">
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">15 </span> Two Wheelers in <span
                                        style="font-weight: 600;"> 3</span> colors </li>
                            </ul>
                        </div>
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">10 </span> Trucks in <span
                                        style="font-weight: 600;">
                                        2</span> colors </li>
                            </ul>
                        </div>
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">15 </span> Four Wheelers in <span
                                        style="font-weight: 600;"> 3</span> colors </li>
                            </ul>
                        </div>
                    </div>
                    <div class="order-content" *ngIf="selectedTheme=='Groceries'">
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">15 </span> Staple Food items in <span
                                        style="font-weight: 600;"> 3</span> colors </li>
                            </ul>
                        </div>
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">10 </span> Homecare items in <span
                                        style="font-weight: 600;"> 2</span> colors </li>
                            </ul>
                        </div>
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">15 </span> Personal care items in <span
                                        style="font-weight: 600;"> 3</span> colors </li>
                            </ul>
                        </div>
                    </div>
                    <div class="order-content" *ngIf="selectedTheme=='Snacks & Beverages'">
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">15 </span> Desserts in <span
                                        style="font-weight: 600;">
                                        3</span> colors </li>
                            </ul>
                        </div>
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">10 </span> FastFood in <span
                                        style="font-weight: 600;">
                                        2</span> colors </li>
                            </ul>
                        </div>
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">15 </span> Snacks in <span
                                        style="font-weight: 600;">
                                        3</span> colors </li>
                            </ul>
                        </div>
                    </div>
                    <div class="order-content" *ngIf="selectedTheme=='Apparels & Accessories'">
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">15 </span> Footwares in <span
                                        style="font-weight: 600;"> 3</span> colors </li>
                            </ul>
                        </div>
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">10 </span> Accessories in <span
                                        style="font-weight: 600;"> 2</span> colors </li>
                            </ul>
                        </div>
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">15 </span> Clothing in <span
                                        style="font-weight: 600;">
                                        3</span> colors </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="order-number">
                        Simple | Order 2
                    </div>
                    <div class="order-content" *ngIf="selectedTheme=='Computer Hardware'">
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">34 </span> Hand held devices in <span
                                        style="font-weight: 600;"> 3</span> colors </li>
                            </ul>
                        </div>
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">15 </span> Servers in red,black and green</li>
                            </ul>
                        </div>
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">19 </span> Personal Computers in <span
                                        style="font-weight: 600;"> 4</span> colors </li>
                            </ul>
                        </div>
                    </div>
                    <div class="order-content" *ngIf="selectedTheme=='Vehicles'">
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">34 </span> Two Wheelers in <span
                                        style="font-weight: 600;"> 3</span> colors </li>
                            </ul>
                        </div>
                    </div>
                    <div class="order-content" *ngIf="selectedTheme=='Vehicles'">
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">15 </span> Trucks in red,black and green
                                </li>
                            </ul>
                        </div>
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">19 </span> Four Wheelers in <span
                                        style="font-weight: 600;"> 4</span> colors </li>
                            </ul>
                        </div>
                    </div>
                    <div class="order-content" *ngIf="selectedTheme=='Groceries'">
                        <div class="content-txt">
                            <ul class="order_ul">

                                <li> <span style="font-weight: 600;">34 </span> Staple Food in <span
                                        style="font-weight: 600;"> 3</span> colors </li>
                            </ul>
                        </div>
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">15 </span> Brooms in red,black and green</li>
                            </ul>
                        </div>
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">19 </span> Personal Care items in <span
                                        style="font-weight: 600;"> 4</span> colors </li>
                            </ul>
                        </div>
                    </div>
                    <div class="order-content" *ngIf="selectedTheme=='Snacks & Beverages'">
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">34 </span> Desserts in <span
                                        style="font-weight: 600;">
                                        3</span> colors </li>
                            </ul>
                        </div>
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">15 </span> Noodles in red,black and green</li>
                            </ul>
                        </div>
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">19 </span> Snacks in <span
                                        style="font-weight: 600;">
                                        4</span> colors </li>
                            </ul>
                        </div>
                    </div>
                    <div class="order-content" *ngIf="selectedTheme=='Apparels & Accessories'">
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">34 </span> Footwares in <span
                                        style="font-weight: 600;"> 3</span> colors </li>
                            </ul>
                        </div>
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">15 </span> Belts in red,black and green</li>
                            </ul>
                        </div>
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">19 </span> Clothing in <span
                                        style="font-weight: 600;">
                                        4</span> colors </li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
            <div style="width:100% ;">
                <div>
                    <div class="order-number">
                        Advanced | Order 1
                    </div>
                    <div class="order-content" *ngIf="selectedTheme=='Computer Hardware'">
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">12 </span> mobiles in <span
                                        style="font-weight: 600;">
                                        3</span> colors </li>
                            </ul>
                        </div>
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">10 </span> tablets in blue and green color </li>
                            </ul>
                        </div>
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">29 </span> personal computers in Red , blue
                                    and
                                    green</li>
                            </ul>
                        </div>
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">12 </span> Servers in <span
                                        style="font-weight: 600;">
                                        4 </span> colors </li>
                            </ul>
                        </div>
                    </div>
                    <div class="order-content" *ngIf="selectedTheme=='Vehicles'">
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">12 </span> Motorcycles in <span
                                        style="font-weight: 600;">
                                        3</span> colors </li>
                            </ul>
                        </div>
                        <div class="content-txt">
                            <li> <span style="font-weight: 600;">10 </span> Scooters in blue and green color
                        </div>
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">29 </span> Four Wheelers in Red , blue and
                                    green</li>
                            </ul>
                        </div>
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">12 </span> Trucks in <span
                                        style="font-weight: 600;"> 4
                                    </span> colors </li>
                            </ul>
                        </div>
                    </div>
                    <div class="order-content" *ngIf="selectedTheme=='Groceries'">
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">12 </span> Salt Box in <span
                                        style="font-weight: 600;">
                                        3</span> colors </li>
                            </ul>
                        </div>
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">10 </span> Wheat Bags in blue and green
                                    color</li>
                            </ul>
                        </div>
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">29 </span> personal Care in Red , blue and
                                    green</li>
                            </ul>
                        </div>
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">12 </span> Homecare items in <span
                                        style="font-weight: 600;"> 4 </span> colors </li>
                            </ul>
                        </div>
                    </div>
                    <div class="order-content" *ngIf="selectedTheme=='Snacks & Beverages'">
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">12 </span> Chocolate in <span
                                        style="font-weight: 600;">
                                        3</span> colors </li>
                            </ul>
                        </div>
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">10 </span> ice cream cone in blue and green
                                    color</li>
                            </ul>
                        </div>
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">29 </span> Snacks in Red , blue and green</li>
                            </ul>
                        </div>
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">12 </span> Noodles in <span
                                        style="font-weight: 600;">
                                        4 </span> colors </li>
                            </ul>
                        </div>
                    </div>
                    <div class="order-content" *ngIf="selectedTheme=='Apparels & Accessories'">
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">12 </span> Shoes in <span
                                        style="font-weight: 600;">
                                        3</span> colors </li>
                            </ul>
                        </div>
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">10 </span> Socks in blue and green color</li>
                            </ul>
                        </div>
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">29 </span> Clothing in Red , blue and green</li>
                            </ul>
                        </div>
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">12 </span> Accessories in <span
                                        style="font-weight: 600;">
                                        4 </span> colors </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="order-number">
                        Advanced | Order 2
                    </div>
                    <div class="order-content" *ngIf="selectedTheme=='Computer Hardware'">
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">10 </span> laptops in blue, green and red
                                    colors</li>
                            </ul>
                        </div>
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">12 </span> desktops in black and blue
                                    colors</li>
                            </ul>
                        </div>
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">17 </span> Servers in <span
                                        style="font-weight: 600;">
                                        3 </span> colors </li>
                            </ul>
                        </div>
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">27 </span> handheld devices in <span
                                        style="font-weight: 600;"> 3 </span> colors </li>
                            </ul>
                        </div>
                    </div>
                    <div class="order-content" *ngIf="selectedTheme=='Vehicles'">
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">10 </span> Cars in blue, green and red
                                    colors</li>
                            </ul>
                        </div>
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">12 </span> Jeeps in black and blue colors</li>
                            </ul>
                        </div>
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">17 </span> Trucks in <span
                                        style="font-weight: 600;"> 3
                                    </span> colors </li>
                            </ul>
                        </div>
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">27 </span> Two Wheelers in <span
                                        style="font-weight: 600;">
                                        3 </span> colors </li>
                            </ul>
                        </div>
                    </div>
                    <div class="order-content" *ngIf="selectedTheme=='Groceries'">
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">10 </span> Soaps in blue, green and red
                                    colors</li>
                            </ul>
                        </div>
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">12 </span> Shampoos in black and blue
                                    colors</li>
                            </ul>
                        </div>
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">17 </span> Brooms in <span
                                        style="font-weight: 600;"> 3
                                    </span> colors </li>
                            </ul>
                        </div>
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">27 </span> Staple Food in <span
                                        style="font-weight: 600;">
                                        3 </span> colors </li>
                            </ul>
                        </div>
                    </div>
                    <div class="order-content" *ngIf="selectedTheme=='Snacks & Beverages'">
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">10 </span> soft drinks in blue, green and
                                    red
                                    colors</li>
                            </ul>
                        </div>
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">12 </span> popcorns in black and blue
                                    colors</li>
                            </ul>
                        </div>
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">17 </span> Noodles in <span
                                        style="font-weight: 600;">
                                        3 </span> colors </li>
                            </ul>
                        </div>
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">27 </span> desserts in <span
                                        style="font-weight: 600;">
                                        3 </span> colors </li>
                            </ul>
                        </div>
                    </div>
                    <div class="order-content" *ngIf="selectedTheme=='Apparels & Accessories'">
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">10 </span> Shirts in blue, green and red
                                    colors</li>
                            </ul>
                        </div>
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">12 </span> Dresses in black and blue colors</li>
                            </ul>
                        </div>
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">17 </span> Belts in <span
                                        style="font-weight: 600;"> 3
                                    </span> colors </li>
                            </ul>
                        </div>
                        <div class="content-txt">
                            <ul class="order_ul">
                                <li> <span style="font-weight: 600;">27 </span> Footwears in <span
                                        style="font-weight: 600;"> 3
                                    </span> colors </li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>


























<style>
    .step-main-div {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 205px;
    }

    .step-main-div div {
        padding: 10px 30px;
        font-size: 18px;
        background-color: #e4e4e4;
    }

    .step-main-div div:first-of-type {
        border-bottom-left-radius: 15px;
    }

    .step-main-div div:last-of-type {
        border-bottom-right-radius: 15px;
    }

    .step-active-css {
        border-top: 3px solid #30E593;
        background-color: #ffffff !important;
    }

    .disabledInput {
        background-color: #dddddd !important;
    }

    .header-drowdwon {
        position: absolute;
        right: 2px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border-radius: 0px 0px 11px 11px;
        opacity: 1;
        border-top: none;
        z-index: 1111;
        box-shadow: 0px 3px 6px #00000029;
    }

    .header-drowdwon-content {
        padding: 5px 15px;
        cursor: pointer;
    }

    .header-drowdwon-content:hover {
        background-color: #C6E8C9;
    }

    .header-drowdwon-content:last-of-type:hover {
        border-radius: 0px 0px 11px 11px;
    }

    .drop_down_type_main_div {
        position: absolute;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 10px;
        width: 18vw;
    }

    .theame_drop_down_ele_div {
        display: flex;
        height: 37px;
    }

    .theame_drop_down_ele_div_wrap {
        position: relative;
        min-width: 18vw;
        padding: 5px;
        cursor: pointer;
    }

    .theame_drop_down_ele_div_sub_wrap {
        margin-left: 12px;
    }


    .hover_main_div {
        padding: 8px;
        width: 18vw;
        text-align: center;
        background: #ADC4FD 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        height: 37px;
    }

    .hover_sub_div {
        display: flex;
        gap: 20px;
    }

    .hover_sub_div img {
        width: 22px;
        height: 22px;
    }

    .theameSelectedCss {
        background: #c8d6f7 0% 0% no-repeat padding-box;
    }
</style>