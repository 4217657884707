<div style="height: 100vh; display: flex;flex-direction: column; position: relative;">
    <app-header [oneHeader]='DigitalGames' [adminFlag]="true"></app-header>
    <div class="game_content_container" [ngClass]="{'publishActiveHCss': publishFlag}">
        <div class="game-content" [ngClass]="{'lightBgColor': !addFileFlag}">
            <div class="content_left">
                <div class="back_button">
                    <img style="height: 34px; cursor:pointer;"
                        src="../../../../assets/img/arrow_back_ios_FILL0_wght400_GRAD0_opsz48.svg" (click)="back()" />
                    <span>{{currentGame.game_name}}</span>
                </div>
                <div class="gmae_desc_heading">Game Description</div>
                <div class="game_desc">
                    <div style="overflow-y: auto;height: 100%;">
                        <textarea
                            style="width: 100%;height: 98%;padding: 10px 10px 0 10px;border: none;outline: none;border-radius: 10px;"
                            placeholder="Enter game description…" [(ngModel)]="description"></textarea>
                    </div>
                </div>
            </div>
            <div class="content_right">
                <div style="display:flex; width:100%">
                    <div class="legend_list" (cdkDropListDropped)="drop($event)" cdkDropList>
                        <div class="legend" *ngFor="let legend of legendsDataArray; index as i"
                            cdkDragBoundary=".legend_list" cdkDrag>
                            <img style="height:20px; width:20; margin-top:15px;cursor: all-scroll;"
                                src="../../../../assets/3_line_btn.svg" alt="" cdkDragHandle>
                            <div class="legend_container">
                                <div class="legend_header" (click)="addFileToLegend(legend)">
                                    <span>{{legend.name}}</span>
                                </div>
                                <div class="legend_body" (cdkDropListDropped)="drop1($event,i)" cdkDropList
                                    *ngIf="legend.files.length > 0">
                                    <div style="display: flex; justify-content:space-between; margin:0x 5px; align-items:center;"
                                        *ngFor="let item of legend.files; index as resource_index"
                                        cdkDragBoundary=".legend_body" cdkDrag>
                                        <div class="container">
                                            <img style="height:16px; width:16px;cursor: all-scroll;"
                                                src="../../../../assets/3_line_btn.svg" alt="" cdkDragHandle>
                                            <img style="height:20px;width: 20px;" src="../../../../assets/play_btn.svg"
                                                alt="" *ngIf="item.type=='video'">
                                            <img style="height:20px;width: 20px;" src="../../../../assets/pdf_icon.svg"
                                                alt="" *ngIf="item.type=='pdf'">
                                            <img style="height:20px;width: 20px;" src="../../../../assets/ppt_icon.svg"
                                                alt="" *ngIf="item.type=='ppt'">
                                            <img style="height:20px;width: 20px;" src="../../../../assets/img_icon.svg"
                                                alt="" *ngIf="item.type=='images'">
                                            <div class="text">{{item.name}}</div>
                                        </div>
                                        <div style="display: flex;gap: 10px;">
                                            <img src="../../../../assets/priview_icon.svg " alt=""
                                                style="height: 18px;cursor: pointer;" (click)="preview(item)">
                                            <img style="height:20px; width:25;cursor: pointer;"
                                                src="../../../../assets/delete_btn.svg" alt=""
                                                (click)="deleteFile(legend.id,item.resource_id,i,resource_index)">
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!addFileFlag" style="flex: 1;" class="lightBgColor">&nbsp;</div>
        <div class="preview_container" *ngIf="addFileFlag">
            <add-legend-dialog [data]="passOnData" (dataEmitter)="gameFileDataHandler($event)"></add-legend-dialog>
        </div>

    </div>
    <div *ngIf="publishFlag" class="publish_btn_main_div">
        <button class="btn_custom" (click)="publishContent()">Publish</button>
    </div>



    <div class="file_upload_progress_main_div" *ngIf="showProgressBar">
        <div class="file_upload_progress_sub_div">
            <div style="font-size: 20px;margin-bottom: 0.5rem;">Please hold on, the file is currently being uploaded.</div>
            <div class="file_upload_progress_progress_div">
                <div class="progress" role="progressbar" aria-label="Success striped example" aria-valuenow="75"
                    aria-valuemin="0" aria-valuemax="100" style="flex: 1;">
                    <div class="progress-bar progress-bar-striped  progress-bar-striped  text-bg-success" [style.width]="progressPercent +'%'">{{progressPercent}}%
                    </div>
                </div>
                <div>
                    {{uplodedFileCount < newTotalFileCount ? uplodedFileCount+1 : uplodedFileCount}} of
                        {{newTotalFileCount}} </div>
                </div>
            </div>
        </div>
    </div>