import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, Injectable, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { DataSharingServiceService } from 'src/app/services/data-sharing-service.service';
import { HttpService } from 'src/app/services/http.service';
// import { saveAs } from 'file-saver';
import * as FileSaver from 'file-saver';
import { Location } from '@angular/common';
@Component({
  selector: 'app-game-resources',
  templateUrl: './game-resources.component.html',
  styleUrls: ['./game-resources.component.css']
})
export class GameResourcesComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toster: ToastrService,
    private httpSv: HttpService,
    private changeDetect: ChangeDetectorRef,
    public sanitizer: DomSanitizer,
    private dataShare: DataSharingServiceService,
    private http: HttpClient,
    private _location: Location
  ) { }
  gameType: string = ''
  gallaryFlag: boolean = false
  contentPreview: boolean = false
  ngOnInit(): void {
    if (sessionStorage.getItem('content-preview') == '1') {
      this.contentPreview = true
    }
    let gameDltId: any = null
    this.route.queryParams.subscribe((result: any) => {
      gameDltId = result.id
      if (parseInt(gameDltId) < 7 || parseInt(gameDltId) == 14 || parseInt(gameDltId) == 15) this.gameType = 'digital'
      else if (parseInt(gameDltId) > 7 && parseInt(gameDltId) < 14 || parseInt(gameDltId) > 15 && parseInt(gameDltId) < 20) this.gameType = 'physical'

    })
    this.getAllGameResouceData(gameDltId)
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.contentPreview) {
      sessionStorage.removeItem('content-preview')
    }
  }
  zoomScale: any = 'page-width'
  zoomFlag: boolean = false
  zoom() {
    if (!this.zoomFlag) {
      this.zoomFlag = true
      this.zoomScale = 'page-height'
      setTimeout(() => {
        this.zoomScale = 'page-width'
      }, 0)
    }
    else if (this.zoomFlag) {
      this.zoomFlag = false
      this.zoomScale = 'page-height'
      setTimeout(() => {
        this.zoomScale = 'page-width'
      }, 0)
    }
  }
  gameDtlIndex: any
  spinLoader: boolean = false
  legendsData: any[] = []
  gameName: string = ''
  gameDis: string = ''
  src: any
  fileName: any
  name: any;
  type: any
  allFiles: any
  downloadSrc: any
  goBack() {
    if (this.dataShare.lastUrlForGameResource) {
      this.router.navigate([this.dataShare.lastUrlForGameResource])
      this.dataShare.lastUrlForGameResource = null
    }
    else {
      this.router.navigate(['/exploreGames'])
    }
  }
  tempDownSrc: any

  downloadPPT(src: any) {
    this.tempDownSrc = this.sanitizer.bypassSecurityTrustResourceUrl(src)
    setTimeout(() => {
      this.tempDownSrc = null
    }, 0)
  }
  resourceUrl(link: any) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(link)
  }
  download(obj: any) {

    if (obj.type == 'pdf' || obj.type == 'ppt')
      FileSaver.saveAs(obj.main_resources_file, obj.name + '.' + obj.type)
    else if (obj.type == 'images')
      FileSaver.saveAs(obj.main_resources_file, obj.name + '.jpeg')

  }
  downloadFile(url: any, type: string): void {
    this.httpSv.downloadFile(url).subscribe((data: Blob) => {
      const blob = new Blob([data], { type: type });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'downloaded-file.pdf';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  }
  getSrc(link: any): SafeResourceUrl {
    link = 'https://playkairos.s3.ap-south-1.amazonaws.com/resources/2xqbpQv3yBQ6gDvkdJ6gzKH87xK3TEOVD7snSW6W.pptx?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAT2IRMJOFV6FHB7MT%2F20240226%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20240226T051831Z&X-Amz-SignedHeaders=host&X-Amz-Expires=3600&X-Amz-Signature=6dd537b3c10d99aa42472412127c03038b62c0cb90983df101aa8e770c00c5da';
    const embeddedLink = `${this.sanitizer.sanitize(SecurityContext.URL, link)}&embedded=true`;
    return this.sanitizer.bypassSecurityTrustResourceUrl(embeddedLink);
  }

  currentResouce: any = {
    html_ppt_url: null,
    is_downloadable: 0,
    main_resources_file: null,
    html_resources_file: null,
    name: null,
    position: null,
    resource_id: null,
    type: null
  }
  gallaryImgSrcArray: any[] = []
  handleLegendClick(legend: any) {
    if (legend.name != 'Gallery') {
      legend.toggleFlag = !legend.toggleFlag
    }
    else {
      this.gallaryFlag = true;
      this.gallaryImgSrcArray = legend.resources
    }
  }
  showResouceFile(obj: any, legend: any) {
    this.spinLoader = false
    if (legend.name != 'Gallery') {
      this.gallaryFlag = false
      this.gallaryImgSrcArray = []
    }
    if (this.currentResouce.resource_id == obj.resource_id) return
    if (obj.type == 'pdf' || obj.type == 'ppt') this.spinLoader = true
    this.currentResouce = obj
  }
  imgSliderFlag: boolean = false
  sliderIndex: any = null
  showImgSlider(index: any, data: any) {
    this.imgSliderFlag = true
    this.sliderIndex = index
  }
  slideLeft() {
    if (this.sliderIndex == 0) {
      this.sliderIndex = this.gallaryImgSrcArray.length - 1
    } else {
      this.sliderIndex--
    }
  }
  slideRight() {
    if (this.sliderIndex == this.gallaryImgSrcArray.length - 1) {
      this.sliderIndex = 0
    } else {
      this.sliderIndex++
    }
  }
  resize() {
    this.imgSliderFlag = false
    this.sliderIndex = null
  }
  getAllGameResouceData(id: any) {
    this.httpSv.getGameResouce(id).subscribe((res: any) => {
      if (res['status']) {
        this.gameName = res['results'][0].game_name
        this.gameDis = res['results'][0].description
        this.legendsData = res['results'][0].legends
        this.legendsData.map((ele: any, i: any) => i == 0 ? ele['toggleFlag'] = true : ele['toggleFlag'] = false)
        if (this.legendsData[0].name == 'Gallery') {
          this.gallaryImgSrcArray = this.legendsData[0].resources
          this.gallaryFlag = true
        } else {
          if (this.legendsData[0].resources[0].type == 'pdf' || this.legendsData[0].resources[0].type == 'ppt') this.spinLoader = true
          this.currentResouce = JSON.parse(JSON.stringify(this.legendsData[0].resources[0]))
        }
      }
      else {
        this.toster.info('No Game Resource found', '', { timeOut: 2000 })
        this.goBack()
      }
    }, (err: any) => {
      this.toster.info(err.error.message, '', { timeOut: 2000 })
      this.goBack()
    })
  }
  closePreview() {
    this._location.replaceState('/admin-dashboard')
    this.router.navigate(['/admin-dashboard'])
  }
}
function ViewChild(arg0: string, arg1: { static: boolean; }): (target: GameResourcesComponent, propertyKey: "downloadLink") => void {
  throw new Error('Function not implemented.');
}

