import { filter } from 'rxjs';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DataSharingServiceService } from 'src/app/services/data-sharing-service.service';
import { Location } from '@angular/common';
import { DashboardService } from 'src/app/Shared/Services/Dashboard.service';
import { MatDialog } from '@angular/material/dialog';
import { ReachOutComponent } from '../reach-out/reach-out.component';
import { HttpService } from 'src/app/services/http.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-my-games',
  templateUrl: './my-games.component.html',
  styleUrls: ['./my-games.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MyGamesComponent implements OnInit {

  // selectedExplore: any = 'physical'
  selectedExplore: any = 'digital'
  constructor(
    private titleService: Title,
    private router: Router,
    private dataShare: DataSharingServiceService,
    private dashboardService: DashboardService,
    private dialog: MatDialog,
    private httpSv: HttpService,
    public toster: ToastrService,
    private _location: Location
  ) {
    this.titleService.setTitle('Kairos | MyGames')
  }

  digitalGames = [
    {
      id: 1,
      name: 'Value Continuum',
      people: 50,
      purchased_on: null,
      expiry_date: null
    },
    {
      id: 2,
      name: 'Bottle Neck Challenge',
      people: 50,
      purchased_on: null,
      expiry_date: null
    },
    {
      id: 3,
      name: 'Ware House',
      people: 50,
      purchased_on: null,
      expiry_date: null
    },
    // {
    //   id: 4,
    //   name: 'Picture Perfect',
    //   people: 50,
    //   purchased_on: null,
    //   expiry_date: null
    // },
    {
      id: 5,
      name: 'Animal Farm',
      people: 50,
      purchased_on: null,
      expiry_date: null
    },
    {
      id: 6,
      name: 'Big Picture',
      people: 50,
      purchased_on: null,
      expiry_date: null
    },
    {
      id: 14,
      name: 'Questico 1',
      people: 50,
      purchased_on: null,
      expiry_date: null
    },
    {
      id: 15,
      name: 'Questico 2',
      people: 50,
      purchased_on: null,
      expiry_date: null
    },
  ];
  digitalGamesAllowed: any[] = []
  pyhsicalGames: any[] = [
    {
      id: 8,
      name: 'Human Matrix',
      gameKit: null,
      purchased_on: null,
      expiry_date: null
    },
    {
      id: 9,
      name: 'String Theory',
      gameKit: null,
      purchased_on: null,
      expiry_date: null
    },
    {
      id: 10,
      name: 'Stromshelter',
      gameKit: null,
      purchased_on: null,
      expiry_date: null
    },
    {
      id: 11,
      name: 'Assemblyline',
      gameKit: null,
      purchased_on: null,
      expiry_date: null
    },
    {
      id: 12,
      name: 'Magic Mats',
      gameKit: null,
      purchased_on: null,
      expiry_date: null
    },
    {
      id: 13,
      name: 'Stranded',
      gameKit: null,
      purchased_on: null,
      expiry_date: null
    },
  ];
  pyhsicalGamesAllowed: any[] = []
  adminFlag: boolean = false
  ngOnInit(): void {
    if (sessionStorage.getItem('is_physical')) {
      this.dataShare.myGames = 'physical'
      // this.router.navigate(['/myGames'])
    }
    else if (sessionStorage.getItem('is_digital')) {
      this.dataShare.myGames = 'digital'
      // this.router.navigate(['/myGames'])
    }
    this.getAllwedGamesList()
    if (this.dataShare.myGames) {
      this.selectedExplore = this.dataShare.myGames
      this.dataShare.myGames = null
    }
    if (sessionStorage.getItem('role')) {
      if (sessionStorage.getItem('role') == '1' || sessionStorage.getItem('role') == '2') {
        this.adminFlag = true
      }
    }
    this.selectedExplore == 'digital' ? this.getGameAccessList(1) : this.getGameAccessList(2)
  }
  selectedExploreGame(data: any) {
    sessionStorage.removeItem('is_digital')
    sessionStorage.removeItem('is_physical')
    if (data == this.selectedExplore) return
    else if (data == 'digital') {
      sessionStorage.setItem('is_digital', 'true')
      this.getGameAccessList(1)
    }
    else if (data == 'physical') {
      sessionStorage.setItem('is_physical', 'true')
      this.getGameAccessList(2)
    }
    this.selectedExplore = data;
  }

  flagCondition: boolean = false
  errorMessage: string = ''
  backClicked() {
    this.router.navigate(['/dashboard'])
  }
  goToGameReport() {
    this.dashboardService.archiveFlag = true
    this.router.navigate(['/scheduled'])
  }
  openReachOut() {
    const dialogRef = this.dialog.open(ReachOutComponent, {
      panelClass: 'reachout',
      hasBackdrop: true,
    })
  }
  accessGameApiData: any[] = []
  licensesDtls: any = {
    left: null,
    purchased: null,
  }
  validity: any = {
    access_till: null,
    purchased_on: null
  }
  alertDigitalColorCssFlag: any = {
    licenseLow: false,
    expiringSoon: false,
  }
  alertPyColorCssFlag: boolean = false 


  pyEpochEndDate: any;
  totalGameKit: any;
  getGameAccessList(game_type: number) {
    this.digitalGamesAllowed = []
    this.pyhsicalGamesAllowed = []
    this.flagCondition = false
    this.httpSv.getGameAccessList(game_type).subscribe((res: any) => {
      if (res['status']) {
        this.accessGameApiData = res['results'].filter((ele: any) => ele.game_id != 4)
        // this.accessGameApiData.filter((ele:any)=> ele.is_active == 1 && ele.status == 1)
        // this.digitalGamesAllowed = this.digitalGames.filter((ele: any) => this.accessGameApiData.filter((item:any)=> item.game_id == ele.id))

        // common date and future date alert validation 
        let todayDate = new Date()
        todayDate.setHours(0, 0, 0, 0)
        todayDate.setHours(23, 59, 59, 0)
        let tenthFutureDate = new Date(todayDate)
        tenthFutureDate.setDate(todayDate.getDate() + 9)
        if (game_type == 1) {
          this.accessGameApiData.forEach((ele: any) => {
            let temp = this.digitalGames.filter((item: any) => item.id == ele.game_id && ele.game_id != 4)
            if (temp.length !== 0) this.digitalGamesAllowed.push(temp[0])

          })
          if (this.digitalGamesAllowed.length != 0) {
            this.licensesDtls.left = this.accessGameApiData[0].no_of_licenses_left
            this.licensesDtls.purchased = this.accessGameApiData[0].no_of_licenses
            this.validity.access_till = this.epochToHumanDate(this.accessGameApiData[0].end_date)
            this.validity.purchased_on = this.epochToHumanDate(this.accessGameApiData[0].start_date)
          }





          if (this.accessGameApiData.length != 0) {
            let tenPercentageOfLicense = 25
            if (this.accessGameApiData[0].no_of_licenses_left <= tenPercentageOfLicense)
              this.alertDigitalColorCssFlag.licenseLow = true
            if (this.accessGameApiData[0].end_date * 1000 <= tenthFutureDate.getTime())
              this.alertDigitalColorCssFlag.expiringSoon = true
          }
        }
        else if (game_type == 2) {
          this.pyhsicalGamesAllowed = this.accessGameApiData
          let pyExpiredGameAaray: any[] = []

          if ( this.pyhsicalGamesAllowed.filter((ele: any) => ele.is_active == 1)[0].end_date * 1000 <= tenthFutureDate.getTime()) {
            this.alertPyColorCssFlag = true
          }

          this.totalGameKit = this.pyhsicalGamesAllowed.reduce((current:any,ele:any )=>{
            return current += ele.game_kits
          },0)

          this.validity.access_till = this.epochToHumanDate(this.accessGameApiData[0].end_date)
        }
        // console.log(this.digitalGamesAllowed)
      }
    })
  }
  allowedGameId: any[] = []
  getAllwedGamesList() {
    this.httpSv.userAllowedGameType().subscribe((res: any) => {
      if (res['status']) {
        this.allowedGameId = res['results']
        if (this.allowedGameId.length == 1) {
          switch (this.allowedGameId[0]) {
            case 1:
              this.selectedExploreGame('digital')
              break;
            case 2:
              this.selectedExploreGame('physical')
              break;
          }
        }
      } else {
        if (res['message'] == 'No games available') {
          this.toster.warning('Sorry, you currently do not have access to any games.', '', { timeOut: 2000 })
          this._location.back()
        } else
          this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
      }
    }, (err: any) => this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 }))
  }
  epochToHumanDate(epoch_time: number) {
    let date = new Date(epoch_time * 1000)
    let dateTemp = date.toLocaleDateString("en-GB", { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/\//g, '-')
    return dateTemp.split('-').reverse().join('-')
  }
  goToGameResource(id: any, flag: boolean = false) {
    if (!flag) {
      this.dataShare.exploreGameSelection = this.selectedExplore
      this.dataShare.myGames = this.selectedExplore
      this.dataShare.lastUrlForGameResource = 'myGames'
      this.router.navigate(['game/resources'], { queryParams: { id: id } })
    }
  }

}
