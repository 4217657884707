<div class="entire" id="headerMainDiv">
    <div class="logo" (click)="routeLandingPage()" style="cursor: pointer;"><img src="../../assets/kairosName.svg">
    </div>
    <div class="title">
        <div class="three-step-div" *ngIf="first != null">
            <span>{{first}}</span>
            <span class="divider">|</span>
            <span
                [ngClass]="secound=='Digital'?'color-blue':secound == 'Questico'?'yellow-color':secound=='Physical'?'color-green':''">{{secound}}</span>
            <span class="divider">|</span>
            <span style="font-weight: 500;">{{third}}</span>
        </div>
        <div class="single-header" *ngIf="first == null && oneHeader != null">
            <span style="font-size: 18px;font-weight: 500;">{{oneHeader}}</span>
        </div>
    </div>
    <div class="header_left_main_div" >
        <!-- <div class="myGames" style="cursor: pointer;" (click)="goToMyGame()">
            <p>My</p>
            <p> Games</p>
        </div>
        <div class="exploreGames" style="cursor: pointer;" (click)="goToExplore()">
            <p>Explore </p>
            <p>All Games</p>
        </div> -->
        <div>{{username}}</div>
        
        <div class="userProfile" style="cursor: pointer;" (click)="toggleFunc()">
            <img src="../../assets/img/user_profile.svg" style="cursor: pointer;">
        </div>
        <div class="userProfile" style="cursor: pointer;" (click)="notificationToggleFunc()" style="position: relative;" *ngIf="adminFlag && !contentAdminFlag">
            <img src="../../assets/notification-svgrepo-com.svg" style="cursor: pointer;width: 2rem">
            <div *ngIf="notification_count && notification_count > '0'" class="notification_count">{{notification_count}}</div>
        </div>
        <!-- <div class="userProfile" style="cursor: pointer;" (click)="notificationToggleFunc()" style="position: relative;" *ngIf="!contentAdminFlag">
            <img src="../../assets/notification-svgrepo-com.svg" style="cursor: pointer;width: 2rem">
            <div *ngIf="notification_count && notification_count > '0'" class="notification_count">{{notification_count}}</div>
        </div> -->
    </div>

</div>
<div class="header-drowdwon" *ngIf="toggleFlag" (appOutsideClick)="outSideClickHandle($event)">
    <div class="header-drowdwon-content" (click)="goToAccountSetting()">
        My Profile
    </div>
    <div class="header-drowdwon-content" (click)="goToMyGame()" *ngIf="!adminFlag">
        My Games
    </div>
    <div class="header-drowdwon-content" (click)="goGameReport()" *ngIf="!adminFlag">
        Games Reports
    </div>
    <div class="header-drowdwon-content" (click)="goToTCPage()" *ngIf="!adminFlag">
        Terms and conditions
    </div>
    <div class="header-drowdwon-content" (click)="goGameTrack()" *ngIf="adminFlag && !contentAdminFlag">
        Track Games
    </div>
    <div class="header-drowdwon-content" (click)="logout()">
        Logout
    </div>
</div>
<div class="header-drowdwon notification_main_div" *ngIf="notificationToggle" (appOutsideClick)="outSideClickHandleNotification($event)">
    <div *ngIf="allNotificationArr.length > 0">
        <div class="notification_ele_div" *ngFor="let item of allNotificationArr">
            <li>{{item.message}}</li>
        </div>
    </div>
    <div *ngIf="allNotificationArr.length == 0">
        <div class="notification_ele_div">No notification</div>
    </div>
</div>