<app-header oneHeader='Games' [adminFlag]="true"></app-header>
<div class="detailedGamesMainDiv">
    <div class="leftContainer">
        <div class="individualSelections" [ngClass]="{highlightdigital:selectedExplore=='digital'} "
            (click)="selectedExploreGame('digital')">
            <img src="../../../assets/img/digitalGame.svg"
                [ngClass]="{rounded:selectedExplore=='digital',circle:selectedExplore!='digital'} ">
            <b>Digital Games</b>
            <i class="arrow right arrow-digital" *ngIf="selectedExplore=='digital'"></i>
        </div>
        <div class="individualSelections" [ngClass]="{highlightphysical:selectedExplore=='physical'} "
            (click)="selectedExploreGame('physical')">
            <img src="../../../assets/img/physicalGame.svg"
                [ngClass]="{rounded:selectedExplore=='physical',circle:selectedExplore!='physical'} ">
            <b>Physical Games</b>
            <i class="arrow right arrow-physical" *ngIf="selectedExplore=='physical'"></i>
        </div>
    </div>
    <div class="rightContainer_myGame">
        <img (click)="navigateBack()" src="../../../../assets/img/arrow_back_ios_FILL0_wght400_GRAD0_opsz48.svg"
            style="width: 36px;cursor: pointer;margin-left: 15px;margin-top: 10px; position: absolute;" />
        <div class="digital_game_main_div" *ngIf="selectedExplore=='digital'">
            <div class="myGames myGamesDis">
                <div class="myGame_img_div" *ngFor="let game of digitalGamesAllowed"
                    (click)="goToGameResource(game.id)">
                    <img src="../../../../assets/img/myGames/vc.png" *ngIf="game.name=='Value Continuum'">
                    <img src="../../../../assets/bn_one_line.svg" *ngIf="game.name=='Bottle Neck Challenge'">
                    <img src="../../../../assets/img/explore_wh.svg" *ngIf="game.name=='Ware House'">
                    <img src="../../../../assets/img/myGames/af.png" *ngIf="game.name=='Animal Farm'">
                    <img src="../../../../assets/img/myGames/bp.png" *ngIf="game.name=='Big Picture'">
                    <img src="../../../../assets/img/Picture_Perfect_Logo.png" *ngIf="game.name=='Picture Perfect'">
                    <img src="../../../../assets/great_ocean_kingdom_logo.svg" *ngIf="game.id=='14'">
                    <img src="../../../../assets/people_of_world_logo.svg" *ngIf="game.id=='15'">
                </div>
            </div>
        </div>
        <div class="digital_game_main_div" *ngIf="selectedExplore=='physical'">
            <div class="myGames myGamesDis">
                <div class="myGame_img_div" *ngFor="let item of physicalGamesAllowed"
                    (click)="goToGameResource(item.game_id)">
                    <img src="../../../../assets/img/myGames/human_matrix.svg" alt="" *ngIf="item.game_id == 8">
                    <img src="../../../../assets/img/string_therory.png" alt="" *ngIf="item.game_id == 9">
                    <img src="../../../../assets/img/strom_selter.png" alt="" *ngIf="item.game_id == 10">
                    <img src="../../../../assets/img/assebembly_line.png" alt="" *ngIf="item.game_id == 11">
                    <img src="../../../../assets/img/magic_mats.png" alt="" *ngIf="item.game_id == 12">
                    <img src="../../../../assets/img/stranded.png" alt="" *ngIf="item.game_id == 13">
                    <img src="../../../../assets/friction_action_game.png" alt="" *ngIf="item.game_id == 16">
                    <img src="../../../../assets/lean_up_game.png" alt="" *ngIf="item.game_id == 17">
                    <img src="../../../../assets/traficjam_game.png" alt="" *ngIf="item.game_id == 18">
                    <img src="../../../../assets/vc_game_py.svg" alt="" *ngIf="item.game_id == 19">
                </div>
            </div>
        </div>
    </div>
</div>