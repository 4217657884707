<div class="term_conditon_component_main_div">
    <div class="term_condtion_component_header_main_div">
        <div class="term_condtion_component_header_back_div" (click)="goBack()"><img src="../../../assets/img/arrow_back_ios_FILL0_wght400_GRAD0_opsz48.svg" alt=""></div>
        <div class="term_condtion_component_header">
            Terms & Conditions
        </div>
    </div>

    <div class="term_condtion_content_component_main_div">
        <div class="term_condtion_component_content_sub_div">
            <div class="clause_heading_component">License Deduction:</div>
            <div class="clause_description_component">
                <span class="clause_description_sub_heading_component">Per Player Deduction: </span> 1 license unit is deducted per player per game.
                <br>
                <br>
                <span class="clause_description_sub_heading_component">If the session is completed with the planned number of players: </span> Licenses used = Number of
                players who joined the game.
                <br>
                <br>
                <span class="clause_description_sub_heading_component">If the session is completed with fewer players than planned: </span> Licenses used = The higher of
                either the number of players who joined the game or 50% of the number of players planned.
                <br>
                <br>
                <span class="clause_description_sub_heading_component">Examples:</span>
                <br>
                Planned players = 100, Players who joined = 80  &rarr; Licenses used = 80
                <br>
                Planned players = 100, Players who joined = 43 → Licenses used = 50
            </div>
        </div>
        <div class="term_condtion_content_sub_div_component">
            <div class="clause_heading_component">License Credit for Cancelled Sessions:</div>
            <div class="clause_description_component">
                <span>To receive a full credit of the licenses, the session must be deleted at least 1 hour before the
                    scheduled start time.</span>
                <br>
                <br>
                <span>If a session is scheduled to start immediately (in the current time slot), it cannot be edited or
                    deleted.</span>
            </div>
        </div>
        <div class="term_condtion_content_sub_div_component">
            <div class="clause_heading_component">Partial License Credit for Unused Sessions:</div>
            <div class="clause_description_component">
                <span>If the session is not deleted or has been run, only 50% of the blocked licenses will be credited
                    back.</span>
            </div>
        </div>
    </div>
</div>