import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { Spinkit } from 'ng-http-loader';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { SwUpdate } from '@angular/service-worker';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'Kairos';
  public spinkit = Spinkit;
  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private swUpdate: SwUpdate,
    private dialog: MatDialog
  ) { }
  ngOnInit() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe(() => {
        this.onRouteChange();
      });


    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        setTimeout(()=>{
          const dialogRef = this.dialog.open(NewVersionDialog,{
            disableClose:true,
          })
          dialogRef.afterClosed().subscribe((result:any)=>{
            window.location.reload();
          })
        },0)
      });
    }
    
   
  }
  onRouteChange() {
    if (this.router.url != '/scheduled' && this.router.url != '/sessionArchive')
      sessionStorage.removeItem('filterData')
  }
}


@Component({
  selector: 'new-version-dialog',
  templateUrl: './new-version-dialog.html',
  encapsulation:ViewEncapsulation.None
})
export class NewVersionDialog implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<NewVersionDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }
  ngOnInit(): void {
    this.currentLicense = this.data
  }
  currentLicense:any = ''
  close() {
    this.dialogRef.close(true)
  }
}