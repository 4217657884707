<app-header [oneHeader]=header [adminFlag]="true"></app-header>
<div style="height: 90vh; background-color:#F8F8F8">
    <div style="display: flex; gap: 7vw; justify-content: center;align-items: center;height: 100%;">
        <div style="padding: 20px;" [ngClass]="{'content-admin': !adminFlag}" id="parent" (click)="handleClick($event)">
            <p><b style="font-size: 24px;">Users</b></p>
            <div class="verticalItemsBox">
                <span class="manageUserButton child" style="cursor:pointer;" (click)="changeCompRoute('user-list')">
                    <p style="margin: 0; padding: 6px 4px" [ngClass]="{'content-admin': !adminFlag}">Manage Users</p>
                </span>
                <!-- <span style="cursor:pointer;" (click)="changeCompRoute('add-user')">Add New User</span> -->
                <button style="border: none; background: none; padding: 0; height: 50px; width:40%;"
                    (click)="changeCompRoute('addUser')" class="child" [ngClass]="{'content-admin': !adminFlag}">
                    <img style="width: 100%;" src="../../../../assets/addNewUser.svg" alt="add new user">
                </button>
                <!-- <img src="../../../../assets/raisedHand.svg" alt="" *ngIf="userReachoutFlag"
                    class="reach_out_home_notify"> -->
            </div>
        </div>
        <div style="padding: 20px;">
            <p><b style="font-size: 24px;">Games</b></p>
            <div class="verticalItemsBox">
                <img style="height: 65px; width:50%; cursor:pointer;" (click)="changeCompRoute('digital')"
                    src="../../../../assets/digital_game_btn.svg" />
                <img style="height: 65px; width:50%; cursor:pointer;" (click)="changeCompRoute('physical')"
                    src="../../../../assets/physical_game_btn.svg" />
                <!-- <span (click)="compRoute = 'game'" >Digital Games</span> -->
                <!-- <span>Physical Games</span> -->
            </div>
        </div>
    </div>
    <!-- <input type="file" name="" id="" (change)="onImageChange($event)" > -->
</div>
<app-game-management *ngIf="compRoute == 'game'" (backEmitter)="changeCompRoute($event)"></app-game-management>