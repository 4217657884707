<div class="reach_out_dialog_main_div">
    <div
        style="display: flex; justify-content: center; background-color: #FFE5E0; position:absolute; top: 0; left: 0; right: 0;" *ngIf="errFlag">
        <span class="err_ele_span" style="margin: 4px 0;">
            <img src="../../../assets/error_icon.svg" alt="">
            <span>Please select or write your requirement</span>
        </span>
    </div>
    <div class="reach_out_heading">Reachout</div>
    <div class="reach_out_sub_heading">Let us know your requirement and we will get back to you soon.</div>
    <div class="reachout_reasons_main_div">
        <button *ngFor="let item of reachReasons;let i = index" [ngClass]="item.selected?'btn_select_active':''"
            (click)="item.selected = !item.selected">{{item.txt}}</button>
    </div>
    <div class="textarea_main_div">
        <textarea name="queries" id="queries" placeholder="Type in if you have other queries…"
            [(ngModel)]="queryTxt"></textarea>
    </div>
    <div style="text-align: center;margin-top: 1.5rem;">
        <button class="btn_custom" (click)="submitReachout()">Submit</button>
    </div>
</div>