import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DataSharingServiceService {

  constructor() { 
  }
  selectedGameForConfig:any=[]
  sessionData:any={}
  currentSelect:any = null
  totalConfigDone:any = []
  sessionTempEditData:any = {}
  newSetup:boolean = true
  lastGameConfigId:any = null
  gameEditAllDelete:boolean = false
  gameEditDeleteId:any = null
  exploreGameSelection:any = ''
  userName:any= ''
  addEditUser= '';
  toastCount:any = 0
  userData:any={};
  selected:any
  lastUrlForGameResource:any = null

  expireDate:any


  myGames:any = null



  dashBoradRedir:any = null

  timeSlotData:any
  role_id_name:any={
    1: 'Super Admin',
    2: 'Content Admin',
    3: 'Facilitator'
  }
  gameContentData:any = null



  gameDeleteContent:any = null

}
