<app-header [adminFlag]="true"></app-header>
<div class="activity_wrapper_main_cointainer">
    <div class="d-flex">
        <div class="sup_main_heading_div mlr_2rem">
            <img class="back_icon" (click)="navigateBack()"
                src="../../../../assets/img/arrow_back_ios_FILL0_wght400_GRAD0_opsz48.svg" />
            <span>&nbsp;</span>
            <span>Reports</span>
            <span>&nbsp;</span>
            <span>/</span>
            <span>&nbsp;</span>
            <span>{{userName}}</span>
        </div>
    </div>
    <div class="" style="margin-top: 20px;">
        <app-activity-report (nameEmitter)="nameHandler($event)" [userData]="this.selected"></app-activity-report>
    </div>
</div>