<div style="position: relative;">
    <div class="header_main_div">
        <span>{{data.name}}</span>
        <img src="../../../../assets/close_preview.svg" alt="" (click)="close()">
    </div>
    <div class="pdf_main_div" *ngIf="viewFlag.pdf">
        <pdf-viewer [src]="src" [rotation]="0" [original-size]="false" [show-all]="true" [fit-to-page]="false"
            [zoom]="0" [zoom-scale]="zoomScale" [stick-to-page]="true" [render-text]="true"
            [external-link-target]="'blank'" (after-load-complete)="spinLoader = false" [autoresize]="true"
            [show-borders]="true" class="pdf-viewer"></pdf-viewer>
    </div>
    <div class="video_main_div" *ngIf="viewFlag.video">
        <video controls [muted]="'muted'" id="videoPlayer" controlsList="nodownload" disablePictureInPicture
            #videoPlayer style="width: 100%;" autoplay src="https://newtestbucket.s3.ap-south-1.amazonaws.com/animal_farm_1.mp4" type="video/mp4" *ngIf="!videoSizeFlag">
            Browser not supported
        </video>
        <!-- <video controls [muted]="'muted'" id="videoPlayer" controlsList="nodownload" disablePictureInPicture
            #videoPlayer style="width: 100%;" autoplay [src]="src" type="video/mp4" *ngIf="!videoSizeFlag">
            Browser not supported
        </video> -->
        <div *ngIf="videoSizeFlag" style="text-align: center;color: red;">
            Video is too big, Can't preview the file.
        </div>
    </div>
    <div class="images_main_div" *ngIf="viewFlag.images">
        <img [src]="src" alt="">
    </div>
    <div class="ppt_main_div" *ngIf="viewFlag.ppt">
        <pdf-viewer [src]="src" [rotation]="0" [original-size]="false" [show-all]="true" [fit-to-page]="false"
            [zoom]="0" zoom-scale="page-fit" [stick-to-page]="true" [render-text]="true"
            [external-link-target]="'blank'" (after-load-complete)="spinLoader = false" [autoresize]="true"
            [show-borders]="true" class="pdf-viewer"></pdf-viewer>
    </div>
    <div class="spinerMain" *ngIf="spinLoader">
        <div class="spin"></div>
    </div>
</div>
<script>
    document.addEventListener('DOMContentLoaded', function() {
        var video = document.getElementById('videoPlayer');
        var observer = new IntersectionObserver(function(entries) {
            if (entries[0].isIntersecting) {
                video.setAttribute('preload', 'auto');
                observer.disconnect();
            }
        }, { threshold: 0.25 });

        observer.observe(video);
    });
</script>
<style>
    .header_main_div {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .header_main_div span {
        font-weight: 500;
        font-size: 22px;
    }

    .header_main_div img {
        cursor: pointer;
    }

    .pdf_main_div {
        height: 85vh;
        width: 51vw;
        border: 1px solid #707070;
        max-width: 606px;
    }

    .ppt_main_div {
        height: 51vh;
        width: 85vw;
        border: 1px solid #707070;
        max-width: 60vw;
        overflow-y: hidden;
    }

    .pdf-viewer {
        width: 100%;
        height: 100%;
    }

    .video_main_div {
        max-height: 80vh;
        width: 70vw;
    }

    .images_main_div {
        max-height: 80vh;
        max-width: 70vw
    }

    .images_main_div img {
        max-width: 70vw;
        min-width: 500px;
        border: 1px solid #707070;
    }


    .spinerMain {
        background: transparent;
        /* Make it white if you need */
        color: #fcbe24;
        padding: 0 24px;
        margin: 0;
        /* height: 100vh; */
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
            Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        position: absolute;
        width: 100%;
        top: 0;
        bottom: 0;
    }

    .spin {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 5px solid #817c7c;
        border-top: 5px solid black;
        border-bottom: 5px solid black;
        animation: round 3s linear infinite;
    }

    @keyframes round {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
</style>