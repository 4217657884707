import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: 'root' })
export class DashboardService {

    public selectedSession:any=null;
    public selectedArchiveSession:any=null;
    public editStepFalg:any =null;
    public archiveFlag:boolean = false;
    public editStep3:any = null
    pyGameIdName:any = {
        8:'Human Matrix',
        9:'String Theory',
        10:'Stromshelter',
        11:'Assemblyline',
        12:'Magic Mats',
        13:'Stranded'
      }
    constructor(private http:HttpClient){}

    public getScheduledData()
    {
        return this.http.get(environment.baseUrl+'api/digital/games/session/scheduled/list');
    }

    public deleteSession(id:any)
    {
        return this.http.delete(environment.baseUrl+'api/digital/games/session/delete/'+id);
    }
    public deleteCurrentSession(id:any)
    {
        return this.http.delete(environment.baseUrl+'api/digital/games/inbetween/session/delete/'+id);
    }
    public getArchiveVCDtls(program_id:any){
        return this.http.get(environment.baseUrl+'api/digital/games/vc/archived/details?program_id='+program_id)
    }
    public getArchiveWHDtls(program_id:any){
        return this.http.get(environment.baseUrl+'api/digital/games/wh/archived/details?program_id='+program_id)
    }
    public getArchiveBNDtls(program_id:any , round:any){
        return this.http.get(environment.baseUrl+'api/digital/games/bn/archived/details?program_id='+program_id+'&round=Round '+round)
    }
    public getArchiveAFDtls(program_id:any){
        return this.http.get(environment.baseUrl+'api/digital/games/af/archived/details?program_id='+program_id)
    }
    getDashboardDtls(){
        return this.http.get(environment.baseUrl +'api/digital/games/session/dashboard/details')
    }
}   