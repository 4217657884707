import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { DataSharingServiceService } from '../services/data-sharing-service.service';
import { HttpService } from '../services/http.service';
export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}
@Injectable({
  providedIn: 'root'
})
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {
  constructor(private dataSharing: DataSharingServiceService,private httpSv:HttpService) { }
  canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
    // if there are no pending changes, just allow deactivation; else confirm first
    if (component.canDeactivate())
      return true
    else {
      const text:string = this.dataSharing.gameEditAllDelete?'Are you sure you want to leave the session setup? This action will result in the deletion of the current session. Please confirm your decision.':'Are you sure you want leave the session set-up ? You have to finish this set-up with in 15 mins to save these time slots'
      if (confirm(text)) {
        this.dataSharing.selectedGameForConfig = []
        this.dataSharing.sessionData = {}
        this.dataSharing.currentSelect = null
        this.dataSharing.totalConfigDone = []
        this.dataSharing.sessionTempEditData = {}
        this.dataSharing.newSetup = true
        this.dataSharing.lastGameConfigId = null
        this.dataSharing.timeSlotData = null
        this.dataSharing.gameEditAllDelete = false
        this.dataSharing.gameEditDeleteId = null
        // this.httpSv.deleteBookedSlot({ program_id: sessionStorage.getItem('epidt') }).subscribe((res: any) => {
        //   sessionStorage.removeItem('epidt')
        //   this.router.navigate(['/scheduled'])
        // })
        return true
      }
      else return false
    }
    // NOTE: this warning message will only be shown when navigating elsewhere within your angular app;
    // when navigating away from your angular app, the browser will show a generic warning message
    // see http://stackoverflow.com/a/42207299/7307355
    // ;
  }
}